// "button" | "checkbox" | "color" | "date" | "datetime-local" | "email" | "file" | "hidden" | "image" | "month" | "number" | "password" | "radio" | "range" | "reset" | "search" | "submit" | "tel" | "text" | "time" | "url" | "week"

import type { StripeCardElement } from '@stripe/stripe-js';

export enum EInputTheme {
  /** $c-accent-alt background */
  default,
  /** white background */
  alt,
}

export const TYPE_TEXT = 'text';
export const TYPE_NUMBER = 'number';
export const TYPE_PASSWORD = 'password';
export const TYPE_EMAIL = 'email';
export const TYPE_TEXTAREA = 'textarea';
export const TYPE_STRIPE_CARD = 'stripe-card';
export const TYPE_SELECT = 'select';
export const TYPE_CHECKBOX = 'checkbox';
export const TYPE_SUBMIT = 'submit';
export const TYPE_BUTTON = 'button';

export type TYPE =
  | typeof TYPE_TEXT
  | typeof TYPE_PASSWORD
  | typeof TYPE_EMAIL
  | typeof TYPE_TEXTAREA
  | typeof TYPE_SELECT
  | typeof TYPE_CHECKBOX
  | typeof TYPE_SUBMIT
  | typeof TYPE_BUTTON
  | typeof TYPE_STRIPE_CARD;

export type Value = string | number | boolean | StripeCardElement | undefined;

export type SelectValue = string;
