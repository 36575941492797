import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TSectionSteps } from '../../types';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent {
  @Input() steps!: TSectionSteps;

  constructor() {}
}
