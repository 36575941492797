import StaryDream from './StaryDream';
import WhosBirthdayTomorrow from './WhosBirthdayTomorrow';
import WelcomeToTheWorld from './WelcomeToTheWorld';
import type { TBookReviews } from './reviews/types';

const Books = [WhosBirthdayTomorrow, WelcomeToTheWorld, StaryDream];

export const Reviews = Books.reduce<TBookReviews>((arr, book) => {
  arr.push(...book.reviews);

  return arr;
}, []);

export default Books;
