import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GiftThanksPageComponent } from './gift-thanks-page.component';
import { FooterModule } from './components/footer/footer.module';
import { HeaderModule } from './components/header/header.module';
import { ContentModule } from './components/content/content.module';

@NgModule({
  declarations: [GiftThanksPageComponent],
  imports: [CommonModule, FooterModule, HeaderModule, ContentModule],
})
export class GiftThanksPageModule {}
