import type { AfterContentInit, OnDestroy, OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Injector,
  Input,
  forwardRef,
} from '@angular/core';
import { Logger } from 'src/app/services/logger.service';
import type { ControlValueAccessor, FormControl } from '@angular/forms';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import Subscriber from 'src/app/subscriber';
import { EInputTheme } from '../../interfaces';
import { BehaviorSubject } from 'rxjs';
import { pairwise } from 'rxjs/operators';
import { checkControlShowError } from 'src/app/functions';
import uniqueId from 'lodash/uniqueId';
import lowerFirst from 'lodash/lowerFirst';
import type {
  IBookSelectInput,
  IBookSelectInputOption,
} from 'src/app/data/books/inputs/select/types';

const log = new Logger('SelectImageTextComponent');
const sub = new Subscriber();

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectImageTextComponent),
  multi: true,
};

@Component({
  selector: 'app-select-image-text',
  templateUrl: './select-image-text.component.html',
  styleUrls: ['./select-image-text.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectImageTextComponent
  implements OnInit, ControlValueAccessor, AfterContentInit, OnDestroy
{
  @Input() public name!: IBookSelectInput['name'];

  @Input() public placeholder!: IBookSelectInput['placeholder'];

  @Input() public label!: IBookSelectInput['label'];

  @Input() public error?: IBookSelectInput['error'];

  @Input() public disabled: boolean = false;

  @Input() public options!: IBookSelectInput['options'];

  @Input() public theme = EInputTheme.default;

  @Input() set value(v: IBookSelectInput['value']) {
    if (v !== this._value.value) {
      log.info('value set', v);
      this._value.next(v);
      this.onChange(v);
      this.onTouched();
    }
  }

  get value(): IBookSelectInput['value'] {
    return this._value.value;
  }

  private _value = new BehaviorSubject<IBookSelectInput['value']>('');

  public id?: string;

  private _isActive = new BehaviorSubject(false);

  public isActive = this._isActive.asObservable();

  private _timer?: number;

  public control?: FormControl;

  public option = new BehaviorSubject<IBookSelectInputOption | undefined>(
    undefined,
  );

  public readonly EInputTheme = EInputTheme;

  constructor(private injector: Injector) {
    sub.push(
      this._value.subscribe((v) => {
        this.option.next(this.options?.find((o) => o.value === v));
      }),
    );
  }

  public get showError(): boolean {
    return checkControlShowError(this.control);
  }

  // on host click handler
  @HostListener('click')
  public onClick(): void {
    log.info('onClick', this.value);
    this._toggle();
  }

  @HostListener('mouseenter')
  private _onMouseenter(): void {
    if (!this._isActive.value) return;

    window.clearTimeout(this._timer);
  }

  @HostListener('mouseleave')
  private _onMouseleave(): void {
    if (!this._isActive.value) return;

    this._timer = window.setTimeout(() => {
      this._hide();

      delete this._timer;
    }, 200);
  }

  ngOnInit(): void {
    setTimeout(() => (this.id = this._getId()));

    sub.push(
      this._isActive.pipe(pairwise()).subscribe(([prevIsActive, isActive]) => {
        const isActiveChanged = prevIsActive !== isActive;

        // each attempt to set isActive as `true` we should clear current timeout that's about to set `false`
        if (isActive) {
          window.clearTimeout(this._timer);
        }

        if (!isActiveChanged) return;

        if (!isActive) {
          this.onTouched();
        }
      }),
    );
  }

  ngAfterContentInit(): void {
    this._initControl();
  }

  ngOnDestroy(): void {
    sub.unsubscribe();
  }

  private _initControl(): void {
    const control = this.getControl();

    if (control) {
      log.info('value set by control', control.value);
      this._value.next(control.value);

      control.valueChanges.subscribe((v: IBookSelectInput['value']) => {
        log.info('value set by control', v);
        this._value.next(v);
      });
    } else {
      log.info('control is not defined');
    }
  }

  private getControl(): FormControl | undefined {
    const ngControl = this.injector.get(NgControl, null);

    if (ngControl) {
      this.control = ngControl.control as FormControl;
    } else {
      // Component is missing form control binding
    }

    return this.control;
  }

  // ngModel implementation
  writeValue(value: IBookSelectInput['value']): void {
    if (value !== this._value.value) {
      this._value.next(value);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public onChange(value: IBookSelectInput['value']): void {}

  public onTouched(): void {}

  // functionality
  private _hide(): void {
    this._isActive.next(false);
  }

  private _show(): void {
    this._isActive.next(true);
  }

  private _toggle(): void {
    const isActive = this._isActive.value;

    if (isActive) {
      this._hide();
    } else {
      this._show();
    }
  }

  private _getId(id: number | string = uniqueId()): string {
    return `${lowerFirst(this.constructor.name)}_${id}`;
  }
}
