import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-block-email-phone-card',
  templateUrl: './block-email-phone-card.component.html',
  styleUrls: ['./block-email-phone-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockEmailPhoneCardComponent {
  // constructor() {}
}
