<div class="wrap">
  <app-section-header-gap
    class="wrap__section-header-gap"
  ></app-section-header-gap>
  <app-section-gift-header
    class="wrap__section-gift-header"
  ></app-section-gift-header>
  <app-section-gift-use-cases
    class="wrap__section-use-cases"
  ></app-section-gift-use-cases>
  <app-section-steps
    class="wrap__section-steps"
    title="ギフトコードでの絵本の贈りかた"
    [steps]="sectionSteps"
  ></app-section-steps>
  <app-section-gift-faq
    class="wrap__section-faq"
  ></app-section-gift-faq>
  <app-section-gift-books
    class="wrap__section-gift-books"
    [books]="Books"
    [(alias)]="alias"
    [(cover)]="cover"
    [(wrapping)]="wrapping"
  ></app-section-gift-books>
  <app-section-gift-cart
    class="wrap__section-gift-cart"
    [book]="book"
    [cover]="cover"
    [wrapping]="wrapping"
  ></app-section-gift-cart>
  <app-section-gift-checkout
    id="checkout"
    class="wrap__section-checkout"
    [alias]="alias"
    [cover]="cover"
    [wrapping]="wrapping"
  ></app-section-gift-checkout>
</div>
