import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionDucksComponent } from './section-ducks.component';

@NgModule({
  declarations: [SectionDucksComponent],
  imports: [CommonModule],
  exports: [SectionDucksComponent],
})
export class SectionDucksModule {}
