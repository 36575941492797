import { environment } from 'src/environments/environment';

export const BASE_HREF = `${location.origin}/`;

export const CURRENCY = '$';

export const LINK_INSTAGRAM = 'https://www.instagram.com/buki_en/';
export const LINK_ORDER_CONTACT = '/ordercontract/';

export const STRIPE_PUBLIC_KEY_TEST =
  'pk_test_51KRAzsIompcHFxFedVsFexVbQdQBF6jPnGMJ08S90vPi5uOom5ocSIqQAPzJLVPCyGSRdb9H700ErR0uJZMtxg7M00sCbXRELy';
export const STRIPE_PUBLIC_KEY_LIVE =
  'pk_live_51KRAzsIompcHFxFea94UV1UKPjuDDslhrR4WV7jcR1N4pF3grmj7jPOCQgCZ6cGMSOACYh46DoKVfAa566QvYjUq007RhpixY3';

export const STRIPE_PUBLIC_KEY = environment.production
  ? STRIPE_PUBLIC_KEY_LIVE
  : STRIPE_PUBLIC_KEY_TEST;
export const API_URL = 'https://us-central1-buki-st12020.cloudfunctions.net/';
