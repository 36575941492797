import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-tab-point',
  templateUrl: './tab-point.component.html',
  styleUrls: ['./tab-point.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabPointComponent {
  @Input() imgSrc!: string;

  // constructor() {}
}
