<div
  class="container"
  [ngClass]="{
    container_active: active && !disabled,
    container_disabled: disabled
  }"
  (click)="onClick()"
>
  <div class="container__section">
    <div class="title">{{ name | titlecase }}</div>
  </div>
  <div class="container__section">
    <div
      class="description"
      [innerHTML]="description"
    ></div>
    <div class="price">
      <app-price
        [currency]="price.currency"
        [price]="price.value"
        [discount]="discount"
        size="xl"
      ></app-price>
    </div>
  </div>
  <svg-icon
    class="container__tick"
    src="assets/images/icons/order-main-toggle-cards-item-tick.svg"
    [applyClass]="true"
  ></svg-icon>
</div>
