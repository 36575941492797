import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { IBookPrice } from 'src/app/data/books/prices/types';
import { BehaviorSubject } from 'rxjs';
import Subscriber from 'src/app/subscriber';

const sub = new Subscriber();

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductComponent implements OnInit, OnDestroy {
  @Input() id: string;

  @Input() title: string;

  @Input() specs: { [key: string]: string };

  @Input() price?: IBookPrice;

  @Input() photo: string;

  @Input() discount?: string;

  public isDesktop = new BehaviorSubject<boolean>(false);

  constructor(private _media: MediaObserver) {}

  ngOnInit(): void {
    sub.push(
      this._media.asObservable().subscribe(() => {
        this._updateIsDesktop();
      }),
    );
  }

  ngOnDestroy(): void {
    sub.unsubscribe();
  }

  private _updateIsDesktop(): void {
    this.isDesktop.next(this._media.isActive('gt-sm'));
  }
}
