import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ITeamMember } from 'src/app/data/team/types';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryComponent {
  @Input() members!: ITeamMember[];

  constructor() {}
}
