<div class="wrap">
  <app-section-header-gap
    class="wrap__section-header-gap"
  ></app-section-header-gap>
  <app-section-gift-books
    class="wrap__section-gift-books"
    [books]="Books"
    [(alias)]="alias"
    [(cover)]="cover"
    [(wrapping)]="wrapping"
  ></app-section-gift-books>
  <app-section-gift-cart
    class="wrap__section-gift-cart"
    [book]="book"
    [cover]="cover"
    [wrapping]="wrapping"
  ></app-section-gift-cart>
  <app-section-gift-checkout
    id="checkout"
    class="wrap__section-checkout"
    [alias]="alias"
    [cover]="cover"
    [wrapping]="wrapping"
    [type]="EGiftOrderType.special"
  ></app-section-gift-checkout>
</div>
