<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div class="container__title">
        <app-image-title type="reviews"></app-image-title>
      </div>
      <div class="container__content">
        <div
          class="yotpo yotpo-main-widget"
          [attr.data-product-id]="bookExternalId"
          [attr.data-price]="bookPrice"
          data-currency="USD"
          [attr.data-name]="caption"
          [attr.data-url]="pageUrl"
          [attr.data-image-url]="pageOrigin + photo"
        ></div>
      </div>
    </div>
  </div>
  <i class="wrap__monster"></i>
</div>
