import type { OnDestroy } from '@angular/core';
import { Component, Output } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { LINK_INSTAGRAM } from 'src/app/constants';
import { environment } from 'src/environments/environment';
import Books from 'src/app/data/books';
import { MediaObserver } from '@angular/flex-layout';
import Subscriber from 'src/app/subscriber';
import { BehaviorSubject } from 'rxjs';
import { HeaderService } from 'app/services/header.service';
import { filter } from 'rxjs/operators';
import {
  ROUTE_PATH_ABOUT,
  ROUTE_PATH_CART,
  ROUTE_PATH_COLLABORATION,
  ROUTE_PATH_CONTACTS,
  ROUTE_PATH_FAQ,
  ROUTE_PATH_GIFT_BUY,
  ROUTE_PATH_INDEX,
} from 'app/app-routing.constants';
import { booksToNavigationItemChildren } from './components/navigation-item/utils';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy {
  @Output() topbarHeightChange = new BehaviorSubject(0);

  public isMenuActive: boolean;

  public readonly environment = environment;

  public readonly booksChildren = booksToNavigationItemChildren(Books);

  public readonly ROUTE_PATH_INDEX = ROUTE_PATH_INDEX;

  public readonly LINK_INSTAGRAM = LINK_INSTAGRAM;

  public readonly ROUTE_PATH_GIFT_BUY = ROUTE_PATH_GIFT_BUY;

  public readonly ROUTE_PATH_FAQ = ROUTE_PATH_FAQ;

  public readonly ROUTE_PATH_ABOUT = ROUTE_PATH_ABOUT;

  public readonly ROUTE_PATH_CONTACTS = ROUTE_PATH_CONTACTS;

  public readonly ROUTE_PATH_COLLABORATION = ROUTE_PATH_COLLABORATION;

  public readonly ROUTE_PATH_CART = ROUTE_PATH_CART;

  private readonly _sub = new Subscriber();

  private _timeoutId?: number;

  constructor(
    public userService: UserService,
    private router: Router,
    public media: MediaObserver,
    public headerService: HeaderService,
  ) {
    this._sub.push(
      this.router.events
        .pipe(
          filter(
            (event): event is NavigationStart =>
              event instanceof NavigationStart,
          ),
        )
        .subscribe((_event) => {
          this.menuClose();
        }),
    );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  public getIconItemText(value: string): string | undefined {
    if (this.media.isActive('gt-md')) {
      return value;
    }

    return undefined;
  }

  public menuShow() {
    this.isMenuActive = true;
  }

  public menuClose() {
    this.isMenuActive = false;
  }

  public menuToggle() {
    if (this.isMenuActive) this.menuClose();
    else this.menuShow();
  }

  public onMouseenter() {
    // const delay = 300;

    this._clearTimeout();

    // if (!this.isMenuActive) {
    //   this._timeoutId = window.setTimeout(() => {
    //     delete this._timeoutId;
    //     this.menuShow();
    //   }, delay);
    // }
  }

  public onMouseleave() {
    const delay = 500;

    this._clearTimeout();

    if (this.isMenuActive) {
      this._timeoutId = window.setTimeout(() => {
        delete this._timeoutId;
        this.menuClose();
      }, delay);
    }
  }

  public onBackdropClick() {
    this._clearTimeout();
    this.menuClose();
  }

  private _clearTimeout(): void {
    window.clearTimeout(this._timeoutId);
    delete this._timeoutId;
  }
}
