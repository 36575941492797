import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EButtonIcon } from 'src/app/shared/button/types';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  @Input() nextText!: string;

  @Input() isBackDisabled: boolean = false;

  @Input() isNextDisabled: boolean = false;

  @Output() back = new EventEmitter<void>();

  @Output() next = new EventEmitter<void>();

  public nextIcons = [EButtonIcon['arrow-right']];

  // constructor() {}
}
