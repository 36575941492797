<div
  *ngIf="location"
  class="wrap"
>
  <div class="wrap__button">
    <button class="button">
      <img
        [src]="location.imgSrc"
        alt="#"
        class="button__icon"
      />
      <!-- <span class="button__text">{{ locations[active].name }}</span> -->
    </button>
  </div>
  <div
    *ngIf="expanded$ | async"
    class="wrap__container"
    @fade
  >
    <div
      #containerElement
      class="container"
    >
      <div class="container__title">Choose your country</div>
      <div class="container__content">
        <button
          *ngFor="let _location of locations"
          class="button"
          (click)="select(_location.url)"
          [disabled]="_location.url === location.url"
        >
          <img
            [src]="_location.imgSrc"
            alt="#"
            class="button__icon"
          />
          <span class="button__text">{{ _location.name }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
