export enum EGiftOrderType {
  standart,
  special,
}

export enum EGiftOrderStatus {
  created,
  paid,
  generated,
}

export enum EGiftOrderCodeType {
  standart,
  special,
}

export enum EFormShipFromValue {
  customer,
  buki,
}
