import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthPageComponent } from './auth-page.component';
import { AuthCardComponent } from './components/auth-card/auth-card.component';
import { SectionHeaderGapModule } from 'app/shared/section-header-gap/section-header-gap.module';
import { ImageTitleModule } from 'app/shared/image-title/image-title.module';
import { InputModule } from 'app/shared/input/input.module';
import { ButtonModule } from 'app/shared/button/button.module';
import { FormSignInComponent } from './components/form-sign-in/form-sign-in.component';
import { FormSignUpComponent } from './components/form-sign-up/form-sign-up.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormResetComponent } from './components/form-reset/form-reset.component';
import { FormSignUpFinalComponent } from './components/form-sign-up-final/form-sign-up-final.component';

@NgModule({
  declarations: [
    AuthPageComponent,
    AuthCardComponent,
    FormSignInComponent,
    FormSignUpComponent,
    FormResetComponent,
    FormSignUpFinalComponent,
  ],
  imports: [
    CommonModule,
    SectionHeaderGapModule,
    ImageTitleModule,
    InputModule,
    ButtonModule,
    TranslateModule,
  ],
})
export class AuthPageModule {}
