import { Component } from '@angular/core';
import { Reviews } from 'src/app/data/books';

@Component({
  templateUrl: './collaboration-page.component.html',
  styleUrls: ['./collaboration-page.component.scss'],
})
export class CollaborationPageComponent {
  public reviews = Reviews;

  // constructor() {}
}
