<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div class="container__title">
        <app-image-title type="cart">ご注文内容</app-image-title>
      </div>
      <div class="container__content">
        <div class="content">
          <div
            class="content__description"
            [innerHTML]="description"
          ></div>
          <div class="content__price">
            <app-price
              [price]="price.value"
              [currency]="price.currency"
              [shippingIncluded]="price.shippingIncluded"
              size="xl"
            ></app-price>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
