import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { FirebaseService } from 'app/services/firebase.service';
import { map, take } from 'rxjs/operators';
import { ROUTE_PATH_AUTH } from 'app/app-routing.constants';

@Injectable({
  providedIn: 'root',
})
export class MyPageGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _firebaseService: FirebaseService,
  ) {}

  /**
   * @param next
   * @param state
   */
  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._firebaseService.user$.pipe(
      take(1),
      map((user) => {
        if (user.isAnonymous) {
          return this._router.parseUrl(ROUTE_PATH_AUTH);
        }

        return true;
      }),
    );
  }
}
