<div class="container">
  <div class="container__background"></div>
  <div class="container__content">
    <div class="content">
      <div class="content__text">
        <div class="text">
          <p class="text__item">Make your child the star of the</p>
          <p class="text__item">story with easy-to-add photos!</p>
        </div>
      </div>
      <div class="content__button">
        <app-button
          color="accent-alt"
          size="sm"
          (buttonClick)="onButtonClick()"
          ><b>See All Books</b></app-button
        >
      </div>
    </div>
  </div>
</div>
