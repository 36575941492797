<app-field
  *ngIf="(id$ | async) as id"
  [label]="label"
  [error]="showError$ | async"
  [forId]="id"
>
  <div
    class="container"
    [ngStyle]="{
      justifyContent: justifyContent
    }"
  >
    <div
      *ngFor="let option of options"
      class="container__section"
    >
      <app-input-radio-color-option
        [color]="option.color"
        [active]="value === option.value"
        [theme]="theme"
        (optionClick)="value = option.value"
      ></app-input-radio-color-option>
    </div>
  </div>
</app-field>
