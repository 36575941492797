<app-field
  [label]="label"
  [error]="(showError && error) || undefined"
  [forId]="id"
>
  <div
    class="container"
    [ngClass]="{
      container_vertical: styleVertical
    }"
    [ngStyle]="{
      gridTemplateColumns: styleColumns
        ? 'repeat(' + styleColumns + ', 1fr)'
        : undefined,
      justifyContent: justifyContent
    }"
  >
    <div
      *ngFor="let option of options"
      class="container__section"
      [ngClass]="{
        container__section_img: !!option.imgSrc
      }"
    >
      <app-input-radio-option
        [label]="option.label"
        [imgSrc]="option.imgSrc"
        [imgExpandTopPx]="option.imgExpandTopPx"
        [imgExpandRightPx]="option.imgExpandRightPx"
        [imgExpandBottomPx]="option.imgExpandBottomPx"
        [imgExpandLeftPx]="option.imgExpandLeftPx"
        [active]="value === option.value"
        [styleCondensed]="styleCondensed"
        (optionClick)="value = option.value"
      ></app-input-radio-option>
    </div>
  </div>
</app-field>
