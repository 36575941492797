export enum EBookDescriptionTextItemType {
  regular,
  caption,
}

export interface IBookDescriptionTextItem {
  type: EBookDescriptionTextItemType;
  text: string;
}

export interface IBookDescriptionItem {
  imgSrc: string;
  text: IBookDescriptionTextItem[];
}

export type TBookDescription = IBookDescriptionItem[];
