import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IBookPrice } from 'src/app/data/books/prices/types';
import { Specs } from 'src/app/interfaces';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('specsCollapse', [
      state(
        '0',
        style({
          height: '*',
        }),
      ),
      state(
        '1',
        style({
          height: '50px',
        }),
      ),
      transition('0 => 1', [animate('150ms')]),
      transition('1 => 0', [animate('300ms')]),
    ]),
  ],
})
export class MobileComponent {
  @Input() id: string;

  @Input() title: string;

  @Input() specs: Specs;

  @Input() price: IBookPrice;

  @Input() photo: string;

  @Input() discount?: string;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter<void>();

  get specsKeys(): string[] {
    return Object.keys(this.specs);
  }

  public specsCollapsed = true;

  // constructor() {}

  public onClose() {
    this.close.emit();
  }

  public showSpecs() {
    this.specsCollapsed = false;
  }

  public hideSpecs() {
    this.specsCollapsed = true;
  }

  public onSpecsToggle() {
    this.specsCollapsed = !this.specsCollapsed;
  }
}
