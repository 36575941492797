import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllBooksComponent } from './all-books.component';
import { SectionHeaderGapModule } from 'src/app/shared/section-header-gap/section-header-gap.module';
import { SectionBooksModule } from 'src/app/shared/section-books/section-books.module';
import { SectionFeaturesModule } from 'src/app/shared/section-features/section-features.module';
import { SectionReviewsYotpoCommonModule } from 'app/shared/section-reviews-yotpo-common/section-reviews-yotpo-common.module';
import { SectionStepsModule } from 'src/app/shared/section-steps/section-steps.module';
import { SectionFaqModule } from 'src/app/shared/section-faq/section-faq.module';
import { SectionBannerMermaidModule } from 'app/shared/section-banner-mermaid/section-banner-mermaid.module';
import { SectionFilterModule } from '../../shared/section-filter/section-filter.module';

@NgModule({
  declarations: [AllBooksComponent],
  imports: [
    CommonModule,
    SectionHeaderGapModule,
    SectionBooksModule,
    SectionFeaturesModule,
    SectionReviewsYotpoCommonModule,
    SectionStepsModule,
    SectionFaqModule,
    SectionBannerMermaidModule,
    SectionFilterModule,
  ],
})
export class AllBooksModule {}
