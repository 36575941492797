import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BookFormComponent } from 'app/components/book-form/book-form.component';
import { NavigationStart, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-book-form-dialog',
  templateUrl: './book-form-dialog.component.html',
  styleUrls: ['./book-form-dialog.component.scss'],
})
export class BookFormDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      alias: BookFormComponent['alias'];
      inputs: BookFormComponent['inputs'];
      gift: BookFormComponent['gift'];
    },
    private _dialogRef: MatDialogRef<ThisType<BookFormDialogComponent>>,
    private _router: Router,
  ) {
    this._router.events
      .pipe(
        filter((e) => e instanceof NavigationStart),
        take(1),
      )
      .subscribe(() => {
        this._dialogRef.close();
      });
  }
}
