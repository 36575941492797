<div class="wrap">
  <app-section-header-gap
    class="wrap__section wrap__section_header-gap"
  ></app-section-header-gap>
  <app-section-text
    class="wrap__section wrap__section_text"
    [articles]="[
      {
        caption: 'Terms and Conditions',
        passages: [
          {
            items: [
              {
                type: 'text',
                text: 'Thank you for choosing STUDIO BUKI. We aim to fulfill your order to your satisfaction and in a timely manner. At STUDIO BUKI, we design and publish personalized children’s books, with each book being uniquely made to order. Our site is owned and operated by STUDIO BUKI, Inc (“We”, “Us”). If you have any questions or concerns, please contact us at yay@hellobuki.com.'
              },
              {
                type: 'text',
                text:
                  'Our Site, ' +
                  '<a target=' +
                  '_blank' +
                  ' href=' +
                  'https://hellobuki.com/' +
                  '>www.hellobuki.com</a> (the “Site“, “Site“), is governed by these Terms and Conditions which outline the use of our site and the terms under which we supply our products. Please read these carefully before using our site or ordering any products. By using our site and/or ordering products, you agree to be bound by these Terms and Conditions.'
              },
              {
                type: 'text',
                text: 'These Terms and Conditions apply to all sales made through our Site and supersede any other terms or conditions. If you do not agree with these Terms and Conditions, please do not use our Site. We may periodically update these Terms and Conditions, so please review them regularly. If you do not agree with any changes made, please stop using our Site. Continued use of our Site indicates acceptance of any changes made.'
              }
            ]
          },
          {
            items: [
              {
                type: 'caption',
                text: 'Definitions'
              },
              {
                type: 'ordered-list',
                items: [
                  {
                    text: 'The term “Buyer” refers to the individual specified in the Order.'
                  },
                  {
                    text: 'The “Contract” is made up of the Order and Order Confirmation.'
                  },
                  {
                    text: '“Faulty” indicates the presence of a defect or imperfection.'
                  },
                  {
                    text: 'The “Order” is your request for a Product from the Site.'
                  },
                  {
                    text: 'The “Price” includes the cost of the product, postage and packaging, and any applicable taxes at the time of the Order (excluding taxes and duties for which the Buyer is responsible under the ‘Pricing and Payment’ section of these Terms and Conditions), subject to any current promotional offers or discounts.'
                  },
                  {
                    text: 'The “Terms and Conditions” are the standard business terms outlined in this document.'
                  }
                ]
              }
            ]
          },
          {
            items: [
              {
                type: 'caption',
                text: 'Accessing and using the Site'
              },
              {
                type: 'text',
                text: 'It is prohibited to use the Site in an inappropriate or illegal manner or in violation of any applicable laws or licenses. You must follow any reasonable instructions we provide regarding the use of the Site.'
              },
              {
                type: 'text',
                text: 'By submitting your order, you acknowledge that we may evaluate your order and its contents for compliance with our guidelines and these Terms and Conditions. We reserve the right to refuse to process an order if we believe that its contents violate these Terms and Conditions or our guidelines.'
              }
            ]
          },
          {
            items: [
              {
                type: 'caption',
                text: 'Property rights and rights of use'
              },
              {
                type: 'text',
                text: 'All intellectual property rights (meaning patents, registered and unregistered trademarks and service marks, domain names, registered designs and design rights, copyright, database rights and moral rights and rights in computer programs) in and to the Site and all content and materials contained in the Site (“Site Content”) are owned by and shall remain owned by Us or Our licensors.'
              },
              {
                type: 'text',
                text: 'Site Content may only be used for your personal, non-commercial purposes and shall not be reproduced, modified, copied, altered, distributed, framed, republished, displayed, transmitted or sold in any form of by any means in whole or in part. You may not remove any copyright or other proprietary notices contained in any Site Content.'
              }
            ]
          },
          {
            items: [
              {
                type: 'caption',
                text: 'Orders and Specifications'
              },
              {
                type: 'text',
                text: 'To place an Order, follow the procedure outlined on the Site. The Site displays the Price of each Product and the payment process.'
              },
              {
                type: 'text',
                text: 'All Products are subject to availability and our approval of your order.'
              },
              {
                type: 'text',
                text: 'We may refuse any Order without providing a reason. An Order is not considered accepted by us until we have unconditionally confirmed it in the Order Confirmation.'
              },
              {
                type: 'text',
                text: 'The Order Confirmation includes details of your Order, the Price, and an estimated delivery time. It is your responsibility to inform us of any errors or mistakes in the Order as soon as possible. Failure to do so may result in the wrong Order being sent to you. Delivery times and dates are estimates only. We will make reasonable efforts to deliver goods within the specified time, but we are not liable for any failure to do so.'
              },
              {
                type: 'text',
                text: 'We strive to accurately display and describe the printed colors of Products on our Site, but we cannot guarantee that the supplied colors will exactly match those on your monitor or mobile phone.'
              },
              {
                type: 'text',
                text: 'We may revise, discontinue or modify products or services at any time without notice, and products may become unavailable. We are not liable if a product or service is unavailable.'
              }
            ]
          },
          {
            items: [
              {
                type: 'caption',
                text: 'Pricing and Payment'
              },
              {
                type: 'text',
                text: 'Your Products will be delivered to the address specified in the Order, usually by 1st class postage unless otherwise agreed.'
              },
              {
                type: 'text',
                text: 'We will try to process your Order and make your Product within the stated time period for each item. However, manufacturing times may vary and delivery times are estimates only. Delivery times are not guaranteed and we are not liable for any loss or expenses resulting from delivery delays.'
              },
              {
                type: 'text',
                text: 'When you receive the Product(s), inspect them for defects or non-conformity before signing for good condition. If you sign for good condition, you are accepting that the goods are in acceptable condition. Any packages that are accepted but not signed for are considered to be in good condition.'
              },
              {
                type: 'text',
                text: 'If you see any damage to the parcel(s), sign for them as damaged. Otherwise, we cannot refund or replace the item beyond your statutory rights.'
              },
              {
                type: 'text',
                text: 'You must be available to accept delivery on the delivery date because the courier will only attempt re-delivery twice before returning the delivery to us at your expense. If delivery is refused or returned due to an incorrect address, you will be charged a return fee.'
              },
              {
                type: 'text',
                text: 'If you fail to accept delivery of the Order without a valid reason, we are not obligated to refund the price.'
              }
            ]
          },
          {
            items: [
              {
                type: 'caption',
                text: 'Risk and Property'
              },
              {
                type: 'text',
                text: 'The risk of damage or loss to your Order passes to you when it is delivered to the agreed address.'
              },
              {
                type: 'text',
                text: 'Even after delivery and the transfer of risk, ownership of the Order does not pass from us to you until we have received full payment of the Price and any other amounts due to us for the Order or any other Order between you and us.'
              }
            ]
          },
          {
            items: [
              {
                type: 'caption',
                text: 'Returns, refunds and rights of cancellation'
              },
              {
                type: 'text',
                text: 'Our books are custom-made to your specifications, making each product unique and non-returnable. You may only cancel an Order in the following situations:'
              },

              {
                type: 'ordered-list',
                items: [
                  {
                    text: 'If you cancel the Order by emailing yay@hellobuki.com before we start printing your Order. We will notify you by email when your Order is being printed, but this does not mean that you can cancel the Order before this notification because printing may have already started. Upon receiving your cancellation email, we will take reasonable steps to cancel your Order before we start printing it.'
                  },
                  {
                    text: 'If we fail to deliver the Order within 30 days of the date you placed it.'
                  },
                  {
                    text: 'If the Product is Faulty and you notify us as soon as possible after discovering the fault or defect (you are considered to have inspected the Product as soon as reasonably possible after delivery and no later than 15 days after receiving it).'
                  }
                ]
              },
              {
                type: 'text',
                text: 'If an Order is cancelled under condition 1, we will give you a full refund for your Order.'
              },
              {
                type: 'text',
                text: 'If an Order is cancelled under conditions 2 or 3, we will refund all amounts paid (including initial and re-delivery charges, if any) for the Order in question.'
              },
              {
                type: 'text',
                text: 'To cancel an Order, send an email to yay@hellobuki.com.'
              }
            ]
          },
          {
            items: [
              {
                type: 'caption',
                text: 'Disclaimers and limitation of liability'
              },
              {
                type: 'text',
                text: 'While we strive to ensure that the information on the Site (“Site Content”) is accurate and free of errors, we do not guarantee its accuracy or completeness. We may change the Site Content, including products, prices, and fees, at any time without notice. The Site Content may be outdated and we are not obligated to update it.'
              },
              {
                type: 'text',
                text: 'We will use reasonable skill and care in providing the Site. Some Site Content may come from third parties and we do not guarantee or take responsibility for its accuracy, completeness, currency or reliability.'
              },
              {
                type: 'text',
                text: 'Therefore, except as stated in these Terms and Conditions, the Site and all Site Content are provided “as is” without any representation or warranty, express or implied (by common law, custom, statute or otherwise). To the fullest extent permitted by law, we disclaim all other conditions, representations, statements and warranties (including any implied warranty that the Site or Site Content is fit for a particular purpose or that your use of the Site or Site Content will not infringe on the rights of any third party).'
              },
              {
                type: 'text',
                text: 'We do not guarantee that the Site, its Content or functions will be uninterrupted or error-free, that defects will be corrected, or that the Site or its server are free of viruses or other harmful components. We do not guarantee that the Site and its Content will meet your requirements or make any representations about the use or results of using any Site Content in terms of completeness, accuracy, currency, reliability or otherwise. We are not responsible for the security of the Site or any disruption to it, loss or corruption of material in transit, or loss or corruption of material or data when downloaded to any computer system.'
              }
            ]
          },
          {
            items: [
              {
                type: 'caption',
                text: 'Liability'
              },
              {
                type: 'text',
                text: 'We guarantee that any Product purchased from us through the Site will be of satisfactory quality and reasonably fit for the purposes for which similar products are commonly used. To the fullest extent permitted by law, we disclaim all other conditions, representations, statements and warranties, express or implied.'
              },
              {
                type: 'text',
                text: 'Our liability for any losses you suffer due to a breach of this Contract by us is limited to the net purchase price of the Product you purchased (excluding taxes and delivery costs).'
              },
              {
                type: 'text',
                text: 'These Terms and Conditions do not exclude or limit our liability for death or personal injury caused by our negligence, any loss you suffer due to your reliance on any fraudulent misrepresentation made by us, or any liability that cannot be excluded or limited under applicable law.'
              },
              {
                type: 'text',
                text: 'You agree that you use and browse the Site and use its Content at your own risk.'
              },
              {
                type: 'text',
                text: 'Subject to the above, you agree that we are not liable for: (a) any direct loss, claim or damage; (b) any indirect loss, claim or damage, or any punitive, special, incidental or consequential damages not directly associated with the incident causing your claim; or (C) any loss of profit, savings or data (whether direct or indirect), arising from or connected to the use of this Site or its Content, these Terms and Conditions, the Products, any failure or delay in using any part of the Site, its Content or services (including unavailability of the Site, its Content or services), or any use of or reliance on any Site Content or other information, material, software, products, services and related graphics obtained through the Site. This applies even if we have been warned of the possibility of such loss or damage.'
              },
              {
                type: 'text',
                text: 'We are not liable for any loss, damage, costs or expenses (including loss of profit) resulting directly or indirectly from our failure or delay in fulfilling any obligation under these Terms and Conditions due to events or circumstances beyond our reasonable control. This includes, but is not limited to, strikes, industrial action, power or equipment failure, government action or natural disasters.'
              },
              {
                type: 'text',
                text: 'The laws of your country of residence may not allow limitations or exclusions of liability for incidental or consequential damages, so the above limitations or exclusions may not apply to you.'
              },
              {
                type: 'text',
                text: 'Due to the inherent risks of using the internet, we are not liable for any damage or viruses that may infect your computer equipment or other property when using or browsing the Site. Downloading or acquiring any Site Content through the Site is done at your own risk and you are solely responsible for any damage to your computer system or loss of data resulting from downloading or acquiring any Site Content.'
              }
            ]
          },
          {
            items: [
              {
                type: 'caption',
                text: 'Indemnity'
              },
              {
                type: 'text',
                text: 'You agree to indemnify, keep indemnified, defend and hold Us and Our parent companies, subsidiaries, affiliates and each of our respective officers, directors, employees, owners, agents, contractors, partners, information providers and licensors harmless from and against any and all claims, damages, liability, demands, losses, costs and expenses (including legal fees) (whether or not foreseeable or avoidable) incurred or suffered by any of such parties and any claims or legal proceedings which are brought or threatened arising out of or in connection with your use of the Site, the Site Content or the services provided through the Site, your conduct in connection with the Site or services provided or with other users of the Site, any purchases, transactions, dealings or arrangements with any third party via the Site or on a third party site, or any violation of these General Terms of Use or of any law or the rights of any third party.'
              }
            ]
          },
          {
            items: [
              {
                type: 'caption',
                text: 'Copyright'
              },
              {
                type: 'text',
                text: 'All titles, books and products purchased from us or the Site are protected by copyright. The copyright belongs to us and the designers, graphic designers, illustrators, authors and others who worked on the respective work. Without written permission, their contents may not be reproduced or used in any form, in whole or in part. The legal limits of copyright, such as for personal use and citation under certain conditions, are reserved.'
              }
            ]
          },
          {
            items: [
              {
                type: 'caption',
                text: 'Termination'
              },
              {
                type: 'text',
                text: 'We may remove the Site or stop providing any services available through it at any time for any reason at our sole discretion.'
              },
              {
                type: 'text',
                text: 'We may also terminate your access to the Site for any reason at our sole discretion, with or without notice.'
              }
            ]
          },
          {
            items: [
              {
                type: 'caption',
                text: 'Data Protection and Privacy'
              },
              {
                type: 'text',
                text: 'By agreeing to these Terms and Conditions, you also agree to our Privacy Policy, which is an integral part of these Terms. We will handle any personal information we collect from you in accordance with this policy. Please make sure to read it.'
              }
            ]
          },
          {
            items: [
              {
                type: 'caption',
                text: 'Governing Law'
              },
              {
                type: 'text',
                text: 'This agreement is subject to the laws of the state where you were billed for our product or service. Any legal disputes must be filed within two years and will be resolved in a state or federal court in Santa Clara County, California. You and STUDIO BUKI agree to this and waive any objections.'
              }
            ]
          },
          {
            items: [
              {
                type: 'caption',
                text: 'Company Information'
              },

              {
                type: 'text',
                text: 'STUDIO BUKI, Inc.</br>970 W. 190TH STREET</br>SUITE 920</br>TORRANCE </br>California'
              },

              {
                type: 'text',
                text: '(585)628-8952'
              },
              {
                type: 'text',
                text: 'yay@hellobuki.com'
              }
            ]
          }
        ]
      }
    ]"
  ></app-section-text>
  <app-section-footer-banner
    class="wrap__section wrap__section_footer-banner"
  ></app-section-footer-banner>
</div>
