// * prices
// TODO
export const GIFT_WRAPPING_PRICE = 0;

export const BOOK_PRICE_STARRY_DREAM = 1999;

export const BOOK_PRICE_STARRY_DREAM_HARD_COVER = 2999;

export const BOOK_PRICE_WELCOME_TO_THE_WOLD = 1999;

export const BOOK_PRICE_WELCOME_TO_THE_WOLD_HARD_COVER = 2999;

export const BOOK_PRICE_WHOS_BIRTHDAY_TOMORROW = 1999;

export const BOOK_PRICE_WHOS_BIRTHDAY_TOMORROW_HARD_COVER = 2999;

// TODO
export const BOOK_PRICE_WHERE_IS_BIRTHDAY_CAKE = 4000;

export const BOOK_PRICE_WHERE_IS_BIRTHDAY_CAKE_HARD_COVER = 5300;

// TODO
export const BOOK_PRICE_STORY_OF_GRANDPA_GRANDMA = 4000;

export const BOOK_PRICE_STORY_OF_GRANDPA_GRANDMA_HARD_COVER = 5300;

// * aliases
/**
 * ! do not touch this constant (written in database)
 * * the correct value is `starry-dream`
 */
export const BOOK_ALIAS_STARRY_DREAM = 'starry-dream';

/**
 * ! do not touch this constant (written in database)
 * * the correct value is `welcome-to-the-world`
 */
export const BOOK_ALIAS_WELCOME_TO_THE_WOLD = 'welcome-to-the-world';

/**
 * ! do not touch this constant (written in database)
 * * the correct value is `whos-birthday-tomorrow`
 */
export const BOOK_ALIAS_WHOS_BIRTHDAY_TOMORROW = 'whos-birthday-tomorrow';

/**
 * ! do not touch this constant (written in database)
 * * the correct value is `where_is_birthday_cake`
 */
export const BOOK_ALIAS_WHERE_IS_BIRTHDAY_CAKE = 'where_is_birthday_cake';

/**
 * ! do not touch this constant (written in database)
 * * the correct value is `story-of-grandpa-grandma`
 */
export const BOOK_ALIAS_STORY_OF_GRANDPA_GRANDMA = 'story-of-grandpa-grandma';

// * id
/**
 * ! do not touch this constant (written in database)
 * * the correct value is 1
 */
export const BOOK_ID_STARRY_DREAM = 1;

/**
 * ! do not touch this constant (written in database)
 * * the correct value is 3
 */
export const BOOK_ID_WELCOME_TO_THE_WOLD = 3;

/**
 * ! do not touch this constant (written in database)
 * * the correct value is 2
 */
export const BOOK_ID_WHOS_BIRTHDAY_TOMORROW = 2;

/**
 * ! do not touch this constant (written in database)
 * * the correct value is 5
 */
export const BOOK_ID_WHERE_IS_BIRTHDAY_CAKE = 5;

/**
 * ! do not touch this constant (written in database)
 * * the correct value is 7
 */
export const BOOK_ID_STORY_OF_GRANDPA_GRANDMA = 7;

/**
 * Photo file names
 */
export const PHOTO_STARRY_DREAM_ORIGINAL: string = 'thumb_1840x1840_file1.jpeg';
export const PHOTO_STARRY_DREAM_EFFECT: string = 'effect_file1.jpeg';
export const PHOTO_WELCOME_TO_THE_WOLD: string =
  'welcome_to_the_wold_photo.jpeg';
export const PHOTO_WHOS_BIRTHDAY_TOMORROW: string =
  'whos-birthday-tomorrow_photo.jpeg';
export const PHOTO_WHERE_IS_BIRTHDAY_CAKE: string =
  'where-is-birthday-cake_photo.jpeg';
export const PHOTO_STORY_OF_GRANDPA_GRANDMA_MAIN: string =
  'story_of_grandpa_grandma_main.jpeg';
export const PHOTO_STORY_OF_GRANDPA_GRANDMA_SUB_1: string =
  'story_of_grandpa_grandma_sub_1.jpeg';
export const PHOTO_STORY_OF_GRANDPA_GRANDMA_SUB_2: string =
  'story_of_grandpa_grandma_sub_2.jpeg';
export const PHOTO_STORY_OF_GRANDPA_GRANDMA_SUB_3: string =
  'story_of_grandpa_grandma_sub_3.jpeg';
// * inputs
// name
export const INPUT_NAME_KIDS_NAME = 'kids-name';
export const INPUT_NAME_HERO_NAME = 'hero-name';

// birthday
export const INPUT_NAME_KIDS_BIRTHDAY = 'kids-birthday';

// birthday month
export const INPUT_NAME_KIDS_BIRTHDAY_MONTH = 'kids-birthday-month';

// birthday day
export const INPUT_NAME_KIDS_BIRTHDAY_DAY = 'kids-birthday-day';

// age to be

export const INPUT_NAME_AGE_TO_BE = 'age-to-be';

// age
export const INPUT_NAME_AGE = 'age';

// who's celebrating

export const INPUT_NAME_WHOS_CELEBRATING = 'whos-celebrating';

export const INPUT_NAME_SEASON = 'season';

export const INPUT_VALUE_ANIMALS = 'animals';

export const INPUT_VALUE_DINOS = 'dinos';

export const INPUT_VALUE_MONSTERS = 'monsters';

// gender
export const INPUT_NAME_GENDER = 'gender';

export const INPUT_VALUE_GENDER_BOY = 'boy';

export const INPUT_VALUE_GENDER_GIRL = 'girl';

// language
export const INPUT_NAME_LANGUAGE = 'language';

export const INPUT_VALUE_ENGLISH = 'en';

export const INPUT_VALUE_JAPANESE = 'jp';

// character
export const INPUT_NAME_CHARACTER = 'character';

export const INPUT_VALUE_CHARACTER_BOY1 = 'boy1';

export const INPUT_VALUE_CHARACTER_BOY2 = 'boy2';

export const INPUT_VALUE_CHARACTER_BOY3 = 'boy3';

export const INPUT_VALUE_CHARACTER_BOY4 = 'boy4';

export const INPUT_VALUE_CHARACTER_BOY5 = 'boy5';

export const INPUT_VALUE_CHARACTER_BOY6 = 'boy6';

export const INPUT_VALUE_CHARACTER_GIRL1 = 'girl1';

export const INPUT_VALUE_CHARACTER_GIRL2 = 'girl2';

export const INPUT_VALUE_CHARACTER_GIRL3 = 'girl3';

export const INPUT_VALUE_CHARACTER_GIRL4 = 'girl4';

export const INPUT_VALUE_CHARACTER_GIRL5 = 'girl5';

export const INPUT_VALUE_CHARACTER_GIRL6 = 'girl6';

// hair
export const INPUT_NAME_HAIR_STYLE = 'hairStyle';

export const INPUT_VALUE_HAIR_STYLE_STRAIGHT = 'straight';

export const INPUT_VALUE_HAIR_STYLE_CURL = 'curl';

// parents
export const INPUT_NAME_PARENTS = 'parents';

export const INPUT_VALUE_PARENTS_MOM_AND_DAD = 'parents1';

export const INPUT_VALUE_PARENTS_MOM_AND_MOM = 'parents2';

export const INPUT_VALUE_PARENTS_DAD_AND_DAD = 'parents3';

export const INPUT_VALUE_PARENTS_MOM = 'parents4';

export const INPUT_VALUE_PARENTS_DAD = 'parents5';

// TimeOfTheYear
export const INPUT_NAME_TIME_OF_THE_YEAR = 'timeOfTheYear';

export const INPUT_VALUE_TIME_OF_THE_YEAR_SPRING = 'spring';

export const INPUT_VALUE_TIME_OF_THE_YEAR_SUMMER = 'summer';

export const INPUT_VALUE_TIME_OF_THE_YEAR_AUTUMN = 'autumn';

export const INPUT_VALUE_TIME_OF_THE_YEAR_WINTER = 'winter';

// BornTime
export const INPUT_VALUE_BORN_TIME_NO_INPUT = 'noinput';

export const INPUT_VALUE_BORN_TIME_DAYTIME = 'daytime';

export const INPUT_VALUE_BORN_TIME_EVENING = 'evening';

// CoverType
export const INPUT_NAME_COVER = 'cover';

export const INPUT_VALUE_COVER_TYPE_SOFT = 'soft';

export const INPUT_VALUE_COVER_TYPE_HARD = 'hard';

// CoverImage
export const INPUT_NAME_COVER_IMAGE = 'coverImage';

export const INPUT_VALUE_COVER_IMAGE_A = 'A';

export const INPUT_VALUE_COVER_IMAGE_B = 'B';

// hair Book Where Is Birthday Cake
export const INPUT_VALUE_HAIR_STYLE_H01 = 'hairStyle_H01';

export const INPUT_VALUE_HAIR_STYLE_H02 = 'hairStyle_H02';

export const INPUT_VALUE_HAIR_STYLE_H03 = 'hairStyle_H03';

export const INPUT_VALUE_HAIR_STYLE_H04 = 'hairStyle_H04';

export const INPUT_VALUE_HAIR_STYLE_H05 = 'hairStyle_H05';

export const INPUT_VALUE_HAIR_STYLE_H06 = 'hairStyle_H06';

export const INPUT_VALUE_HAIR_STYLE_H07 = 'hairStyle_H07';

export const INPUT_VALUE_HAIR_STYLE_H08 = 'hairStyle_H08';

export const INPUT_VALUE_HAIR_STYLE_H09 = 'hairStyle_H09';

export const INPUT_VALUE_HAIR_STYLE_H10 = 'hairStyle_H10';

// glasses
export const INPUT_NAME_GLASSES = 'glasses';

// Gift
export const INPUT_NAME_GIFT = 'gift';

// wrapping
export const INPUT_NAME_WRAPPING = 'wrapping';

export const INPUT_VALUE_WRAPPING_STANDART = 'standart';

export const INPUT_VALUE_WRAPPING_GIFT = 'gift';

// planets
export const INPUT_NAME_PLANETS = 'planets';

export const INPUT_VALUE_PLANETS_0 = 'planet0';

export const INPUT_VALUE_PLANETS_1 = 'planet1';

export const INPUT_VALUE_PLANETS_2 = 'planet2';

export const INPUT_VALUE_PLANETS_3 = 'planet3';

export const INPUT_VALUE_PLANETS_4 = 'planet4';

export const INPUT_VALUE_PLANETS_5 = 'planet5';

// step
export const INPUT_NAME_STEP = 'step';

export const INPUT_VALUE_STEP_COVER_IMAGE = 'coverImage';

export const INPUT_VALUE_STEP_CHARACTER = 'character';

export const INPUT_VALUE_STEP_SUB_CHARACTER = 'subCharacter';

export const INPUT_VALUE_STEP_HOROSCOPE = 'horoscope';

export const INPUT_VALUE_STEP_TEXT = 'text';

export const INPUT_VALUE_STEP_PHOTO = 'photo';

export const INPUT_VALUE_STEP_SUB_PHOTO = 'subPhoto';

export const INPUT_VALUE_STEP_PLANET_STORY = 'planetStory';

export const INPUT_VALUE_STEP_STARMAP = 'starmap';

export const INPUT_VALUE_STEP_MESSAGE = 'message';

export const INPUT_VALUE_STEP_PARENTS = 'parents';

export const INPUT_VALUE_STEP_BIRTH_TIME = 'birthTime';

export const INPUT_VALUE_STEP_AGE_TO_BE_BIRTH_TIME = 'ageToBeBirthTime';

export const INPUT_VALUE_STEP_WHOS_CELEBRATING = 'whosCelebrating';

export const INPUT_VALUE_STEP_SEASON = 'season';

export const INPUT_VALUE_LIKES_DISLIKES = 'likesDislikes';

// horoscope
export const INPUT_NAME_HOROSCOPE = 'horoscope';

export const INPUT_VALUE_HOROSCOPE_ARIES = 'aries';

export const INPUT_VALUE_HOROSCOPE_TAURUS = 'taurus';

export const INPUT_VALUE_HOROSCOPE_GEMINI = 'gemini';

export const INPUT_VALUE_HOROSCOPE_CANCER = 'cancer';

export const INPUT_VALUE_HOROSCOPE_LEO = 'leo';

export const INPUT_VALUE_HOROSCOPE_VIRGO = 'virgo';

export const INPUT_VALUE_HOROSCOPE_LIBRA = 'libra';

export const INPUT_VALUE_HOROSCOPE_SCORPIO = 'scorpio';

export const INPUT_VALUE_HOROSCOPE_SAGITTARIUS = 'sagittarius';

export const INPUT_VALUE_HOROSCOPE_CAPRICORN = 'capricorn';

export const INPUT_VALUE_HOROSCOPE_AQUARIUS = 'aquarius';

export const INPUT_VALUE_HOROSCOPE_PISCES = 'pisces';

// textType
export const INPUT_NAME_TEXT_TYPE = 'textType';

export const INPUT_VALUE_TEXT_TYPE_SMALL = 'small';

export const INPUT_VALUE_TEXT_TYPE_LARGE = 'large';

// Book Story Of Grandpa Grandma
export const INPUT_NAME_GRANDCHILDREN_HOW_MANY = 'grandchildrenHowMany';
export const INPUT_NAME_GRANDCHILDREN_NAME_1 = 'grandchildrenName1';
export const INPUT_NAME_GRANDCHILDREN_NAME_2 = 'grandchildrenName2';
export const INPUT_NAME_GRANDCHILDREN_NAME_3 = 'grandchildrenName3';

export const INPUT_VALUE_GENDER_GRANDPA = 'grandpa';
export const INPUT_VALUE_GENDER_GRANDMA = 'grandma';

export const INPUT_NAME_HAIR_COLOR_YONG = 'hairColorYong';

export const INPUT_VALUE_HAIR_STYLE_GRANDPA_H01 = 'bald';
export const INPUT_VALUE_HAIR_STYLE_GRANDPA_H02 = 'new_bald';
export const INPUT_VALUE_HAIR_STYLE_GRANDPA_H03 = 'short';
export const INPUT_VALUE_HAIR_STYLE_GRANDPA_H04 = 'side_block';
export const INPUT_VALUE_HAIR_STYLE_GRANDPA_H05 = 'long';
export const INPUT_VALUE_HAIR_STYLE_GRANDPA_H06 = 'curl';

export const INPUT_VALUE_HAIR_STYLE_GRANDMA_H01 = 'pixie';
export const INPUT_VALUE_HAIR_STYLE_GRANDMA_H02 = 'short';
export const INPUT_VALUE_HAIR_STYLE_GRANDMA_H03 = 'medium';
export const INPUT_VALUE_HAIR_STYLE_GRANDMA_H04 = 'long';
export const INPUT_VALUE_HAIR_STYLE_GRANDMA_H05 = 'wave';
export const INPUT_VALUE_HAIR_STYLE_GRANDMA_H06 = 'curl';

// skin color
export const INPUT_NAME_SKIN_COLOR = 'skinColor';
export const INPUT_NAME_P1_SKIN_COLOR = 'p1skinColor';
export const INPUT_NAME_P2_SKIN_COLOR = 'p2skinColor';

export const INPUT_VALUE_SKIN_COLOR_WHITE = 'white';
export const INPUT_VALUE_SKIN_COLOR_NUTMEG = 'nutmeg';
export const INPUT_VALUE_SKIN_COLOR_BROWN = 'brown';
export const INPUT_VALUE_SKIN_COLOR_BLACK = 'black';

export const INPUT_VALUE_SKIN_COLOR_SOGG_C1 = 'white';
export const INPUT_VALUE_SKIN_COLOR_SOGG_C2 = 'natumeg';
export const INPUT_VALUE_SKIN_COLOR_SOGG_C3 = 'brown';
export const INPUT_VALUE_SKIN_COLOR_SOGG_C4 = 'black';

// beard style
export const INPUT_NAME_BEARD_STYLE = 'beardStyle';

export const INPUT_VALUE_BEARD_STYLE_SOGG_B1 = 'mastach';
export const INPUT_VALUE_BEARD_STYLE_SOGG_B2 = 'full';
export const INPUT_VALUE_BEARD_STYLE_SOGG_B3 = 'santa';
export const INPUT_VALUE_BEARD_STYLE_SOGG_NONE = 'none';

// hair color
export const INPUT_NAME_HAIR_COLOR = 'hairColor';
export const INPUT_NAME_P1_HAIR_COLOR = 'p1hairColor';
export const INPUT_NAME_P2_HAIR_COLOR = 'p2hairColor';

export const INPUT_VALUE_HAIR_COLOR_BLONDE = 'blonde';
export const INPUT_VALUE_HAIR_COLOR_RED = 'red';
export const INPUT_VALUE_HAIR_COLOR_BROWN = 'brown';
export const INPUT_VALUE_HAIR_COLOR_BLACK = 'black';

export const INPUT_VALUE_HAIR_COLOR_SOGG_C1 = 'blonde';
export const INPUT_VALUE_HAIR_COLOR_SOGG_C2 = 'red';
export const INPUT_VALUE_HAIR_COLOR_SOGG_C3 = 'brown';
export const INPUT_VALUE_HAIR_COLOR_SOGG_C4 = 'black';
export const INPUT_VALUE_HAIR_COLOR_SOGG_C5 = 'grey';

// frekles
export const INPUT_NAME_FREKLES = 'frekles';

// cloth color
export const INPUT_NAME_CLOTH_COLOR = 'clothColor';

export const INPUT_VALUE_CLOTH_COLOR_BLUE = 'blue';
export const INPUT_VALUE_CLOTH_COLOR_YELLOW = 'yellow';
export const INPUT_VALUE_CLOTH_COLOR_RED = 'red';
export const INPUT_VALUE_CLOTH_COLOR_GREEN = 'green';

// likes
export const INPUT_NAME_LIKE = 'like';

export const INPUT_VALUE_LIKE_SOGG_ROLE_PLAY = 'rolePlay';
export const INPUT_VALUE_LIKE_SOGG_READING = 'reading';
export const INPUT_VALUE_LIKE_SOGG_DRAWING = 'drawing';
export const INPUT_VALUE_LIKE_SOGG_MUSIC = 'music';
export const INPUT_VALUE_LIKE_SOGG_ANIMALS = 'animals';
export const INPUT_VALUE_LIKE_SOGG_WATER_ANIMALS = 'waterAnimals';
export const INPUT_VALUE_LIKE_SOGG_INSECTS = 'insects';
export const INPUT_VALUE_LIKE_SOGG_CRAFTING = 'crafting';
export const INPUT_VALUE_LIKE_SOGG_PARK = 'park';
export const INPUT_VALUE_LIKE_SOGG_DOLLS = 'dolls';
export const INPUT_VALUE_LIKE_SOGG_VEHICLES = 'vehicles';
export const INPUT_VALUE_LIKE_SOGG_EATING = 'eating';

// dislikes
export const INPUT_NAME_DISLIKE = 'dislike';

export const INPUT_VALUE_DISLIKE_SOGG_SECRET = 'secret';
export const INPUT_VALUE_DISLIKE_SOGG_VEGGIES = 'veggies';
export const INPUT_VALUE_DISLIKE_SOGG_CLEANING_UP = 'cleaningUp';
export const INPUT_VALUE_DISLIKE_SOGG_BUGS = 'bugs';
export const INPUT_VALUE_DISLIKE_SOGG_GHOST_AND_MONSTERS = 'ghostAndMonsters';
export const INPUT_VALUE_DISLIKE_SOGG_BATH = 'bath';
