import type {
  TCountry,
  TCountryCode,
  TCountryName,
  TRegion,
  TRegionCode,
  TRegionName,
} from './interfaces';
import Regions, { Countries } from './data';

export const getRegionByName = <T extends TRegionName>(name: T) => {
  const region = Regions.find((_region) => _region.name === name) as Extract<
    TRegion,
    { name: T }
  >;

  return region;
};

export const getRegionByCode = <T extends TRegionCode>(code: T) => {
  const region = Regions.find((_region) => _region.code === code) as Extract<
    TRegion,
    { code: T }
  >;

  return region;
};

export const getCountryByName = <T extends TCountryName>(name: T) => {
  const region = Countries.find((_region) => _region.name === name) as Extract<
    TCountry,
    { name: T }
  >;

  return region;
};

export const getCountryByCode = <T extends TCountryCode>(code: T) => {
  const region = Countries.find((_region) => _region.code === code) as Extract<
    TCountry,
    { code: T }
  >;

  return region;
};
