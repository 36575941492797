import { ChangeDetectionStrategy, Component } from '@angular/core';
import Media from 'src/app/data/media/data';

@Component({
  selector: 'app-section-media',
  templateUrl: './section-media.component.html',
  styleUrls: ['./section-media.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionMediaComponent {
  public readonly data = Media;

  constructor() {}
}
