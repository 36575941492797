import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { slideY } from 'src/app/animations';

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideY()],
})
export class FieldComponent {
  @Input() public forId?: string;

  @Input() public label?: string;

  @Input() public error?: string;

  // constructor() {}
}
