import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsComponent } from './tabs.component';
import { TabsItemComponent } from './components/tabs-item/tabs-item.component';
import { TabsNavItemComponent } from './components/tabs-nav-item/tabs-nav-item.component';

@NgModule({
  declarations: [TabsComponent, TabsItemComponent, TabsNavItemComponent],
  imports: [CommonModule],
  exports: [TabsComponent, TabsItemComponent],
})
export class TabsModule {}
