<div class="wrap">
  <div class="wrap__character"></div>
  <app-card class="wrap__container">
    <div class="container">
      <p class="container__text">
        We have sent you an order confirmation email.<br />If somehow you didn’t
        received the email, please contact us!
      </p>
      <a
        href="mailto:yay@hellobuki.com"
        class="container__link"
        >yay@hellobuki.com</a
      >
    </div>
  </app-card>
</div>
