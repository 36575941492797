import type { OnInit } from '@angular/core';
import { ChangeDetectorRef, Component } from '@angular/core';
import type { ActivatedRouteSnapshot } from '@angular/router';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  tap,
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { gtagTrigger } from 'analytics';
import * as Sentry from '@sentry/angular';
import { Logger } from '@shared/logger';
import { LayoutFooter, LayoutHeader } from './app-routing.enum';
// import {FirebaseService} from './services/firebase.service';
import { LoaderService } from './services/loader.service';
import { UserService } from './services/user.service';
import {
  ROUTE_PATH_ALL_BOOKS,
  ROUTE_PATH_BOOK,
  ROUTE_PATH_BOOK_STARRY_DREAM,
  ROUTE_PATH_BOOK_STORY_OF_GRANDPA_GRANDMA,
  ROUTE_PATH_BOOK_WELCOME_TO_THE_WORLD,
  ROUTE_PATH_BOOK_WHERE_IS_BIRTHDAY_CAKE,
  ROUTE_PATH_BOOK_WHOS_BIRTHDAY_TOMORROW,
  ROUTE_PATH_CART,
  ROUTE_PATH_ORDER,
  ROUTE_PATH_PREVIEW,
} from './app-routing.constants';

const LOCAL_STORAGE_KEY_FEEDBACK_ASKED = 'feedbackAsked';

/** list of paths where user used to go from the CART page */
const CART_NEXT_USUAL_PATHS = [
  ROUTE_PATH_ORDER,
  ROUTE_PATH_ALL_BOOKS,
  ROUTE_PATH_PREVIEW,
  ROUTE_PATH_BOOK,
  ROUTE_PATH_BOOK_STARRY_DREAM,
  ROUTE_PATH_BOOK_WELCOME_TO_THE_WORLD,
  ROUTE_PATH_BOOK_WHOS_BIRTHDAY_TOMORROW,
  ROUTE_PATH_BOOK_WHERE_IS_BIRTHDAY_CAKE,
  ROUTE_PATH_BOOK_STORY_OF_GRANDPA_GRANDMA,
];

const getFirstRouteState = (snap: ActivatedRouteSnapshot) =>
  snap.root.firstChild;

const log = new Logger('AppComponent');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public topbarHeight: number = 0;

  public readonly LAYOUT_HEADER = LayoutHeader;

  public readonly LAYOUT_FOOTER = LayoutFooter;

  public LayoutHeader?: LayoutHeader;

  public LayoutFooter?: LayoutFooter;

  public overflow: 'hidden' | null = null;

  constructor(
    // private firebaseService: FirebaseService,
    public router: Router,
    private _userService: UserService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private titleService: Title,
    private meta: Meta,
    private _loaderService: LoaderService,
    private _user: UserService,
    private _cdr: ChangeDetectorRef,
  ) {
    if (this.router.navigated) {
      this._setRouteData(getFirstRouteState(this.route.snapshot));
    }

    this.router.events
      .pipe(
        filter(
          (event): event is ActivationEnd => event instanceof ActivationEnd,
        ),
        map((event) => getFirstRouteState(event.snapshot)),
        distinctUntilChanged(),
        tap((snap) => {
          this._setRouteData(snap);
          gtagTrigger('event', 'page_view');
        }),
        pairwise(),
      )
      .subscribe(([prevSnap, nextSnap]) => {
        const prevPath = prevSnap.routeConfig.path;
        const nextPath = nextSnap.routeConfig.path;

        log.info('PATHS', prevPath, nextPath);

        if (prevPath === ROUTE_PATH_CART) {
          if (CART_NEXT_USUAL_PATHS.indexOf(nextPath) === -1) {
            log.info('PATHS TRIGGER');

            if (
              localStorage.getItem(LOCAL_STORAGE_KEY_FEEDBACK_ASKED) === 'true'
            ) {
              log.warn('prevented asking feedback');
              return;
            }

            const { booksInCartList } = this._userService;

            if (booksInCartList.length === 0) {
              log.warn('prevented asking feedback');
              return;
            }

            const eventId = Sentry.captureMessage('User Feedback');
            Sentry.showReportDialog({
              eventId,
              title: 'Thank you for checking out books!',
              subtitle:
                'Can you please tell us how we can improve (or what issues you found)?',
              subtitle2:
                'We will send you a 5% discount coupon to your e-mail to thank you.',
              labelSubmit: 'Submit Feedback',
              onLoad: () => {
                localStorage.setItem(LOCAL_STORAGE_KEY_FEEDBACK_ASKED, 'true');
              },
            });
          }
        }
      });

    this._user.init();
  }

  ngOnInit() {
    this._loaderService.shown$.subscribe((shown) => {
      this.overflow = shown ? 'hidden' : null;
      this._cdr.detectChanges();
    });

    this.translateService.use(environment.defaultLocale);
  }

  private _setRouteData(snapshot: ActivatedRouteSnapshot) {
    this.titleService.setTitle(snapshot.data?.title);

    if (snapshot.data?.meta_title) {
      this.meta.updateTag({
        name: 'title',
        content: snapshot.data?.meta_title,
      });
    } else {
      const ogTagsManage = this.meta.getTag('name="title"');
      this.meta.removeTagElement(ogTagsManage);
    }

    if (snapshot.data?.description) {
      this.meta.updateTag({
        name: 'description',
        content: snapshot.data?.description,
      });
    } else {
      const ogTagsManage = this.meta.getTag('name="description"');
      this.meta.removeTagElement(ogTagsManage);
    }

    if (snapshot.data?.og_title) {
      this.meta.updateTag({
        property: 'og:title',
        content: snapshot.data?.og_title,
      });
    } else {
      const ogTagsManage = this.meta.getTag('property="og:title"');
      this.meta.removeTagElement(ogTagsManage);
    }

    if (snapshot.data?.og_description) {
      this.meta.updateTag({
        property: 'og:description',
        content: snapshot.data?.og_description,
      });
    } else {
      const ogTagsManage = this.meta.getTag('property="og:description"');
      this.meta.removeTagElement(ogTagsManage);
    }

    if (snapshot.data?.og_url) {
      this.meta.updateTag({
        property: 'og:url',
        content: snapshot.data?.og_url,
      });
    } else {
      const ogTagsManage = this.meta.getTag('property="og:url"');
      this.meta.removeTagElement(ogTagsManage);
    }

    if (snapshot.data?.og_image) {
      this.meta.updateTag({
        property: 'og:image',
        content: snapshot.data?.og_image,
      });
    } else {
      const ogTagsManage = this.meta.getTag('property="og:image"');
      this.meta.removeTagElement(ogTagsManage);
    }

    if (snapshot.data?.og_site_name) {
      this.meta.updateTag({
        property: 'og:site_name',
        content: snapshot.data?.og_site_name,
      });
    } else {
      const ogTagsManage = this.meta.getTag('property="og:site_name"');
      this.meta.removeTagElement(ogTagsManage);
    }

    if (snapshot.data?.fb_app_id) {
      this.meta.updateTag({
        property: 'fb:app_id',
        content: snapshot.data?.fb_app_id,
      });
    } else {
      const ogTagsManage = this.meta.getTag('property="fb:app_id"');
      this.meta.removeTagElement(ogTagsManage);
    }

    if (snapshot.data?.og_type) {
      this.meta.updateTag({
        property: 'og:type',
        content: snapshot.data?.og_type,
      });
    } else {
      const ogTagsManage = this.meta.getTag('property="og:type"');
      this.meta.removeTagElement(ogTagsManage);
    }

    if (snapshot.data?.twitter_card) {
      this.meta.updateTag({
        name: 'twitter:card',
        content: snapshot.data?.twitter_card,
      });
    } else {
      const ogTagsManage = this.meta.getTag('name="twitter:card"');
      this.meta.removeTagElement(ogTagsManage);
    }

    if (snapshot.data?.twitter_site) {
      this.meta.updateTag({
        name: 'twitter:site',
        content: snapshot.data?.twitter_site,
      });
    } else {
      const ogTagsManage = this.meta.getTag('name="twitter:site"');
      this.meta.removeTagElement(ogTagsManage);
    }

    this.LayoutHeader = snapshot.data?.LayoutHeader;
    this.LayoutFooter = snapshot.data?.LayoutFooter;
  }
}
