<app-field
  *ngIf="idObservable | async as id"
  [label]="(!withOwnLabel && label) || undefined"
  [error]="(showError && error) || undefined"
  [forId]="id"
>
  <div
    class="input-wrap"
    [ngClass]="{
      'input-wrap_invalid': showError,
      'input-wrap_theme-alt': theme === EInputTheme.alt
    }"
    [ngSwitch]="type"
  >
    <textarea
      class="input-wrap__item input-wrap__item_textarea"
      *ngSwitchCase="TYPE_TEXTAREA"
      [id]="id"
      [attr.name]="name"
      [(ngModel)]="value"
      [rows]="rows"
      [disabled]="disabled"
      (blur)="onTouched()"
      [placeholder]="placeholder"
    ></textarea>
    <ng-container *ngSwitchCase="TYPE_SELECT">
      <!-- <app-select class="input-wrap__item input-wrap__item_select"
        [ngClass]="{
          'input-wrap__item_disabled': disabled
        }"
        *ngIf="control"
        [id]="id"
        [name]="name"
        [placeholder]="placeholder"
        [formControl]="control"
        [selectButtonTheme]="selectButtonTheme"
      >
        <ng-container
          [ngTemplateOutlet]="contentRef"
        ></ng-container>
      </app-select> -->
      <app-select
        class="input-wrap__item input-wrap__item_select"
        [ngClass]="{
          'input-wrap__item_disabled': disabled
        }"
        [id]="id"
        [name]="name || ''"
        [placeholder]="placeholder"
        [(ngModel)]="value"
        [disabled]="disabled"
        [selectButtonTheme]="selectButtonTheme"
      >
        <ng-container [ngTemplateOutlet]="contentRef"></ng-container>
      </app-select>
    </ng-container>
    <ng-container *ngSwitchCase="TYPE_CHECKBOX">
      <app-checkbox
        *ngIf="control"
        [id]="id"
        [name]="name"
        [formControl]="control"
        [label]="label"
        [invalid]="showError"
        [theme]="theme"
      >
        <ng-container [ngTemplateOutlet]="contentRef"></ng-container>
      </app-checkbox>
      <app-checkbox
        *ngIf="!control"
        [id]="id"
        [name]="name || ''"
        [(ngModel)]="value"
        [disabled]="disabled"
        [label]="label"
        [invalid]="showError"
        [theme]="theme"
      >
        <ng-container [ngTemplateOutlet]="contentRef"></ng-container>
      </app-checkbox>
    </ng-container>
    <input
      class="input-wrap__item input-wrap__item_button"
      [ngClass]="{
        'input-wrap__item_flat': flat,
        'input-wrap__item_bordered': bordered
      }"
      *ngSwitchCase="TYPE_SUBMIT"
      [id]="id"
      [attr.name]="name"
      [type]="type"
      [value]="value"
      [disabled]="disabled"
      (blur)="onTouched()"
    />
    <input
      class="input-wrap__item input-wrap__item_button"
      [ngClass]="{
        'input-wrap__item_flat': flat,
        'input-wrap__item_bordered': bordered
      }"
      *ngSwitchCase="TYPE_BUTTON"
      [id]="id"
      [attr.name]="name"
      [type]="type"
      [value]="value"
      [disabled]="disabled"
      (blur)="onTouched()"
    />
    <app-stripe-card
      class="input-wrap__item input-wrap__item_input"
      #stripeCard
      *ngSwitchCase="TYPE_STRIPE_CARD"
      [id]="id"
      [attr.name]="name"
      (valueChange)="value = $event"
      [disabled]="disabled"
      (focus)="control?.markAsUntouched()"
      (blur)="onTouched()"
    ></app-stripe-card>
    <input
      class="input-wrap__item input-wrap__item_input"
      *ngSwitchCase="TYPE_NUMBER"
      [id]="id"
      [attr.name]="name"
      type="number"
      [(ngModel)]="value"
      [disabled]="disabled"
      (blur)="onTouched()"
      [placeholder]="placeholder"
    />
    <input
      class="input-wrap__item input-wrap__item_input"
      *ngSwitchDefault
      [id]="id"
      [attr.name]="name"
      [type]="type"
      [(ngModel)]="value"
      [disabled]="disabled"
      (blur)="onTouched()"
      [placeholder]="placeholder"
    />
  </div>
</app-field>

<ng-template #contentRef>
  <ng-content></ng-content>
</ng-template>
