<div
  class="container"
  [routerLink]="link"
  routerLinkActive="container_active"
>
  <div
    *ngIf="imgSrc"
    class="container__section"
  >
    <img
      class="img"
      [src]="imgSrc"
      alt="book cover"
    >
  </div>
  <div class="container__section">
    <div
      class="text"
      translate
    ><ng-content></ng-content></div>
  </div>
</div>
