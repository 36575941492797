import { ChangeDetectionStrategy, Component } from '@angular/core';
import type { IBook } from 'app/data/books/types';
import StaryDream from 'app/data/books/StaryDream';
import WhosBirthdayTomorrow from 'app/data/books/WhosBirthdayTomorrow';
import WelcomeToTheWorld from 'app/data/books/WelcomeToTheWorld';
import { RoutingService } from 'app/services/routing.service';
import { ROUTE_QUERY_PARAM_PURPOSE } from 'app/app-routing.constants';
import {
  PROPERTY_PURPOSE_ADVENTURE,
  PROPERTY_PURPOSE_BIRTHDAY,
} from 'app/data/books/properties/constants';

@Component({
  selector: 'app-section-books-preview',
  templateUrl: './section-books-preview.component.html',
  styleUrls: ['./section-books-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionBooksPreviewComponent {
  public readonly StaryDream = StaryDream;

  public readonly WhosBirthdayTomorrow = WhosBirthdayTomorrow;

  public readonly WelcomeToTheWorld = WelcomeToTheWorld;

  constructor(private _routing: RoutingService) {}

  public onAdventureBooksButtonClick() {
    this._routing.goToAllBooks({
      [ROUTE_QUERY_PARAM_PURPOSE]: PROPERTY_PURPOSE_ADVENTURE,
    });
  }

  public onBirthdayBooksButtonClick() {
    this._routing.goToAllBooks({
      [ROUTE_QUERY_PARAM_PURPOSE]: PROPERTY_PURPOSE_BIRTHDAY,
    });
  }

  public onButtonClick(alias: IBook['alias']) {
    this._routing.goToPreview(alias);
  }
}

// birthday-books.png
