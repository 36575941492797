import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GiftBuySpecialPageComponent } from './gift-buy-special-page.component';
import { ImageTitleModule } from 'src/app/shared/image-title/image-title.module';
import { SectionHeaderGapModule } from 'src/app/shared/section-header-gap/section-header-gap.module';
import { SectionGiftBooksModule } from 'src/app/shared/section-gift-books/section-gift-books.module';
import { SectionGiftCartModule } from 'src/app/shared/section-gift-cart/section-gift-cart.module';
import { SectionGiftCheckoutModule } from 'src/app/shared/section-gift-checkout/section-gift-checkout.module';

@NgModule({
  declarations: [GiftBuySpecialPageComponent],
  imports: [
    CommonModule,
    ImageTitleModule,
    SectionHeaderGapModule,
    SectionGiftBooksModule,
    SectionGiftCartModule,
    SectionGiftCheckoutModule,
  ],
})
export class GiftBuySpecialPageModule {}
