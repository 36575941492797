import type { IBookCreate } from 'src/app/services/book-create/book-create';
import type {
  BookStoryOfGrandpaGrandmaData,
  CommonBookData,
} from '@shared/models';
import type {
  TBeardStyleSOGG,
  TGenderGrandpaGrandma,
  THairStyleSOGG,
  TLanguage,
  TSHairColorInYoungerYearsSOGG,
  TSHairColorSOGG,
  TSkinColorSOGG,
} from '@shared/book/interfaces';
import { BOOK_ALIAS_STORY_OF_GRANDPA_GRANDMA } from '@shared/book/constants';
import capitalize from 'lodash/capitalize';

export interface StoryOfGrandpaGrandmaBookCreateData {
  heroName: string;
  gender: TGenderGrandpaGrandma;
  language: TLanguage;
  howMany: 1 | 2 | 3;
  hairStyle: THairStyleSOGG;
  hairColorInYoungerYears: TSHairColorInYoungerYearsSOGG;
  hairColor: TSHairColorSOGG;
  skinColor: TSkinColorSOGG;
  beardStyle: TBeardStyleSOGG;
  childrenName1: string;
  childrenName2: string;
  childrenName3: string;
  glasses: boolean;
}

export class StoryOfGrandpaGrandmaBookCreate
  implements IBookCreate<BookStoryOfGrandpaGrandmaData>
{
  createEmptyBook(): BookStoryOfGrandpaGrandmaData {
    return {
      steps: {
        character: {
          stepType: 'character',
          isDone: true,
        },
        subCharacter: {
          stepType: 'subCharacter',
          isDone: true,
        },
        message: {
          stepType: 'message',
          isDone: false,
        },
        likesDislikes: {
          stepType: 'likesDislikes',
          isDone: false,
        },
        photo: {
          stepType: 'photo',
          isDone: false,
        },
        subPhoto: {
          stepType: 'subPhoto',
          isDone: false,
        },
      },
      uid: undefined,
      bookId: undefined,
      heroName: undefined,
      gender: undefined,
      fileUploadStatus: 0,
      beardStyle: undefined,
      hairStyle: undefined,
      language: 'jp',
      hairColorInYoungerYears: undefined,
      skinColor: undefined,
      hairColor: undefined,
      glasses: false,
      grandchildren: {
        howMany: 1,
        data: [{ id: 1, childrenName: undefined, fileUploadStatus: 0 }],
      },
      //todo all set false , temp
      like: {
        rolePlay: false,
        reading: false,
        drawing: false,
        music: false,
        animals: false,
        waterAnimals: false,
        insects: false,
        crafting: false,
        park: false,
        dolls: true,
        vehicles: true,
        eating: true,
      },
      dislike: {
        secret: false,
        veggies: false,
        cleaningUp: false,
        bugs: false,
        ghostAndMonsters: true,
        bath: false,
      },

      createTimeStamp: undefined,
      alias: BOOK_ALIAS_STORY_OF_GRANDPA_GRANDMA,
      cover: 'soft',
      messageText: undefined,
      wrapping: 'standart',
    };
  }

  createNewBook(
    uid: string,
    docId: string,
    createBookData: StoryOfGrandpaGrandmaBookCreateData,
    serverTimestamp: any,
    common?: Partial<CommonBookData>,
  ): BookStoryOfGrandpaGrandmaData {
    const newBook: BookStoryOfGrandpaGrandmaData = this.createEmptyBook();

    createBookData.heroName =
      createBookData.heroName === 'en'
        ? capitalize(createBookData.heroName)
        : createBookData.heroName;

    newBook.uid = uid;
    newBook.bookId = docId;
    newBook.createTimeStamp = serverTimestamp;
    newBook.heroName = createBookData.heroName;
    newBook.gender = createBookData.gender;
    newBook.language = createBookData.language;
    newBook.hairStyle = createBookData.hairStyle;
    if (createBookData.hairColor != 'grey') {
      createBookData.hairColorInYoungerYears = createBookData.hairColor;
    }
    newBook.hairColorInYoungerYears = createBookData.hairColorInYoungerYears;
    newBook.hairColor = createBookData.hairColor;
    newBook.skinColor = createBookData.skinColor;
    newBook.beardStyle = createBookData.beardStyle;
    newBook.glasses = createBookData.glasses;
    const { childrenName1, childrenName2, childrenName3 } = createBookData;

    switch (createBookData.howMany) {
      case 1:
        newBook.messageText =
          `${childrenName1}へ\n` +
          `\n` +
          `じんせいは　やまあり　たにあり。\n` +
          `うれしいこと、かなしいこと。\n` +
          `どれも　だいじな　たからもの。\n` +
          `いまを　めいっぱい　たのしんで。\n`;
        break;
      case 2:
        newBook.messageText =
          `${childrenName1}、${childrenName2}へ\n` +
          `\n` +
          `じんせいは　やまあり　たにあり。\n` +
          `うれしいこと、かなしいこと。\n` +
          `どれも　だいじな　たからもの。\n` +
          `いまを　めいっぱい　たのしんで。\n`;
        break;
      case 3:
        newBook.messageText =
          `${childrenName1}、${childrenName2}、${childrenName3}へ\n` +
          `\n` +
          `じんせいは　やまあり　たにあり。\n` +
          `うれしいこと、かなしいこと。\n` +
          `どれも　だいじな　たからもの。\n` +
          `いまを　めいっぱい　たのしんで。\n`;
        break;
    }

    const grandchildren: {
      howMany: 1 | 2 | 3;
      data: {
        id: 1 | 2 | 3;
        childrenName: string;
        fileUploadStatus: number;
      }[];
    } = {
      howMany: createBookData.howMany,
      data: [],
    };

    if (createBookData.howMany >= 1) {
      grandchildren.data.push({
        id: 1,
        childrenName: createBookData.childrenName1,
        fileUploadStatus: 0,
      });
    }
    if (createBookData.howMany >= 2) {
      grandchildren.data.push({
        id: 2,
        childrenName: createBookData.childrenName2,
        fileUploadStatus: 0,
      });
    }
    if (createBookData.howMany >= 3) {
      grandchildren.data.push({
        id: 3,
        childrenName: createBookData.childrenName3,
        fileUploadStatus: 0,
      });
    }

    newBook.grandchildren = grandchildren;

    Object.assign(newBook, common);
    return newBook;
  }
}
