<div class="container">
  <!-- <div class="container__title">
    <app-image-title type="faq">よくある質問</app-image-title>
  </div> -->
  <div class="container__content">
    <app-faq-group [data]="faqData"></app-faq-group>
  </div>
  <!-- <div
    *ngIf="condensed"
    class="container__button"
  >
    <app-button
      color="accent-2"
      size="sm"
      (buttonClick)="onButtonClick()"
    ><b>全てのFAQをみる</b></app-button>
  </div> -->
</div>
