<app-field
  [label]="label"
  [error]="showError && error"
  [forId]="id"
>
  <div
    class="container"
    [ngClass]="{
      'container_invalid': showError,
      'container_theme-alt': theme === EInputTheme.alt
    }"
  >
    <input
      class="container__item"
      [id]="id"
      [name]="name"
      [matDatepicker]="picker"
      [min]="min"
      [max]="max"
      [(ngModel)]="value"
      [disabled]="disabled"
      (blur)="onTouched()"
      [placeholder]="placeholder"
      (focus)="picker.open()"
      (click)="picker.open()"
    >
    <mat-datepicker-toggle
      class="container__toggle"
      [for]="picker"
    ></mat-datepicker-toggle>
    <mat-datepicker touchUi #picker></mat-datepicker>
  </div>
</app-field>
