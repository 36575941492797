import type { AfterViewInit, OnDestroy } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Output,
  ViewChild,
} from '@angular/core';
import { DiscountService } from 'src/app/services/discount.service';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import ResizeObserver from 'resize-observer-polyfill';
import type { IDiscountCampaign } from '@shared/discount/interfaces';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopbarComponent implements AfterViewInit, OnDestroy {
  @ViewChild('wrap') wrapRef: ElementRef<HTMLDivElement>;

  @Output() topbarHeightChange = new BehaviorSubject(0);

  public messageObservable: Observable<IDiscountCampaign['message']>;

  private _resizeObserver = new ResizeObserver((entries) => {
    entries.forEach(({ contentRect: { height } }) => {
      this._updateTopbarHeight(height);
    });
  });

  constructor(private _discountService: DiscountService) {
    this.messageObservable =
      this._discountService.activeDiscountCampaignsObservable.pipe(
        map((discountCampaign) => discountCampaign?.message || ''),
      );
  }

  ngAfterViewInit() {
    this._resizeObserver.observe(this.wrapRef.nativeElement);
  }

  ngOnDestroy() {
    this._resizeObserver.disconnect();
    this.topbarHeightChange.next(0);
  }

  private _updateTopbarHeight(height: number) {
    if (this.topbarHeightChange.value !== height) {
      this.topbarHeightChange.next(height);
    }
  }
}
