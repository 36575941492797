import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { KonbiniConfirmationComponent } from '../components/konbini-confirmation/konbini-confirmation.component';

@Injectable({
  providedIn: 'root',
})
export class DialogsService {
  constructor(private _dialog: MatDialog) {}

  public showKonbiniConfirmation() {
    return this._dialog.open<KonbiniConfirmationComponent, undefined, boolean>(
      KonbiniConfirmationComponent,
      {
        maxWidth: '',
        panelClass: 'order-overlay-pane',
        backdropClass: 'order-overlay-dark-backdrop',
      },
    );
  }
}
