<div class="wrap">
  <!-- <div class="wrap__section wrap__section_mod"></div> -->
  <app-section-header-banner
    class="wrap__section wrap__section_header-banner"
  ></app-section-header-banner>
  <app-section-promise
    class="wrap__section wrap__section_promise"
  ></app-section-promise>
  <app-section-team class="wrap__section wrap__section_team"></app-section-team>
  <app-section-story
    class="wrap__section wrap__section_story"
  ></app-section-story>
  <app-section-media
    class="wrap__section wrap__section_media"
  ></app-section-media>
  <app-section-company
    class="wrap__section wrap__section_company"
  ></app-section-company>
</div>
