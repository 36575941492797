<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div class="container__title">
        <app-image-title [type]="imageTitleType">{{ imageTitleText }}</app-image-title>
      </div>
      <div class="container__content">
        <p *ngFor="let t of text" class="text">{{ t }}</p>
      </div>
    </div>
  </div>
</div>
