import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviewComponent } from './preview.component';
import { SectionDetailsModule } from './components/section-details/section-details.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [PreviewComponent],
  imports: [CommonModule, SectionDetailsModule, SharedModule],
})
export class PreviewModule {}
