<div
  style="min-height: 100vh; flex-direction: column"
  [ngStyle]="{
    overflow: overflow,
    marginTop: topbarHeight + 'px'
  }"
>
  <app-header
    class="app-header"
    *ngIf="LayoutHeader === LAYOUT_HEADER.shown"
    (topbarHeightChange)="topbarHeight = $event"
  ></app-header>
  <div
    fxFlex
    class="app-content"
  >
    <div
      fxLayout="column"
      fxFlexFill
      attr="asdf"
    >
      <div fxFlex>
        <router-outlet></router-outlet>
      </div>
      <app-section-footer
        *ngIf="LayoutFooter === LAYOUT_FOOTER.shown"
      ></app-section-footer>
    </div>
  </div>
</div>

<app-loader></app-loader>

<!--<div style="background: #009E00; z-index: 20; margin-top: -1px;width: 100%; height: 300px; position: absolute"></div>-->
