<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div class="container__title">
        <app-image-title type="books">贈る絵本を選ぶ</app-image-title>
      </div>
      <div class="container__content">
        <div class="content">
          <div class="content__compensator"></div>
          <ng-container *ngFor="let book of books">
            <ng-container *ngIf="cover$ | async as _cover">
              <ng-container *ngIf="wrapping$ | async as _wrapping">
                <div
                  *ngIf="
                    (alias$ | async) === undefined ||
                    (alias$ | async) === book.alias
                  "
                  @slideY
                  class="content__book"
                >
                  <div class="book-wrap">
                    <div class="book-wrap__item">
                      <app-book
                        [selected]="(alias$ | async) === book.alias"
                        [alias]="book.alias"
                        [photo]="book.menuPhoto"
                        [caption]="book.caption"
                        [summary]="book.summary"
                        [notice]="book.notice"
                        [prices]="book.prices"
                        (bookClick)="onBookClick(book.alias)"
                        [cover]="_cover"
                        (coverChange)="cover = $event"
                        [wrapping]="_wrapping"
                        (wrappingChange)="wrapping = $event"
                      ></app-book>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
          <div
            *ngIf="alias$ | async"
            class="content__change"
            @slideY
          >
            <div class="change-wrap">
              <div class="change-wrap__item">
                <app-change (buttonClick)="onChangeClick()"></app-change>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
