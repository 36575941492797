import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  EBookBannerTextColor,
  EBookBannerTextSize,
  IBookBanner,
} from 'src/app/data/books/banner/types';
import { MediaObserver } from '@angular/flex-layout';
import { BehaviorSubject } from 'rxjs';
import { Logger } from 'src/app/services/logger.service';
import Subscriber from 'src/app/subscriber';
import { MediaService } from 'app/services/media.service';
import { TBookAlias } from '@shared/book/interfaces';
import { getNgClass } from 'app/utils';

const sub = new Subscriber();
const log = new Logger('BannerComponent');

/**
 * @default textColor EBookBannerTextColor.dark
 */
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() alias!: TBookAlias;

  @Input() imgSrc!: IBookBanner['imgSrc'];

  @Input() imgSrcLtMd?: IBookBanner['imgSrcLtMd'];

  @Input() text!: IBookBanner['text'];

  @Input() textLtMd?: IBookBanner['textLtMd'];

  /** @default EBookBannerTextColor.dark */
  @Input() textColor: IBookBanner['textColor'] = EBookBannerTextColor.dark;

  /** @default EBookBannerTextSize.md */
  @Input() textSize: IBookBanner['textSize'] = EBookBannerTextSize.md;

  private _backgroundImage = new BehaviorSubject('');

  public backgroundImage = this._backgroundImage.asObservable();

  public readonly getNgClass = getNgClass;

  constructor(private _media: MediaObserver, public media: MediaService) {}

  ngOnInit(): void {
    sub.push(
      this._media.asObservable().subscribe(() => {
        this._updateBackgroundImage();
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.imgSrc || changes.imgSrcLtMd) {
      this._updateBackgroundImage();
    }
  }

  ngOnDestroy(): void {
    sub.unsubscribe();
  }

  private _updateBackgroundImage(): void {
    const src = this._media.isActive('lt-md') ? this.imgSrcLtMd : this.imgSrc;
    this._backgroundImage.next(`url(${src})`);

    // log.info('changed', { src })
  }
}
