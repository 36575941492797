import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-section-collaboration-statement',
  templateUrl: './section-collaboration-statement.component.html',
  styleUrls: ['./section-collaboration-statement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionCollaborationStatementComponent {
  // constructor() {}
}
