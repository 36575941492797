/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EBookSelectInputType } from 'app/data/books/inputs/select/types';
import { EBookInputType } from 'app/data/books/inputs/shared';
import {
  IBookInputNameToValueMap,
  TBookInput,
  TBookInputs,
} from 'app/data/books/inputs/types';
import {
  bookInputShowWhenCheck,
  bookInputToCanvas,
  bookInputToCheckbox,
  bookInputToDate,
  bookInputToGroup,
  bookInputToMultiselect,
  bookInputToRadio,
  bookInputToRadioColor,
  bookInputToRadioIcon,
  bookInputToRadioImage,
  bookInputToSelect,
  bookInputToText,
  bookInputValidatorWhenSet,
  checkBookRadioImageInput,
} from 'app/data/books/inputs/utils';
import { Logger } from 'app/services/logger.service';
import { EInputTheme } from 'app/shared/input/interfaces';
import Subscriber from 'app/subscriber';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { getInputClass, updateFormControls } from './functions';
import isEqual from 'lodash/isEqual';
import { Constructor } from 'typescript-mix';

const log = new Logger('BookFormMixin');

export function BookFormMixin<B extends Constructor<{}>>(constructor: B) {
  return class extends constructor {
    set inputs(inputs: TBookInputs) {
      if (this.inputsStore === inputs) return;

      this.inputsStore = inputs;

      const initialData = inputs.reduce((obj, { name, value }) => {
        obj[name] = value;

        return obj;
      }, {} as IBookInputNameToValueMap);

      this._setImagesCache(inputs);

      this._updateInputs(initialData);
    }

    get inputs() {
      return this._inputs;
    }

    public inputsStore: TBookInputs = [];

    public _inputs: TBookInputs = [];

    public readonly formGroup = new FormGroup({});

    public readonly valid$ = new BehaviorSubject(false);

    public readonly inputsUpdate$ = new Subject<
      ReturnType<typeof updateFormControls>
    >();

    public readonly valueUpdate$ = new Subject<IBookInputNameToValueMap>();

    // get formGroup(): FormGroup {
    //   return inputsToFormGroup(this.inputs)
    // }

    public readonly bookInputToRadio = bookInputToRadio;

    public readonly bookInputToRadioImage = bookInputToRadioImage;

    public readonly bookInputToRadioColor = bookInputToRadioColor;

    public readonly bookInputToRadioIcon = bookInputToRadioIcon;

    public readonly bookInputToSelect = bookInputToSelect;

    public readonly bookInputToText = bookInputToText;

    public readonly bookInputToDate = bookInputToDate;

    public readonly bookInputToCheckbox = bookInputToCheckbox;

    public readonly bookInputToGroup = bookInputToGroup;

    public readonly bookInputToCanvas = bookInputToCanvas;

    public readonly bookInputToMultiselect = bookInputToMultiselect;

    public readonly EBookInputType = EBookInputType;

    public readonly EBookSelectInputType = EBookSelectInputType;

    public readonly EInputTheme = EInputTheme;

    public readonly _sub = new Subscriber();

    public _imagesCache: {
      [key: string]: HTMLImageElement;
    } = {};

    public getFormItemClass = (input: TBookInput) =>
      getInputClass('form__item', input);

    constructor(...rest: any[]) {
      super(...rest);

      this._sub.push(
        this.formGroup.valueChanges
          .pipe(
            // undefined invalid
            map((data) =>
              Object.keys(data).reduce<IBookInputNameToValueMap>((acc, key) => {
                acc[key] = this.formGroup.controls[key].invalid
                  ? undefined
                  : data[key];

                return acc;
              }, {}),
            ),
            distinctUntilChanged<IBookInputNameToValueMap>(isEqual),
          )
          .subscribe((data) => {
            log.info('formGroup.valueChanges', data);

            if (this.inputs.length === Object.keys(data).length) {
              this._updateInputs(data);
            }

            this.valueUpdate$.next(data);
          }),
        this.formGroup.statusChanges
          .pipe(distinctUntilChanged())
          .subscribe((status) => {
            this.valid$.next(status === 'VALID');
          }),
      );
    }

    public _ngOnDestroy(): void {
      this._sub.unsubscribe();
      this._resetImagesCache();
    }

    /** updates inputs according to `data` */
    public _updateInputs(data: IBookInputNameToValueMap) {
      const prevInputs = this.inputs;

      this._filterInputs(data);

      const nextInputs = this.inputs;

      const res = updateFormControls(this.formGroup, prevInputs, nextInputs);

      this.inputsUpdate$.next(res);
    }

    /** filters `this.inputsStore` and updates `this.inputs` */
    public _filterInputs(data: IBookInputNameToValueMap) {
      this._inputs = this.inputsStore
        .filter(({ showWhen }) => {
          return bookInputShowWhenCheck(data, showWhen);
        })
        .map((input) => {
          const _input = bookInputValidatorWhenSet(data, input);
          const prevInput = this._inputs.find((_prevInput) =>
            isEqual(_input, _prevInput),
          );

          return prevInput || _input;
        });
    }

    public _setImagesCache(inputs: TBookInputs) {
      inputs.forEach((input) => {
        if (checkBookRadioImageInput(input)) {
          const { options } = input;

          options.forEach((option) => {
            const key = input.name + option.value;

            if (!this._imagesCache[key]) {
              const img = new Image();

              img.src = option.imgSrc;

              this._imagesCache[key] = img;
            }
          });
        }
      });
    }

    public _resetImagesCache() {
      this._imagesCache = {};
    }

    public setFormGroup(
      inputs: TBookInput[],
      values?: IBookInputNameToValueMap,
    ) {
      this.inputs = inputs;

      if (values) {
        this.setFormGroupValues(values);
      }
    }

    /** works as patchValue and applied only to existing inputs, dynamic change is handled */
    public setFormGroupValues(values: IBookInputNameToValueMap) {
      const prev = Object.values(this.formGroup.controls);

      this.formGroup.patchValue(values);

      const next = Object.values(this.formGroup.controls);

      if (!isEqual(prev, next)) {
        this.setFormGroupValues(values);
      }
    }
  };
}

@Component({ template: '' })
export class BookFormCommon
  extends BookFormMixin(class {})
  implements OnDestroy
{
  @Input() set inputs(inputs: TBookInputs) {
    super.inputs = inputs;
  }

  get inputs() {
    return super.inputs;
  }

  /**
   * excluded `hiddenInputs` from `inputs`
   */
  get visibleInputs() {
    return this.inputs.filter(
      (input) => this.hiddenInputs.indexOf(input) === -1,
    );
  }

  /** used to filter inputs from template */
  @Input() hiddenInputs: TBookInputs = [];

  // constructor() {
  //   super();
  // }

  ngOnDestroy() {
    this._ngOnDestroy();
  }
}

// export const BookFormCommon = BookFormMixin(class {});
