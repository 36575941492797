import type { ITeam } from './types';

const Team: ITeam = {
  photoSrc: {
    desktop: 'assets/images/team/common.png',
    mobile: 'assets/images/team/common-mobile.png',
  },
  members: [
    {
      photoSrc: 'assets/images/team/1.png',
      name: 'Fukumi Kazlou',
      position: 'CEO',
      instagramSrc: 'https://www.instagram.com/fukumikozlov/',
      specs: [
        [
          'Position at BÜKI',
          'I’m an idea machine! Sadly, most of my ideas end up in the reject pile.',
        ],
        [
          'Dream wish to fulfil',
          'Create a magical factory where your wildest imaginations can come to life.',
        ],
        [
          'Favorite  book and character',
          "The crow's bakery, I love bath, Elmer, and Where the Wild Things Are",
        ],
      ],
    },
    {
      photoSrc: 'assets/images/team/2.png',
      name: 'Yahor Kazlou',
      position: 'CSO',
      instagramSrc: undefined,
      specs: [
        [
          'Position at BÜKI',
          'Create well-structured and thought-out plans, and then watch as everything goes off the rails anyway.',
        ],
        ['Dream wish to fulfil', 'Create works that will remain in history.'],
        ['Favorite  book and character', 'Wolf (Well, Just You Wait!)'],
      ],
    },
    {
      photoSrc: 'assets/images/team/3.png',
      name: 'Pete Cherviak',
      position: 'CDO',
      instagramSrc: undefined,
      specs: [
        [
          'Position at BÜKI',
          'Dream up all the things I want technology to do for me, and then spend the rest of my time trying to figure out how to make it happen. ',
        ],
        ['Dream wish to fulfil', "Create a global children's charity program."],
        ['Favorite  book and character', 'Lightning McQueen'],
      ],
    },
    {
      photoSrc: 'assets/images/team/4.png',
      name: 'Tanja Tiuh',
      position: 'Illustrator',
      instagramSrc: 'https://www.instagram.com/tiuh_t/',
      specs: [
        [
          'Position at BÜKI',
          'Draw illustrations for picture books and make everyone feel like the main character. ',
        ],
        [
          'Dream wish to fulfil',
          'Create a picture book that will bring back the joy and wonder of childhood memories.',
        ],
        [
          'Favorite  book and character',
          'Moomin, Mary Poppins, Huckleberry Finn Adventure, Karlsson on Yane',
        ],
      ],
    },
    {
      photoSrc: 'assets/images/team/5.png',
      name: 'Shylai Uladzimir',
      position: 'Senior Architect',
      instagramSrc: undefined,
      specs: [
        [
          'Position at BÜKI',
          'I’m a night owl, burning the midnight oil to come up with solutions that no one has ever thought of before.',
        ],
        [
          'Dream wish to fulfil',
          'My dream is to create AR/VR games that will captivate and excite children all over the world.',
        ],
        ['Favorite  book and character', 'DuckTales'],
      ],
    },
    {
      photoSrc: 'assets/images/team/6.png',
      name: 'Yevhen Sokolov',
      position: 'Senior Developer',
      instagramSrc: undefined,
      specs: [
        [
          'Position at BÜKI',
          'I’m a well-oiled machine that turns caffeine into code.',
        ],
        [
          'Dream wish to fulfil',
          'My dream is to create a quest game for children that will stand the test of time and be remembered for generations to come.',
        ],
        ['Favorite  book and character', 'Cheburashka'],
      ],
    },
    {
      photoSrc: 'assets/images/team/7.png',
      name: 'Svetlana Novikova',
      position: 'Illustrator',
      instagramSrc: 'https://www.instagram.com/karton.std/',
      specs: [
        [
          'Position at BÜKI',
          'Creating a picture book so enchanting that even adults will wish they could be kids again.',
        ],
        [
          'Dream wish to fulfil',
          'My dream is to bring the characters from my picture book to life in 3D or full size.',
        ],
        [
          'Favorite  book and character',
          'The Truth About Old People, The Heart and the Bottle',
        ],
      ],
    },
    {
      photoSrc: 'assets/images/team/8.png',
      name: 'Natalia Agapitova',
      position: 'Illustrator',
      instagramSrc: 'https://www.instagram.com/nataly_agapitova/',
      specs: [
        [
          'Position at BÜKI',
          'I draw pictures with all my heart and soul, even when I’m being interrupted by playful children and curious cats.',
        ],
        [
          'Dream wish to fulfil',
          'My dream is to create a picture book that will be loved and cherished by children all over the world.',
        ],
        [
          'Favorite  book and character',
          '5 Nice Mouse, Little Witch, Ghoulia, Tilda',
        ],
      ],
    },
    {
      photoSrc: 'assets/images/team/9.png',
      name: 'Kitune Morino',
      position: 'Assistant Manager',
      instagramSrc: 'https://www.instagram.com/foxkonkonkitune/',
      specs: [
        [
          'Position at BÜKI',
          'Remembering what I need to do while still dreaming big.',
        ],
        ['Dream wish to fulfil', 'My dream is to become a wise forest witch.'],
        [
          'Favorite  book and character',
          'Happy question (Yuko Onari), In response to the stars (Mikie Ando)',
        ],
      ],
    },
  ],
};

export default Team;
