<div class="wrap">
  <app-section-header-gap
    class="wrap__section wrap__section_header-gap"
  ></app-section-header-gap>
  <app-section-text
    class="wrap__section wrap__section_text"
    [articles]="articles"
  ></app-section-text>
  <app-section-footer-banner
    class="wrap__section wrap__section_footer-banner"></app-section-footer-banner>
</div>
