<div
  class="container"
  [ngClass]="{
    'container_active': isActive,
    'container_even': isEven
  }"
  (click)="onTabsNavItemClick()"
>
  <div class="container__image">
    <img
      class="image"
      [ngClass]="{ 'image_active': isActive }"
      [src]="imgSrcActive"
      alt="#"
    >
    <img
      class="image"
      [ngClass]="{ 'image_active': !isActive }"
      [src]="imgSrcInactive"
      alt="#"
    >
  </div>
  <div class="container__title">
    <h3 class="title">{{ title }}</h3>
  </div>
</div>
