import type { TFeaturesData } from './types';

export const featuresData: TFeaturesData = [
  {
    imgSrc: 'assets/images/feature1.png',
    text: ['Make your child’s book special with easy customizations'],
  },
  {
    imgSrc: 'assets/images/feature2.png',
    text: ['Rated 4.8/5'],
  },
  {
    imgSrc: 'assets/images/feature3.png',
    text: ['Ethically responsible'],
  },
  {
    imgSrc: 'assets/images/feature4.png',
    text: ['A great gift for family and friends'],
  },
];
