export const LIMIT_BOOKS_TO_ORDER = 4;

// * db
export const DB_SYSTEM = 'system';
export const DB_PRINT = 'print';
export const DB_GIFTS = 'gifts';
export const DB_GIFT = 'gift';
export const DB_PARAMETERS = 'parameters';
export const DB_DISCOUNT_CAMPAIGNS = 'discountCampaigns';
export const DB_USERS = 'users';
export const DB_STRIPE_CUSTOMERS = 'stripeCustomers';
export const DB_CHECKOUTS = 'checkouts';
export const DB_GIFT_CHECKOUTS = 'giftCheckouts';
export const DB_PAYMENT_METHODS = 'paymentMethods';
export const DB_ORDERS = 'orders';
export const DB_GIFT_ORDERS = 'giftOrders';
export const DB_COUPONS = 'coupons';
export const DB_BOOKS = 'books';
export const DB_TRANSFER = 'transfer';
export const DB_SHARED = 'shared';
export const DB_PRODUCTION = 'production';
