import type { ElementRef, OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { fade } from 'app/animations';
import Subscriber from 'app/subscriber';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { locations } from './location-picker.data';
import type { TLocation } from './location-picker.interfaces';
import { controlPopupPosition } from './location-picker.utils';
import cookie from 'cookie';
import {
  COOKIE_KEY_IP_REDIR,
  COOKIE_VALUE_IP_REDIR_NO,
} from '@shared/cookies/constants';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-location-picker',
  templateUrl: './location-picker.component.html',
  styleUrls: ['./location-picker.component.scss'],
  animations: [fade()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationPickerComponent implements OnInit {
  @Input() locationIndex = 0;

  @Input() locations = locations;

  get location() {
    return this.locations[this.locationIndex];
  }

  // ! do not move delay to animations
  @Input() delayShow: number = 300;

  @Input() delayHide: number = 500;

  @ViewChild('containerElement') set containerElementRef(
    elementRef: ElementRef<HTMLDivElement> | undefined,
  ) {
    this._containerElementRef = elementRef;
    this._controlPosition(elementRef?.nativeElement);
  }

  private _containerElementRef?: ElementRef<HTMLDivElement>;

  public expanded$ = new BehaviorSubject(false);

  private _sub = new Subscriber();

  private _timeoutId?: number;

  constructor(private _route: ActivatedRoute, private _router: Router) {}

  ngOnInit(): void {
    let resizeTimer: number;
    this._sub.push(
      fromEvent(window, 'resize').subscribe(() => {
        // * fixing position on resize
        clearTimeout(resizeTimer);
        this._controlPosition();
      }),
    );
    console.log(this.locations);
  }

  public show(delay: number = this.delayShow) {
    this._setExpanded(true, delay);
  }

  public hide(delay: number = this.delayHide) {
    this._setExpanded(false, delay);
  }

  public select(url: TLocation['url']) {
    document.cookie = cookie.serialize(
      COOKIE_KEY_IP_REDIR,
      COOKIE_VALUE_IP_REDIR_NO,
      { path: '/' },
    );

    window.location.pathname = url;
  }

  private _controlPosition(
    containerEl = this._containerElementRef?.nativeElement,
  ) {
    const parentElement = containerEl?.parentElement;

    if (this.expanded$.value && parentElement) {
      controlPopupPosition(parentElement, 40);
    }
  }

  private _setExpanded(value: boolean, delay: number) {
    this._clearTimeout();

    if (this.expanded$.value !== value) {
      this._timeoutId = window.setTimeout(() => {
        delete this._timeoutId;
        this.expanded$.next(value);
      }, delay);
    }
  }

  private _clearTimeout(): void {
    clearTimeout(this._timeoutId);
    delete this._timeoutId;
  }

  @HostListener('mouseenter')
  private _onMouseenter(): void {
    this.show();
  }

  @HostListener('mouseleave')
  private _onMouseleave(): void {
    this.hide();
  }
}
