/* eslint-disable @typescript-eslint/naming-convention */
import type { ErrorObject } from 'serialize-error';
import type Stripe from 'stripe';
import type { TBookAlias, TBookCover, TBookWrapping } from './book/interfaces';
import type {
  TCountryCode,
  TRegionCode,
  TShippingMethod,
} from './shipping/interfaces';
import type firebase from 'firebase/app';

export type KnownKeys<T> = {
  [K in keyof T]: string extends K ? never : number extends K ? never : K;
} extends { [_ in keyof T]: infer U }
  ? U
  : never;

export type RemoveIndex<T extends Record<any, any>> = Pick<T, KnownKeys<T>>;

export enum EFormPaymentType {
  card,
}

export interface UserData {
  uid?: string;
  /**
   * @deprecated The property should not be used
   */
  email?: string;
  lastBookId?: string;
  /**
   * set `null` to request the value from parameters
   */
  fakeDate?: firebase.firestore.Timestamp | null;
  adminPassword?: string;
}

export interface BookInOrder {
  alias: TBookAlias;
  bookId: string;
  /**
   * 0 - initialized
   * 1 - printing
   * 5 - errored
   * 10 - done
   */
  status: number;
  wrapping: TBookWrapping;
  cover: TBookCover;
  /** added on print stage */
  barBookId?: string;
  /** added on print stage */
  taskPrintBookId?: string;
}

export interface IAddress {
  firstName: string;
  lastName: string;
  /** street */
  addressFirst: string;
  /** apt */
  addressSecond: string;
  /** city */
  city: string;
  postalCode: string;
  country: TCountryCode;
  state: TRegionCode;
  /** without code because it is defined by country field */
  phone: string;
}

export interface IAddressBillingData extends IAddress {
  email: string;
}

export interface IAddressShippingData extends IAddress {}

export interface IPaymentInformation {
  /**
   * for now we are not setting this field as stripe
   * doesn't provide it to us in checkout details
   */
  couponCode?: string;
  /** @see Stripe.Checkout.Session.TotalDetails.amount_discount */
  discountAmount: number;
  /** @see Stripe.Checkout.Session.amount_subtotal */
  productSubtotal: number;
  /**
   * total excluding shipping
   * @see Stripe.Checkout.Session.amount_total
   * @see Stripe.Checkout.Session.TotalDetails.amount_shipping
   */
  productTotal: number;
  /** @see Stripe.Checkout.Session.TotalDetails.amount_shipping */
  shippingPrice: number;
  /** @see Stripe.Checkout.Session.TotalDetails.amount_tax */
  taxPrice: number;
  /** @see Stripe.Checkout.Session.amount_total */
  orderTotal: number;
  /** payment method type */
  paymentMethod: string;
  /** payment intent id */
  paymentIntent: string;
  /** for future different payment types */
  type: EFormPaymentType;
}

export interface IOrderData {
  uid: string;
  orderId: string;
  bitrixDealId?: string;
  createTimeStamp: firebase.firestore.FieldValue;
  books: Array<BookInOrder>;
  /** gift id that applied to the order */
  gift?: string;
  addressBillingData?: IAddressBillingData;
  addressShippingData?: IAddressShippingData;
  shippingMethod?: TShippingMethod;
  /**
   * 20 - printed and not loaded
   * 30 - loaded
   */
  status: number;
  /** stripe customer id */
  customerId?: string;
  /** stripe checkout session id */
  sessionId?: string;
  /** stripe coupon id */
  couponCode?: string;
  userIp: string;
  userAgent: string;
  fbp?: string;
  fbc?: string;
  /** set on BE when checkout session is created */
  paymentInformation?: IPaymentInformation;
  barOrderId?: string;
  barProductId?: string;
  referrer: string;
  hostname: string;
  origin: string;
  processServerId?: string;
  /** describes whether to send order to the print company on 'staging' or not */
  printOnStaging?: boolean;
  error?: ErrorObject;
}

export interface IPaymentIntentRes {
  clientSecret: string;
}

export interface ICouponRequest {
  id: string;
}

export interface ICouponError {
  id: string;
  error: string;
}

export type TCouponData = Stripe.Coupon | ICouponRequest | ICouponError;

export type TCheckoutData = Stripe.Checkout.Session | { error: string };

export interface IStripeCustomer {
  customer_id: string;
  setup_secret: string | null;
}

export type TStripeCustomerData = IStripeCustomer | { error: string };

export interface IDateInterval {
  start: Date;
  end: Date;
}
