import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ESelectButtonTheme } from './enums';

@Component({
  selector: 'app-select-button',
  templateUrl: './select-button.component.html',
  styleUrls: ['./select-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectButtonComponent {
  @Input() hover?: boolean;

  @Input() active?: boolean;

  @Input() disabled?: boolean;

  @Input() theme?: ESelectButtonTheme;

  @Output() buttonClick = new EventEmitter();

  public readonly ESelectButtonTheme = ESelectButtonTheme;

  // constructor() {}

  public onButtonClick(): void {
    this.buttonClick.emit();
  }
}
