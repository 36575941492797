import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-section-story',
  templateUrl: './section-story.component.html',
  styleUrls: ['./section-story.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionStoryComponent {
  constructor() {}
}
