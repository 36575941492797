<div class="container">
  <div class="container__header">
    <div class="tabs-nav">
      <app-tabs-nav-item
        class="tabs-nav__item"
        *ngFor="let tab of children.toArray(); let index = index"
        [imgSrcActive]="tab.imgSrcActive"
        [imgSrcInactive]="tab.imgSrcInactive"
        [title]="tab.title"
        [isActive]="index === activeTabIndex"
        [isEven]="!!(index % 2)"
        (tabsNavItemClick)="onTabsNavItemClick(index)"
      ></app-tabs-nav-item>
    </div>
  </div>
  <div
    *ngFor="let tab of children.toArray(); let index = index"
    class="container__content"
    [ngClass]="{
      'container__content_active': index === activeTabIndex,
      'container__content_even': !!(index % 2)
    }"
  >
    <ng-container *ngTemplateOutlet="tab.template"></ng-container>
  </div>
</div>
