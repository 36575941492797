import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionBannerMermaidComponent } from './section-banner-mermaid.component';
import { ButtonModule } from '../button/button.module';

@NgModule({
  declarations: [SectionBannerMermaidComponent],
  imports: [CommonModule, ButtonModule],
  exports: [SectionBannerMermaidComponent],
})
export class SectionBannerMermaidModule {}
