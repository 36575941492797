export const environment = {
  production: true,
  localBackend: false,
  locales: ['en-US', 'jp-JP'],
  defaultLocale: 'en-US',
  firebaseConfig: {
    apiKey: 'AIzaSyCAyc0xpjL9SgwoLFq_-m7f32qFItQieZs',
    authDomain: 'us-buki.firebaseapp.com',
    projectId: 'us-buki',
    storageBucket: 'us-buki.appspot.com',
    messagingSenderId: '1050397853575',
    appId: '1:1050397853575:web:aef8fbd3b47d320444f522',
    databaseURL: 'https://us-buki-default-rtdb.firebaseio.com',
  },
};
