export const locations = [
  {
    imgSrc: (require('./assets/US.png').default ||
      require('./assets/US.png')) as string,
    name: 'United States',
    url: '/',
  },
  {
    imgSrc: (require('./assets/JP.png').default ||
      require('./assets/JP.png')) as string,
    name: '日本',
    url: '/jp/',
  },
] as const;
