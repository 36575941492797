<div class="container">
  <img
    class="container__image"
    [src]="
      (isLtMd | async)
        ? data.photoSrc.mobile
        : data.photoSrc.desktop
    "
    alt="#"
  >
  <div class="container__content">
    <ng-container *ngIf="(isLtMd | async); then gallery else grid"></ng-container>
    <ng-template #grid>
      <app-grid [members]="data.members"></app-grid>
    </ng-template>
    <ng-template #gallery>
      <app-gallery [members]="data.members"></app-gallery>
    </ng-template>
  </div>
</div>
