<button
  class="button"
  [ngClass]="{
    'button_active': active,
    'button_theme-alt': theme === ESelectButtonTheme.alt
  }"
  [disabled]="disabled"
>
  <svg-icon
    class="button__svg-icon"
    src="assets/images/icons/input-select-arrow.svg"
  ></svg-icon>
</button>
