<section class="wrap">
  <div
    *ngFor="let article of articles"
    class="wrap__container"
  >
    <article class="container">
      <div class="container__caption">
        <h1 class="caption">{{ article.caption }}</h1>
      </div>
      <div
        *ngFor="let passage of article.passages"
        class="container__passage"
      >
        <article class="passage">
          <div
            *ngFor="let item of passage.items"
            class="passage__item"
            [ngClass]="['passage__item_' + item.type]"
          >
            <h2
              *ngIf="articlePassageItemToCaption(item) as caption"
              class="passage-caption"
              [innerHTML]="caption.text"
            ></h2>
            <app-passage-text
              *ngIf="articlePassageItemToText(item) as text"
              [text]="text.text"
            ></app-passage-text>
            <app-passage-ordered-list
              *ngIf="articlePassageItemToOrderedList(item) as orderedList"
              [items]="orderedList.items"
              [listStyleType]="orderedList.listStyleType"
            ></app-passage-ordered-list>
          </div>
        </article>
      </div>
    </article>
  </div>
</section>
