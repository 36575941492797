import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spec',
  templateUrl: './spec.component.html',
  styleUrls: ['./spec.component.scss'],
})
export class SpecComponent {
  @Input() key: string;

  @Input() value: string;

  // constructor() {}
}
