<div class="header"
  [ngClass]="{
    'header_column': direction === 'column'
  }"
>
  <h2 class="header__title">{{ title }}</h2>
  <div class="header__content">
    <ng-content></ng-content>
  </div>
</div>
