<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div class="container__background"></div>
      <div class="container__content">
        <div class="content">
          <app-image-title
            class="content__title"
            type="story"
          ></app-image-title>
          <p class="content__text">
            Once upon a time, there was a girl who was fascinated by books. She
            loved their mysterious stories and beautiful illustrations. She
            spent lots of time imagining herself as the main character in her
            own story.
          </p>
          <p class="content__text">
            In her imagination, she could become anyone. She could ride a dragon
            and fly around, or be a princess who could talk to animals, or even
            become a superhero to save the world.
          </p>
          <p class="content__text">
            But one day, she realized something. “The characters in these
            stories are always special and unique,” she thought to herself. “I’m
            not special. I’m just an ordinary kid.” So she put her wonderful
            fantasy world into a treasure box and locked it away.
          </p>
          <p class="content__text">
            Time passed and the girl grew up and became a mother. When she gave
            birth to her precious child, she remembered the loneliness she used
            to feel when she wished to be someone else. She thought, “I wish my
            child could believe in himself, that he is special and can be
            anything he wants to be.”
          </p>
          <p class="content__text">
            As she wished hard, friends who could use magic (illustration and
            technology) joined her. Together, they were able to create books
            where anyone could be the main character. In this way, her dream was
            fulfilled. Now, whose mysterious and fascinating dream will come
            true next?
          </p>
        </div>
      </div>
      <div class="container__character"></div>
    </div>
  </div>
</div>
