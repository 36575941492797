export const SHIPPING_METHOD_STANDART = 'standart';

export const SHIPPING_METHOD_FAST = 'fast';

export const SHIPPING_METHOD_EXPRESS = 'express';

export const SHIPPING_METHODS = [
  SHIPPING_METHOD_STANDART,
  SHIPPING_METHOD_FAST,
  SHIPPING_METHOD_EXPRESS,
] as const;
