import {
  EBookSelectInputType,
  IBookSelectInput,
  IBookSelectInputOption,
} from './types';
import { EBookInputType, TGetBookInput } from '../shared';

export const getBookSelectInputOptionData = ({
  label,
  imgSrc,
  value,
}: IBookSelectInputOption): IBookSelectInputOption => ({
  label,
  imgSrc,
  value,
});

export const getBookSelectInputData: TGetBookInput<
  IBookSelectInput,
  'type',
  'selectType'
> = (params) =>
  ({
    selectType: EBookSelectInputType.default,
    ...params,
    type: EBookInputType.select,
  } as const);
