import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutComponent } from './about.component';
import { SectionHeaderBannerComponent } from './components/section-header-banner/section-header-banner.component';
import { SectionPromiseComponent } from './components/section-promise/section-promise.component';
import { SectionStoryComponent } from './components/section-story/section-story.component';
import { SectionMediaComponent } from './components/section-media/section-media.component';
import { ImageTitleModule } from 'src/app/shared/image-title/image-title.module';
import { SectionTeamModule } from './components/section-team/section-team.module';
import { SectionCompanyModule } from '../../shared/section-company/section-company.module';
import { SectionCollaborationModule } from 'app/shared/section-collaboration/section-collaboration.module';
import { SectionDucksModule } from 'app/shared/section-ducks/section-ducks.module';

@NgModule({
  declarations: [
    AboutComponent,
    SectionHeaderBannerComponent,
    SectionPromiseComponent,
    SectionStoryComponent,
    SectionMediaComponent,
  ],
  imports: [
    CommonModule,
    ImageTitleModule,
    SectionTeamModule,
    SectionCompanyModule,
    SectionCollaborationModule,
    SectionDucksModule,
  ],
})
export class AboutModule {}
