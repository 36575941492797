import type { Route } from '@angular/router';
import {
  ROUTE_PATH_AUTH,
  ROUTE_PATH_BOOK,
  ROUTE_PATH_BOOK_STARRY_DREAM,
  ROUTE_PATH_BOOK_STORY_OF_GRANDPA_GRANDMA,
  ROUTE_PATH_BOOK_WELCOME_TO_THE_WORLD,
  ROUTE_PATH_BOOK_WHERE_IS_BIRTHDAY_CAKE,
  ROUTE_PATH_BOOK_WHOS_BIRTHDAY_TOMORROW,
  ROUTE_PATH_GIFT_BUY,
  ROUTE_PATH_GIFT_BUY_SPECIAL,
  ROUTE_PATH_GIFT_ORDER,
  ROUTE_PATH_GIFT_THANKS,
  ROUTE_PATH_GIFT_USE,
  ROUTE_PATH_GIFT_USE_NO_LOGO,
  ROUTE_PATH_MY_PAGE,
  ROUTE_PATH_PARAM_AUTH_TYPE,
  ROUTE_PATH_PARAM_GIFT_ID,
  ROUTE_PATH_PARAM_ORDER_ID,
} from './app-routing.constants';
import { GiftBuyPageComponent } from './pages/gift-buy-page/gift-buy-page.component';
import { LayoutFooter, LayoutHeader } from './app-routing.enum';
import { GiftBuySpecialPageComponent } from './pages/gift-buy-special-page/gift-buy-special-page.component';
import { GiftUsePageComponent } from './pages/gift-use-page/gift-use-page.component';
import { GiftThanksPageComponent } from './pages/gift-thanks-page/gift-thanks-page.component';
import { GiftOrderPageComponent } from './pages/gift-order-page/gift-order-page.component';
import { GiftOrderPageResolver } from './pages/gift-order-page/gift-order-page.resolver';
import { BookComponent } from './pages/book/book.component';
import { AuthPageComponent } from './pages/auth-page/auth-page.component';
import { AuthPageGuard } from './pages/auth-page/auth-page.guard';
import { MyPageComponent } from './pages/my-page/my-page.component';
import { MyPageGuard } from './pages/my-page/my-page.guard';

export const ROUTE_BOOK: Route = {
  path: ROUTE_PATH_BOOK,
  component: BookComponent,
  data: {
    LayoutHeader: LayoutHeader.shown,
    LayoutFooter: LayoutFooter.shown,
    title: "Preview | BÜKI Personalized children's books",
  },
};

export const ROUTE_BOOK_STARRY_DREAM: Route = {
  path: ROUTE_PATH_BOOK_STARRY_DREAM,
  loadChildren: () =>
    import(
      './pages/book/pages/book-starry-dream/book-starry-dream.module'
    ).then((m) => m.BookStarryDreamModule),
  data: {
    LayoutHeader: LayoutHeader.shown,
    LayoutFooter: LayoutFooter.shown,
    title: "Preview | BÜKI Personalized children's books",
    meta_title: "Preview | BÜKI Personalized children's books",
    og_url: 'https://hellobuki.com/book/starry-dream/',
  },
};

export const ROUTE_BOOK_WELCOME_TO_THE_WORLD: Route = {
  path: ROUTE_PATH_BOOK_WELCOME_TO_THE_WORLD,
  loadChildren: () =>
    import(
      './pages/book/pages/book-welcome-to-the-wold/book-welcome-to-the-wold.module'
    ).then((m) => m.BookWelcomeToTheWoldModule),
  data: {
    LayoutHeader: LayoutHeader.shown,
    LayoutFooter: LayoutFooter.shown,
    title: "Preview | BÜKI Personalized children's books",
    meta_title: "Preview | BÜKI Personalized children's books",
    og_url: 'https://hellobuki.com/book/welcome-to-the-world/',
  },
};

export const ROUTE_BOOK_WHOS_BIRTHDAY_TOMORROW: Route = {
  path: ROUTE_PATH_BOOK_WHOS_BIRTHDAY_TOMORROW,
  loadChildren: () =>
    import(
      './pages/book/pages/book-whos-birthday-tomorrow/book-whos-birthday-tomorrow.module'
    ).then((m) => m.BookWhosBirthdayTomorrowModule),
  data: {
    LayoutHeader: LayoutHeader.shown,
    LayoutFooter: LayoutFooter.shown,
    title: "Preview | BÜKI Personalized children's books",
    meta_title: "Preview | BÜKI Personalized children's books",
    description:
      'Make birthday planning a blast with this customizable book! Follow along as the characters prepare for your child’s big day and enjoy the chaos of party planning.',
    og_description:
      'Make birthday planning a blast with this customizable book! Follow along as the characters prepare for your child’s big day and enjoy the chaos of party planning.',
    og_url: 'https://hellobuki.com/book/whos-birthday-tomorrow/',
  },
};

export const ROUTE_BOOK_WHERE_IS_BIRTHDAY_CAKE: Route = {
  path: ROUTE_PATH_BOOK_WHERE_IS_BIRTHDAY_CAKE,
  loadChildren: () =>
    import(
      './pages/book/pages/book-where-is-birthday-cake/book-where-is-birthday-cake.module'
    ).then((m) => m.BookWhereIsBirthdayCakeModule),
  data: {
    LayoutHeader: LayoutHeader.shown,
    LayoutFooter: LayoutFooter.shown,
    title: 'Book | プレビュー',
    meta_title: 'BÜKI 物語の主人公になれる絵本（ブーキー）',
    og_url: 'https://hellobuki.com/book/where_is_birthday_cake/',
  },
};

export const ROUTE_BOOK_STORY_OF_GRANDPA_GRANDMA: Route = {
  path: ROUTE_PATH_BOOK_STORY_OF_GRANDPA_GRANDMA,
  loadChildren: () =>
    import(
      './pages/book/pages/book-story-of-grandpa-grandma/book-story-of-grandpa-grandma.module'
    ).then((m) => m.BookStoryOfGrandpaGrandmaModule),
  data: {
    LayoutHeader: LayoutHeader.shown,
    LayoutFooter: LayoutFooter.shown,
    title: 'Book | プレビュー',
    //todo SOGG
    meta_title: 'BÜKI 物語の主人公になれる絵本（ブーキー）',
    og_url: 'https://hellobuki.com/book/story_of_grandpa_grandma/',
  },
};

export const ROUTE_GIFT_BUY: Route = {
  path: ROUTE_PATH_GIFT_BUY,
  component: GiftBuyPageComponent,
  data: {
    LayoutHeader: LayoutHeader.shown,
    LayoutFooter: LayoutFooter.shown,
    title: 'ギフトコードで絵本を贈る',
    meta_title: 'BÜKI 物語の主人公になれる絵本（ブーキー）',
    description:
      "ギフトコードのご利用で、もっと手軽に簡単に、'BÜKIのオリジナル絵本を大切な相手に贈ることができます。",
    og_title: 'ギフトコードで手軽に絵本を贈る',
    og_description:
      "ギフトコードのご利用で、もっと手軽に簡単に、'BÜKIのオリジナル絵本を大切な相手に贈ることができます。",
    og_url: `https://hellobuki.com/${ROUTE_PATH_GIFT_BUY}/`,
    og_image: 'https://hellobuki.com/assets/images/ogp-gift.jpg',
    og_site_name: 'BÜKI（ブーキー）',
    fb_app_id: '174662759861666',
    og_type: 'website',
    twitter_card: 'summary',
  },
};

export const ROUTE_GIFT_BUY_SPECIAL: Route = {
  path: ROUTE_PATH_GIFT_BUY_SPECIAL,
  component: GiftBuySpecialPageComponent,
  data: {
    LayoutHeader: LayoutHeader.shown,
    LayoutFooter: LayoutFooter.shown,
    // title: 'FAQ | よくある質問',
    // meta_title: 'BÜKI 物語の主人公になれる絵本（ブーキー）',
    // description: 'BÜKIのよくある質問はこちら。',
    // og_title: 'BÜKIに関するよくある質問',
    // og_description: 'BÜKIのよくある質問はこちら。',
    // og_url: 'https://hellobuki.com/faq/',
    // og_image: 'https://hellobuki.com/assets/images/ogp-buki.jpg',
    // og_site_name: 'BÜKI（ブーキー）',
    // fb_app_id: '174662759861666',
    // og_type: 'website',
    // twitter_card: 'summary'
  },
};

export const ROUTE_GIFT_USE: Route = {
  path: ROUTE_PATH_GIFT_USE,
  component: GiftUsePageComponent,
  data: {
    LayoutHeader: LayoutHeader.shown,
    LayoutFooter: LayoutFooter.shown,
    title: 'ギフトコードを使う',
    meta_title: 'BÜKI 物語の主人公になれる絵本（ブーキー）',
    description:
      '贈られたギフトコードで、絵本を作りましょう。主人公となる子の名前や誕生日をいれれば、世界に一冊の絵本のできあがりです！',
    og_title: 'ギフトコードを使う',
    og_description:
      '贈られたギフトコードで、絵本を作りましょう。主人公となる子の名前や誕生日をいれれば、世界に一冊の絵本のできあがりです！',
    og_url: `https://hellobuki.com/${ROUTE_PATH_GIFT_USE}/`,
    og_image: 'https://hellobuki.com/assets/images/ogp-gift.jpg',
    og_site_name: 'BÜKI（ブーキー）',
    fb_app_id: '174662759861666',
    og_type: 'website',
    twitter_card: 'summary',
  },
};

export const ROUTE_GIFT_USE_WITH_GIFT_ID: Route = Object.assign(
  {},
  ROUTE_GIFT_USE,
  {
    path: `${ROUTE_PATH_GIFT_USE}/:${ROUTE_PATH_PARAM_GIFT_ID}`,
  },
);

export const ROUTE_GIFT_USE_NO_LOGO: Route = {
  path: ROUTE_PATH_GIFT_USE_NO_LOGO,
  component: GiftUsePageComponent,
  data: {
    LayoutHeader: LayoutHeader.hidden,
    LayoutFooter: LayoutFooter.hidden,
    title: 'ギフトコードを使う',
    meta_title: 'BÜKI 物語の主人公になれる絵本（ブーキー）',
    description:
      '贈られたギフトコードで、絵本を作りましょう。主人公となる子の名前や誕生日をいれれば、世界に一冊の絵本のできあがりです！',
    og_title: 'ギフトコードを使う',
    og_description:
      '贈られたギフトコードで、絵本を作りましょう。主人公となる子の名前や誕生日をいれれば、世界に一冊の絵本のできあがりです！',
    og_url: `https://hellobuki.com/${ROUTE_PATH_GIFT_USE_NO_LOGO}/`,
    og_image: 'https://hellobuki.com/assets/images/ogp-gift.jpg',
    og_site_name: 'BÜKI（ブーキー）',
    fb_app_id: '174662759861666',
    og_type: 'website',
    twitter_card: 'summary',
  },
};

export const ROUTE_GIFT_USE_NO_LOGO_WITH_GIFT_ID: Route = Object.assign(
  {},
  ROUTE_GIFT_USE_NO_LOGO,
  {
    path: `${ROUTE_PATH_GIFT_USE_NO_LOGO}/:${ROUTE_PATH_PARAM_GIFT_ID}`,
  },
);

export const ROUTE_GIFT_THANKS: Route = {
  path: ROUTE_PATH_GIFT_THANKS,
  component: GiftThanksPageComponent,
  data: {
    title: 'ギフトコードで絵本を贈る ',
    meta_title: 'BÜKI 物語の主人公になれる絵本（ブーキー）',
    description:
      "ギフトコードのご利用で、もっと手軽に簡単に、'BÜKIのオリジナル絵本を大切な相手に贈ることができます。",
    og_title: 'ギフトコードで手軽に絵本を贈る',
    og_description:
      "ギフトコードのご利用で、もっと手軽に簡単に、'BÜKIのオリジナル絵本を大切な相手に贈ることができます。",
    og_url: `https://hellobuki.com/${ROUTE_PATH_GIFT_THANKS}/`,
    og_image: 'https://hellobuki.com/assets/images/ogp-gift.jpg',
    og_site_name: 'BÜKI（ブーキー）',
    fb_app_id: '174662759861666',
    og_type: 'website',
    twitter_card: 'summary',
  },
};

export const ROUTE_GIFT_ORDER: Route = {
  path: ROUTE_PATH_GIFT_ORDER,
  component: GiftOrderPageComponent,
  data: {
    LayoutHeader: LayoutHeader.hidden,
    LayoutFooter: LayoutFooter.hidden,
    title: 'Checkout | お会計',
    meta_title: 'BÜKI 物語の主人公になれる絵本（ブーキー）',
  },
  resolve: {
    resolved: GiftOrderPageResolver,
  },
};

export const ROUTE_GIFT_ORDER_WITH_ORDER_ID: Route = Object.assign(
  {},
  ROUTE_GIFT_ORDER,
  {
    path: `${ROUTE_PATH_GIFT_ORDER}/:${ROUTE_PATH_PARAM_ORDER_ID}`,
  },
);

export const ROUTE_AUTH: Route = {
  path: `${ROUTE_PATH_AUTH}`,
  component: AuthPageComponent,
  canActivate: [AuthPageGuard],
  data: {
    LayoutHeader: LayoutHeader.shown,
    LayoutFooter: LayoutFooter.shown,
  },
};

export const ROUTE_AUTH_WITH_TYPE: Route = {
  path: `${ROUTE_PATH_AUTH}/:${ROUTE_PATH_PARAM_AUTH_TYPE}`,
  component: AuthPageComponent,
  canActivate: [AuthPageGuard],
  data: {
    LayoutHeader: LayoutHeader.shown,
    LayoutFooter: LayoutFooter.shown,
  },
};

export const ROUTE_MY_PAGE: Route = {
  path: `${ROUTE_PATH_MY_PAGE}`,
  component: MyPageComponent,
  canActivate: [MyPageGuard],
  data: {
    LayoutHeader: LayoutHeader.shown,
    LayoutFooter: LayoutFooter.shown,
  },
};

export const ROUTE_MY_PAGE_WITH_ORDER_ID: Route = {
  path: `${ROUTE_PATH_MY_PAGE}/:${ROUTE_PATH_PARAM_ORDER_ID}`,
  component: MyPageComponent,
  canActivate: [MyPageGuard],
  data: {
    LayoutHeader: LayoutHeader.shown,
    LayoutFooter: LayoutFooter.shown,
  },
};
