import type { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { resetOnBeforeUnload, setOnBeforeUnload } from 'app/functions';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit, OnDestroy {
  // constructor() {}

  ngOnInit() {
    setOnBeforeUnload(`
      Thank you for checking out books!\n
      Can you please tell us how we can improve (or what issues you found)?\n
      We will send you a 5% discount coupon to your e-mail to thank you.
    `);
  }

  ngOnDestroy() {
    resetOnBeforeUnload();
  }
}
