import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EBookPointType, IBookPoint } from 'src/app/data/books/points/types';
import { BehaviorSubject } from 'rxjs';
import { slideY } from 'src/app/animations';

@Component({
  selector: 'app-point',
  templateUrl: './point.component.html',
  styleUrls: ['./point.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideY()],
})
export class PointComponent {
  @Input() caption: IBookPoint['caption'];

  @Input() description: IBookPoint['description'];

  @Input() type = EBookPointType.default;

  private _shown = new BehaviorSubject(false);

  public shown = this._shown.asObservable();

  get containerClass() {
    const base = 'container';
    const arr = [base];

    if (this.type === EBookPointType.additional) {
      arr.push(`${base}_additional`);
    }

    return arr;
  }

  // constructor() {}

  public toggle(): void {
    if (this._shown.value) {
      this.hide();
    } else {
      this.show();
    }
  }

  public show(): void {
    this._shown.next(true);
  }

  public hide(): void {
    this._shown.next(false);
  }
}
