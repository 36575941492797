<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div class="container__title">
        <app-image-title type="reviews"></app-image-title>
      </div>
      <div class="container__content">
        <div
          class="yotpo yotpo-reviews-carousel"
          data-header-customisation-enabled="1"
          data-header-customisation-color="#56575f"
          data-header-customisation-font-size="18"
          data-header-customisation-text="Reviews"
          data-header-customisation-alignment="left"
          data-background-color="transparent"
          data-mode="most_recent"
          data-type="product"
          data-count="9"
          data-show-bottomline="1"
          data-autoplay-enabled="1"
          data-autoplay-speed="4500"
          data-show-navigation="1"
        >
          &nbsp;
        </div>
      </div>
    </div>
  </div>
  <i class="wrap__monster"></i>
</div>
