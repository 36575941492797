import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
} from '@angular/core';
import { TNavigationItemChildren } from '../../types';
import { slideY } from 'src/app/animations';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  animations: [slideY()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemComponent {
  @Input() title?: string;

  @Input() link?: string;

  @Input() href?: string;

  @Input() target: '_blank' | '_self' | '_parent' | '_top' = '_self';

  @Input() svgIconSrc?: string;

  @Input() children: TNavigationItemChildren = [];

  private _isDropdownActive = new BehaviorSubject(false);

  public isDropdownActive = this._isDropdownActive.asObservable();

  private _dropdownTimer?: number;

  constructor() {}

  @HostListener('mouseenter')
  private _onMouseenter(): void {
    window.clearTimeout(this._dropdownTimer);

    this._isDropdownActive.next(true);
  }

  @HostListener('mouseleave')
  private _onMouseleave(): void {
    this._dropdownTimer = window.setTimeout(() => {
      this._isDropdownActive.next(false);

      delete this._dropdownTimer;
    }, 100);
  }
}
