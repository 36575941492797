export const Countries = [
  {
    name: 'United States',
    code: 'US',
  },
] as const;

const Regions = [
  {
    name: 'Alaska',
    code: 'AK',
    latitude: 63.607216680330794,
    longitude: -151.69921875000003,
    timeZone: 'America/Anchorage',
  },
  {
    name: 'Alabama',
    code: 'AL',
    latitude: 33.2588817,
    longitude: -86.8295337,
    timeZone: 'America/Chicago',
  },
  {
    name: 'Arkansas',
    code: 'AR',
    latitude: 35.2048883,
    longitude: -92.4479108,
    timeZone: 'America/Chicago',
  },
  {
    name: 'Arizona',
    code: 'AZ',
    latitude: 34.395342,
    longitude: -111.763275,
    timeZone: 'America/Phoenix',
  },
  {
    name: 'California',
    code: 'CA',
    latitude: 36.7014631,
    longitude: -118.755997,
    timeZone: 'America/Los_Angeles',
  },
  {
    name: 'Colorado',
    code: 'CO',
    latitude: 38.7251776,
    longitude: -105.607716,
    timeZone: 'America/Denver',
  },
  {
    name: 'Connecticut',
    code: 'CT',
    latitude: 41.6500201,
    longitude: -72.7342163,
    timeZone: 'America/New_York',
  },
  {
    name: 'District of Columbia',
    code: 'DC',
    latitude: 38.89384735,
    longitude: -76.98804281910948,
    timeZone: 'America/New_York',
  },
  {
    name: 'Delaware',
    code: 'DE',
    latitude: 38.6920451,
    longitude: -75.4013315,
    timeZone: 'America/New_York',
  },
  {
    name: 'Florida',
    code: 'FL',
    latitude: 27.7567667,
    longitude: -81.4639835,
    timeZone: 'America/New_York',
  },
  {
    name: 'Georgia',
    code: 'GA',
    latitude: 32.3293809,
    longitude: -83.1137366,
    timeZone: 'America/New_York',
  },
  {
    name: 'Hawaii',
    code: 'HI',
    latitude: 19.593801499999998,
    longitude: -155.42837009716908,
    timeZone: 'Pacific/Honolulu',
  },
  {
    name: 'Iowa',
    code: 'IA',
    latitude: 41.9216734,
    longitude: -93.3122705,
    timeZone: 'America/Chicago',
  },
  {
    name: 'Idaho',
    code: 'ID',
    latitude: 43.6447642,
    longitude: -114.015407,
    timeZone: 'America/Denver',
  },
  {
    name: 'Illinois',
    code: 'IL',
    latitude: 40.0796606,
    longitude: -89.4337288,
    timeZone: 'America/Chicago',
  },
  {
    name: 'Indiana',
    code: 'IN',
    latitude: 40.3270127,
    longitude: -86.1746933,
    timeZone: 'America/Indiana/Indianapolis',
  },
  {
    name: 'Kansas',
    code: 'KS',
    latitude: 38.27312,
    longitude: -98.5821872,
    timeZone: 'America/Chicago',
  },
  {
    name: 'Kentucky',
    code: 'KY',
    latitude: 37.5726028,
    longitude: -85.1551411,
    timeZone: 'America/New_York',
  },
  {
    name: 'Louisiana',
    code: 'LA',
    latitude: 30.8703881,
    longitude: -92.007126,
    timeZone: 'America/Chicago',
  },
  {
    name: 'Massachusetts',
    code: 'MA',
    latitude: 42.3788774,
    longitude: -72.032366,
    timeZone: 'America/New_York',
  },
  {
    name: 'Maryland',
    code: 'MD',
    latitude: 39.5162401,
    longitude: -76.9382069,
    timeZone: 'America/New_York',
  },
  {
    name: 'Maine',
    code: 'ME',
    latitude: 45.709097,
    longitude: -68.8590201,
    timeZone: 'America/New_York',
  },
  {
    name: 'Michigan',
    code: 'MI',
    latitude: 43.6211955,
    longitude: -84.6824346,
    timeZone: 'America/Detroit',
  },
  {
    name: 'Minnesota',
    code: 'MN',
    latitude: 45.9896587,
    longitude: -94.6113288,
    timeZone: 'America/Chicago',
  },
  {
    name: 'Missouri',
    code: 'MO',
    latitude: 38.7604815,
    longitude: -92.5617875,
    timeZone: 'America/Chicago',
  },
  {
    name: 'Mississippi',
    code: 'MS',
    latitude: 32.9715285,
    longitude: -89.7348497,
    timeZone: 'America/Chicago',
  },
  {
    name: 'Montana',
    code: 'MT',
    latitude: 47.3752671,
    longitude: -109.638757,
    timeZone: 'America/Denver',
  },
  {
    name: 'North Carolina',
    code: 'NC',
    latitude: 35.6729639,
    longitude: -79.0392919,
    timeZone: 'America/New_York',
  },
  {
    name: 'North Dakota',
    code: 'ND',
    latitude: 47.6201461,
    longitude: -100.540737,
    timeZone: 'America/Chicago',
  },
  {
    name: 'Nebraska',
    code: 'NE',
    latitude: 41.7370229,
    longitude: -99.5873816,
    timeZone: 'America/Chicago',
  },
  {
    name: 'New Hampshire',
    code: 'NH',
    latitude: 43.4849133,
    longitude: -71.6553992,
    timeZone: 'America/New_York',
  },
  {
    name: 'New Jersey',
    code: 'NJ',
    latitude: 40.0757384,
    longitude: -74.4041622,
    timeZone: 'America/New_York',
  },
  {
    name: 'New Mexico',
    code: 'NM',
    latitude: 34.5708167,
    longitude: -105.993007,
    timeZone: 'America/Denver',
  },
  {
    name: 'Nevada',
    code: 'NV',
    latitude: 39.5158825,
    longitude: -116.8537227,
    timeZone: 'America/Los_Angeles',
  },
  {
    name: 'New York',
    code: 'NY',
    latitude: 40.7127281,
    longitude: -74.0060152,
    timeZone: 'America/New_York',
  },
  {
    name: 'Ohio',
    code: 'OH',
    latitude: 40.2253569,
    longitude: -82.6881395,
    timeZone: 'America/New_York',
  },
  {
    name: 'Oklahoma',
    code: 'OK',
    latitude: 34.9550817,
    longitude: -97.2684063,
    timeZone: 'America/Chicago',
  },
  {
    name: 'Oregon',
    code: 'OR',
    latitude: 43.9792797,
    longitude: -120.737257,
    timeZone: 'America/Los_Angeles',
  },
  {
    name: 'Pennsylvania',
    code: 'PA',
    latitude: 40.9699889,
    longitude: -77.7278831,
    timeZone: 'America/New_York',
  },
  {
    name: 'Rhode Island',
    code: 'RI',
    latitude: 41.7962409,
    longitude: -71.5992372,
    timeZone: 'America/New_York',
  },
  {
    name: 'South Carolina',
    code: 'SC',
    latitude: 33.6874388,
    longitude: -80.4363743,
    timeZone: 'America/New_York',
  },
  {
    name: 'South Dakota',
    code: 'SD',
    latitude: 44.6471761,
    longitude: -100.348761,
    timeZone: 'America/Chicago',
  },
  {
    name: 'Tennessee',
    code: 'TN',
    latitude: 35.7730076,
    longitude: -86.2820081,
    timeZone: 'America/Chicago',
  },
  {
    name: 'Texas',
    code: 'TX',
    latitude: 31.2638905,
    longitude: -98.5456116,
    timeZone: 'America/Chicago',
  },
  {
    name: 'Utah',
    code: 'UT',
    latitude: 39.4225192,
    longitude: -111.714358,
    timeZone: 'America/Denver',
  },
  {
    name: 'Virginia',
    code: 'VA',
    latitude: 37.1232245,
    longitude: -78.4927721,
    timeZone: 'America/New_York',
  },
  {
    name: 'Vermont',
    code: 'VT',
    latitude: 44.5990718,
    longitude: -72.5002608,
    timeZone: 'America/New_York',
  },
  {
    name: 'Washington',
    code: 'WA',
    latitude: 38.8950368,
    longitude: -77.0365427,
    timeZone: 'America/Los_Angeles',
  },
  {
    name: 'Wisconsin',
    code: 'WI',
    latitude: 44.4308975,
    longitude: -89.6884637,
    timeZone: 'America/Chicago',
  },
  {
    name: 'West Virginia',
    code: 'WV',
    latitude: 38.4758406,
    longitude: -80.8408415,
    timeZone: 'America/New_York',
  },
  {
    name: 'Wyoming',
    code: 'WY',
    latitude: 43.1700264,
    longitude: -107.568534,
    timeZone: 'America/Denver',
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
    latitude: 18.2247706,
    longitude: -66.4858295,
    timeZone: 'America/Puerto_Rico',
  },
  {
    name: 'US Armed Forces Europe',
    code: 'AE',
    latitude: 49.15296965617042,
    longitude: 10.898437500000002,
    timeZone: 'Europe/Paris',
  },
  {
    name: 'US Armed Forces Pacific',
    code: 'AP',
    latitude: 36.421282443649496,
    longitude: 138.03222656250003,
    timeZone: 'Pacific/Honolulu',
  },
  {
    name: 'US Armed Forces - Americas',
    code: 'AA',
    latitude: 38.8950368,
    longitude: -77.0365427,
    timeZone: 'America/New_York',
  },
] as const;

export default Regions;
