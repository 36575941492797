import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGiftSpecialComponent } from './form-gift-special.component';
import { FormHeaderModule } from '../form-header/form-header.module';
import { InputModule } from '../input/input.module';

@NgModule({
  declarations: [FormGiftSpecialComponent],
  imports: [CommonModule, FormHeaderModule, InputModule],
  exports: [FormGiftSpecialComponent],
})
export class FormGiftSpecialModule {}
