import { IBookRadioColorInput, IBookRadioColorInputOption } from './types';
import { EBookInputType, TGetBookInput } from '../shared';

export const getBookRadioColorInputOptionData = ({
  color,
  value,
}: IBookRadioColorInputOption): IBookRadioColorInputOption => ({
  color,
  value,
});

export const getBookRadioColorInputData: TGetBookInput<IBookRadioColorInput> = (
  params,
) =>
  ({
    ...params,
    type: EBookInputType.radioColor,
  } as const);
