<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div
        *ngIf="purposes"
        class="container__item"
      >
        <app-input
          type="select"
          placeholder="Choose by occasion"
          [(ngModel)]="purpose"
          [theme]="EInputTheme.alt"
        >
          <option
            *ngFor="let _purpose of purposes"
            [value]="_purpose"
          >
            {{ _purpose | translate }}
          </option>
        </app-input>
      </div>
      <div
        *ngIf="ages"
        class="container__item"
      >
        <app-input
          type="select"
          placeholder="Choose by age"
          [(ngModel)]="age"
          [theme]="EInputTheme.alt"
        >
          <option
            *ngFor="let _age of ages"
            [value]="_age"
          >
            {{ _age === 0 ? 'Newborn' : _age }}
          </option>
        </app-input>
      </div>
      <div class="container__character">
        <div class="character"></div>
      </div>
      <div class="container__reset">
        <ng-container *ngTemplateOutlet="resetTpl"></ng-container>
      </div>
    </div>
  </div>
  <div class="wrap__reset">
    <ng-container *ngTemplateOutlet="resetTpl"></ng-container>
  </div>
</div>

<ng-template #resetTpl>
  <button
    class="reset"
    (click)="reset()"
  >
    Reset
  </button>
</ng-template>
