import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageTitleComponent } from './image-title.component';

@NgModule({
  declarations: [ImageTitleComponent],
  imports: [CommonModule],
  exports: [ImageTitleComponent],
})
export class ImageTitleModule {}
