import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { controlPopupPosition } from 'src/app/functions';
import Subscriber from 'src/app/subscriber';
import { Logger } from 'src/app/services/logger.service';

const log = new Logger('NavigationItemComponent');
const sub = new Subscriber();

/** NOTE: the parent element has to has a `position: relative;` */
@Component({
  selector: 'app-navigation-dropdown',
  templateUrl: './navigation-dropdown.component.html',
  styleUrls: ['./navigation-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationDropdownComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() active?: boolean;

  @ViewChild('content') contentRef: ElementRef<HTMLDivElement>;

  private _destroy = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {
    let resizeTimer: number;

    const onResize = () => {
      // * fixing position on resize
      clearTimeout(resizeTimer);

      resizeTimer = window.setTimeout(() => this._controlPosition());
    };

    window.addEventListener('resize', onResize);

    sub.push(
      this._destroy.subscribe(() => {
        sub.unsubscribe();

        window.removeEventListener('resize', onResize);
      }),
    );
  }

  ngAfterViewInit(): void {
    window.setTimeout(() => this._controlPosition());
  }

  ngOnDestroy(): void {
    this._destroy.emit();
  }

  private _controlPosition(): void {
    log.info();
    const contentElement = this.contentRef.nativeElement;

    if (contentElement) {
      controlPopupPosition(contentElement, 20);
    }
  }
}
