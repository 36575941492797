import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionMessageDateComponent } from './section-message-date.component';
import { TranslateModule } from '@ngx-translate/core';
import { SelectComponent } from './components/select/select.component';
import { InputModule } from '../input/input.module';

@NgModule({
  declarations: [SectionMessageDateComponent, SelectComponent],
  imports: [CommonModule, TranslateModule, InputModule],
  exports: [SectionMessageDateComponent],
})
export class SectionMessageDateModule {}
