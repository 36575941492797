import pickBy from 'lodash/pickBy';
import omit from 'lodash/omit';
import capitalize from 'lodash/capitalize';
import isEqual from 'lodash/isEqual';
import type { IDateInterval } from './interfaces';
import type { TCountryCode } from './shipping/interfaces';
import { Logger } from './logger';

/**
 * @param name error name *ex. `not enough data provided`*. default is `something went wrong`
 * @param args data that will help to debug *ex. `{ name: undefined }`*
 * @returns `new Error('Not enough data provided! Please try again. name: undefined')`
 */
export const getError = (
  name: string = 'something went wrong',
  args: { [key: string]: any } = {},
) => {
  const message = `${capitalize(
    name,
  )}! Please try again or contact the support.`;

  const params = Object.keys(args).reduce((_params, key, i) => {
    const separator = i !== 0 ? ', ' : '';

    return `${_params}${separator}${key}: ${args[key]}`;
  }, '');

  return new Error(`${message} ${params}`);
};

type TRange = [number, number];
export const rangesIntersection = <A extends TRange, B extends TRange>(
  [a1, a2]: A,
  [b1, b2]: B,
): TRange => {
  const leftLimit = Math.max(a1, b1);
  const rightLimit = Math.min(a2, b2);

  return [leftLimit, rightLimit] as TRange;
};

export const getPercentFromValue = (v: number, p: number) => (v / 100) * p;

export const removePercentFromValue = (v: number, p: number) =>
  v - getPercentFromValue(v, p);

export const datesOverlap = (a: IDateInterval, b: IDateInterval) => {
  const bStart = a.start <= b.start && a.end >= b.start;
  const aStart = b.start <= a.start && b.end >= a.start;

  return bStart || aStart;
};

/**
 * RA - Removed/Added from/to nextItems
 */
export const compareArraysRA = <T>(prevItems: T[], nextItems: T[]) => {
  const removed = prevItems.reduce((_removed, item) => {
    if (!nextItems.find((_item) => isEqual(_item, item))) {
      _removed.push(item);
    }

    return _removed;
  }, [] as T[]);

  const added = nextItems.reduce((_added, item) => {
    if (!prevItems.find((_item) => isEqual(_item, item))) {
      _added.push(item);
    }

    return _added;
  }, [] as T[]);

  return {
    removed,
    added,
  };
};

/**
 * RAC - Removed/Added/Changed from/to/with nextItems
 */
export const compareArraysRAC = <
  K extends string,
  T extends { [key in K]: any },
>(
  prevItems: T[],
  nextItems: T[],
  key: K,
) => {
  const removed = prevItems.reduce((_removed, item) => {
    if (!nextItems.find((_item) => _item[key] === item[key])) {
      _removed.push(item);
    }

    return _removed;
  }, [] as T[]);

  const { added, changed } = nextItems.reduce(
    (obj, item) => {
      const { added: _added, changed: _changed } = obj;

      const prevItem = prevItems.find((_item) => _item[key] === item[key]);

      if (!prevItem) {
        _added.push(item);
      } else if (!isEqual(item, prevItem)) {
        _changed.push(item);
      }

      return obj;
    },
    { added: [] as T[], changed: [] as T[] },
  );

  return {
    removed,
    added,
    changed,
  };
};

export const compareObjectsRAC = <
  A extends { [key in string | number]: {} },
  B extends { [key in string | number]: {} },
>(
  prevItems: A,
  nextItems: B,
) => {
  const removed = omit(
    prevItems,
    Object.keys(nextItems) as unknown as keyof B[],
  );

  const added = omit(nextItems, Object.keys(prevItems) as unknown as keyof A[]);

  const changed = pickBy(
    omit(nextItems, Object.keys(added) as unknown as keyof typeof added[]),
    (value, key) => !isEqual(value, prevItems[key]),
  );

  return {
    removed,
    added,
    changed,
  };
};

const phoneCountryCodeMap: { [key in TCountryCode]: string } = {
  US: '+1',
};

const getPhoneLog = new Logger('getPhone');

export const getPhone = (
  phone: string,
  country: TCountryCode,
  fallbackCode = '+1',
) => {
  const log = getPhoneLog;

  if (phone.match(/^\+/g)) {
    log.warn(`Code already exists in phone "${phone}"`);
    return phone;
  }

  let code = phoneCountryCodeMap[country];

  if (!code) {
    log.error(
      `Code "${country}" not handled yet! Used "${fallbackCode}" as default.`,
    );
    code = fallbackCode;
  }

  return `${code}${phone}`;
};

/**
 * removes undefined from object and array `recursively`
 * @param obj - [undeifined, 1, 2] => [empty, 1, 2]
 * @param obj - { a: undefined, b: '' } => { b: '' }
 */
export const deleteUndefinedFromObject = <T extends { [key: string]: any }>(
  obj: T,
) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined) {
      delete obj[key];
    } else if (obj[key] instanceof Object) {
      deleteUndefinedFromObject(obj[key]);
    }
  });

  return obj;
};
