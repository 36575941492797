import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TBookDescription } from 'src/app/data/books/desicription/types';
import { MediaObserver } from '@angular/flex-layout';
import { BehaviorSubject } from 'rxjs';
import Subscriber from 'src/app/subscriber';

const sub = new Subscriber();

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DescriptionComponent implements OnInit, OnDestroy {
  @Input() description!: TBookDescription;

  private _forceImageFirst = new BehaviorSubject(false);

  public forceImageFirst = this._forceImageFirst.asObservable();

  constructor(private _media: MediaObserver) {}

  ngOnInit(): void {
    sub.push(
      this._media.asObservable().subscribe(() => {
        this._updateForceImageFirst();
      }),
    );
  }

  ngOnDestroy(): void {
    sub.unsubscribe();
  }

  private _updateForceImageFirst(): void {
    this._forceImageFirst.next(this._media.isActive('lt-md'));
  }
}
