<section class="wrap">
  <div class="wrap__character"></div>
  <div class="wrap__container">
    <div class="container">
      <div class="container__header">
        <div class="header">
          <div class="header__title">
            <h1 class="title">Thank you!</h1>
          </div>
          <div
            *ngIf="orderId"
            class="header__text"
          >
            <p class="text">ご注文番号：{{ orderId }}</p>
          </div>
        </div>
      </div>
      <app-copy-plate
        *ngIf="giftId"
        class="container__plate"
        label="ギフトコード"
        [toCopy]="location.origin + giftUsePath"
      >
        <a
          [routerLink]="giftUsePath"
          target="_blank"
        >{{ location.host }}{{ giftUsePath }}</a>
      </app-copy-plate>
      <app-block-sns-card
        class="container__sns"
      ></app-block-sns-card>
      <div class="container__button">
        <app-button
          class="button"
          [fullWidth]="true"
          (buttonClick)="routing.goToIndex()"
        >Home</app-button>
      </div>
    </div>
  </div>
</section>
