import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TFaqData } from '../../data/faq/types';
import { faqData, faqDataCondensed } from '../../data/faq/data';
// import { Logger } from 'src/app/services/logger.service';
import { Router } from '@angular/router';

// const log = new Logger('SectionFaqComponent');

@Component({
  selector: 'app-section-faq',
  templateUrl: './section-faq.component.html',
  styleUrls: ['./section-faq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionFaqComponent {
  @Input() condensed = false;

  get faqData(): TFaqData {
    return this.condensed ? faqDataCondensed : faqData;
  }

  constructor(private _router: Router) {}

  public onButtonClick(): void {
    // log.info('onButtonClick');
    this._router.navigate(['faq']);
  }
}
