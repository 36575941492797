import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  Provider,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  IBookCanvasInput,
  IBookCanvasInputItem,
} from 'app/data/books/inputs/canvas/types';
import { MediaService } from 'app/services/media.service';
import { slideY } from 'app/animations';
import { BookFormInputCommon } from '../../book-form-input.common';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputGroupComponent),
  multi: true,
};

@Component({
  selector: 'app-input-group',
  templateUrl: './input-group.component.html',
  styleUrls: ['./input-group.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    slideY({
      durationEnter: '300ms',
      durationLeave: '300ms',
      preserveStyles: ['display', 'marginLeft'],
    }),
  ],
})
export class InputGroupComponent extends BookFormInputCommon {
  @Input() label!: string;

  @Input() items!: IBookCanvasInputItem[];

  /** canvas width */
  @Input() width!: IBookCanvasInput['width'];

  /** canvas height */
  @Input() height!: IBookCanvasInput['height'];

  constructor(protected _injector: Injector, public media: MediaService) {
    super(_injector);
  }
}
