import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IBook } from 'app/data/books/types';
// import { Logger } from 'src/app/services/logger.service';
import { MediaObserver } from '@angular/flex-layout';
import { DiscountService } from 'src/app/services/discount.service';
import { getBooksDiscount } from '@shared/discount/utils';
import { ROUTE_PATH_GIFT_BUY } from 'app/app-routing.constants';
import { scrollToSelector } from 'app/functions';
import { BASE_HREF } from 'app/constants';

// const log = new Logger('Preview -> SectionDetailsComponent');

const giftHref = `${BASE_HREF}${ROUTE_PATH_GIFT_BUY}`;

@Component({
  selector: 'app-section-details',
  templateUrl: './section-details.component.html',
  styleUrls: ['./section-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionDetailsComponent {
  @Input() book!: IBook;

  public readonly getBooksDiscount = getBooksDiscount;

  constructor(
    public media: MediaObserver,
    public discountService: DiscountService,
  ) {}

  onNoticeButtonClick() {
    scrollToSelector('app-section-banner-mermaid');
  }
}
