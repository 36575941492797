import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { IndexComponent } from './pages/index/index.component';
import { FaqComponent } from './pages/faq/faq.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AboutComponent } from './pages/about/about.component';
import { TermsComponent } from './pages/terms/terms.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { CartComponent } from './pages/cart/cart.component';
import { OrderComponent } from './pages/order/order.component';
import { LayoutFooter, LayoutHeader } from './app-routing.enum';
import { PolicyComponent } from './pages/policy/policy.component';
import { OrdercontractComponent } from './pages/ordercontract/ordercontract.component';
import { PreviewComponent } from './pages/preview/preview.component';
import {
  ROUTE_PATH_404,
  ROUTE_PATH_ABOUT,
  ROUTE_PATH_ALL_BOOKS,
  ROUTE_PATH_CART,
  ROUTE_PATH_COLLABORATION,
  ROUTE_PATH_CONTACTS,
  ROUTE_PATH_FAQ,
  ROUTE_PATH_INDEX,
  ROUTE_PATH_ORDER,
  ROUTE_PATH_ORDERCONTRACT,
  ROUTE_PATH_POLICY,
  ROUTE_PATH_PREVIEW,
  ROUTE_PATH_TERMS,
} from './app-routing.constants';
import { AllBooksComponent } from './pages/all-books/all-books.component';
import {
  BOOK_ALIAS_STARRY_DREAM,
  BOOK_ALIAS_STORY_OF_GRANDPA_GRANDMA,
  BOOK_ALIAS_WELCOME_TO_THE_WOLD,
  BOOK_ALIAS_WHERE_IS_BIRTHDAY_CAKE,
  BOOK_ALIAS_WHOS_BIRTHDAY_TOMORROW,
} from '@shared/book/constants';
import { CollaborationPageComponent } from './pages/collaboration-page/collaboration-page.component';
import {
  ROUTE_AUTH,
  ROUTE_AUTH_WITH_TYPE,
  ROUTE_BOOK,
  ROUTE_BOOK_STARRY_DREAM,
  ROUTE_BOOK_STORY_OF_GRANDPA_GRANDMA,
  ROUTE_BOOK_WELCOME_TO_THE_WORLD,
  ROUTE_BOOK_WHERE_IS_BIRTHDAY_CAKE,
  ROUTE_BOOK_WHOS_BIRTHDAY_TOMORROW,
  ROUTE_GIFT_BUY,
  ROUTE_GIFT_BUY_SPECIAL,
  ROUTE_GIFT_ORDER,
  ROUTE_GIFT_ORDER_WITH_ORDER_ID,
  ROUTE_GIFT_THANKS,
  ROUTE_GIFT_USE,
  ROUTE_GIFT_USE_NO_LOGO,
  ROUTE_GIFT_USE_NO_LOGO_WITH_GIFT_ID,
  ROUTE_GIFT_USE_WITH_GIFT_ID,
  ROUTE_MY_PAGE,
  ROUTE_MY_PAGE_WITH_ORDER_ID,
} from './routes';

const routes: Routes = [
  { path: 'index', redirectTo: ROUTE_PATH_INDEX, pathMatch: 'full' },
  // { path: 'index' , loadChildren: () => import('./pages/index/index.module').then(m => m.IndexModule) },
  {
    path: ROUTE_PATH_INDEX,
    component: IndexComponent,
    data: {
      LayoutHeader: LayoutHeader.shown,
      LayoutFooter: LayoutFooter.shown,
      title: "Personalized children's books | BÜKI",
      meta_title: "BÜKI Personalized children's books",
      description:
        "Create a memorable gift with personalized books for your beloved child to make him/her feel even more special! It's a magical story with your child's photo and name.",
      og_title: "BÜKI Personalized children's books",
      og_description:
        "Create a memorable gift with personalized books for your beloved child to make him/her feel even more special! It's a magical story with your child's photo and name.",
      og_url: 'https://hellobuki.com/',
      og_image: 'https://hellobuki.com/assets/images/ogp.jpg',
      og_site_name: 'BÜKI',
      fb_app_id: '174662759861666',
      og_type: 'website',
      twitter_card: 'summary_large_image',
    },
  },
  {
    path: ROUTE_PATH_ABOUT,
    component: AboutComponent,
    data: {
      LayoutHeader: LayoutHeader.shown,
      LayoutFooter: LayoutFooter.shown,
      title: "About | BÜKI Personalized children's books",
      meta_title: "BÜKI Personalized children's books",
      description:
        'Children are the hero of the world. Our mission is to empower children through personalized books that inspire, educate, and bring joy. We believe that every child is special and deserves to be the hero of their own story.',
      og_title: "About | BÜKI Personalized children's books",
      og_description:
        'Children are the hero of the world. Our mission is to empower children through personalized books that inspire, educate, and bring joy. We believe that every child is special and deserves to be the hero of their own story.',
      og_url: 'https://hellobuki.com/about/',
      og_image: 'https://hellobuki.com/assets/images/ogp-about.jpg',
      og_site_name: 'BÜKI',
      fb_app_id: '174662759861666',
      og_type: 'website',
      twitter_card: 'summary_large_image',
    },
  },
  {
    path: ROUTE_PATH_TERMS,
    component: TermsComponent,
    data: {
      LayoutHeader: LayoutHeader.shown,
      LayoutFooter: LayoutFooter.shown,
      title: "Terms | BÜKI Personalized children's books",
      meta_title: "BÜKI Personalized children's books",
    },
  },
  {
    path: ROUTE_PATH_POLICY,
    component: PolicyComponent,
    data: {
      LayoutHeader: LayoutHeader.shown,
      LayoutFooter: LayoutFooter.shown,
      title: "Provacy policy | BÜKI Personalized children's books",
      meta_title: "BÜKI Personalized children's books",
    },
  },
  {
    path: ROUTE_PATH_FAQ,
    component: FaqComponent,
    data: {
      LayoutHeader: LayoutHeader.shown,
      LayoutFooter: LayoutFooter.shown,
      title: "FAQ | BÜKI Personalized children's books",
      meta_title: "BÜKI Personalized children's books",
      description: 'You are lost? Maybe you can find your answer here!',
      og_title: "BÜKI Personalized children's books",
      og_description: 'You are lost? Maybe you can find your answer here!',
      og_url: 'https://hellobuki.com/faq/',
      og_image: 'https://hellobuki.com/assets/images/ogp-buki.jpg',
      og_site_name: 'BÜKI',
      fb_app_id: '174662759861666',
      og_type: 'website',
      twitter_card: 'summary',
    },
  },
  {
    path: ROUTE_PATH_CONTACTS,
    component: ContactsComponent,
    data: {
      LayoutHeader: LayoutHeader.shown,
      LayoutFooter: LayoutFooter.shown,
      title: "Contact | BÜKI Personalized children's books",
      meta_title: "BÜKI Personalized children's books",
      description:
        'Do you need some assistance? Let our supportive fairies help you!',
      og_title: "BÜKI Personalized children's books",
      og_description:
        'Do you need some assistance? Let our supportive fairies help you!',
      og_url: 'https://hellobuki.com/contacts/',
      og_image: 'https://hellobuki.com/assets/images/ogp-buki.jpg',
      og_site_name: 'BÜKI',
      fb_app_id: '174662759861666',
      og_type: 'website',
      twitter_card: 'summary',
      twitter_site: 'https://hellobuki.com/contacts/',
    },
  },
  {
    path: ROUTE_PATH_ORDERCONTRACT,
    component: OrdercontractComponent,
    data: {
      LayoutHeader: LayoutHeader.shown,
      LayoutFooter: LayoutFooter.shown,
      title: '特定商取引法に基づく表示 BÜKI',
      meta_title: 'BÜKI 物語の主人公になれる絵本（ブーキー）',
    },
  },
  {
    path: ROUTE_PATH_CART,
    component: CartComponent,
    data: {
      LayoutHeader: LayoutHeader.shown,
      LayoutFooter: LayoutFooter.shown,
      title: "Cart | BÜKI Personalized children's books",
      meta_title: "BÜKI Personalized children's books",
    },
  },
  {
    path: ROUTE_PATH_ORDER,
    component: OrderComponent,
    data: {
      LayoutHeader: LayoutHeader.hidden,
      LayoutFooter: LayoutFooter.hidden,
      title: "Checkout | BÜKI Personalized children's books",
      meta_title: "BÜKI Personalized children's books",
    },
  },
  ROUTE_BOOK,
  ROUTE_BOOK_STARRY_DREAM,
  ROUTE_BOOK_WELCOME_TO_THE_WORLD,
  ROUTE_BOOK_WHOS_BIRTHDAY_TOMORROW,
  ROUTE_BOOK_WHERE_IS_BIRTHDAY_CAKE,
  ROUTE_BOOK_STORY_OF_GRANDPA_GRANDMA,
  {
    path: ROUTE_PATH_ALL_BOOKS,
    component: AllBooksComponent,
    data: {
      LayoutHeader: LayoutHeader.shown,
      LayoutFooter: LayoutFooter.shown,
      title: "All books | BÜKI Personalized children's books",
      meta_title: "BÜKI Personalized children's books",
      description:
        'Discover personalized books for your kids! Are you ready to take them to a special adventure?',
      og_title: "All books | BÜKI Personalized children's books",
      og_description:
        'Discover personalized books for your kids! Are you ready to take them to a special adventure?',
      og_url: 'https://hellobuki.com/all-books/',
      og_image: 'https://hellobuki.com/assets/images/ogp-allbooks-EN.jpg',
      og_site_name: 'BÜKI',
      fb_app_id: '174662759861666',
      og_type: 'website',
      twitter_card: 'summary',
      twitter_site: 'https://hellobuki.com/all-books/',
    },
  },
  {
    path: ROUTE_PATH_404,
    component: NotFoundComponent,
    data: {
      LayoutHeader: LayoutHeader.shown,
      LayoutFooter: LayoutFooter.hidden,
      title: "404 | BÜKI Personalized children's books",
      meta_title: "BÜKI Personalized children's books",
    },
  },
  {
    path: BOOK_ALIAS_STARRY_DREAM,
    component: PreviewComponent,
    data: {
      LayoutHeader: LayoutHeader.shown,
      LayoutFooter: LayoutFooter.shown,
      title: "Adventure in the Stars | BÜKI Personalized children's books",
      meta_title: "BÜKI Personalized children's books",
      description:
        "Discover the stars and learn something new in a grand adventure with your child's zodiac sign in this original picture book!",
      og_title: "BÜKI Personalized children's books",
      og_description:
        "Discover the stars and learn something new in a grand adventure with your child's zodiac sign in this original picture book!",
      og_url: 'https://hellobuki.com/starry-dream/',
      og_image: 'https://hellobuki.com/assets/images/ogp-bk1-EN.jpg',
      og_site_name: 'BÜKI',
      fb_app_id: '174662759861666',
      og_type: 'website',
      twitter_card: 'summary',
      twitter_site: 'https://hellobuki.com/starry-dream/',
    },
  },
  {
    path: BOOK_ALIAS_WELCOME_TO_THE_WOLD,
    component: PreviewComponent,
    data: {
      LayoutHeader: LayoutHeader.shown,
      LayoutFooter: LayoutFooter.shown,
      title: "Welcome to the World | BÜKI Personalized children's books",
      meta_title: "BÜKI Personalized children's books",
      description:
        'Welcome your newborn with this charming original book. Experience the warmth and love of a family as they welcome a new member into this world.',
      og_title: "BÜKI Personalized children's books",
      og_description:
        'Welcome your newborn with this charming original book. Experience the warmth and love of a family as they welcome a new member into this world.',
      og_url: 'https://hellobuki.com/welcome-to-the-world/',
      og_image: 'https://hellobuki.com/assets/images/ogp-bk3-EN.jpg',
      og_site_name: 'BÜKI',
      fb_app_id: '174662759861666',
      og_type: 'website',
      twitter_card: 'summary',
      twitter_site: 'https://hellobuki.com/welcome-to-the-world/',
    },
  },
  {
    path: BOOK_ALIAS_WHOS_BIRTHDAY_TOMORROW,
    component: PreviewComponent,
    data: {
      LayoutHeader: LayoutHeader.shown,
      LayoutFooter: LayoutFooter.shown,
      title: "Tomorrow is Your Birthday! | BÜKI Personalized children's books",
      meta_title: "BÜKI Personalized children's books",
      description:
        'Make birthday planning a blast with this customizable book! Follow along as the characters prepare for your child’s big day and enjoy the chaos of party planning.',
      og_title: "BÜKI Personalized children's books",
      og_description:
        'Make birthday planning a blast with this customizable book! Follow along as the characters prepare for your child’s big day and enjoy the chaos of party planning.',
      og_url: 'https://hellobuki.com/whos-birthday-tomorrow/',
      og_image: 'https://hellobuki.com/assets/images/ogp-bk2-EN.jpg',
      og_site_name: 'BÜKI',
      fb_app_id: '174662759861666',
      og_type: 'website',
      twitter_card: 'summary',
      twitter_site: 'https://hellobuki.com/whos-birthday-tomorrow/',
    },
  },
  {
    path: BOOK_ALIAS_WHERE_IS_BIRTHDAY_CAKE,
    component: PreviewComponent,
    data: {
      LayoutHeader: LayoutHeader.shown,
      LayoutFooter: LayoutFooter.shown,
      title: 'みつけて！たんじょうびまでのだいぼうけん BÜKI',
      meta_title: 'BÜKI 物語の主人公になれる絵本（ブーキー）',
      description:
        '「みつけて！たんじょうびまでのだいぼうけん」お誕生日の”探す”絵本！1月、2月・・、お友達が無くしたものは、１年のどこにある？見つけたものを合わせると、サプライズが！【税込3,990円～】',
      og_title: 'みつけて！たんじょうびまでのだいぼうけん',
      og_description:
        '「みつけて！たんじょうびまでのだいぼうけん」お誕生日の”探す”絵本！1月、2月・・、お友達が無くしたものは、１年のどこにある？見つけたものを合わせると、サプライズが！',
      og_url: `https://hellobuki.com/${BOOK_ALIAS_WHERE_IS_BIRTHDAY_CAKE}`,
      og_image:
        'https://hellobuki.com/assets/images/ogp-where-is-birthday-cake.jpg',
      og_site_name: 'BÜKI（ブーキー）',
      fb_app_id: '174662759861666',
      og_type: 'website',
      twitter_card: 'summary',
      twitter_site: `https://hellobuki.com/${BOOK_ALIAS_WHERE_IS_BIRTHDAY_CAKE}`,
    },
  },
  {
    path: BOOK_ALIAS_STORY_OF_GRANDPA_GRANDMA,
    component: PreviewComponent,
    data: {
      LayoutHeader: LayoutHeader.shown,
      LayoutFooter: LayoutFooter.shown,
      title: 'わたしたちの、おじいちゃん/おばあちゃん BÜKI',
      meta_title: 'BÜKI 物語の主人公になれる絵本（ブーキー）',
      description:
        '「わたしたちの、おじいちゃん/おばあちゃん」僕の私のおじいちゃん・おばあちゃんが絵本の主人公！大切な子に読みたい、おじいちゃん・おばあちゃんの心温まるストーリー【税込4,000円～】',
      og_title: 'わたしたちの、おじいちゃん/おばあちゃん',
      og_description:
        '「わたしたちの、おじいちゃん/おばあちゃん」僕の私のおじいちゃん・おばあちゃんが絵本の主人公！大切な子に読みたい、おじいちゃん・おばあちゃんの心温まるストーリー',
      og_url: `https://hellobuki.com/${BOOK_ALIAS_STORY_OF_GRANDPA_GRANDMA}`,
      og_image:
        'https://hellobuki.com/assets/images/ogp-story-of-grandpa-grandma.jpg',
      og_site_name: 'BÜKI（ブーキー）',
      fb_app_id: '174662759861666',
      og_type: 'website',
      twitter_card: 'summary',
      twitter_site: `https://hellobuki.com/${BOOK_ALIAS_STORY_OF_GRANDPA_GRANDMA}`,
    },
  },
  ROUTE_GIFT_BUY,
  ROUTE_GIFT_BUY_SPECIAL,
  ROUTE_GIFT_USE,
  ROUTE_GIFT_USE_WITH_GIFT_ID,
  ROUTE_GIFT_USE_NO_LOGO,
  ROUTE_GIFT_USE_NO_LOGO_WITH_GIFT_ID,
  ROUTE_GIFT_THANKS,
  ROUTE_GIFT_ORDER,
  ROUTE_GIFT_ORDER_WITH_ORDER_ID,
  ROUTE_AUTH,
  ROUTE_AUTH_WITH_TYPE,
  ROUTE_MY_PAGE,
  ROUTE_MY_PAGE_WITH_ORDER_ID,
  {
    path: ROUTE_PATH_COLLABORATION,
    component: CollaborationPageComponent,
    data: {
      LayoutHeader: LayoutHeader.shown,
      LayoutFooter: LayoutFooter.shown,
      title: 'Collaboration | 法人向けサービス',
      meta_title: 'BÜKI 物語の主人公になれる絵本（ブーキー）',
      description:
        'BÜKIのデジタル・テクノロジーを使って、一緒に小さなお客さまに特別な一冊を提供しませんか？パーソナライズ絵本の出版や景品利用など、お問い合わせください。',
      og_title: '法人向けサービス',
      og_description:
        'BÜKIのデジタル・テクノロジーを使って、一緒に小さなお客さまに特別な一冊を提供しませんか？パーソナライズ絵本の出版や景品利用など、お問い合わせください。',
      og_url: 'https://hellobuki.com/collaboration/',
      og_image: 'https://hellobuki.com/assets/images/ogp-buki.jpg',
      og_site_name: 'BÜKI（ブーキー）',
      fb_app_id: '174662759861666',
      og_type: 'website',
      twitter_card: 'summary',
    },
  },
  {
    path: ROUTE_PATH_PREVIEW,
    component: PreviewComponent,
    data: {
      LayoutHeader: LayoutHeader.shown,
      LayoutFooter: LayoutFooter.shown,
      title: "Preview | BÜKI Personalized children's books",
      // defined on the backend
      // meta_title: 'BÜKI 物語の主人公になれる絵本（ブーキー）',
      // og_url: 'https://hellobuki.com/',
    },
  },
  { path: '**', redirectTo: ROUTE_PATH_404 },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 100],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
