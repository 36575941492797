export enum EBookBannerTextColor {
  light = 'light', // ! default
  dark = 'dark',
}

export enum EBookBannerTextSize {
  md = 'md', // ! default
  sm = 'sm',
}

export interface IBookBanner {
  imgSrc: string;
  imgSrcLtMd?: string;
  text: string;
  textLtMd?: string;
  textColor?: EBookBannerTextColor;
  textSize?: EBookBannerTextSize;
}
