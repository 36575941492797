<div class="content">
  <div
    *ngFor="let item of data"
    class="content__section"
  >
    <app-faq-item
      [question]="item.question"
      [answer]="item.answer"
    ></app-faq-item>
  </div>
</div>
