import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AsideComponent } from './aside.component';
import { HeaderComponent } from './components/header/header.component';
import { CalculatorModule } from 'src/app/shared/calculator/calculator.module';
import { ProductModule } from './components/product/product.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { RouterModule } from '@angular/router';
import { LinkComponent } from './components/link/link.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    AsideComponent,
    HeaderComponent,
    LinkComponent,
    ToggleComponent,
  ],
  imports: [
    CommonModule,
    CalculatorModule,
    ProductModule,
    AngularSvgIconModule,
    RouterModule,
    TranslateModule,
  ],
  exports: [AsideComponent],
})
export class AsideModule {}
