import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-section-header-gap',
  templateUrl: './section-header-gap.component.html',
  styleUrls: ['./section-header-gap.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionHeaderGapComponent {
  constructor() {}
}
