import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import type {
  TBookAlias,
  TBookCover,
  TBookWrapping,
  TCharacterSD,
  TClothColor,
  TGender,
  THairColor,
  THairStyle,
  THairWIBC,
  THoroscopes,
  TLanguage,
  TParents,
  TSkinColor,
  TWhosCelebrating,
} from '@shared/book/interfaces';
import type {
  BookData,
  BookStoryOfGrandpaGrandmaData,
  BookWelcomeToTheWoldData,
  BookWhereIsBirthdayCakeData,
  BookWhosBirthdayTomorrowData,
  CommonBookData,
  TBookData,
} from '@shared/models';
import {
  BOOK_ALIAS_STARRY_DREAM,
  BOOK_ALIAS_STORY_OF_GRANDPA_GRANDMA,
  BOOK_ALIAS_WELCOME_TO_THE_WOLD,
  BOOK_ALIAS_WHERE_IS_BIRTHDAY_CAKE,
  BOOK_ALIAS_WHOS_BIRTHDAY_TOMORROW,
  INPUT_NAME_AGE_TO_BE,
  INPUT_NAME_BEARD_STYLE,
  INPUT_NAME_CHARACTER,
  INPUT_NAME_CLOTH_COLOR,
  INPUT_NAME_FREKLES,
  INPUT_NAME_GENDER,
  INPUT_NAME_GLASSES,
  INPUT_NAME_GRANDCHILDREN_HOW_MANY,
  INPUT_NAME_GRANDCHILDREN_NAME_1,
  INPUT_NAME_GRANDCHILDREN_NAME_2,
  INPUT_NAME_GRANDCHILDREN_NAME_3,
  INPUT_NAME_HAIR_COLOR,
  INPUT_NAME_HAIR_COLOR_YONG,
  INPUT_NAME_HAIR_STYLE,
  INPUT_NAME_HERO_NAME,
  INPUT_NAME_KIDS_BIRTHDAY,
  INPUT_NAME_KIDS_BIRTHDAY_DAY,
  INPUT_NAME_KIDS_BIRTHDAY_MONTH,
  INPUT_NAME_KIDS_NAME,
  INPUT_NAME_LANGUAGE,
  INPUT_NAME_PARENTS,
  INPUT_NAME_SKIN_COLOR,
  INPUT_NAME_WHOS_CELEBRATING,
} from '@shared/book/constants';
import { filter, take } from 'rxjs/operators';
import firebase from 'firebase/app';
// firebase specific imports are in main.ts
import {
  DB_BOOKS,
  DB_CHECKOUTS,
  DB_COUPONS,
  DB_GIFTS,
  DB_GIFT_ORDERS,
  DB_ORDERS,
  DB_STRIPE_CUSTOMERS,
  DB_USERS,
} from '@shared/constants';
import type {
  TGiftOrderCreateParams,
  TGiftOrderFirestore,
  TUserGiftFirestore,
} from '@shared/gift/interfaces';
import type {
  IOrderData,
  IStripeCustomer,
  TCheckoutData,
  TCouponData,
  UserData,
} from '@shared/interfaces';
import { RoutingService } from './routing.service';
import { StarryDreamBookCreate } from 'src/app/services/book-create/starry-dream-book-create';
import { WelcomeToTheWoldBookCreate } from 'src/app/services/book-create/welcome-to-the-wold-book-create';
import { BookWhereIsBirthdayCakeCreate } from 'src/app/services/book-create/book-where-is-birthday-cake-create';
import { WhosBirthdayTomorrowBookCreate } from 'src/app/services/book-create/whos-birthday-tomorrow-book-create';
import type { StoryOfGrandpaGrandmaBookCreateData } from 'src/app/services/book-create/story-of-grandpa-grandma-book-create';
import { StoryOfGrandpaGrandmaBookCreate } from 'src/app/services/book-create/story-of-grandpa-grandma-book-create';

import DocumentReference = firebase.firestore.DocumentReference;
import DocumentData = firebase.firestore.DocumentData;
import CollectionReference = firebase.firestore.CollectionReference;
import { Logger } from '@shared/logger';

const log = new Logger('FirebaseService');

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  private _user$ = new BehaviorSubject<firebase.User | undefined | null>(
    undefined,
  );

  public user$ = this._user$
    .asObservable()
    .pipe(filter((_): _ is firebase.User | null => _ !== undefined));

  get user() {
    return this._user$.value;
  }

  private _userData$ = new BehaviorSubject<UserData | undefined>(undefined);

  public userData$ = this._userData$
    .asObservable()
    .pipe(filter((_): _ is UserData => !!_));

  private _userOrders$ = new BehaviorSubject<IOrderData[] | undefined>(
    undefined,
  );

  public userOrders$ = this._userOrders$
    .asObservable()
    .pipe(filter((_): _ is IOrderData[] => !!_));

  private _userBooks$ = new BehaviorSubject<TBookData[] | undefined>(undefined);

  public userBooks$ = this._userBooks$
    .asObservable()
    .pipe(filter((_): _ is TBookData[] => !!_));

  public auth!: firebase.auth.Auth;

  public db!: firebase.firestore.Firestore;

  private realTimeDB!: firebase.database.Database;

  private storage!: firebase.storage.Storage;

  private _initedSubject = new BehaviorSubject<boolean>(false);

  // triggers only when initialized
  public initedObservable = this._initedSubject
    .asObservable()
    .pipe(filter((v) => v));

  constructor(private _routingService: RoutingService) {
    this.init();
  }

  async init() {
    firebase.initializeApp(environment.firebaseConfig);

    // window.analytics = analytics();
    this.auth = firebase.auth();
    this.db = firebase.firestore();
    this.storage = firebase.storage();
    this.realTimeDB = firebase.database();

    if (environment.localBackend) {
      firebase.functions().useEmulator('localhost', 5001);
      this.db.useEmulator('localhost', 8080);
      this.storage.useEmulator('localhost', 9199);
      this.realTimeDB.useEmulator('localhost', 9000);
      // this.auth.useEmulator('http://localhost:9099');
    }

    let userRefUnsubscribe: (() => void) | undefined;
    let userOrdersRefUnsubscribe: (() => void) | undefined;
    let userBooksRefUnsubscribe: (() => void) | undefined;

    this.auth.onAuthStateChanged(async (user) => {
      log.info(user);
      this._user$.next(user);

      if (user) {
        const userRef = await this._getUserDataRef();
        if (userRefUnsubscribe) userRefUnsubscribe();
        userRefUnsubscribe = userRef.onSnapshot((snapshot) => {
          this._userData$.next(snapshot.data());
        });

        const userOrdersRef = await this.getUserOrdersRef();
        if (userOrdersRefUnsubscribe) userOrdersRefUnsubscribe();
        userOrdersRefUnsubscribe = userOrdersRef.onSnapshot((snapshot) => {
          this._userOrders$.next(
            snapshot.docs.map((doc) => doc.data() as IOrderData),
          );
        });

        const userBooksRef = await this._getUserBooksRef();
        if (userBooksRefUnsubscribe) userBooksRefUnsubscribe();
        userBooksRefUnsubscribe = userBooksRef.onSnapshot((snapshot) => {
          this._userBooks$.next(
            snapshot.docs.map((doc) => doc.data() as TBookData),
          );
        });
      } else {
        // User is signed out.
        await this.auth.signInAnonymously().catch((error) => {
          console.error(error);
          alert(error.message);
        });
        // this._routingService.goToIndex();
      }
    });

    /** wait untill the user is authenticated */
    await this._userBooks$.pipe(take(1)).toPromise();

    this._initedSubject.next(true);
    this._initedSubject.complete();
  }

  /*async createUser(email) {
    await this.db.collection('users').doc(this.user.uid).set({
      uid: this.user.uid,
      email
    });
    await this.updateUserData();
  }*/

  async getBookData(bookId: string) {
    const bookDocRef = await this._getUserBookRef(bookId);
    const bookDoc = await bookDocRef.get();

    if (bookDoc.exists) {
      return bookDoc.data() as any;
    }
  }

  async deleteBook(bookId: string) {
    const bookDocRef = await this._getUserBookRef(bookId);

    await bookDocRef.set(
      {
        isDelete: true,
      },
      { merge: true },
    );
  }

  async updateBookCover(bookId: string, cover: TBookCover) {
    const bookDocRef = await this._getUserBookRef(bookId);

    if (!bookDocRef) {
      log.error('updateBookCover => bookDocRef is', bookDocRef);
      return;
    }

    await bookDocRef.set(
      {
        cover,
      },
      { merge: true },
    );
  }

  async updateBookWrapping(bookId: string, wrapping: TBookWrapping) {
    const bookDocRef = await this._getUserBookRef(bookId);

    if (!bookDocRef) {
      log.error('updateBookWrapping => bookDocRef is', bookDocRef);
      return;
    }

    await bookDocRef.set(
      {
        wrapping,
      },
      { merge: true },
    );
  }

  async createBookByAlias(
    alias: TBookAlias,
    bookCreateData: any,
    common?: Partial<CommonBookData>,
  ): Promise<string> {
    switch (alias) {
      case BOOK_ALIAS_STARRY_DREAM:
        return this.createBookStarryDream(
          bookCreateData[INPUT_NAME_KIDS_NAME],
          new Date(bookCreateData[INPUT_NAME_KIDS_BIRTHDAY]),
          bookCreateData[INPUT_NAME_LANGUAGE],
          bookCreateData[INPUT_NAME_GENDER],
          bookCreateData[INPUT_NAME_CHARACTER],
          common,
        );
      case BOOK_ALIAS_WELCOME_TO_THE_WOLD:
        return this.createBookWelcomeToTheWold(
          bookCreateData[INPUT_NAME_KIDS_NAME],
          new Date(bookCreateData[INPUT_NAME_KIDS_BIRTHDAY]),
          bookCreateData[INPUT_NAME_HAIR_COLOR],
          bookCreateData[INPUT_NAME_FREKLES],
          bookCreateData[INPUT_NAME_SKIN_COLOR],
          bookCreateData[INPUT_NAME_HAIR_STYLE],
          bookCreateData[INPUT_NAME_CLOTH_COLOR],
          bookCreateData[INPUT_NAME_LANGUAGE],
          bookCreateData[INPUT_NAME_PARENTS],
          common,
        );
      case BOOK_ALIAS_WHOS_BIRTHDAY_TOMORROW:
        return this.createBookWhosBirthdayTomorrow(
          bookCreateData[INPUT_NAME_KIDS_NAME],
          bookCreateData[INPUT_NAME_KIDS_BIRTHDAY_MONTH],
          bookCreateData[INPUT_NAME_KIDS_BIRTHDAY_DAY],
          bookCreateData[INPUT_NAME_AGE_TO_BE],
          bookCreateData[INPUT_NAME_WHOS_CELEBRATING],
          bookCreateData[INPUT_NAME_LANGUAGE],
          bookCreateData[INPUT_NAME_GENDER],
          bookCreateData[INPUT_NAME_CHARACTER],
          common,
        );
      case BOOK_ALIAS_WHERE_IS_BIRTHDAY_CAKE:
        return this.createBookWhereIsBirthdayCake(
          bookCreateData[INPUT_NAME_KIDS_NAME],
          bookCreateData[INPUT_NAME_KIDS_BIRTHDAY_MONTH],
          bookCreateData[INPUT_NAME_KIDS_BIRTHDAY_DAY],
          bookCreateData[INPUT_NAME_AGE_TO_BE],
          bookCreateData[INPUT_NAME_GENDER],
          bookCreateData[INPUT_NAME_HAIR_STYLE],
          bookCreateData[INPUT_NAME_GLASSES],
          common,
        );
      case BOOK_ALIAS_STORY_OF_GRANDPA_GRANDMA:
        return this.createBookStoryOfGrandpaGrandma(
          {
            heroName: bookCreateData[INPUT_NAME_HERO_NAME],
            gender: bookCreateData[INPUT_NAME_GENDER],
            language: bookCreateData[INPUT_NAME_LANGUAGE] || 'jp',
            howMany: Number(
              bookCreateData[INPUT_NAME_GRANDCHILDREN_HOW_MANY],
            ) as 1 | 2 | 3,
            hairStyle: bookCreateData[INPUT_NAME_HAIR_STYLE],
            hairColorInYoungerYears: bookCreateData[INPUT_NAME_HAIR_COLOR_YONG],
            hairColor: bookCreateData[INPUT_NAME_HAIR_COLOR],
            skinColor: bookCreateData[INPUT_NAME_SKIN_COLOR],
            beardStyle: bookCreateData[INPUT_NAME_BEARD_STYLE] || 'none',
            childrenName1: bookCreateData[INPUT_NAME_GRANDCHILDREN_NAME_1],
            childrenName2: bookCreateData[INPUT_NAME_GRANDCHILDREN_NAME_2],
            childrenName3: bookCreateData[INPUT_NAME_GRANDCHILDREN_NAME_3],
            glasses: bookCreateData[INPUT_NAME_GLASSES],
          },
          common,
        );
    }
  }

  private async createBookStoryOfGrandpaGrandma(
    createBookData: StoryOfGrandpaGrandmaBookCreateData,
    common?: Partial<CommonBookData>,
  ): Promise<string> {
    const { user } = this;

    if (!user)
      throw new Error(
        'createBookStoryOfGrandpaGrandma => user is anauthorised',
      );

    const doc: DocumentReference<DocumentData> = await this.getNewBookDoc();
    const newBook: BookStoryOfGrandpaGrandmaData =
      this.createNewBookStoryOfGrandpaGrandma(
        user.uid,
        doc.id,
        createBookData,
        firebase.firestore.FieldValue.serverTimestamp(),
        common,
      );
    return this.createBook<BookStoryOfGrandpaGrandmaData>(doc, newBook);
  }

  private async createBookWhereIsBirthdayCake(
    heroName: string,
    birthMonth: string,
    birthDate: string,
    age: string,
    gender: TGender,
    hairStyle: THairWIBC,
    glasses: boolean,
    common?: Partial<CommonBookData>,
  ): Promise<string> {
    const { user } = this;

    if (!user)
      throw new Error('createBookWhereIsBirthdayCake => user is anauthorised');

    const doc: DocumentReference<DocumentData> = await this.getNewBookDoc();
    const newBook: BookWhereIsBirthdayCakeData =
      this.createNewBookWhereIsBirthdayCake(
        user.uid,
        doc.id,
        heroName,
        birthMonth,
        birthDate,
        age,
        gender,
        hairStyle,
        glasses,
        firebase.firestore.FieldValue.serverTimestamp(),
        common,
      );
    return this.createBook<BookWhereIsBirthdayCakeData>(doc, newBook);
  }

  private async createBookWhosBirthdayTomorrow(
    heroName: string,
    birthMonth: string,
    birthDate: string,
    ageToBe: string,
    whosCelebrating: TWhosCelebrating,
    language: TLanguage,
    gender: TGender,
    character: TCharacterSD,
    common?: Partial<CommonBookData>,
  ): Promise<string> {
    const { user } = this;

    if (!user)
      throw new Error('createBookWhosBirthdayTomorrow => user is anauthorised');

    const doc: DocumentReference<DocumentData> = await this.getNewBookDoc();
    const newBook: BookWhosBirthdayTomorrowData =
      this.createNewBookWhosBirthdayTomorrow(
        user.uid,
        doc.id,
        heroName,
        birthMonth,
        birthDate,
        ageToBe,
        whosCelebrating,
        language,
        gender,
        character,
        firebase.firestore.FieldValue.serverTimestamp(),
        common,
      );
    return this.createBook<BookWhosBirthdayTomorrowData>(doc, newBook);
  }

  private async createBookWelcomeToTheWold(
    heroName: string,
    birthDate: Date,
    hairColor: THairColor,
    frekles: boolean,
    skinColor: TSkinColor,
    hairStyle: THairStyle,
    clothColor: TClothColor,
    language: TLanguage,
    parents: TParents,
    common?: Partial<CommonBookData>,
  ): Promise<string> {
    const { user } = this;

    if (!user)
      throw new Error('createBookWelcomeToTheWold => user is anauthorised');

    const doc: DocumentReference<DocumentData> = await this.getNewBookDoc();
    const newBook: BookWelcomeToTheWoldData =
      this.createNewBookWelcomeToTheWold(
        user.uid,
        doc.id,
        heroName,
        birthDate,
        hairColor,
        hairColor,
        hairColor,
        frekles,
        skinColor,
        skinColor,
        skinColor,
        hairStyle,
        clothColor,
        language,
        parents,
        firebase.firestore.FieldValue.serverTimestamp(),
        common,
      );
    return this.createBook<BookWelcomeToTheWoldData>(doc, newBook);
  }

  private async createBookStarryDream(
    heroName: string,
    birthDate: Date,
    language: TLanguage,
    gender: TGender,
    character: TCharacterSD,
    common?: Partial<CommonBookData>,
  ): Promise<string> {
    const { user } = this;

    if (!user) throw new Error('createBookStarryDream => user is anauthorised');

    const doc: DocumentReference<DocumentData> = await this.getNewBookDoc();
    const newBook: BookData = this.createNewBookStarryDream(
      user.uid,
      doc.id,
      heroName,
      birthDate,
      language,
      gender,
      character,
      firebase.firestore.FieldValue.serverTimestamp(),
      undefined,
      common,
    );
    return this.createBook<BookData>(doc, newBook);
  }

  private async createBook<T>(
    doc: DocumentReference<DocumentData>,
    newBook: T,
  ): Promise<string> {
    const { user } = this;

    if (!user) throw new Error('createBook => user is anauthorised');

    await doc.set(newBook);
    await this.db.collection('users').doc(user.uid).set(
      {
        lastBookId: doc.id,
      },
      { merge: true },
    );
    return doc.id;
  }

  private async getNewBookDoc() {
    const { user } = this;

    if (!user) throw new Error('getNewBookDoc => user is anauthorised');

    return this.db.collection('users').doc(user.uid).collection('books').doc();
  }

  public createNewBookStoryOfGrandpaGrandma(
    uid: string,
    docId: string,
    createBookData: StoryOfGrandpaGrandmaBookCreateData,
    serverTimestamp: any,
    common?: Partial<CommonBookData>,
  ): BookStoryOfGrandpaGrandmaData {
    const bookCreator = new StoryOfGrandpaGrandmaBookCreate();

    return bookCreator.createNewBook(
      uid,
      docId,
      createBookData,
      serverTimestamp,
      common,
    );
  }

  public createNewBookStarryDream(
    uid: string,
    docId: string,
    heroName: string,
    birthDate: Date,
    language: TLanguage,
    gender: TGender,
    character: TCharacterSD,
    serverTimestamp: any,
    zodiacSigns?: THoroscopes,
    common?: Partial<CommonBookData>,
  ): BookData {
    const bookCreator = new StarryDreamBookCreate();

    return bookCreator.createNewBook(
      uid,
      docId,
      heroName,
      birthDate,
      language,
      gender,
      character,
      serverTimestamp,
      zodiacSigns,
      common,
    );
  }

  public createNewBookWelcomeToTheWold(
    uid: string,
    docId: string,
    heroName: string,
    birthDate: Date,
    hairColor: THairColor,
    p1hairColor: THairColor,
    p2hairColor: THairColor,
    frekles: boolean,
    skinColor: TSkinColor,
    p1skinColor: TSkinColor,
    p2skinColor: TSkinColor,
    hairStyle: THairStyle,
    clothColor: TClothColor,
    language: TLanguage,
    parents: TParents,
    serverTimestamp: any,
    common?: Partial<CommonBookData>,
  ): BookWelcomeToTheWoldData {
    const bookCreator = new WelcomeToTheWoldBookCreate();

    return bookCreator.createNewBook(
      uid,
      docId,
      heroName,
      birthDate,
      hairColor,
      p1hairColor,
      p2hairColor,
      frekles,
      skinColor,
      p1skinColor,
      p2skinColor,
      hairStyle,
      clothColor,
      language,
      parents,
      serverTimestamp,
      common,
    );
  }

  public createNewBookWhereIsBirthdayCake(
    uid: string,
    docId: string,
    heroName: string,
    birthMonth: string,
    birthDate: string,
    age: string,
    gender: TGender,
    hairStyle: THairWIBC,
    glasses: boolean,
    serverTimestamp: any,
    common?: Partial<CommonBookData>,
  ): BookWhereIsBirthdayCakeData {
    const bookCreator = new BookWhereIsBirthdayCakeCreate();
    return bookCreator.createNewBook(
      uid,
      docId,
      heroName,
      birthMonth,
      birthDate,
      age,
      gender,
      hairStyle,
      glasses,
      serverTimestamp,
      common,
    );
  }

  public createNewBookWhosBirthdayTomorrow(
    uid: string,
    docId: string,
    heroName: string,
    birthMonth: string,
    birthDate: string,
    ageToBe: string,
    whosCelebrating: TWhosCelebrating,
    language: TLanguage,
    gender: TGender,
    character: TCharacterSD,
    serverTimestamp: any,
    common?: Partial<CommonBookData>,
  ): BookWhosBirthdayTomorrowData {
    const bookCreator = new WhosBirthdayTomorrowBookCreate();
    return bookCreator.createNewBook(
      uid,
      docId,
      heroName,
      birthMonth,
      birthDate,
      ageToBe,
      whosCelebrating,
      language,
      gender,
      character,
      serverTimestamp,
      common,
    );
  }

  /*async _getUserBookRef(bookId: string): Promise<DocumentReference<DocumentData>> {
    if (this.user) {
      return this.db.collection('users')
        .doc(this.user.uid)
        .collection('books')
        .doc(bookId);
    } else {
      return new Promise<DocumentReference<DocumentData>>(
        (resolve, reject) => {
          auth().onAuthStateChanged(async (user) => {
            if (user) {
              resolve(this.db.collection('users')
                .doc(user.uid)
                .collection('books')
                .doc(bookId));
            } else {
              reject();
            }
          }, error => {
            reject(error);
          });
        }
      );
    }
  }*/

  private async _getUserDataRef() {
    const user = await this.userAuth();

    if (!user) throw new Error('_getUserDataRef => user is anauthorised');

    return this.db
      .collection(DB_USERS)
      .doc(user.uid) as DocumentReference<UserData>;
  }

  public async setUserData(
    data: Partial<UserData>,
    options: firebase.firestore.SetOptions,
  ) {
    const userDataRef = await this._getUserDataRef();

    return userDataRef.set(data, options);
  }

  private async _getUserBooksRef() {
    const user = await this.userAuth();

    if (!user) throw new Error('getUserOrdersRef => user is anauthorised');

    return this.db.collection(DB_USERS).doc(user.uid).collection(DB_BOOKS);
  }

  private async _getUserBookRef(bookId: string) {
    const booksRef = await this._getUserBooksRef();

    return booksRef.doc(bookId);
  }

  public async getUserBook(bookId: string) {
    const userBooks = await this.userBooks$.pipe(take(1)).toPromise();

    return userBooks.find((book) => book.bookId === bookId);
  }

  public async setUserBook<T extends TBookData>(
    bookId: string,
    data: Partial<T>,
    options?: firebase.firestore.SetOptions,
  ) {
    const userBookRef = await this._getUserBookRef(bookId);

    if (userBookRef) {
      return options ? userBookRef.set(data, options) : userBookRef.set(data);
    } else {
      alert('Something went wrong! Please reload the page and try again.');
    }
  }

  async getUserOrdersRef() {
    const user = await this.userAuth();

    if (!user) throw new Error('getUserOrdersRef => user is anauthorised');

    return this.db
      .collection(DB_USERS)
      .doc(user.uid)
      .collection(DB_ORDERS) as CollectionReference<IOrderData>;
  }

  async getUserOrderRef(orderId: string) {
    const usersOrdersRef = await this.getUserOrdersRef();

    return usersOrdersRef.doc(orderId);
  }

  async getUserGiftOrdersRef() {
    const user = await this.userAuth();

    if (!user) throw new Error('getUserGiftOrdersRef => user is anauthorised');

    return this.db
      .collection(DB_USERS)
      .doc(user.uid)
      .collection(DB_GIFT_ORDERS) as CollectionReference<TGiftOrderFirestore>;
  }

  async getUserGiftOrderRef(orderId: string) {
    const usersOrdersRef = await this.getUserOrdersRef();

    if (!usersOrdersRef) return;

    return usersOrdersRef.doc(
      orderId,
    ) as never as DocumentReference<TGiftOrderFirestore>;
  }

  async createUserGiftOrder(giftOrderCreateParams: TGiftOrderCreateParams) {
    const userGiftOrdersRef = await this.getUserGiftOrdersRef();

    return userGiftOrdersRef.add(giftOrderCreateParams);
  }

  async getUserGiftsRef() {
    const user = await this.userAuth();

    if (!user) throw new Error('getUserGiftsRef => user is anauthorised');

    return this.db
      .collection(DB_USERS)
      .doc(user.uid)
      .collection(DB_GIFTS) as CollectionReference<TUserGiftFirestore>;
  }

  async getUserGiftRef(giftId: string) {
    const userGiftRef = await this.getUserGiftsRef();

    return userGiftRef.doc(giftId);
  }

  async getUserGiftDoc(giftId: string) {
    const userGiftRef = await this.getUserGiftRef(giftId);

    return userGiftRef.get();
  }

  async getUserGift(giftId: string) {
    const userGiftDoc = await this.getUserGiftDoc(giftId);

    return userGiftDoc.data();
  }

  async createUserGift(giftId: string) {
    const userGiftDocRef = await this.getUserGiftRef(giftId);

    await userGiftDocRef.set({});

    return userGiftDocRef;
  }

  async getStripeCustomerRef() {
    const user = await this.userAuth();

    if (!user) throw new Error('getStripeCustomerRef => user is anauthorised');

    return this.db
      .collection(DB_STRIPE_CUSTOMERS)
      .doc(user.uid) as firebase.firestore.DocumentReference<IStripeCustomer>;
  }

  async getStripeCouponRef(orderId: string) {
    const stripeCustomerRef = await this.getStripeCustomerRef();

    return stripeCustomerRef
      .collection(DB_COUPONS)
      .doc(orderId) as firebase.firestore.DocumentReference<TCouponData>;
  }

  async getStripeCheckoutRef(orderId: string) {
    const stripeCustomerRef = await this.getStripeCustomerRef();

    return stripeCustomerRef
      .collection(DB_CHECKOUTS)
      .doc(orderId) as firebase.firestore.DocumentReference<TCheckoutData>;
  }

  async userAuth() {
    return this.user$.pipe(take(1)).toPromise();
  }

  getBookFileDirRef(bookId: string): firebase.storage.Reference {
    // TODO: const user = await this.userAuth();
    const { user } = this;

    if (!user) throw new Error('getBookFileDirRef => user is anauthorised');

    return this.storage.ref().child('usersBook').child(user.uid).child(bookId);
  }

  getBookFiles() {
    // TODO: const user = await this.userAuth();
    const { user } = this;

    if (!user) throw new Error('getBookFiles => user is anauthorised');

    return this.realTimeDB.ref(`user/files/${user.uid}`);
  }
}
