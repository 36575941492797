import type { TFaqData } from './types';
import {
  ROUTE_PATH_ALL_BOOKS,
  ROUTE_PATH_CONTACTS,
} from 'app/app-routing.constants';
import { BASE_HREF } from 'app/constants';
import { BOOK_ALIAS_STORY_OF_GRANDPA_GRANDMA } from '@shared/book/constants';

const contactsHref = `${BASE_HREF}${ROUTE_PATH_CONTACTS}`;
const allBooksHref = `${BASE_HREF}${ROUTE_PATH_ALL_BOOKS}`;
const soggHref = `${BASE_HREF}${BOOK_ALIAS_STORY_OF_GRANDPA_GRANDMA}`;

const link = (href: string, content: string) =>
  /* html */ `<a href="${href}" target="_blank">${content}</a>`;

export const faqData: TFaqData = [
  {
    question: `Which countries can you ship to?`,
    answer: /*html*/ `Presently we produce and ship locally only in the US and Japan.<br>We are working to add more countries and cross-border shipping but cannot offer service to other countries at this time.
    <br><br>This shop is for orders in the US. We produce locally. If you want to order in Japan, please ${link(
      'https://hellobuki.com/jp/',
      'visit ',
    )} our Japanese shop.`,
  },
  {
    question: `How do I make a personalized book?`,
    answer: /*html*/ `It's very easy and quick to personalize our books.<br>
    1. Go to book product page.<br>
    2. Enter child's name, date of birth, select the avatar and press "Open the book".<br>
    3. (Our service uses information you provided to automatically start customization of your book).<br>
    4. Preview all pages of your book. In the process a few pop-ups will appear to ask for additional customization options.<br>
    5. You can change your information inputs and try all our books from the beginning as many times as you want!<br><br>
    Please order your personalized books after you confirmed and finalized all the input.<br>
    Our books are made-to-order, and production starts soon after you finished the payment.<br>
    We cannot cancel or change the book after production started.`,
  },
  {
    question: `How do I place an order?`,
    answer: /*html*/ `Once you personalized your book, add it to the cart.<br>
    Then, follow 3 simple steps in the Checkout (input address, select shipping method and make an online payment).<br><br>

    After you completed payment, you will receive confirmation e-mail with your order and book(s) summary.<br>
    Soon after this, our printing partner will start production of your original book.<br>
    It takes 3 business days to produce and ship the book from the factory.<br>
    Right now we have production partners in the US and Japan.<br><br>

    Please order your personalized books after you confirmed and finalized all the input.<br>
    Our books are made-to-order, and production starts soon after you finished the payment.<br>
    We cannot cancel or change the book after production started.`,
  },
  {
    question: `What are the shipping methods?`,
    answer: /*html*/ `After you place an order, it takes 3 business days to produce and send your book from the factory.<br>
    We produce locally in the US and Japan. Shipping time is added on top of that.<br><br>

    Shipping estimator can be accessed from each book's product page, cart or checkout.<br>
    Please check it carefully before you order.<br><br>

    We offer 3 shipping options to your convenience: UPS Mail Innovations, USPS Priority Mail and UPS 2nd Day.<br>
    Shipping option selection and the cost will be displayed during the checkout process.<br><br>

    At the present time we can produce and ship only to United Stated and Japan.
    `,
  },
  {
    question: `Who is BÜKI?`,
    answer: /*html*/ `We are personalized books publisher established and run by parents who believe that children are true heroes of our world.<br><br>

    We strive to create books where any child can become the story’s hero.<br>
    Our books bring joy, help development, teach new things and increase confidence of children.<br><br>

    To us - all children are special.`,
  },
  {
    question: `I cannot upload photos.`,
    answer: /*html*/ `Please only use photos that meet the following specifications.<ul><li>Supported formats: JPEG, PNG, HEIC</li><li>Size: minimum 100KB and maximum 20MB</li></ul><br>In case all specs are met and still having troubles, try following:<br>1. Restart the book from browser (not an SNS app).<br>2. Slow wi-fi sometimes causes errors when uploading photos to our system.Please try different wi-fi network or upload from your mobile network.<br><br>Note that after you start uploading the photo it may take up to 30 seconds to process it and reflect in the book.`,
  },
  {
    question: `What payment methods can I use?`,
    answer: /*html*/ `At the present time we accept Visa, Mastercard, American Express, and Discover cards.`,
  },
  {
    question: `Order confirmation e-mail did not arrive.`,
    answer: /*html*/ `After you place an order you will see Order Confirmation page, and we will send you an e-mail with order and book details summary.<br><br>If your confirmation e-mail hasn't arrived in your inbox, please check your spam (junk) and promotions folders.
<br><br>
If you still cannot find an e-mail from us, please don't worry and kindly reach us via ${link(
      contactsHref,
      'Contact Form',
    )}. We will get back to you within 1-2 business days.`,
  },
  {
    question: `Can I add gift wrapping?`,
    answer: /*html*/ `Sorry, at the present time we do not provide gift wrapping options.<br>We are working hard to implement this service soon.`,
  },
  {
    question: `Can I cancel or change the book after ordering?`,
    answer: /*html*/ `All our books are made-to-order, and unfortunately, we cannot cancel or modify the books that you ordered.<br>Please check your books <span class="faq-red">prior</span> to payment for any typos, mistakes and best attached photo.`,
  },
  {
    question: `My payment is declined / cannot complete payment.`,
    answer: /*html*/ `In most cases, we see issues with payment happen when a customer unintentionally entered the wrong credit card number or CVV code.<br>Can you kindly double check if you filled accurately all the required fields?<br><br>In this did not help, then your card was declined by the payment processing service or the bank.<br>1. Please try another card.<br>2. Contact your financial institution to confirm the status of your credit card.`,
  },
  {
    question: `My order is late.`,
    answer: /*html*/ `We apologize for inconvenience.<br>After you placed the order it takes up to 3 business days to produce and ship your books, then the shipping time is added on top.<br>The shipping estimates we provide on our website are accurate for the vast majority of cases, however, sometimes delays and accidents in transportation happen.<br><br>Please follow the next steps:<br>1. Check the Shipping Confirmation e-mail we sent for estimated delivery dates and tracking code.<br>2. Using tracking code please check the status of delivery.<br>3. If the order is getting delayed, or if the tracking information shows another problem, please ${link(
      contactsHref,
      'contact us',
    )}.<br><br>Please do not worry, we will help to find your books!`,
  },
  {
    question: `How can I track my order?`,
    answer: /*html*/ `All of our shipping options come with tracking code, which you can find in your Shipping Confirmation email, once your order is produced and shipped (takes up to 3 business days).<br><br>If you cannot find an e-mail from us, please don't worry and kindly reach us via <span>yay@hellobuki.com</span>.<br>We will help you.`,
  },
  {
    question: `Book arrived with issues (damages or similar issues).`,
    answer: /*html*/ `We are sorry for causing you trouble. However, please allow us to make it right.<br><br>Please follow the next steps:<br>1. Take photos of the damaged / parts of the book with issues.<br>2. Contact us via e-mail at <span>yay@hellobuki.com</span>.<br>3. Please include damaged photos, a brief explanation of the situation and some information that can help us identify your order (Order IT, Book ID which you can find in the book or in the Order Confirmation e-mail).<br><br>After we check your order and confirm the situation, we will send your new reprinted book right away.`,
  },
  {
    question: `Can you speed up the shipping?`,
    answer: /*html*/ `We provide several shipping options, including a little costly but express delivery.<br><br>Other than estimated delivery dates, at this time unfortunately we cannot expedite your order any further.`,
  },
  {
    question: `Can I send the book to different address than mine?`,
    answer: /*html*/ `Yes, during the checkout process you can input your Customer Information (Billing Address) but indicate different name and address in the Shipping Information.`,
  },
  {
    question: `Other requests, questions and wishes.`,
    answer: /*html*/ `Feel free to reach out to us with wishes, questions, requests and ideas. ^^`,
  },
];

export const faqDataCondensed: TFaqData = faqData.slice(0, 4);
