import type { AfterViewInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  QueryList,
} from '@angular/core';
import { TabsItemComponent } from './components/tabs-item/tabs-item.component';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent implements AfterViewInit {
  @ContentChildren(TabsItemComponent) children!: QueryList<TabsItemComponent>;

  public activeTabIndex = 0;

  constructor(private _cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    /** detectChanges to draw children templates */
    this._cdr.detectChanges();
  }

  public onTabsNavItemClick(index: number): void {
    this.activeTabIndex = index;
  }
}
