import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { EventEmitter, Input, Output } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import Regions from '@shared/shipping/data';
import { MediaService } from 'app/services/media.service';
import Subscriber from 'app/subscriber';
import {
  addressFirstValidatorList,
  addressSecondValidatorList,
  cityValidatorList,
  countryValidatorList,
  firstNameValidatorList,
  lastNameValidatorList,
  phoneValidatorList,
  postalCodeValidatorList,
  stateValidatorList,
} from 'app/validators';
import {
  ADDRESS_1_ERROR,
  ADDRESS_2_ERROR,
  ADDRESS_3_ERROR,
  FIRSTNAME_ERROR,
  LASTNAME_ERROR,
  PHONE_ERROR,
  POSTAL_CODE_ERROR,
  REQUIRED_ERROR,
} from 'app/validators.constants';
import isEqual from 'lodash/isEqual';
import { BehaviorSubject } from 'rxjs';
import type { IFormDataShippingAddress } from './form-shipping-address.interfaces';

@Component({
  selector: 'app-form-shipping-address',
  templateUrl: './form-shipping-address.component.html',
  styleUrls: ['./form-shipping-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormShippingAddressComponent implements OnInit, OnDestroy {
  @Input() headerTitle = 'Shipping Information';

  @Input() set active(status: boolean) {
    if (this.active !== status) {
      this.activeFormControl.setValue(status);
    }
  }

  get active(): boolean {
    return this.activeFormControl.value;
  }

  @Output() activeChange = new EventEmitter<boolean>();

  /** `undefined` when invalid */
  @Input() set data(data: IFormDataShippingAddress | undefined) {
    if (!isEqual(data, this.data)) {
      this._data$.next(data);
      this.dataChange.emit(data);

      if (data && !isEqual(data, this.formGroup.value)) {
        this.formGroup.setValue(data);
      }
    }
  }

  get data(): IFormDataShippingAddress | undefined {
    return this._data$.value;
  }

  private _data$ = new BehaviorSubject<IFormDataShippingAddress | undefined>(
    undefined,
  );

  @Output() dataChange = new EventEmitter<
    IFormDataShippingAddress | undefined
  >();

  private _touchEventEmitterSub = new Subscriber();

  @Input() set touchEventEmitter(e: EventEmitter<void> | undefined) {
    this._touchEventEmitterSub.unsubscribe(); // unsubscribe from previous sub`s

    if (!e) return;

    this._touchEventEmitterSub.push(
      // add new subscription
      e.subscribe(() => {
        this.touch();
      }),
    );
  }

  public activeFormControl = new FormControl(true);

  public formGroup: FormGroup = new FormGroup({
    // name
    firstName: new FormControl('', firstNameValidatorList),
    lastName: new FormControl('', lastNameValidatorList),
    // address
    /// street
    addressFirst: new FormControl('', addressFirstValidatorList),
    /// apt
    addressSecond: new FormControl('', addressSecondValidatorList),
    /// city
    city: new FormControl('', cityValidatorList),
    // postal code
    postalCode: new FormControl('', postalCodeValidatorList),
    country: new FormControl('US', countryValidatorList),
    state: new FormControl(Regions[0].code, stateValidatorList),
    // phone
    phone: new FormControl('', phoneValidatorList),
  });

  public FIRSTNAME_ERROR = FIRSTNAME_ERROR;

  public LASTNAME_ERROR = LASTNAME_ERROR;

  public ADDRESS_1_ERROR = ADDRESS_1_ERROR;

  public ADDRESS_2_ERROR = ADDRESS_2_ERROR;

  public ADDRESS_3_ERROR = ADDRESS_3_ERROR;

  public REQUIRED_ERROR = REQUIRED_ERROR;

  public POSTAL_CODE_ERROR = POSTAL_CODE_ERROR;

  public PHONE_ERROR = PHONE_ERROR;

  public regions = Regions;

  private readonly _sub = new Subscriber();

  constructor(public media: MediaService, private _cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.formGroup.controls.country.disable();

    this._sub.push(
      this.formGroup.valueChanges.subscribe(
        (data: IFormDataShippingAddress): void => {
          this._cdr.detectChanges();

          if (this.formGroup.valid) {
            this.data = {
              ...data,
              /** @see this.formGroup.controls.country.disable() */
              country: 'US',
            };
          } else {
            this.data = undefined;
          }
        },
      ),

      this.activeFormControl.valueChanges.subscribe((status) => {
        this.activeChange.emit(status);
      }),
    );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  public touch() {
    this.formGroup.markAllAsTouched();
    this._cdr.detectChanges(); // detect changes to redraw the component
  }
}
