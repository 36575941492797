<section class="form">
  <header class="form__section form__section_header">
    <app-form-header title="Gift Special Parameters"></app-form-header>
  </header>
  <form
    class="form__section form__section_content"
    [formGroup]="formGroup"
  >
    <div class="section">
      <div class="section__input">
        <div class="input-wrap">
          <div class="input-wrap__label">
            <div class="label">
              <app-label
                class="label__item"
                [forId]="inputCount.id"
                >Count</app-label
              >
            </div>
          </div>
          <div class="input-wrap__input">
            <div class="input">
              <div class="input__item">
                <app-input
                  #inputCount
                  type="number"
                  formControlName="count"
                  error="Please set the value from 1 to 1000"
                ></app-input>
              </div>
              <!-- adding to fill empty space making input 50% size -->
              <div
                class="input__item"
                *ngIf="!(media.isMobileObservable | async)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="section__input">
        <div class="input-wrap">
          <div class="input-wrap__label">
            <div class="label">
              <app-label
                class="label__item"
                [forId]="inputExpiration.id"
                >Expiration</app-label
              >
            </div>
          </div>
          <div class="input-wrap__input">
            <div class="input">
              <div class="input__item">
                <app-input-date
                  #inputExpiration
                  type="text"
                  formControlName="expiration"
                  [min]="expirationMinDate"
                ></app-input-date>
              </div>
              <!-- adding to fill empty space making input 50% size -->
              <div
                class="input__item"
                *ngIf="!(media.isMobileObservable | async)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="section__input">
        <div class="input-wrap">
          <div class="input-wrap__label">
            <div class="label">
              <app-label
                class="label__item"
                [forId]="inputPassword.id"
                >Password</app-label
              >
            </div>
          </div>
          <div class="input-wrap__input">
            <div class="input">
              <div class="input__item">
                <app-input
                  #inputPassword
                  type="password"
                  formControlName="password"
                ></app-input>
              </div>
              <!-- adding to fill empty space making input 50% size -->
              <div
                class="input__item"
                *ngIf="!(media.isMobileObservable | async)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="section__input">
        <div class="input-wrap">
          <div class="input-wrap__label">
            <div class="label">
              <app-label
                class="label__item"
                [forId]="inputCampaignCode.id"
                >Campaign Code</app-label
              >
            </div>
          </div>
          <div class="input-wrap__input">
            <div class="input">
              <div class="input__item">
                <app-input
                  #inputCampaignCode
                  type="text"
                  formControlName="campaignCode"
                ></app-input>
              </div>
              <!-- adding to fill empty space making input 50% size -->
              <div
                class="input__item"
                *ngIf="!(media.isMobileObservable | async)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="section__input">
        <div class="input-wrap">
          <div class="input-wrap__label">
            <div class="label">
              <app-label
                class="label__item"
                [forId]="inputCampaignDescription.id"
                >Campaign Description</app-label
              >
            </div>
          </div>
          <div class="input-wrap__input">
            <div class="input">
              <div class="input__item">
                <app-input
                  #inputCampaignDescription
                  type="text"
                  formControlName="campaignDescription"
                ></app-input>
              </div>
              <!-- adding to fill empty space making input 50% size -->
              <div
                class="input__item"
                *ngIf="!(media.isMobileObservable | async)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="section__input">
        <div class="input-wrap">
          <div class="input-wrap__label">
            <div class="label">
              <app-label
                class="label__item"
                [forId]="inputCodeType.id"
                >Code Type</app-label
              >
            </div>
          </div>
          <div class="input-wrap__input">
            <div class="input">
              <div class="input__item">
                <app-input
                  #inputCodeType
                  type="select"
                  formControlName="codeType"
                >
                  <option [value]="EGiftOrderCodeType.standart.toString()">
                    Standart
                  </option>
                  <option [value]="EGiftOrderCodeType.special.toString()">
                    Special
                  </option>
                </app-input>
              </div>
              <!-- adding to fill empty space making input 50% size -->
              <div
                class="input__item"
                *ngIf="!(media.isMobileObservable | async)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="section__input"
        *ngIf="
          (formGroup.controls.codeType.valueChanges | async) ===
          EGiftOrderCodeType.special.toString()
        "
      >
        <div class="input-wrap">
          <div class="input-wrap__label">
            <div class="label">
              <app-label
                class="label__item"
                [forId]="inputCampaignNumber.id"
                >Campaign Number</app-label
              >
            </div>
          </div>
          <div class="input-wrap__input">
            <div class="input">
              <div class="input__item">
                <app-input
                  #inputCampaignNumber
                  type="text"
                  formControlName="campaignNumber"
                  placeholder="Leave empty to generate automatically."
                  error="Should contain three digits or be empty to generate automatically."
                ></app-input>
              </div>
              <!-- adding to fill empty space making input 50% size -->
              <div
                class="input__item"
                *ngIf="!(media.isMobileObservable | async)"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>
