<div class="wrap">
  <div class="wrap__main">
    <div class="main">
      <div class="main__photo">
        <app-photo
          [src]="photo"
          [alt]="title"
        ></app-photo>
      </div>
      <div class="main__title">
        <div class="title">
          <div class="title__component">
            <app-title
              [title]="title"
            ></app-title>
          </div>
          <div *ngIf="!(isLtSm | async)" class="title__spec">
            <ng-container *ngTemplateOutlet="specsTemplate"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isLtSm | async" class="wrap__spec">
    <ng-container *ngTemplateOutlet="specsTemplate"></ng-container>
  </div>
  <div
    *ngIf="price"
    class="wrap__price"
  >
    <app-price
      class="price"
      [price]="price.value"
      [currency]="price.currency"
      [discount]="discount"
    ></app-price>
  </div>
</div>

<ng-template #specsTemplate>
  <app-spec
    *ngFor="let key of specsKeys"
    [key]="key"
    [value]="specs[key]"
  ></app-spec>
</ng-template>
