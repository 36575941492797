import type { AfterContentInit, OnInit } from '@angular/core';
import { Component, Injector, Input, forwardRef } from '@angular/core';
import { Logger } from 'src/app/services/logger.service';
import type { ControlValueAccessor, FormControl } from '@angular/forms';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import type { IBookDateInput } from 'src/app/data/books/inputs/date/types';
import { checkControlShowError } from 'src/app/functions';
import { EInputTheme } from '../../interfaces';
import uniqueId from 'lodash/uniqueId';
import lowerFirst from 'lodash/lowerFirst';

const log = new Logger('InputDateComponent');

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputDateComponent),
  multi: true,
};

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class InputDateComponent
  implements OnInit, ControlValueAccessor, AfterContentInit
{
  @Input() set value(v: IBookDateInput['value']) {
    if (v !== this._value) {
      log.info('value set', v);
      this._value = v;
      this.onChange(v);
      this.onTouched();
    }
  }

  get value(): IBookDateInput['value'] {
    return this._value;
  }

  @Input() public name?: string;

  @Input() public disabled = false;

  @Input() public label = '';

  @Input() public placeholder: string = '';

  @Input() public error = '';

  @Input() public theme = EInputTheme.default;

  @Input() public min?: IBookDateInput['min'];

  @Input() public max?: IBookDateInput['max'];

  public readonly EInputTheme = EInputTheme;

  public id?: string;

  public control?: FormControl;

  private _value?: IBookDateInput['value'];

  constructor(private injector: Injector) {}

  get showError(): boolean {
    return checkControlShowError(this.control);
  }

  writeValue(value: IBookDateInput['value']): void {
    if (value !== this._value) {
      this._value = value;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public onChange(value: IBookDateInput['value']) {}

  public onTouched() {}

  ngOnInit(): void {
    setTimeout(() => (this.id = this._getId()));
  }

  // The form control is only set after initialization
  ngAfterContentInit(): void {
    this._getControl();
  }

  private _getControl() {
    const ngControl = this.injector.get(NgControl, null);

    if (ngControl) {
      this.control = ngControl.control as FormControl;
    } else {
      // Component is missing form control binding
    }
  }

  private _getId(id: number | string = uniqueId()): string {
    return `${lowerFirst(this.constructor.name)}_${id}`;
  }
}
