import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-banner-one',
  templateUrl: './banner-one.component.html',
  styleUrls: ['./banner-one.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerOneComponent {
  // constructor() {}
}
