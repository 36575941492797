import type { IShipping as IShippingFE } from './types';
import memoizee from 'memoizee';
import {
  SHIPPING_METHOD_EXPRESS,
  SHIPPING_METHOD_FAST,
  SHIPPING_METHOD_STANDART,
} from '@shared/shipping/constants';
import type {
  IException,
  TShippingMap,
  TShippingMethodsMap,
} from '@shared/production/interfaces';
import { getExpectedArrivalDate } from '@shared/production/utils';
import format from 'date-fns/format';
import type { TRegionName } from '@shared/shipping/interfaces';
import enUS from '@shared/translations/en-US.json';

export const getShippingStandart = memoizee(
  (
    expectedArrivalDate: string,
    regionName: TRegionName,
    price: number,
  ): IShippingFE => ({
    id: SHIPPING_METHOD_STANDART,
    name: enUS[`shipping.${SHIPPING_METHOD_STANDART}`],
    description: `Estimated delivery: ${expectedArrivalDate} to ${regionName}<br>UPS Mail Innovations. After shipping takes 7-10 days to arrive.`,
    price,
    currency: '$',
  }),
);

export const getShippingFast = memoizee(
  (
    expectedArrivalDate: string,
    regionName: TRegionName,
    price: number,
  ): IShippingFE => ({
    id: SHIPPING_METHOD_FAST,
    name: enUS[`shipping.${SHIPPING_METHOD_FAST}`],
    description: `Estimated delivery: ${expectedArrivalDate} to ${regionName}<br>USPS Priority Mail. After shipping takes 1-3 days to arrive. `,
    price,
    currency: '$',
  }),
);

export const getShippingExpress = memoizee(
  (
    expectedArrivalDate: string,
    regionName: TRegionName,
    price: number,
  ): IShippingFE => ({
    id: SHIPPING_METHOD_EXPRESS,
    name: enUS[`shipping.${SHIPPING_METHOD_EXPRESS}`],
    description: `Estimated delivery: ${expectedArrivalDate} to ${regionName}<br>Our fastest option (UPS 2nd Day). May arrive on Saturdays to urban areas.
    (No P.O. Boxes please)`,
    price,
    currency: '$',
  }),
);

const formatDate = (date: [Date] | [Date, Date], _format = 'MMM d'): string => {
  if (date.length === 1) {
    return format(date[0], _format);
  }

  return `${format(date[0], _format)} - ${format(date[1], _format)}`;
};

/** @returns all available shippings */
export const getShippings = (
  booksCount: number,
  expectedShippingDate: Date,
  regionName: keyof TShippingMap,
  shippingMethodsMap: TShippingMethodsMap,
  exceptions: IException[],
) => {
  return [
    getShippingStandart(
      formatDate(
        getExpectedArrivalDate(
          expectedShippingDate,
          regionName,
          shippingMethodsMap[SHIPPING_METHOD_STANDART],
          exceptions,
        ),
      ),
      regionName,
      shippingMethodsMap[SHIPPING_METHOD_STANDART][regionName].prices[
        booksCount - 1
      ],
    ),
    getShippingFast(
      formatDate(
        getExpectedArrivalDate(
          expectedShippingDate,
          regionName,
          shippingMethodsMap[SHIPPING_METHOD_FAST],
          exceptions,
        ),
      ),
      regionName,
      shippingMethodsMap[SHIPPING_METHOD_FAST][regionName].prices[
        booksCount - 1
      ],
    ),
    getShippingExpress(
      formatDate(
        getExpectedArrivalDate(
          expectedShippingDate,
          regionName,
          shippingMethodsMap[SHIPPING_METHOD_EXPRESS],
          exceptions,
        ),
      ),
      regionName,
      shippingMethodsMap[SHIPPING_METHOD_EXPRESS][regionName].prices[
        booksCount - 1
      ],
    ),
  ];
};

/** @returns shipping based on products count */
// export const getCurrentShipping = (
//   productsCount: number,
//   region = getTheCheapestPremiumShippingRegion(Regions),
// ): IShippingFE =>
//   getShippingByName(getCurrentShippingName(productsCount), region);

/** comparing chippings by name */
export const compareShippings = (
  shippingA: IShippingFE,
  shippingB: IShippingFE,
): boolean => shippingA?.name === shippingB?.name;
