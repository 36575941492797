import type {
  BookWhereIsBirthdayCakeData,
  CommonBookData,
} from '@shared/models';
import type { TGender, THairWIBC } from '@shared/book/interfaces';
import { MONTH_INDEX_TO_TIME_OF_THE_YEAR_MAP } from '@shared/book/maps';
import { BOOK_ALIAS_WHERE_IS_BIRTHDAY_CAKE } from '@shared/book/constants';
import type { IBookCreate } from 'src/app/services/book-create/book-create';

export class BookWhereIsBirthdayCakeCreate
  implements IBookCreate<BookWhereIsBirthdayCakeData>
{
  createNewBook(
    uid: string,
    docId: string,
    heroName: string,
    birthMonth: string,
    birthDate: string,
    age: string,
    gender: TGender,
    hairStyle: THairWIBC,
    glasses: boolean,
    serverTimestamp: any,
    common: Partial<CommonBookData> = {},
  ): BookWhereIsBirthdayCakeData {
    const newBook: BookWhereIsBirthdayCakeData = this.createEmptyBook();
    newBook.uid = uid;
    newBook.bookId = docId;
    newBook.heroName = heroName;
    newBook.birthMonth = birthMonth;
    newBook.birthDate = birthDate;
    newBook.age = age;
    newBook.gender = gender;
    newBook.hairStyle = hairStyle;
    newBook.glasses = glasses;
    newBook.createTimeStamp = serverTimestamp;
    newBook.steps.character.isDone = true;
    newBook.steps.ageToBeBirthTime.isDone = true;
    newBook.timeOfTheYear =
      MONTH_INDEX_TO_TIME_OF_THE_YEAR_MAP[Number(birthMonth) - 1];
    newBook.steps.season.isDone = true;
    newBook.messageText =
      `${heroName}へ\n` +
      `\n${age}さいの　おたんじょうび　おめでとう！\n${age}さいの　まいにちも\n` +
      `たのしいことを　たくさんみつけて\n` +
      `すてきな　いちねんにしてね。\n`;

    Object.assign(newBook, common);

    return newBook;
  }

  createEmptyBook(): BookWhereIsBirthdayCakeData {
    return {
      alias: BOOK_ALIAS_WHERE_IS_BIRTHDAY_CAKE,
      steps: {
        character: {
          stepType: 'character',
          isDone: false,
        },
        message: {
          stepType: 'message',
          isDone: false,
        },
        ageToBeBirthTime: {
          stepType: 'ageToBeBirthTime',
          isDone: false,
        },
        season: {
          stepType: 'season',
          isDone: false,
        },
        photo: {
          stepType: 'photo',
          isDone: false,
        },
      },
      age: undefined,
      birthMonth: undefined,
      birthDate: undefined,
      bookId: undefined,
      createTimeStamp: undefined,
      gender: undefined,
      heroName: undefined,
      messageText: undefined,
      timeOfTheYear: undefined,
      uid: undefined,
      hairStyle: undefined,
      glasses: false,
      cover: 'soft',
      fileUploadStatus: 0,
      wrapping: 'standart',
    };
  }
}
