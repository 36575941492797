import type {
  AfterContentInit,
  AfterViewInit,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Injector,
  Input,
  forwardRef,
} from '@angular/core';
import type { ControlValueAccessor, FormControl } from '@angular/forms';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { SelectValue } from '../../interfaces';
import { Logger } from 'src/app/services/logger.service';
import { BehaviorSubject } from 'rxjs';
import Subscriber from 'src/app/subscriber';
import { pairwise } from 'rxjs/operators';
import { ESelectButtonTheme } from '../select-button/enums';

const log = new Logger('SelectComponent');
const sub = new Subscriber();

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectComponent),
  multi: true,
};

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class SelectComponent
  implements
    OnInit,
    ControlValueAccessor,
    AfterContentInit,
    AfterViewInit,
    OnDestroy
{
  @ViewChild('native') nativeRef?: ElementRef<HTMLSelectElement>;

  @Input() public id?: string;

  @Input() public name?: string;

  @Input() public placeholder: string = '';

  @Input() set value(v: SelectValue) {
    if (v !== this._value) {
      log.info('value set', v);
      this._value = v;
      this.onChange(v);
      this.onTouched();
    }
  }

  get value(): SelectValue {
    return this._value;
  }

  private _value: SelectValue = '';

  @Input() public disabled?: boolean;

  @Input() public selectButtonTheme?: ESelectButtonTheme;

  private _isActive = new BehaviorSubject(false);

  public isActive = this._isActive.asObservable();

  private _timer?: number;

  public options: HTMLOptionElement[] = [];

  public get option(): HTMLOptionElement | undefined {
    const { options, value } = this;
    let option: HTMLOptionElement | undefined;

    for (let i = 0; i < options.length; i++) {
      if (
        options[i].value === value ||
        (options[i].value === '' && (value === null || value === undefined))
      ) {
        option = options[i];

        break;
      }
    }

    return option;
  }

  public control?: FormControl;

  public Node = Node;

  constructor(private injector: Injector, private _cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    sub.push(
      this._isActive.pipe(pairwise()).subscribe(([prevIsActive, isActive]) => {
        const isActiveChanged = prevIsActive !== isActive;

        // each attempt to set isActive as `true` we should clear current timeout that's about to set `false`
        if (isActive) {
          window.clearTimeout(this._timer);
        }

        if (!isActiveChanged) return;

        if (!isActive) {
          this.onTouched();
        }
      }),
    );
  }

  ngAfterContentInit(): void {
    this._initControl();
  }

  ngAfterViewInit(): void {
    const select = this.nativeRef?.nativeElement;

    if (!select) {
      log.error('ngAfterViewInit => select not found');
      return;
    }

    this.writeOptions(select);
  }

  ngOnDestroy(): void {
    sub.unsubscribe();
  }

  private _initControl(): void {
    const control = this.getControl();

    if (control) {
      log.info('value set by control', control.value);
      this._value = control.value;

      control.valueChanges.subscribe((v: SelectValue) => {
        log.info('value set by control', v);
        this._value = v;
        this._cdr.detectChanges();
      });
    } else {
      log.info('control is not defined');
    }
  }

  protected getControl(): FormControl | undefined {
    const ngControl = this.injector.get(NgControl, null);

    if (ngControl) {
      this.control = ngControl.control as FormControl;
    } else {
      // Component is missing form control binding
    }

    return this.control;
  }

  public writeOptions(select: HTMLSelectElement): void {
    const options = select.childNodes as NodeListOf<HTMLOptionElement>;

    this.options = Array.from(options);
  }

  // ngModel implementation
  writeValue(value: SelectValue): void {
    if (value !== this._value) {
      this._value = value;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public onChange(value: SelectValue): void {}

  public onTouched(): void {}

  // functionality
  private _hide(): void {
    this._isActive.next(false);
  }

  private _show(): void {
    this._isActive.next(true);
  }

  private _toggle(): void {
    if (this.disabled) return;

    const isActive = this._isActive.value;

    if (isActive) {
      this._hide();
    } else {
      this._show();
    }
  }

  // on host click handler
  @HostListener('click')
  public onClick(): void {
    log.info('onClick', this.value);
    this._toggle();
  }

  @HostListener('mouseenter')
  private _onMouseenter(): void {
    if (!this._isActive.value) return;

    window.clearTimeout(this._timer);
  }

  @HostListener('mouseleave')
  private _onMouseleave(): void {
    if (!this._isActive.value) return;

    this._timer = window.setTimeout(() => {
      this._hide();

      delete this._timer;
    }, 200);
  }
}
