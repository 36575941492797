import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { ISectionStepsItem } from '../../types';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepComponent {
  @Input() imgSrc!: ISectionStepsItem['imgSrc'];

  @Input() text!: ISectionStepsItem['text'];

  constructor() {}
}
