import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BookData } from '@shared/models';

@Component({
  selector: 'app-book-button',
  templateUrl: './book-button.component.html',
  styleUrls: ['./book-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookButtonComponent {
  @Input() booksList!: BookData[];

  constructor() {}
}
