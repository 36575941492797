import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { emailValidatorList } from 'src/app/validators';
import { EMAIL_ERROR, REQUIRED_ERROR } from 'src/app/validators.constants';
import { TOPIC_LIST } from 'src/app/shared/form-contacts/constants';
import { FirebaseService } from 'src/app/services/firebase.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import firebase from 'firebase/app';
import { BehaviorSubject } from 'rxjs';
import { Logger } from 'src/app/services/logger.service';
import Subscriber from 'src/app/subscriber';
import { MediaObserver } from '@angular/flex-layout';

const log = new Logger('FormContactsComponent');

@Component({
  selector: 'app-form-contacts',
  templateUrl: './form-contacts.component.html',
  styleUrls: ['./form-contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormContactsComponent implements OnInit, OnDestroy {
  public readonly formGroup: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', emailValidatorList),
    topic: new FormControl('0', [Validators.required]),
    message: new FormControl('', [
      Validators.required,
      Validators.maxLength(1000),
    ]),
  });

  public readonly REQUIRED_ERROR = REQUIRED_ERROR;

  public readonly EMAIL_ERROR = EMAIL_ERROR;

  public readonly TOPIC_LIST = TOPIC_LIST;

  private readonly _isSendingSubject = new BehaviorSubject<boolean>(false);

  public readonly isSendingObservable = this._isSendingSubject.asObservable();

  private readonly _isMobileSubject = new BehaviorSubject<boolean>(false);

  public readonly isMobileObservable = this._isMobileSubject.asObservable();

  private readonly _sub = new Subscriber();

  constructor(
    private _firebaseService: FirebaseService,
    private _snackBar: MatSnackBar,
    private _media: MediaObserver,
  ) {}

  ngOnInit(): void {
    this._sub.push(
      this._media.asObservable().subscribe(() => {
        this._updateIsMobile();
      }),
    );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  public async onSubmit() {
    const data: {
      name: string;
      email: string;
      topic: string;
      message: string;
    } = this.formGroup.value;

    data.topic = TOPIC_LIST[Number(data.topic)];

    await this._firebaseService.userAuth();

    let hasError: boolean = false;
    let responseData: { error: number; errorMessage?: string } | undefined;

    this._isSendingSubject.next(true);

    try {
      responseData = (await firebase
        .functions()
        .httpsCallable('submitContactsForm')(data)) as any;
    } catch (e) {
      hasError = true;
      log.error(e);
    } finally {
      if (responseData) {
        if (responseData.error > 0) {
          log.error(responseData.error, responseData.errorMessage);
          hasError = true;
        }
      } else {
        hasError = true;
      }
    }

    this._isSendingSubject.next(false);

    if (!hasError) {
      this._snackBar.open(
        'お問い合わせありがとうございます。確認しご連絡いたします。',
        'ok',
        {
          duration: 5000,
          panelClass: ['snackBar-successful'],
        },
      );

      this.formGroup.reset({
        name: '',
        email: '',
        topic: '',
        message: '',
      });
    } else {
      this._snackBar.open('send fail', 'close', {
        duration: 5000,
        panelClass: ['snackBar-fail'],
      });
    }
  }

  private _updateIsMobile(): void {
    this._isMobileSubject.next(this._media.isActive('lt-md'));
  }
}
