<div class="container">
  <div class="container__section container__section_next">
    <div class="content">
      <div
        class="content__description"
      >絵本の内容にお間違いはありませんか？<br>ご注文後はキャンセルできません</div>
      <app-button
        class="content__button"
        [icons]="nextIcons"
        [disabled]="isNextDisabled"
        (buttonClick)="next.emit()"
        [fullWidth]="true"
      >{{ nextText }}</app-button>
    </div>
  </div>
  <div class="container__section">
    <button class="back-btn"
      (click)="back.emit()"
      [disabled]="isBackDisabled"
    >
      <svg-icon class="back-btn__icon"
        src="assets/images/icons/order-footer-navigation-back.svg"
      ></svg-icon>
      <span class="back-btn__text" translate>Back</span>
    </button>
  </div>
</div>
