import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-section-collaboration-contacts',
  templateUrl: './section-collaboration-contacts.component.html',
  styleUrls: ['./section-collaboration-contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionCollaborationContactsComponent {
  // constructor() {}
}
