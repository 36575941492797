import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionReviewsYotpoCommonComponent } from './section-reviews-yotpo-common.component';
import { ImageTitleModule } from '../image-title/image-title.module';

@NgModule({
  declarations: [SectionReviewsYotpoCommonComponent],
  imports: [CommonModule, ImageTitleModule],
  exports: [SectionReviewsYotpoCommonComponent],
})
export class SectionReviewsYotpoCommonModule {}
