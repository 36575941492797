import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-auth-card',
  templateUrl: './auth-card.component.html',
  styleUrls: ['./auth-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthCardComponent {
  /** innerHTML text */
  @Input() title: string;

  constructor() {}
}
