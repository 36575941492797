import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputGroupComponent } from './input-group.component';
import { TranslateModule } from '@ngx-translate/core';
import { InputModule } from 'app/shared/input/input.module';

@NgModule({
  declarations: [InputGroupComponent],
  imports: [CommonModule, InputModule, TranslateModule],
  exports: [InputGroupComponent],
})
export class InputGroupModule {}
