import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionBooksPreviewComponent } from './section-books-preview.component';
import { BookItemComponent } from './components/book-item/book-item.component';
import { ButtonModule } from '../button/button.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SectionBooksPreviewComponent, BookItemComponent],
  imports: [CommonModule, ButtonModule, TranslateModule],
  exports: [SectionBooksPreviewComponent],
})
export class SectionBooksPreviewModule {}
