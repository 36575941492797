<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div class="container__title">
        <app-image-title type="gift">ギフトコードを使う</app-image-title>
      </div>
      <div class="container__content">
        <p class="text">BÜKIは物語の主人公になれる絵本。</p>
        <p class="text">受け取ったギフトコードを入力し、絵本をつくりましょう。</p>
      </div>
    </div>
  </div>
</div>
