<ng-container [ngSwitch]="type">
  <app-cart
    *ngSwitchCase="CalculatorComponentType.cart"
    [items]="localItems"
    [total]="total"
    [currency]="currency"
  ></app-cart>
  <app-order
    *ngSwitchCase="CalculatorComponentType.order"
    [items]="localItems"
    [total]="total"
    [currency]="currency"
  ></app-order>
  <app-lite
    *ngSwitchCase="CalculatorComponentType.lite"
    [total]="total"
    [currency]="currency"
  ></app-lite>
</ng-container>
