<div class="container">
  <div class="container__title">
    <p class="title">Curretly available payment options:</p>
  </div>
  <div class="container__icons">
    <img
      class="payments-img"
      src="assets/images/cart-payments.png"
      alt="visa | mastercard | american express | jcb | dinners club international"
    />
  </div>
  <!-- <div class="container__icons">
    <img
      class="payments-img payments-img_konbini"
      src="assets/images/konbini-payments.png"
      alt="familymart | lawson | seicomart"
    />
  </div> -->
</div>
