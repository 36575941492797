import { ChangeDetectionStrategy, Component } from '@angular/core';
import { footerMainMenu, footerSideMenu } from 'src/app/data/footer/data';
import { EMenuItemStyle } from './components/menu-item/types';

@Component({
  selector: 'app-section-footer',
  templateUrl: './section-footer.component.html',
  styleUrls: ['./section-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionFooterComponent {
  public readonly footerMainMenu = footerMainMenu;

  public readonly footerSideMenu = footerSideMenu;

  public readonly EMenuItemStyle = EMenuItemStyle;

  // constructor() {}
}
