import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import type { IStep } from '../../interfaces';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() step?: IStep['id'];

  @Input() steps?: IStep[];

  constructor(public router: Router) {}
}
