<div
  class="content"
  [formGroup]="formGroup"
>
  <div class="content__input">
    <app-input
      name="email"
      [label]="'Email Address' | translate"
      placeholder="sample@hellobuki.com"
      formControlName="email"
      type="email"
      [error]="EMAIL_ERROR"
      [theme]="EInputTheme.alt"
    ></app-input>
  </div>
  <div class="content__input">
    <app-input
      type="checkbox"
      label="{{'Receive newsletters from BÜKI'| translate}}"
      name="emailReceive"
      placeholder="sample@hellobuki.com"
      formControlName="emailReceive"
      [theme]="EInputTheme.alt"
    ></app-input>
  </div>
  <div class="content__input">
    <app-input
      name="password"
      [label]="'Password' | translate"
      formControlName="password"
      type="password"
      [error]="REQUIRED_ERROR"
      [theme]="EInputTheme.alt"
    ></app-input>
  </div>
  <div class="content__input">
    <app-input
      name="password2"
      label="同じパスワードを入力してください"
      formControlName="password2"
      type="password"
      [error]="PASSWORD_MATCH_ERROR"
      [theme]="EInputTheme.alt"
    ></app-input>
  </div>
  <div class="content__button">
    <app-button
      (buttonClick)="onSignUpButtonClick()"
      [fullWidth]="true"
      [disabled]="formGroup.invalid"
    >{{ 'Create Account' | translate }}</app-button>
  </div>
  <div class="content__button">
    <app-button
      [color]="EButtonColor['accent-2']"
      (buttonClick)="onSignInButtonClick()"
      [fullWidth]="true"
    >{{ 'Login' | translate }}</app-button>
  </div>
</div>
