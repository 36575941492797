import { IBookPrice } from './types';
import { CURRENCY } from 'src/app/constants';
import memoizee from 'memoizee';

export const getBookPriceData = memoizee(
  (value: IBookPrice['value'], currency: string = CURRENCY): IBookPrice => ({
    value,
    currency,
  }),
);

export const thousandsByComma = (value: string | number): string =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const bookPriceToString = ({ currency, value }: IBookPrice) =>
  `${currency}${thousandsByComma(value)}`;
