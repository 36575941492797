const hashCode = (str: string): number => {
  // java String#hashCode
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    // tslint:disable-next-line:no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

const intToRGB = (i: number): string => {
  // tslint:disable-next-line:no-bitwise
  const c = (i & 0x00ffffff).toString(16).toUpperCase();

  return '00000'.substring(0, 6 - c.length) + c;
};

const stringToHexColor = (str: string): string => {
  return `#${intToRGB(hashCode(str))}`;
};

/**
 * Simple logger system with the possibility of registering custom outputs.
 *
 * 4 different log levels are provided, with corresponding methods:
 * - debug   : for debug information
 * - info    : for informative status of the application (success, ...)
 * - warning : for non-critical errors that do not prevent normal application behavior
 * - error   : for critical errors that prevent normal application behavior
 *
 * Example usage:
 * ```
 * import { Logger } from 'app/core/logger.service';
 *
 * const log = new Logger('myFile');
 * ...
 * log.debug('something happened');
 * ```
 *
 * To disable debug and info logs in production, add this snippet to your root component:
 * ```
 * export class AppComponent implements OnInit {
 *   ngOnInit() {
 *     if (environment.production) {
 *       Logger.enableProductionMode();
 *     }
 *     ...
 *   }
 * }
 *
 * If you want to process logs through other outputs than console, you can add TLogOutput functions to Logger.outputs.
 */

/**
 * The possible log levels.
 * ELogLevel.Off is never emitted and only used with Logger.level property to disable logs.
 */
export enum ELogLevel {
  Off = 0,
  Error,
  Warning,
  Info,
  Debug,
}

/**
 * Log output handler function.
 */
export type TLogOutput = (
  source: string,
  level: ELogLevel,
  ...objects: any[]
) => void;

export class Logger {
  /**
   * Current logging level.
   * Set it to ELogLevel.Off to disable logs completely.
   */
  static level = ELogLevel.Debug;

  /**
   * Additional log outputs.
   */
  static outputs: TLogOutput[] = [];

  /**
   * Enables production mode.
   * Sets logging level to ELogLevel.Warning.
   */
  static enableProductionMode() {
    Logger.level = ELogLevel.Warning;
  }

  constructor(private source?: string, private sourceLevel?: ELogLevel) {}

  /**
   * Logs messages or objects  with the debug level.
   * Works the same as console.log().
   */
  debug(...objects: any[]) {
    this.log(console.log, ELogLevel.Debug, objects);
  }

  /**
   * Logs messages or objects  with the info level.
   * Works the same as console.log().
   */
  info(...objects: any[]) {
    this.log(console.info, ELogLevel.Info, objects);
  }

  /**
   * Logs messages or objects  with the warning level.
   * Works the same as console.log().
   */
  warn(...objects: any[]) {
    this.log(console.warn, ELogLevel.Warning, objects);
  }

  /**
   * Logs messages or objects  with the error level.
   * Works the same as console.log().
   */
  error(...objects: any[]) {
    this.log(console.error, ELogLevel.Error, objects);
  }

  private log(func: Function, level: ELogLevel, objects: any[]) {
    const currentLevel =
      typeof this.sourceLevel === 'number' ? this.sourceLevel : Logger.level;

    if (level <= currentLevel) {
      const log = this.source
        ? [
            `%c[${this.source}]`,
            `color:${stringToHexColor(this.source)};font-weight:bold;`,
          ].concat(objects)
        : objects;
      func.apply(console, log);
      Logger.outputs.forEach((output) =>
        // @ts-ignore
        output.apply(output, [this.source, level].concat(objects)),
      );
    }
  }
}
