import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormCustomerInfoComponent } from './form-customer-info.component';
import { FormHeaderModule } from '../form-header/form-header.module';
import { InputModule } from '../input/input.module';

@NgModule({
  declarations: [FormCustomerInfoComponent],
  imports: [CommonModule, FormHeaderModule, InputModule],
  exports: [FormCustomerInfoComponent],
})
export class FormCustomerInfoModule {}
