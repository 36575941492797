import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TFeaturesData } from './types';
import { featuresData } from './data';

@Component({
  selector: 'app-section-features',
  templateUrl: './section-features.component.html',
  styleUrls: ['./section-features.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionFeaturesComponent {
  @Input() featuresData: TFeaturesData = featuresData;

  constructor() {}
}
