<div class="container">
  <div class="container__section">
    <button class="back-btn"
      (click)="back.emit()"
      [disabled]="isBackDisabled"
    >
      <svg-icon class="back-btn__icon"
        src="assets/images/icons/order-footer-navigation-back.svg"
      ></svg-icon>
      <span class="back-btn__text" translate>Back</span>
    </button>
  </div>
  <div
    *ngIf="!isFinal"
    class="container__section"
  >
    <app-button
      class="btn"
      [icons]="nextIcons"
      [disabled]="isNextDisabled"
      (buttonClick)="next.emit()"
    >{{ nextText }}</app-button>
  </div>
</div>
