<div
  class="container"
  [ngClass]="{
    'container_active': isActive,
    'container_invalid': invalid,
    'container_option': option,
    'container_theme-alt': theme === EInputTheme.alt
  }"
>
  <div
    *ngIf="option"
    class="container__img"
  >
    <img
      [src]="option.imgSrc"
      alt="#"
      class="img"
    >
  </div>
  <div
    *ngIf="option"
    class="container__label"
  >{{ option.label | translate }}</div>
  <div
    *ngIf="!option"
    class="container__placeholder"
  >{{ placeholder | translate }}</div>
  <div class="container__button">
    <app-select-button
      [active]="isActive"
      [theme]="selectButtonTheme"
    ></app-select-button>
  </div>
</div>
