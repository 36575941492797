/* eslint-disable @angular-eslint/no-output-native */
import type { AfterViewInit, OnDestroy } from '@angular/core';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { StripeService } from 'src/app/services/stripe.service';
import type {
  StripeCardElement,
  StripeCardElementChangeEvent,
  StripeCardElementOptions,
} from '@stripe/stripe-js';

const ERROR_NOT_COMPLETE = 'カード番号・有効期限・CVCを入力してください';

@Component({
  selector: 'app-stripe-card',
  templateUrl: './stripe-card.component.html',
  styleUrls: ['./stripe-card.component.scss'],
})
export class StripeCardComponent implements AfterViewInit, OnDestroy {
  @ViewChild('cardRef') cardRef!: ElementRef;

  @Input() public id?: string;

  @Input() public name?: string;

  @Input() public disabled: boolean = false;

  @Output() public valueChange = new EventEmitter<
    StripeCardElement | undefined
  >();

  @Output() public errorChange = new EventEmitter<string>();

  @Output() public blur = new EventEmitter<void>();

  @Output() public focus = new EventEmitter<void>();

  private _value?: StripeCardElement;

  set value(v: StripeCardElement | undefined) {
    this._value = v;
    this.valueChange.emit(v);
  }

  get value(): StripeCardElement | undefined {
    return this._value;
  }

  private _error?: string;

  set error(error: string | undefined) {
    // if (this._error !== error) {
    this._error = error;
    this.errorChange.emit(error);
    console.log('errorChange', error);
    // }
  }

  get error() {
    return this._error;
  }

  public card?: StripeCardElement;

  public onCardChangeHandler = this.onCardChange.bind(this);

  public onCardFocusHandler = this.onCardFocus.bind(this);

  public onCardBlurHandler = this.onCardBlur.bind(this);

  constructor(private stripeService: StripeService) {}

  ngOnDestroy() {
    if (this.card) {
      // We remove event listener here to keep memory clean
      // this.card.removeEventListener('change', this.onCardChangeHandler);
      this.card.destroy();
    }
  }

  ngAfterViewInit() {
    this.initiateCardElement();
  }

  async initiateCardElement() {
    // Giving a base style here, but most of the style is in scss file
    const options: StripeCardElementOptions = {
      hidePostalCode: true,
      style: {
        base: {
          color: '#32325d',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      },
    };

    this.error = ERROR_NOT_COMPLETE; // initial value will be changed on first card change

    const elements = await this.stripeService.elements;

    this.card = elements.create('card', options);
    this.card.mount(this.cardRef.nativeElement);
    this.card.on('change', this.onCardChangeHandler);
    this.card.on('focus', this.onCardFocusHandler);
    this.card.on('blur', this.onCardBlurHandler);
  }

  onCardChange(change: StripeCardElementChangeEvent) {
    console.log('onCardChange', change);

    if (change.error) this.error = change.error.message;
    else if (!change.complete) this.error = ERROR_NOT_COMPLETE;
    else this.error = undefined;

    this.value = this.error ? undefined : this.card;
  }

  onCardFocus() {
    this.focus.emit();
  }

  onCardBlur() {
    this.blur.emit();
  }
}
