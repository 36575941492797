import type { OnDestroy, OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import { Logger } from 'src/app/services/logger.service';
import { StripeService } from 'src/app/services/stripe.service';
import { REQUIRED_ERROR } from 'src/app/validators.constants';
import Subscriber from 'src/app/subscriber';
import type { IOrderData, TCouponData } from '@shared/interfaces';
import { BehaviorSubject } from 'rxjs';
import { FirebaseService } from 'app/services/firebase.service';

const log = new Logger('SectionCartComponent');

@Component({
  selector: 'app-coupon[orderId]',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss'],
})
export class CouponComponent implements OnInit, OnDestroy {
  @Input() orderId!: IOrderData['orderId'];

  @Input() set coupon(coupon: TCouponData | undefined) {
    if (!isEqual(this.coupon, coupon)) {
      this._coupon = coupon;
      this.couponChange.emit(coupon);
    }
  }

  get coupon() {
    return this._coupon;
  }

  private _coupon: TCouponData | undefined = undefined;

  @Output() couponChange: EventEmitter<TCouponData> = new EventEmitter();

  // * couponId FormControl
  public couponIdControl = new FormControl('', [Validators.required]);

  // * Errors
  public readonly stripeError$ = new BehaviorSubject<string | undefined>(
    undefined,
  );

  public readonly REQUIRED_ERROR = REQUIRED_ERROR;

  private _sub = new Subscriber();

  public loading$ = new BehaviorSubject(true);

  constructor(
    private _firebaseService: FirebaseService,
    private _stripeService: StripeService,
  ) {}

  async ngOnInit() {
    const { orderId } = this;
    const couponRef = await this._firebaseService.getStripeCouponRef(orderId);

    this._sub.push(
      {
        unsubscribe: couponRef.onSnapshot((snap) => {
          const coupon = snap.data();

          this.coupon = coupon;

          if (coupon) {
            if ('error' in coupon) {
              this.loading$.next(false);
              this.stripeError$.next(coupon.error);
              this.couponIdControl.setErrors([{ forbidden: true }]);

              log.error('coupon error', coupon);
            } else if ('valid' in coupon) {
              this.loading$.next(false);
              this.stripeError$.next(undefined);
              this.couponIdControl.disable();
            }
          }
        }),
      },

      this.couponChange.subscribe(({ id }) => {
        this.couponIdControl.setValue(id);
      }),
    );

    this.loading$.next(false);
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  /**
   * Applies coupon to the order by `couponId`
   */
  async redeemCoupon() {
    this.loading$.next(true);

    await this._stripeService.redeemCoupon(
      this.orderId,
      this.couponIdControl.value,
    );
  }
}
