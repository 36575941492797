import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormShippingAddressComponent } from './form-shipping-address.component';
import { FormHeaderModule } from '../form-header/form-header.module';
import { InputModule } from '../input/input.module';

@NgModule({
  declarations: [FormShippingAddressComponent],
  imports: [CommonModule, FormHeaderModule, InputModule],
  exports: [FormShippingAddressComponent],
})
export class FormShippingAddressModule {}
