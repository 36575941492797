import type {
  IBookLoaderParams,
  IDefaultLoaderParams,
  TLoaderParams,
} from './interfaces';
import { ELoaderType } from './loader.enum';

export const loaderParamsToBookLoaderParams = (
  params: TLoaderParams,
): IBookLoaderParams | undefined => {
  if (params.type === ELoaderType.book) {
    return params;
  }
};

export const loaderParamsToDefaultLoaderParams = (
  params: TLoaderParams,
): IDefaultLoaderParams | undefined => {
  if (params.type === ELoaderType.default) {
    return params;
  }
};
