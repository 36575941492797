import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import type {
  ILoaderProgress,
  TLoaderParams,
} from '../components/loader/interfaces';
import {
  defaultLoaderParams,
  requiresConfirmationDict,
} from '../components/loader/constants';
import { map } from 'rxjs/operators';
import { loaderParamsToBookLoaderParams } from 'app/components/loader/functions';
import { Logger } from './logger.service';

const log = new Logger('LoaderService');

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private _params$ = new BehaviorSubject<TLoaderParams>(defaultLoaderParams);

  public params$ = this._params$.asObservable();

  // public paramsObservable = this._paramsSubject.asObservable();

  private _loading$ = new BehaviorSubject<boolean>(false);

  public loading$ = this._loading$.asObservable();

  // public loadingObservable = this._loadingSubject.asObservable();

  private _confirmed$ = new BehaviorSubject<boolean | undefined>(undefined);

  public confirmed$ = this._confirmed$.asObservable();

  // public confirmedObservable = this._confirmedSubject.asObservable();

  public shown$ = combineLatest([this._loading$, this._confirmed$]).pipe(
    map(([loading, confirmed]) => loading || confirmed === false),
  );

  get params() {
    return this._params$.value;
  }

  get loading() {
    return this._loading$.value;
  }

  get confirmed() {
    return this._confirmed$.value;
  }

  get requiresConfirmation() {
    return this.confirmed === false;
  }

  // create observable if needed
  get shown() {
    return this.loading || this.requiresConfirmation;
  }

  // constructor() {}

  public setTitle(title: string) {
    const params = loaderParamsToBookLoaderParams(this._params$.value);

    if (!params) {
      log.error('setTitle => cannot set title for default params', { title });
      return;
    }

    params.data.title = title;

    this._params$.next(params);
  }

  public setProgress(progress: ILoaderProgress) {
    const params = loaderParamsToBookLoaderParams(this._params$.value);

    if (!params) {
      log.error('setProgress => cannot set progress for default params', {
        progress,
      });
      return;
    }

    params.data.progress = progress;

    this._params$.next(params);
  }

  public pushProgressMax(max: ILoaderProgress['max']) {
    const params = loaderParamsToBookLoaderParams(this._params$.value);

    if (!params) {
      log.error(
        'pushProgress => cannot update progress.max for default params',
        { max },
      );
      return;
    }

    const { progress } = params.data;

    if (!progress) {
      log.error(
        "pushProgress => cannot update progress.max as it's not set yet",
        { max },
      );
      return;
    }

    params.data.progress = {
      max: progress.max + max,
      value: progress.value,
    };

    this._params$.next(params);
  }

  /**
   * @param value to add
   */
  public pushProgressValue(value: ILoaderProgress['value']) {
    const params = loaderParamsToBookLoaderParams(this._params$.value);

    if (!params) {
      log.error(
        'pushProgress => cannot update progress.value for default params',
        { value },
      );
      return;
    }

    const { progress } = params.data;

    if (!progress) {
      log.error(
        "pushProgress => cannot update progress.value as it's not set yet",
        { value },
      );
      return;
    }

    params.data.progress = {
      max: progress.max,
      value: progress.value + value,
    };

    this._params$.next(params);
  }

  // overload
  public show(params: TLoaderParams = defaultLoaderParams) {
    const requiresConfirmation = requiresConfirmationDict[params.type];

    this._params$.next(params);
    this._confirmed$.next(requiresConfirmation ? false : undefined);
    this.start();
  }

  public hide() {
    this.stop();

    if (this.requiresConfirmation) {
      this.confirm();
    }
  }

  public start() {
    this._loading$.next(true);
  }

  public stop() {
    this._loading$.next(false);
  }

  public confirm() {
    this._confirmed$.next(true);
  }
}
