<div
  class="container"
  [ngClass]="{
    'container_active': active,
    'container_theme-alt': theme === EInputTheme.alt
  }"
  (click)="onOptionClick()"
>
  <div class="container__img">
    <img
      [src]="imgSrc"
      alt="#"
      class="img"
    >
  </div>
</div>
