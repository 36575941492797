import { BREAKPOINT, BreakPoint } from '@angular/flex-layout';

// ! also edit src/mq.scss
const gap = 0.1;
const ltsm = 480 - gap;
const ltmd = 768 - gap;
const ltlg = 1000 - gap;
const ltxl = 1440 - gap;

export const CUSTOM_BREAKPOINTS: BreakPoint[] = [
  {
    alias: 'xs',
    // suffix: 'Xs',
    mediaQuery: `screen and (min-width: 0px) and (max-width: ${ltsm}px)`,
    // priority: 1001,
    // overlapping: false
  },
  {
    alias: 'sm',
    // suffix: 'Sm',
    mediaQuery: `screen and (min-width: ${
      ltsm + gap
    }px) and (max-width: ${ltmd}px)`,
    // priority: 901,
    // overlapping: false
  },
  {
    alias: 'md',
    // suffix: 'Md',
    mediaQuery: `screen and (min-width: ${
      ltmd + gap
    }px) and (max-width: ${ltlg}px)`,
    // priority: 801,
    // overlapping: false
  },
  {
    alias: 'lg',
    // suffix: 'Lg',
    mediaQuery: `screen and (min-width: ${
      ltlg + gap
    }px) and (max-width: ${ltxl}px)`,
    // priority: 701,
    // overlapping: false
  },
  {
    alias: 'xl',
    // suffix: 'Xl',
    mediaQuery: `screen and (min-width: ${
      ltxl + gap
    }px) and (max-width: 5000px)`,
    // priority: 601,
    // overlapping: false
  },
  // lt
  {
    alias: 'lt-sm',
    // suffix: 'LtSm',
    mediaQuery: `screen and (max-width: ${ltsm}px)`,
    // priority: 951,
    // overlapping: false
  },
  {
    alias: 'lt-md',
    // suffix: 'LtMd',
    mediaQuery: `screen and (max-width: ${ltmd}px)`,
    // priority: 851,
    // overlapping: false
  },
  {
    alias: 'lt-lg',
    // suffix: 'LtLg',
    mediaQuery: `screen and (max-width: ${ltlg}px)`,
    // priority: 751,
    // overlapping: false
  },
  {
    alias: 'lt-xl',
    // suffix: 'LtXl',
    mediaQuery: `screen and (max-width: ${ltxl}px)`,
    // priority: 651,
    // overlapping: false
  },
  // gt
  {
    alias: 'gt-xs',
    // suffix: 'GtXs',
    mediaQuery: `screen and (min-width: ${ltsm + gap}px)`,
    // priority: -949,
    // overlapping: false
  },
  {
    alias: 'gt-sm',
    // suffix: 'GtSm',
    mediaQuery: `screen and (min-width: ${ltmd + gap}px)`,
    // priority: -849,
    // overlapping: false
  },
  {
    alias: 'gt-md',
    // suffix: 'GtMd',
    mediaQuery: `screen and (min-width: ${ltlg + gap}px)`,
    // priority: -749,
    // overlapping: false
  },
  {
    alias: 'gt-lg',
    // suffix: 'GtLg',
    mediaQuery: `screen and (min-width: ${ltxl + gap}px)`,
    // priority: -649,
    // overlapping: false
  },
];

export const CustomBreakPointsProvider = {
  provide: BREAKPOINT,
  useValue: CUSTOM_BREAKPOINTS,
  multi: true,
};
