// * do not forget to update button.component.scss
export enum EButtonColor {
  'accent' = 'accent',
  'accent-alt' = 'accent-alt',
  'accent-2' = 'accent-2',
  'default' = 'default',
}

export enum EButtonSize {
  /** 93 */
  xl = 'xl',
  /** 70 */
  lg = 'lg',
  /** 60 */
  md = 'md',
  /** 54 */
  sm = 'sm',
  /** 46 */
  xs = 'xs',
  /** 33 */
  xxs = 'xxs',
}

export enum EButtonIcon {
  'arrow-right' = 'arrow-right',
}
