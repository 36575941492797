<div class="container">
  <div class="container__title">
    <h1 class="title">Personalized picture and story books for children</h1>
  </div>
  <div class="container__content">
    <!-- <p
      class="content"
      translate
    >
    </p> -->
  </div>
  <div class="container__button">
    <button
      class="button"
      (click)="onButtonClick()"
    >
      <span class="button__text">See all books</span>
    </button>
  </div>
</div>
