import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormShipFromComponent } from './form-ship-from.component';
import { ProductRadioCardModule } from 'app/shared/product-radio-card/product-radio-card.module';

@NgModule({
  declarations: [FormShipFromComponent],
  imports: [CommonModule, ProductRadioCardModule],
  exports: [FormShipFromComponent],
})
export class FormShipFromModule {}
