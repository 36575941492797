import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TBookAlias } from '@shared/book/interfaces';
import { IBook } from 'app/data/books/types';

@Component({
  selector: 'app-books[books]',
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BooksComponent {
  @Input() books!: IBook[];

  @Output() buttonClick = new EventEmitter<TBookAlias>();

  constructor() {}

  public onButtonClick(alias: TBookAlias) {
    this.buttonClick.emit(alias);
  }
}
