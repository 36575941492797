<div class="container">
  <div class="container__section" *ngIf="!isCompleted">
    <app-navigation
      [isFinal]="isFinal"
      [nextText]="nextText"
      [isBackDisabled]="isBackDisabled"
      [isNextDisabled]="isNextDisabled"
      (back)="back.emit()"
      (next)="next.emit()"
    ></app-navigation>
  </div>
  <div class="container__section">
    <app-links></app-links>
  </div>
</div>
