import { Component, EventEmitter, Input } from '@angular/core';
import { IBookPrice } from 'src/app/data/books/prices/types';

@Component({
  selector: 'app-toggle-cards-item',
  templateUrl: './toggle-cards-item.component.html',
  styleUrls: ['./toggle-cards-item.component.scss'],
})
export class ToggleCardsItemComponent {
  @Input() name!: string;

  @Input() description!: string;

  @Input() price!: IBookPrice;

  @Input() value!: number;

  @Input() disabled?: boolean;

  @Input() discount?: string;

  public active?: boolean;

  public click = new EventEmitter<void>();

  // constructor() {}

  onClick() {
    this.click.emit();
  }
}
