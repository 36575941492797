import { Component } from '@angular/core';
import type {
  TBookAlias,
  TBookCover,
  TBookWrapping,
} from '@shared/book/interfaces';
import type { IBook } from 'app/data/books/types';
import { INPUT_VALUE_COVER_TYPE_SOFT } from '@shared/book/constants';
import type { TSectionSteps } from 'src/app/shared/section-steps/types';
import { GiftBooks, GiftSteps } from './gift-buy-page.data';

@Component({
  templateUrl: './gift-buy-page.component.html',
  styleUrls: ['./gift-buy-page.component.scss'],
})
export class GiftBuyPageComponent {
  public alias?: TBookAlias;

  public cover: TBookCover = INPUT_VALUE_COVER_TYPE_SOFT;

  public wrapping: TBookWrapping = 'standart';

  /**
   * mapped with adding `SHIPPING_REGULAR_PRICE` to each `IBookPrice['value']`
   */
  public readonly Books: IBook[] = GiftBooks;

  public readonly sectionSteps: TSectionSteps = GiftSteps;

  get book(): IBook | undefined {
    const { alias, Books: _Books } = this;

    return alias && _Books.find((b) => b.alias === alias);
  }

  // constructor() {}
}
