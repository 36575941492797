import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PRODUCTION_TIME_ZONE } from '@shared/production/constants';
import { TRegion, TShippingMethod } from '@shared/shipping/interfaces';
import { ProductionService } from 'app/services/production.service/production.service';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-date[region][shippingMethod]',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateComponent {
  @Input() set shippingMethod(m: TShippingMethod) {
    this.shippingMethod$.next(m);
  }

  public readonly shippingMethod$ = new ReplaySubject<TShippingMethod>(1);

  @Input() set region(r: TRegion) {
    this.region$.next(r);
  }

  public readonly region$ = new ReplaySubject<TRegion>(1);

  public readonly expectedArrivalDate$ = this._productionService
    .getExpectedArrivalDate$(
      this.shippingMethod$,
      this.region$.pipe(map((r) => r.name)),
    )
    .pipe(
      map(([date]) =>
        formatInTimeZone(date, PRODUCTION_TIME_ZONE, 'yyyy/MM/dd'),
      ),
    );

  constructor(private _productionService: ProductionService) {}
}
