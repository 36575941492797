import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IBookRadioInputOption } from 'src/app/data/books/inputs/radio/types';

@Component({
  selector: 'app-input-radio-option',
  templateUrl: './input-radio-option.component.html',
  styleUrls: ['./input-radio-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputRadioOptionComponent {
  @Input() label?: IBookRadioInputOption['label'];

  @Input() imgSrc?: IBookRadioInputOption['imgSrc'];

  @Input() imgExpandTopPx: IBookRadioInputOption['imgExpandTopPx'] = 0;

  @Input() imgExpandRightPx: IBookRadioInputOption['imgExpandRightPx'] = 0;

  @Input() imgExpandBottomPx: IBookRadioInputOption['imgExpandBottomPx'] = 0;

  @Input() imgExpandLeftPx: IBookRadioInputOption['imgExpandLeftPx'] = 0;

  @Input() active?: boolean;

  /** defines whether to show in condensed view or not */
  @Input() public styleCondensed = false;

  @Output() optionClick = new EventEmitter<void>();

  constructor() {}

  public onOptionClick(): void {
    this.optionClick.emit();
  }
}
