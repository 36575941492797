<div
  class="wrap"
  *ngIf="steps"
>
  <div
    class="wrap__container"
    *ngFor="let s of steps"
  >
    <div class="container">
      <div class="container__section">
        <i
          class="badge"
          [ngClass]="{
            badge_active: s.id === step,
            badge_done: s.id < step
          }"
        >
          <span class="badge__item">{{ s.id }}</span>
          <svg-icon
            class="badge__tick"
            src="assets/images/icons/order-header-steps-tick.svg"
          ></svg-icon>
        </i>
      </div>
      <div class="container__section">
        <span
          class="text"
          [ngClass]="{
            text_active: s.id <= step
          }"
          >{{ s.name }}</span
        >
      </div>
    </div>
  </div>
</div>
