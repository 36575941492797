<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div class="container__title">
        <app-image-title type="gift">ギフトコードで絵本を贈る</app-image-title>
      </div>
      <div class="container__content">
        <p class="text">BÜKIの物語の主人公になれる絵本。</p>
        <p class="text">お仕立券のように、もっと気軽に贈れるギフトコードでのプレゼントはいかがでしょうか。</p>
      </div>
    </div>
  </div>
</div>
