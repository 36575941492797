import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Property } from 'csstype';

@Component({
  selector: 'app-section-message',
  templateUrl: './section-message.component.html',
  styleUrls: ['./section-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionMessageComponent {
  @Input() public message!: string;

  /** @default 'md' */
  @Input() public type: 'sm' | 'md' = 'md';

  /** @default 'center' */
  @Input() public textAlign: Property.TextAlign = 'center';

  // constructor() {}
}
