import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionReviewsComponent } from './section-reviews.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ReviewComponent } from './components/review/review.component';
import { SwiperModule } from 'swiper/angular';
import { ImageTitleModule } from '../image-title/image-title.module';

@NgModule({
  declarations: [SectionReviewsComponent, CarouselComponent, ReviewComponent],
  imports: [CommonModule, SwiperModule, ImageTitleModule],
  exports: [SectionReviewsComponent],
})
export class SectionReviewsModule {}
