import {
  ROUTE_PATH_PARAM_AUTH_TYPE_RESET,
  ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_IN,
  ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_UP,
  ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_UP_FINAL,
  ROUTE_PATH_QUERY_PARAM_AUTH_EMAIL,
} from './app-routing.constants';

export const ROUTE_PATH_PARAM_AUTH_TYPE_ARR = [
  ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_IN,
  ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_UP,
  ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_UP_FINAL,
  ROUTE_PATH_PARAM_AUTH_TYPE_RESET,
] as const;

export const ROUTE_PATH_QUERY_PARAM_AUTH_ARR = [
  ROUTE_PATH_QUERY_PARAM_AUTH_EMAIL,
] as const;

/** map between `ROUTE_PATH_PARAM_AUTH_TYPE_*` and it's required query parameters */
export const ROUTE_PATH_PARAM_AUTH_TYPE_REQUIRED_QUERY_PARAM_MAP: {
  [key in typeof ROUTE_PATH_PARAM_AUTH_TYPE_ARR[number]]?: typeof ROUTE_PATH_QUERY_PARAM_AUTH_ARR[number][];
} = {
  [ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_UP_FINAL]: [
    ROUTE_PATH_QUERY_PARAM_AUTH_EMAIL,
  ],
};
