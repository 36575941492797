import type { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { FirebaseService } from '../../services/firebase.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BOOK_ALIAS_STARRY_DREAM } from '@shared/book/constants';
import Subscriber from 'src/app/subscriber';
import { Logger } from 'src/app/services/logger.service';

const log = new Logger('BookComponent');
const sub = new Subscriber();

/**
 * used to redirect to specific book component by `bookId` provided
 */
@Component({
  template: '',
})
export class BookComponent implements OnInit, OnDestroy {
  constructor(
    private firebaseService: FirebaseService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.loadBookAlias();
  }

  ngOnDestroy(): void {
    sub.unsubscribe();
  }

  private loadBookAlias() {
    sub.push(
      this.route.paramMap.subscribe(async (paramMap) => {
        const bookId = paramMap.get('id');

        // * do not handle !bookId because component doesn't handle such a route
        if (!bookId) {
          await this.router.navigate(['/404'], { replaceUrl: true });
          return;
        }

        const bookData = await this.firebaseService.getBookData(bookId);

        if (!bookData) {
          await this.router.navigate(['/404'], { replaceUrl: true });
          return;
        }

        const alias = bookData.alias || BOOK_ALIAS_STARRY_DREAM;

        log.info({ alias });

        await this.router.navigate(['book', alias, bookId], {
          replaceUrl: true,
          queryParamsHandling: 'preserve',
        });
      }),
    );
  }
}
