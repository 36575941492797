<button
  mat-fab
  color="warn"
  aria-label="Debug"
  [matMenuTriggerFor]="menuDebug"
  fxHide.xs
>
  <mat-icon>
    menu_book
  </mat-icon>
</button>
<mat-menu #menuDebug="matMenu" class="debug-menu">
  <button mat-menu-item routerLink="/404">404</button>

  <button
    mat-menu-item
    *ngFor="let book of booksList"
    routerLink="/book/{{book.bookId}}"
  >
    {{book.heroName}} {{book.bookId}}
    {{book.alias}}
  </button>
</mat-menu>
