import { Component } from '@angular/core';

@Component({
  selector: 'app-section-right',
  templateUrl: '../../templates/section.template.html',
  styleUrls: ['./section-right.component.scss'],
})
export class SectionRightComponent {
  // constructor() {}
}
