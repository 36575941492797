import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Logger } from '@shared/logger';
import { PRODUCTION_TIME_ZONE } from '@shared/production/constants';
import {
  SHIPPING_METHOD_FAST,
  SHIPPING_METHOD_STANDART,
} from '@shared/shipping/constants';
import Regions from '@shared/shipping/data';
import type { TRegionName } from '@shared/shipping/interfaces';
import { ProductionService } from 'app/services/production.service/production.service';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { ReplaySubject, of } from 'rxjs';
import { map } from 'rxjs/operators';

const log = new Logger('SectionMessageDateComponent');

const mappedRegions = Regions.map(({ name }) => name);

@Component({
  selector: 'app-section-message-date',
  templateUrl: './section-message-date.component.html',
  styleUrls: ['./section-message-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionMessageDateComponent {
  public readonly regions$ = this._productionService.shippingMethodsMap$.pipe(
    map((shippingMethodsMap) =>
      (Object.keys(shippingMethodsMap.fast) as TRegionName[]).sort(
        (a, b) => mappedRegions.indexOf(a) - mappedRegions.indexOf(b),
      ),
    ),
  );

  public readonly region$ = new ReplaySubject<TRegionName>(1);

  public readonly items$ = this.regions$.pipe(
    map((regions) =>
      regions
        .map((_name) => {
          const region = Regions.find(({ name }) => _name === name);

          if (!region) {
            log.error('region not found by', _name);
            return undefined;
          }

          return {
            label: region.name,
            value: region.name,
          };
        })
        .filter((v): v is { label: TRegionName; value: TRegionName } => !!v),
    ),
  );

  public readonly expectedArrivalDateStandart$ = this._productionService
    .getExpectedArrivalDate$(of(SHIPPING_METHOD_STANDART), this.region$)
    .pipe(
      map(([date]) =>
        formatInTimeZone(date, PRODUCTION_TIME_ZONE, 'yyyy/MM/dd'),
      ),
    );

  public readonly expectedArrivalDateFast$ = this._productionService
    .getExpectedArrivalDate$(of(SHIPPING_METHOD_FAST), this.region$)
    .pipe(
      map(([date]) =>
        formatInTimeZone(date, PRODUCTION_TIME_ZONE, 'yyyy/MM/dd'),
      ),
    );

  public readonly endOfCycleDate$ =
    this._productionService.endOfCycleDate$.pipe(
      map((date) => formatInTimeZone(date, PRODUCTION_TIME_ZONE, 'yyyy/MM/dd')),
    );

  constructor(private _productionService: ProductionService) {}
}
