import type {
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  BEToFEWrappingName,
  getBookDataSpecs,
  getCalculatorItems,
  getCover,
  getProduct,
  getProductPrice,
} from 'src/app/functions';
import type { Product } from 'src/app/interfaces';
import { UserService } from 'src/app/services/user.service';
import type { ICalculatorComponentItem } from 'src/app/shared/calculator/calculator.component';
import { ECalculatorComponentType } from 'src/app/shared/calculator/calculator.component';
import { wrappings } from 'src/app/static';
import { MediaObserver } from '@angular/flex-layout';
import { slideY } from 'src/app/animations';
import { IShipping } from 'src/app/data/shipping/types';
import { DiscountService } from 'src/app/services/discount.service';
import Subscriber from 'src/app/subscriber';
import { getBooksDiscount } from '@shared/discount/utils';
import { take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { RoutingService } from 'app/services/routing.service';
import { TCouponData } from '@shared/interfaces';
import type { BookInOrder } from '@shared/interfaces';
import { Logger } from 'app/services/logger.service';
import type { IDiscountCampaign } from '@shared/discount/interfaces';

const log = new Logger('AsideComponent');

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss'],
  animations: [slideY()],
})
export class AsideComponent implements OnInit, OnChanges, OnDestroy {
  @Input() title = 'Order Summary';

  @Input() coupon?: TCouponData;

  @Input() books!: BookInOrder[];

  /** selected shipping */
  @Input() shipping?: IShipping; // * index of shipping type

  @Input() alwaysExpanded = false;

  @Input() hidePrice = false;

  @Output() editCartClick = new EventEmitter<void>();

  public products: Product[] = []; // getting on {books} update

  public calculatorItems: ICalculatorComponentItem[] = [];

  private booksUpdate = new Subject<void>();

  private couponUpdate = new Subject<void>();

  private shippingUpdate = new Subject<void>();

  public CalculatorComponentType = ECalculatorComponentType;

  public readonly getBooksDiscount = getBooksDiscount;

  private readonly _sub = new Subscriber();

  private _isExpanded = false;

  get toggleTitle() {
    const { length } = this.products;

    return `${length} ${length === 1 ? 'item' : 'items'}`;
  }

  get isExpanded(): boolean {
    return (
      this.alwaysExpanded || this.media.isActive('gt-lg') || this._isExpanded
    );
  }

  set isExpanded(expanded: boolean) {
    this._isExpanded = expanded;
  }

  get isHeaderToggleVisible() {
    return !this.alwaysExpanded && this.media.isActive('lt-xl');
  }

  get isHeaderLinkVisible() {
    return !this.media.isActive('lt-xl');
  }

  get isHeaderCalculatorVisible() {
    return this.calculatorItems && this.media.isActive('lt-xl');
  }

  get isHeaderContentVisible() {
    return (
      this.isHeaderToggleVisible ||
      this.isHeaderLinkVisible ||
      this.isHeaderCalculatorVisible
    );
  }

  constructor(
    private userService: UserService,
    private media: MediaObserver,
    public discountService: DiscountService,
    private _routing: RoutingService,
  ) {}

  public getProductPrice = (product: Product) =>
    this.hidePrice ? undefined : getProductPrice(product);

  async ngOnInit() {
    await this.updateProducts();
    this.updateCalculatorItems();

    this._sub.push(
      this.booksUpdate.subscribe(async () => {
        await this.updateProducts();
        this.updateCalculatorItems();
      }),

      this.shippingUpdate.subscribe(() => {
        this.updateCalculatorItems();
      }),

      // handle couponUpdate to update calculation
      this.couponUpdate.subscribe(() => {
        this.updateCalculatorItems();
      }),

      this.discountService.activeDiscountCampaignsObservable.subscribe(
        (discountCampaign) => {
          this.updateCalculatorItems(undefined, discountCampaign);
        },
      ),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      const { books, shipping, coupon } = changes;

      if (books && books.currentValue) {
        this.booksUpdate.next();
      }

      if (shipping && shipping.currentValue) {
        this.shippingUpdate.next();
      }

      if (coupon && coupon.currentValue) {
        this.couponUpdate.next();
      }
    }
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  private async updateProducts() {
    const { books } = this;

    if (!books) {
      log.warn('updateProducts: !books');
      return;
    }

    const booksList = await this.userService.booksList$
      .pipe(take(1))
      .toPromise();

    this.products.length = 0;

    books.map((bookInOrder) => {
      const book = booksList.find((b) => b.bookId === bookInOrder.bookId);

      if (!book) {
        log.error(
          'updateProducts: book',
          bookInOrder,
          'not found by id in',
          booksList,
        );
        return;
      }

      this.products.push(
        getProduct({
          alias: bookInOrder.alias,
          bookId: bookInOrder.bookId,
          hero: book.heroName,
          gender: 'gender' in book ? book.gender : undefined,
          children:
            'grandchildren' in book
              ? book.grandchildren.data.map(({ childrenName }) => childrenName)
              : undefined,
          wrapping: wrappings.find(
            (w) => w.name === BEToFEWrappingName(bookInOrder.wrapping),
          ),
          cover: getCover(bookInOrder.alias, bookInOrder.cover),
          specs: getBookDataSpecs(book),
        }),
      );

      // console.log('pushed', book, this.products[this.products.length - 1]);
    });
  }

  public async updateCalculatorItems(
    products = this.products,
    discountCampaign?: IDiscountCampaign,
    couponData = this.coupon,
  ) {
    if (this.hidePrice || !this.shipping) return;

    discountCampaign =
      discountCampaign ||
      (await this.discountService.activeDiscountCampaignsObservable
        .pipe(take(1))
        .toPromise());

    this.calculatorItems = getCalculatorItems(
      products,
      discountCampaign,
      couponData,
      this.shipping,
    );
  }

  public toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  public onEditCartClick() {
    if (this.editCartClick.observers.length) {
      this.editCartClick.emit();
    } else {
      this._routing.goToCart();
    }
  }
}
