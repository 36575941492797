import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { FirebaseService } from 'app/services/firebase.service';
import { ECardTitleColor } from './components/card/card.enum';
import type { IOrderData } from '@shared/interfaces';
import type { BookData, TBookData } from '@shared/models';
import { Logger } from 'app/services/logger.service';
import { ActivatedRoute } from '@angular/router';
import Subscriber from 'app/subscriber';
import { ROUTE_PATH_PARAM_ORDER_ID } from 'app/app-routing.constants';
import { RoutingService } from 'app/services/routing.service';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getBookDataSpecs, getProductName } from 'app/functions';

const log = new Logger('MyPageComponent');

@Component({
  templateUrl: './my-page.component.html',
  styleUrls: ['./my-page.component.scss'],
})
export class MyPageComponent implements OnInit {
  public readonly ECardTitleColor = ECardTitleColor;

  private readonly _sub = new Subscriber();

  public orderId?: string;

  public userOrder$?: Observable<IOrderData>;

  public userOrderBooks$?: Observable<TBookData[]>;

  public readonly getProductName = getProductName;

  public readonly getBookDataSpecs = getBookDataSpecs;

  constructor(
    public firebaseService: FirebaseService,
    private _routingService: RoutingService,
    private _activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this._sub.push(
      this._activatedRoute.paramMap.subscribe((paramMap) => {
        // param check implemented in AuthPageGuard
        this.orderId = paramMap.get(ROUTE_PATH_PARAM_ORDER_ID);
        this.userOrder$ = this.firebaseService.userOrders$.pipe(
          map((_userOrders) =>
            _userOrders.find((_order) => _order.orderId === this.orderId),
          ),
        );
        this.userOrderBooks$ = this.firebaseService.userBooks$
          .pipe
          // @ts-ignore
          // map(_userBooks => _userBooks.filter(_book => _book.orderId === this.orderId))
          ();
      }),

      // this._activatedRoute.queryParams.subscribe(queryParams => {
      //   this.queryParams = queryParams;
      // }),
    );
  }

  public onOrderEditButtonClick(orderId: IOrderData['orderId']) {
    log.info('onOrderEditButtonClick', orderId);
    this._routingService.goToMyPage(orderId);
  }

  public onBookEditButtonClick(bookId: BookData['bookId']) {
    log.info('onBookEditButtonClick', bookId);
    this._routingService.goToBook(bookId);
  }

  public onMyPageButtonClick() {
    this._routingService.goToMyPage();
  }

  public async onSignOutButtonClick() {
    await this.firebaseService.auth.signOut();
    location.reload();
  }
}
