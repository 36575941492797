import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { fade } from '../../animations';
import { ILoaderProgress } from '../../interfaces';

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fade()],
})
export class BookComponent {
  @Input() shown?: boolean;

  @Input() title?: string;

  // @Input() subtitle?: string;

  @Input() loading = false;

  /** value from 0 to 100 */
  @Input() progress?: ILoaderProgress;

  @Output() buttonClick = new EventEmitter<void>();

  // constructor() {}

  public onButtonClick() {
    this.buttonClick.emit();
  }
}
