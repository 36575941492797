import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Logger } from 'app/services/logger.service';
import { TranslateService } from '@ngx-translate/core';

const log = new Logger('FormSignUpFinalComponent');

@Component({
  selector: 'app-form-sign-up-final',
  templateUrl: './form-sign-up-final.component.html',
  styleUrls: ['./form-sign-up-final.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSignUpFinalComponent {
  @Input() email!: string;

  @Output() signUpFinalButtonClick = new EventEmitter<void>();

  constructor(public translateService: TranslateService) {}

  public onSignUpFinalButtonClick() {
    log.info('onSignUpFinalButtonClick');
    this.signUpFinalButtonClick.emit();
  }
}
