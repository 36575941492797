import { ValidatorFn } from '@angular/forms';
import { IBookInputShowWhen, IBookInputValidatorWhen } from './types';

export enum EBookInputType {
  radio,
  radioCover,
  radioImage,
  radioColor,
  radioIcon,
  select,
  text,
  date,
  checkbox,
  canvas,
  group,
  multiselect,
}

export interface IBookInputCommon {
  name: string;
  type: EBookInputType;
  validator?: ValidatorFn | ValidatorFn[];
  validatorWhen?: IBookInputValidatorWhen[];
  /** validation error that will be shown to the user */
  error?: string;
  value?: any;
  showWhen?: IBookInputShowWhen[];
}

/**
 * @template B Base interface
 * @template O Optional fields
 * @template P Partial fields
 */
export type TGetBookInput<
  B extends IBookInputCommon,
  O extends keyof B = 'type',
  P extends keyof B | void = void,
> = <
  T extends P extends keyof B
    ? Omit<B, O | P> & Partial<Pick<B, P>>
    : Omit<B, O>,
>(
  params: T,
) => Readonly<B & T>;
