import { Component } from '@angular/core';
import type { IArticle } from 'src/app/shared/section-text/section-text.interfaces';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss'],
})
export class PolicyComponent {
  public readonly articles: IArticle[] = [
    {
      caption: 'Privacy Policy',
      passages: [
        {
          items: [
            {
              type: 'text',
              text: 'This Privacy Policy outlines how we handle your personal data when you use our website <a target="_blank" href="www.hellobuki.com">www.hellobuki.com</a> (the “Website”) or visit a website that uses our advertising technology. It should be read in conjunction with our Terms and Conditions of Use, which can be found on STUDIO BUKI’s (“we,” “our,” or “us”) Terms and Conditions page. We take the protection of your personal data seriously and this policy explains our practices and procedures. Please read it carefully to understand how we collect, process and protect your personal data.',
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: 'What personal information do we collect?',
            },
            {
              type: 'text',
              text: 'Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).',
            },
            {
              type: 'text',
              text: 'We may collect, use, store and transfer different kinds of personal data:',
            },
            {
              type: 'ordered-list',
              items: [
                {
                  text: 'Information you provide – You may supply us with information about yourself by completing forms on our Website or by communicating with us via email, phone or other means. This includes information given when registering on our Website, placing an order, providing details for a specific product, participating in discussion boards or social media on our Website, entering a contest or survey, or reporting an issue with our Website. The information may include your name, billing and delivery addresses, email, phone number, age, birth date, gender, photo, username and password, and other registration details. It may also include the recipient’s name, birth date, age, gender and address. Each piece of information may be used independently or in combination with other information you provide.',
                },
                {
                  text: 'Information we collect about you and your device – When you visit our Website, we may automatically gather the following information:',
                  items: [
                    {
                      text: 'Technical details, including the Internet Protocol (IP) address used to connect your device to the Internet, login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform. ',
                    },
                    {
                      text: 'Information about your visit, such as the full Uniform Resource Locators (URL) clickstream to, through and from our website (including date and time), products viewed, purchased or searched for, page response times, download errors, duration of visits to specific pages, page interaction information (e.g. scrolling, clicks and mouse-overs), methods used to navigate away from the page and any phone number used to contact our customer service.',
                    },
                  ],
                },

                {
                  text: 'Information we collect about you through advertising – We may place ads on third-party websites (e.g. Google Ads, Meta). When you view or interact with our ads, we and our partners may use technologies such as cookies to gather and store information about you, including cookie data and anonymized IDs for attribution and advertising purposes. These technologies help us better understand our customers and the effectiveness of our advertisements.',
                },
                {
                  text: 'Information we obtain from other sources – We may receive information about you from other websites we operate or services we provide. We also collaborate with third parties, including business partners, technical, payment and delivery service sub-contractors, advertising networks, analytics providers, search information providers and credit reference agencies, and may receive information about you from them.',
                },
                {
                  text: 'Location information – We may collect information about your location, such as the IP address of the device you use to access our Website.',
                },
                {
                  text: 'Your marketing and communications data – This includes your preferences for receiving marketing from us and your preferred methods of communication.',
                },
                {
                  text: 'Financial data - We do not gather your financial information. It is collected and stored by third-party payment processors.',
                },
              ],
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: 'Our Photo management policy',
            },
            {
              type: 'text',
              text: 'At STUDIO BUKI, we understand the importance of your trust in us when you store your photos on our platform. We treat your photos as private information that belongs to you.',
            },
            {
              type: 'text',
              text: 'Your photos are used only to embed them in our products, order fulfillment and customer support operations. We do not use them for advertisements, analysis or research without your consent.',
            },
            {
              type: 'text',
              text: 'Your photos are automatically deleted from our servers 45 days after you place your order.',
            },
            {
              type: 'text',
              text: 'For order fulfillment purposes, we may share your photos with our employees and agents who work to fulfill your order and service your account. If you order a product that includes your photo, we may also share it with service providers who help us fulfill your order. These parties are only allowed to use your photos to perform services for STUDIO BUKI and cannot use them for any other purpose.',
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: 'If you do not provide us with personal data',
            },
            {
              type: 'text',
              text: 'If we are required by law or by the terms of a contract with you to collect personal data and you do not provide it when requested, we may be unable to fulfill our contractual obligations (e.g. if you do not provide suitable delivery instructions for our products or services). In such cases, we may have to cancel a product or service you have with us. We will inform you if this occurs.',
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: 'How do we use the personal information we collect?',
            },
            {
              type: 'text',
              text: 'We use data held about you in the following ways:',
            },
            {
              type: 'ordered-list',
              items: [
                {
                  text: 'To enable us to provide you with our books and related services.',
                },
                {
                  text: 'To allow you to use interactive features of the Website at your discretion.',
                },
                {
                  text: 'To conduct contests and promotions.',
                },
                {
                  text: 'To contact you about requested products and services.',
                },
                {
                  text: 'To inform you of changes to the Website and our products and services.',
                },
                {
                  text: 'To present Website content effectively for you and your device.',
                },
                {
                  text: 'To manage the Website and perform internal operations such as troubleshooting, data analysis, testing, research, statistics and surveys.',
                },
                {
                  text: 'To ensure the safety and security of the Website.',
                },
                {
                  text: 'To measure and understand the effectiveness of our advertising and deliver relevant ads to you.',
                },
                {
                  text: 'To remind you of our products or services on specified dates.',
                },
                {
                  text: 'To provide you with information, products or services that you request or that may interest you, with your consent.',
                },
                {
                  text: 'To contact you via email, online push notifications, mobile communication (e.g. SMS and push notifications) and post.',
                },
              ],
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: 'E-mails we send and opting out',
            },
            {
              type: 'text',
              text: 'If you have registered with us but have not made a purchase (e.g. by entering a contest or subscribing to a newsletter), we will only send you marketing communications if you opted in and gave us your express consent. However, if you have made a purchase, we may still need to send occasional service-related messages.',
            },
            {
              type: 'text',
              text: 'We will obtain your express opt-in consent before sharing your personal data with any third party for marketing purposes. We require all third parties to respect the security of your personal data and comply with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process it for specified purposes and according to our instructions.',
            },
            {
              type: 'text',
              text: 'You can unsubscribe from our emails using the link in each email we send. To update your registered details, change your subscription preferences or unsubscribe completely, please contact us via our Contact Us page on our Website (<a target="_blank" href="https://hellobuki.com/contacts">https://hellobuki.com/contacts)</a>.',
            },
            {
              type: 'text',
              text: 'You can also unsubscribe from online push notifications by disabling notifications in your browser.',
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: 'How do we process information?',
            },
            {
              type: 'text',
              text: 'We handle your personal data responsibly and use it only for authorized purposes. We take measures to prevent unauthorized access, disclosure, alteration or destruction. Data processing is performed using computers and IT tools in accordance with organizational procedures and for the purposes specified.',
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: 'What data security measures do we take?',
            },
            {
              type: 'text',
              text: 'We may transfer, process and store user information on servers located in various countries. For instance, information collected in the United States may be transferred to and processed by third parties in other countries, for example Japan. We may also subcontract processing or share your information with third parties in countries other than your home country. As a result, your personal information may be subject to different privacy laws. Our staff or suppliers operating outside your home country may also process your information for purposes such as fulfilling your order or providing support services.',
            },
            {
              type: 'text',
              text: 'We only transfer personal data to third parties in territories where it complies with applicable laws and regulations. This includes situations where the territory offers adequate protection standards, the third party is based in the United States and participates in the Safe Harbor program, or we have implemented adequate safeguards such as approved standard contractual provisions.',
            },
            {
              type: 'text',
              text: 'By submitting your personal data, you agree to its transfer, storage and processing. We will take reasonable measures to ensure that your data is treated securely and in accordance with our privacy policy.',
            },
            {
              type: 'text',
              text: 'We have implemented appropriate security measures to prevent accidental loss, unauthorized use, access, alteration or disclosure of your personal data. Access to your personal data is limited to employees, agents, contractors and third parties with a legitimate business need. They may only process your data according to our instructions and are bound by confidentiality obligations. We have procedures in place to address suspected data breaches and will notify you and any relevant regulators as required by law.',
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: 'How long do you retain my information?',
            },
            {
              type: 'text',
              text: 'We retain your data only as long as necessary to provide the requested service or product or to fulfill the purposes outlined in this policy.',
            },
            {
              type: 'text',
              text: 'To determine the appropriate retention period for personal data, we consider factors such as the amount, nature and sensitivity of the data, the potential risk of harm from unauthorized use or disclosure, the purposes for processing the data and whether those purposes can be achieved through other means, and applicable legal requirements.',
            },
            {
              type: 'text',
              text: 'In some cases, we may anonymize your personal data for research or statistical purposes. In such cases, we may use the information indefinitely without further notice to you since it can no longer be associated with you.',
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: 'How do we share your information with third parties?',
            },
            {
              type: 'text',
              text: 'We do not sell, license or share personal information with unaffiliated third parties for their marketing purposes. However, we may share personal information only in the following situations.',
            },
            {
              type: 'text',
              text: '1. Service providers<br>We may use third-party service providers to perform tasks on our behalf, such as fulfilling orders, processing payments or delivering advertisements. These service providers may receive information such as your name and contact details, payment information, photos and other content you upload to our Websites and Apps, purchase history, cookies and device identifiers, IP address and browsing activity. They are only permitted to use this information to provide the specific service on our behalf.',
            },
            {
              type: 'text',
              text: '2. Partners and affiliates<br>We may also share your information with our corporate affiliates and subsidiaries for purposes related to operating our website, conducting our business or serving our customers. These parties must agree to keep the information confidential.',
            },
            {
              type: 'text',
              text: '3. Law enforcement, mergers, and other situations<br>In limited circumstances, we may be required to share your personal information. For example, we may need to share information to comply with a subpoena or other legal process, to comply with laws, to protect the security or integrity of our databases or website, to take precautions against liability or in the event of a corporate reorganization. We may also share information with law enforcement without a subpoena or similar process if it may help protect the safety of a customer, photo subject or the public. We reserve the right to report any information that we believe may indicate criminal activity or violation of any law to the appropriate authorities.Your information may also be transferred to another organization if we transfer ownership or operation of our Website and Apps due to a merger, acquisition or liquidation. In such cases, the new company’s use of your information will still be subject to this Notice and your expressed privacy preferences.',
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: 'What are your legal rights?',
            },
            {
              type: 'text',
              text: 'By sending a request to yay@hellobuki.com, you can ask for your data to be corrected, updated, supplemented, blocked or erased. You can also inquire about the storage of your personal data and verify its accuracy by learning about its content and origin.',
            },
            {
              type: 'text',
              text: 'If you ask for your data to be erased, we will cease processing it and delete it unless there is a compelling legal reason that takes precedence over your interests, rights and freedoms or if it is necessary for legal claims.',
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: 'Cookies policy',
            },
            {
              type: 'text',
              text: 'A cookie is a small text file that we save on your computer or browser if you agree. It contains information that is sent to your computer’s hard drive.',
            },
            {
              type: 'text',
              text: '“Page tags” or web beacons are used to track website or email usage, such as how many times a page or email has been viewed. They are invisible and may be included in advertisements or emails sent by us.',
            },
            {
              type: 'text',
              text: 'We use cookies to personalize your experience on our website, assist you in using it, monitor how you use it to improve our services, and target ads that may interest you. By using cookies and page tags together, we can improve our website and measure the effectiveness of our marketing campaigns.',
            },
            {
              type: 'text',
              text: 'Third parties may also use cookies that we do not control. These may be analytical/performance or targeting cookies.',
            },
            {
              type: 'text',
              text: 'You can block cookies by changing your browser settings, but this may prevent you from accessing all or parts of our site. All cookies will expire after a maximum of twelve months, except for essential cookies.',
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: 'Links to third party websites policy',
            },
            {
              type: 'text',
              text: 'Our website may include links to third-party websites, including our partners, advertisers and affiliates. These websites have their own privacy policies and we are not responsible for how they handle personal data. If you visit these websites or use their services, please review their privacy policies before sharing any personal information.',
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: 'Changes in this privacy policy',
            },
            {
              type: 'text',
              text: 'We may update our privacy policy at any time for any reason. If we do make changes, we will let you know by updating the policy on this page and including a new revision date. We may also notify you by email if necessary. By continuing to use or access our services after we notify you of changes, you are agreeing to the updated policy.',
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: 'Further information about this privacy policy',
            },
            {
              type: 'text',
              text: 'If you have any questions, comments or requests concerning our privacy policy, please feel free to contact us at yay@hellobuki.com.',
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: 'Latest update: 27 April 2023',
            },
          ],
        },
      ],
    },
  ];

  // constructor() {}
}
