import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IFeaturesItem } from '../../types';

@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureComponent {
  @Input() imgSrc!: IFeaturesItem['imgSrc'];

  @Input() text!: IFeaturesItem['text'];

  constructor() {}
}
