export const getFilledNumbersArray = (
  start: number,
  end: number,
  length = 2,
  char = '0',
): string[] => {
  const arr: string[] = [];

  for (let i = start; i <= end; i++) {
    const value = String(i).padStart(length, char);

    arr.push(value);
  }

  return arr;
};

export const hours = getFilledNumbersArray(0, 23);
export const minutes = getFilledNumbersArray(0, 59);

// TODO: function to check if day is valid for the selected months
export const months = getFilledNumbersArray(1, 12);
export const days = getFilledNumbersArray(1, 31);
