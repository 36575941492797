import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { InputModule } from 'src/app/shared/input/input.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LinksComponent } from './components/links/links.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'src/app/shared/button/button.module';

@NgModule({
  declarations: [FooterComponent, NavigationComponent, LinksComponent],
  imports: [
    CommonModule,
    InputModule,
    AngularSvgIconModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule,
    ButtonModule,
  ],
  exports: [FooterComponent],
})
export class FooterModule {}
