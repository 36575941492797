import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader.component';
import { DefaultComponent } from './components/default/default.component';
import { BookComponent } from './components/book/book.component';
import { ButtonModule } from 'src/app/shared/button/button.module';

@NgModule({
  declarations: [LoaderComponent, DefaultComponent, BookComponent],
  imports: [CommonModule, ButtonModule],
  exports: [LoaderComponent],
})
export class LoaderModule {}
