import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookFormDialogComponent } from './book-form-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BookFormModule } from 'app/components/book-form/book-form.module';

@NgModule({
  declarations: [BookFormDialogComponent],
  imports: [CommonModule, MatDialogModule, BookFormModule],
  exports: [BookFormDialogComponent, MatDialogModule],
})
export class BookFormDialogModule {}
