import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { fade } from '../../animations';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fade()],
})
export class DefaultComponent {
  @Input() shown?: boolean;

  constructor() {}
}
