<!--<p *ngIf="firebaseService.user">{{firebaseService.user.uid}}</p>-->
<!--style="margin-top: -140px"-->
<div class="wrap">
  <app-banner-one></app-banner-one>
  <app-section-features class="wrap__section-features"></app-section-features>
  <app-section-books-preview
    class="wrap__section-books-preview"
  ></app-section-books-preview>
  <app-section-reviews-yotpo-common
    class="wrap__section-reviews"
  ></app-section-reviews-yotpo-common>
  <app-section-footer-banner></app-section-footer-banner>
  <app-section-books class="wrap__section-books"></app-section-books>
  <app-section-see-other
    class="wrap__section-see-other"
  ></app-section-see-other>
  <app-section-steps class="wrap__section-steps"></app-section-steps>
  <app-section-faq
    class="wrap__section-faq"
    [condensed]="true"
  ></app-section-faq>
  <app-section-banner-mermaid></app-section-banner-mermaid>
</div>
