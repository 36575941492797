<div *ngIf="(firebaseService.user$ | async) as userData" class="wrap">
  <app-section-header-gap class="wrap__header-gap"></app-section-header-gap>
  <app-image-title
    type="cart"
    class="wrap__image-title"
  >My Page</app-image-title>
  <div
    *ngIf="orderId"
    class="wrap__breadcrumbs"
  >
    <ul class="breadcrumbs">
      <li class="breadcrumbs__item">
        <a
          class="breadcrumb"
          (click)="onMyPageButtonClick()"
        >My Page</a>
      </li>
      <li class="breadcrumbs__item">
        <p class="breadcrumb">Order ID: {{ orderId }}</p>
      </li>
    </ul>
  </div>
  <div class="wrap__container">
    <div class="container">
      <div
        *ngIf="!orderId"
        class="container__left"
      >
        <div class="content">
          <div class="content__title">
            <h3 class="title">Customer Information</h3>
          </div>
          <div class="content__card">
            <app-card>
              <app-list
                [data]="{
                  'Email': userData.email
                }"
              ></app-list>
            </app-card>
          </div>
          <div class="content__footer">
            <a
              class="breadcrumb"
              (click)="onSignOutButtonClick()"
            >Sign out</a>
          </div>
        </div>
      </div>
      <div class="container__right">
        <div
          *ngIf="!orderId"
          class="content"
        >
          <div class="content__title">
            <h3 class="title">Order history</h3>
          </div>
          <div
            *ngFor="let order of (firebaseService.userOrders$ | async)"
            class="content__card"
          >
            <app-card
              [title]="'In Preparation (editable)'"
              [titleColor]="ECardTitleColor.cLink"
              [buttonText]="'Confirm・Edit'"
              (buttonClick)="onOrderEditButtonClick(order.orderId)"
            >
              <app-list
                [data]="{
                  'Order ID': order.orderId
                }"
              ></app-list>
            </app-card>
          </div>
        </div>
        <div
          *ngIf="orderId"
          class="content"
        >
          <div class="content__title">
            <h3 class="title">Order history</h3>
          </div>
          <div
            *ngIf="(userOrder$ | async) as order"
            class="content__card"
          >
            <app-card
              [title]="'In Preparation (editable)'"
              [titleColor]="ECardTitleColor.cLink"
            >
              <app-list
                [data]="{
                  'Order ID': order.orderId
                }"
              ></app-list>
            </app-card>
          </div>
          <div
            *ngFor="let book of (userOrderBooks$ | async)"
            class="content__card"
          >
            <app-card
              [title]="'Ordered Book: ' + getProductName(book.alias, book.heroName)"
              [buttonText]="'Confirm・Edit'"
              (buttonClick)="onBookEditButtonClick(book.bookId)"
            >
              <app-list
                [data]="getBookDataSpecs(book)"
              ></app-list>
            </app-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
