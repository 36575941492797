<section class="form">
  <header class="form__section form__section_header">
    <app-form-header title="Payment"></app-form-header>
  </header>
  <form
    class="form__section form__section_content"
    [formGroup]="formGroup"
  >
    <div class="accordeon">
      <div class="accordeon__header">
        <div class="header">
          <div class="header__item">
            <button
              class="tick"
              [ngClass]="{
                tick_active: typeFormControl.value === EFormPaymentType.card
              }"
              (click)="
                typeFormControl.setValue(
                  typeFormControl.value === EFormPaymentType.card
                    ? null
                    : EFormPaymentType.card
                )
              "
            >
              <i class="tick__icon"></i>
              <span class="tick__text">Credit Card</span>
            </button>
          </div>
          <div class="header__item">
            <ng-container
              *ngIf="(mediaService.isLtMdObservable | async) === false"
            >
              <ng-container *ngTemplateOutlet="secureBadge"></ng-container>
            </ng-container>
            <img
              class="payment-icons"
              [src]="
                (mediaService.isLtSmObservable | async)
                  ? 'assets/images/cart-payments-mobile.png'
                  : 'assets/images/cart-payments.png'
              "
            />
          </div>
        </div>
        <div
          *ngIf="(mediaService.isLtMdObservable | async) === true"
          class="header"
        >
          <div class="header__item"></div>
          <div class="header__item">
            <ng-container *ngTemplateOutlet="secureBadge"></ng-container>
          </div>
        </div>
      </div>
      <div
        *ngIf="typeFormControl.value === EFormPaymentType.card"
        class="accordeon__content"
        @slideY
      >
        <div class="section">
          <!-- Card Number -->
          <div class="section__input">
            <div class="input-wrap input-wrap_column">
              <div class="input-wrap__label">
                <div class="label">
                  <app-label class="label__item"
                    >Complete your payment with one of our secure checkout
                    methods.</app-label
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="section__input section__input_buttons">
            <div class="buttons">
              <div class="buttons__item">
                <app-input
                  class="checkbox"
                  type="checkbox"
                  formControlName="terms"
                  [error]="REQUIRED_ERROR"
                >
                  <span class="checkbox__item">I accept </span>
                  <a
                    class="checkbox__item"
                    routerLink="/terms"
                    target="_blank"
                    >BUKI's Terms and Conditions</a
                  >
                </app-input>
              </div>
              <div class="buttons__item buttons__item_button">
                <app-button
                  [disabled]="submitButtonDisabled"
                  (buttonClick)="submitButtonClick.emit()"
                  [fullWidth]="true"
                  >Pay now</app-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>

<ng-template #secureBadge>
  <div class="secure-badge">
    <i class="secure-badge__icon"></i>
    <div class="secure-badge__text">Secure and encrypted payment</div>
  </div>
</ng-template>
