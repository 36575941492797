import { Injectable } from '@angular/core';
import { STRIPE_PUBLIC_KEY } from '../../constants';
import { FirebaseService } from './../firebase.service';
import { Logger } from './../logger.service';
import type { Stripe } from '@stripe/stripe-js';

const stripe = new Promise<Stripe>((resolve, reject) => {
  // ! do not download the stripe file !
  const script = document.createElement('script');

  script.onload = () => {
    const { Stripe } = window;

    if (!Stripe) {
      reject('Stripe is not loaded!');
      return;
    }

    resolve(Stripe(STRIPE_PUBLIC_KEY));
  };

  script.src = 'https://js.stripe.com/v3/';

  document.head.appendChild(script);
});

// const logStyle = 'color: #5469d4;'
const log = new Logger('StripeService');

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  public readonly stripe = stripe;

  public readonly elements = stripe.then((_stripe) => _stripe.elements());

  constructor(private _firebaseService: FirebaseService) {}

  /** applies coupon to the order by `couponId` */
  public async redeemCoupon(orderId: string, couponId: string) {
    log.info('redeemCoupon', { orderId, couponId });
    const user = await this._firebaseService.userAuth();

    if (!user) {
      log.error('redeemCoupon => user is unauthorized');
      return;
    }

    const stripeCouponRef = await this._firebaseService.getStripeCouponRef(
      orderId,
    );

    return stripeCouponRef.set({ id: couponId });
  }
}
