import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KonbiniConfirmationComponent } from './components/konbini-confirmation/konbini-confirmation.component';
import { ButtonModule } from '../button/button.module';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [KonbiniConfirmationComponent],
  imports: [
    CommonModule,
    ButtonModule,
    MatDialogModule,
    BrowserAnimationsModule,
  ],
})
export class DialogsModule {}
