import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RoutingService } from 'src/app/services/routing.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoComponent {
  constructor(private _routing: RoutingService) {}

  public onButtonClick(): void {
    this._routing.goToAllBooks();
  }
}
