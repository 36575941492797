<div class="container">
  <div
    *ngFor="let feature of featuresData"
    class="container__item"
  >
    <app-feature
      [imgSrc]="feature.imgSrc"
      [text]="feature.text"
    ></app-feature>
  </div>
</div>
