import type { AfterContentInit, OnDestroy } from '@angular/core';
import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { ToggleCardsItemComponent } from '../toggle-cards-item/toggle-cards-item.component';
import Subscriber from 'src/app/subscriber';

const sub = new Subscriber();

@Component({
  selector: 'app-toggle-cards',
  templateUrl: './toggle-cards.component.html',
  styleUrls: ['./toggle-cards.component.scss'],
})
export class ToggleCardsComponent implements AfterContentInit, OnDestroy {
  @Input() set value(value: number | undefined) {
    if (this.value !== value) {
      this._value = value;
      this.setItems(value);
      this.valueChange.emit(value);
    }
  }

  get value() {
    return this._value;
  }

  @Output() valueChange: EventEmitter<number>;

  @ContentChildren(ToggleCardsItemComponent)
  items?: QueryList<ToggleCardsItemComponent>;

  private _value?: number;

  constructor() {
    this.valueChange = new EventEmitter();
  }

  private setItems(value: number | undefined) {
    if (this.items)
      this.items.map((item) => {
        if (item.value === value) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
  }

  private initItems() {
    if (this.items) {
      this.items.map((item) => {
        sub.push(
          item.click.subscribe(() => {
            this.value = item.value;
          }),
        );
      });
    }
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.setItems(this.value);
      this.initItems();
    });
  }

  ngOnDestroy() {
    sub.unsubscribe();
  }

  onContentChange(): void {
    sub.unsubscribe();
    this.setItems(this.value);
    this.initItems();
  }
}
