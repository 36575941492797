<div class="container">
  <div class="container__content">
    <nav class="content">
      <a
        class="content__logo"
        routerLink="/index"
      >
        <app-logo></app-logo>
      </a>
      <div
        *ngIf="footerMainMenu.length"
        class="content__main-menu"
      >
        <ul class="menu">
          <li
            *ngFor="let menuItem of footerMainMenu"
            class="menu__item"
          >
            <app-menu-item
              [title]="menuItem.title"
              [link]="menuItem.link"
              [href]="menuItem.href"
              [svgIconSrc]="menuItem.svgIconSrc"
              [target]="menuItem.target"
              >{{ menuItem.title }}</app-menu-item
            >
          </li>
        </ul>
      </div>
      <div class="content__side-menu">
        <ul class="menu menu_side">
          <li
            *ngFor="let menuItem of footerSideMenu"
            class="menu__item"
          >
            <app-menu-item
              [title]="menuItem.title"
              [link]="menuItem.link"
              [href]="menuItem.href"
              [svgIconSrc]="menuItem.svgIconSrc"
              [target]="menuItem.target"
              [styleMod]="EMenuItemStyle.side"
              >{{ menuItem.title }}</app-menu-item
            >
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
