import { IMedia } from './types';

const Media: IMedia = {
  caption: 'メディア掲載',
  items: [
    {
      photoSrc: 'assets/images/about-media/8.jpg',
      pageSrc: 'https://www.nikkei.com/article/DGXZQOUC307RB0Q2A330C2000000/',
    },
    {
      photoSrc: 'assets/images/about-media/5.jpg',
      pageSrc: 'https://jbpress.ismedia.jp/articles/-/64757',
    },
    // {
    //   photoSrc: 'assets/images/about-media/1.jpg',
    //   pageSrc: 'https://kidsna.com/magazine/entertainment-report-211214-12847',
    // },
    {
      photoSrc: 'assets/images/about-media/2.jpg',
      pageSrc: 'https://style.ehonnavi.net/ehon/2021/02/02_521.html',
    },
    {
      photoSrc: 'assets/images/about-media/6.jpg',
      pageSrc: 'https://babytech.jp/2021/11/buki_newbook/',
    },
    {
      photoSrc: 'assets/images/about-media/3.jpg',
      pageSrc: 'https://oyako-kufu.com/articles/290',
    },
    {
      photoSrc: 'assets/images/about-media/4.jpg',
      pageSrc: 'https://books.j-cast.com/topics/2021/01/16014134.html',
    },
    {
      photoSrc: 'assets/images/about-media/7.jpg',
      pageSrc: 'https://memorico.jp/%E3%82%A2%E3%82%A4%E3%83%86%E3%83%A0/3285/',
    },
  ],
};

export default Media;
