<form
  [ngClass]="formClass"
  [formGroup]="formGroup"
>
  <div
    *ngFor="let input of inputs"
    [ngClass]="getFormItemClass(input)"
    @slideY
    [@.disabled]="
      input.type !== EBookInputType.radioImage &&
      input.type !== EBookInputType.canvas &&
      input.type !== EBookInputType.group
    "
  >
    <app-input
      *ngIf="bookInputToText(input) as input"
      [name]="input.name"
      [placeholder]="input.placeholder | translate"
      [label]="input.label | translate"
      [formControl]="formGroup.controls[input.name]"
      [error]="input.error"
      [theme]="EInputTheme.alt"
    ></app-input>
    <!-- <app-input
      *ngIf="bookInputToDate(input) as input"
      type="date"
      [name]="input.name"
      [placeholder]="input.placeholder | translate"
      [label]="input.label | translate"
      [formControl]="formGroup.controls[input.name]"
    ></app-input> -->
    <app-input-date
      *ngIf="bookInputToDate(input) as input"
      [placeholder]="input.placeholder | translate"
      [label]="input.label | translate"
      [max]="input.max"
      [min]="input.min"
      [formControl]="formGroup.controls[input.name]"
      [theme]="EInputTheme.alt"
    ></app-input-date>
    <ng-container *ngIf="bookInputToSelect(input) as input">
      <ng-container [ngSwitch]="input.selectType">
        <app-select-image-text
          *ngSwitchCase="EBookSelectInputType.imageText"
          [name]="input.name"
          [placeholder]="input.placeholder | translate"
          [label]="input.label | translate"
          [error]="input.error"
          [options]="input.options"
          [formControl]="formGroup.controls[input.name]"
          [theme]="EInputTheme.alt"
        ></app-select-image-text>
        <app-input
          *ngSwitchDefault
          type="select"
          [name]="input.name"
          [placeholder]="input.placeholder | translate"
          [label]="input.label | translate"
          [formControl]="formGroup.controls[input.name]"
          [theme]="EInputTheme.alt"
        >
          <option
            *ngFor="let option of input.options"
            [value]="option.value"
          >
            {{ option.label }}
          </option>
        </app-input>
      </ng-container>
    </ng-container>
    <app-input-radio
      *ngIf="bookInputToRadio(input) as input"
      [label]="input.label | translate"
      [formControl]="formGroup.controls[input.name]"
      [options]="input.options"
      [styleCondensed]="
        input.name === 'whos-celebrating' && (media.isSmObservable | async)
      "
      [styleColumns]="
        (input.name === 'whos-celebrating' &&
          (media.isXsObservable | async) &&
          1) ||
        0
      "
    ></app-input-radio>
    <app-input-radio-image
      *ngIf="bookInputToRadioImage(input) as input"
      [label]="input.label | translate"
      [formControl]="formGroup.controls[input.name]"
      [options]="input.options"
      [theme]="EInputTheme.alt"
    ></app-input-radio-image>
    <app-input
      *ngIf="bookInputToCheckbox(input) as input"
      [label]="input.label | translate"
      [formControl]="formGroup.controls[input.name]"
      type="checkbox"
      [theme]="EInputTheme.alt"
    ></app-input>
    <app-input-canvas
      *ngIf="bookInputToCanvas(input) as input"
      [label]="input.label | translate"
      [inputs]="input.inputs"
      [items]="input.items"
      [width]="input.width"
      [height]="input.height"
      [noLtMdBackground]="input.noLtMdBackground"
      [borderRadius]="input.borderRadius"
      [crop]="input.crop"
      [cropLtMd]="input.cropLtMd"
      [formControl]="formGroup.controls[input.name]"
      [error]="input.error"
    ></app-input-canvas>
    <app-input-group
      *ngIf="bookInputToGroup(input) as input"
      [label]="input.label | translate"
      [inputs]="input.inputs"
      [formControl]="formGroup.controls[input.name]"
      [error]="input.error"
    ></app-input-group>
  </div>
  <div class="form__item form__item_submit">
    <app-button
      [disabled]="!(valid$ | async)"
      (buttonClick)="submit()"
      >{{ 'Open the book' | translate }}</app-button
    >
  </div>
</form>
