import { Component } from '@angular/core';
import { TBookAlias, TBookCover, TBookWrapping } from '@shared/book/interfaces';
import { IBook } from 'app/data/books/types';
import { INPUT_VALUE_COVER_TYPE_SOFT } from '@shared/book/constants';
import { EGiftOrderType } from '@shared/gift/enums';
import { GiftBooks } from '../gift-buy-page/gift-buy-page.data';

@Component({
  templateUrl: './gift-buy-special-page.component.html',
  styleUrls: ['./gift-buy-special-page.component.scss'],
})
export class GiftBuySpecialPageComponent {
  public alias?: TBookAlias;

  public cover: TBookCover = INPUT_VALUE_COVER_TYPE_SOFT;

  public wrapping: TBookWrapping = 'standart';

  /**
   * mapped with adding `SHIPPING_REGULAR_PRICE` to each `IBookPrice['value']`
   */
  public readonly Books: IBook[] = GiftBooks;

  public readonly EGiftOrderType = EGiftOrderType;

  get book(): IBook | undefined {
    const { alias, Books: _Books } = this;

    return alias && _Books.find((b) => b.alias === alias);
  }

  // constructor() {}
}
