import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import type { TBookAlias } from '@shared/book/interfaces';
import type { IBook } from 'app/data/books/types';

@Component({
  selector: 'app-carousel[books]',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent {
  @Input() books!: IBook[];

  @Output() buttonClick = new EventEmitter<TBookAlias>();

  // constructor() {}

  public onButtonClick(alias: TBookAlias) {
    this.buttonClick.emit(alias);
  }
}
