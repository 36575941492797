import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalculatorComponent } from './calculator.component';
import { CartComponent } from './components/cart/cart.component';
import { OrderComponent } from './components/order/order.component';
import { PriceModule } from '../price/price.module';
import { LiteComponent } from './components/lite/lite.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    CalculatorComponent,
    CartComponent,
    OrderComponent,
    LiteComponent,
  ],
  imports: [CommonModule, PriceModule, TranslateModule],
  exports: [CalculatorComponent],
})
export class CalculatorModule {}
