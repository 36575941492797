import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductComponent } from './product.component';
import { ButtonModule } from '../button/button.module';
import { TranslateModule } from '@ngx-translate/core';
import { PriceModule } from '../price/price.module';

@NgModule({
  declarations: [ProductComponent],
  imports: [CommonModule, ButtonModule, TranslateModule, PriceModule],
  exports: [ProductComponent],
})
export class ProductModule {}
