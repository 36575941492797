import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-change',
  templateUrl: './change.component.html',
  styleUrls: ['./change.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeComponent {
  @Output() buttonClick = new EventEmitter();

  constructor() {}

  public onButtonClick(): void {
    this.buttonClick.emit();
  }
}
