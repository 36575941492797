export const PROPERTY_PURPOSE_ADVENTURE = 'Everyday / Adventure Books';

export const PROPERTY_PURPOSE_BIRTHDAY = 'Happy Birthday Books';

export const PROPERTY_PURPOSE_NEWBORN = 'Newborn Celebration Books';

export const PROPERTY_PURPOSE_ARR = [
  PROPERTY_PURPOSE_ADVENTURE,
  PROPERTY_PURPOSE_BIRTHDAY,
  PROPERTY_PURPOSE_NEWBORN,
] as const;
