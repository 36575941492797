import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ITeamMember } from 'src/app/data/team/types';

@Component({
  selector: 'app-card-member',
  templateUrl: './card-member.component.html',
  styleUrls: ['./card-member.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardMemberComponent {
  @Input() member!: ITeamMember;

  constructor() {}
}
