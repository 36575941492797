<div class="container">
  <div
    *ngIf="imageFirst"
    class="container__section container__section_img"
  >
    <img [src]="imgSrc" alt="#" class="img">
  </div>
  <div class="container__section container__section_text">
    <div class="text">
      <ng-container
        *ngFor="let textItem of text"
      >
        <h4
          *ngIf="textItem.type === EBookDescriptionTextItemType.caption"
          class="text__caption"
        >{{ textItem.text }}</h4>
        <p
          *ngIf="textItem.type === EBookDescriptionTextItemType.regular"
          class="text__regular"
        >{{ textItem.text }}</p>
      </ng-container>
    </div>
  </div>
  <div
    *ngIf="!imageFirst"
    class="container__section container__section_img"
  >
    <img [src]="imgSrc" alt="#" class="img">
  </div>
</div>
