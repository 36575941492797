<div class="container">
  <div class="container__content">
    <app-button
      color="accent-2"
      size="sm"
      (buttonClick)="onButtonClick()"
      ><b>Explore more</b></app-button
    >
  </div>
  <i class="container__mouse"></i>
</div>
