import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MediaService } from 'src/app/services/media.service';

@Component({
  selector: 'app-block-sns-card',
  templateUrl: './block-sns-card.component.html',
  styleUrls: ['./block-sns-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockSnsCardComponent {
  constructor(public media: MediaService) {}
}
