import { EBookInputType, IBookInputCommon } from '../shared';

export enum EBookSelectInputType {
  default,
  imageText,
}

export interface IBookSelectInputOption {
  label: string;
  value: string;
  imgSrc?: string;
}

export interface IBookSelectInput extends IBookInputCommon {
  label: string;
  placeholder: string;
  type: EBookInputType.select;
  selectType: EBookSelectInputType;
  options: IBookSelectInputOption[];
  /** defines the selected `IBookSelectInputOption` */
  value?: IBookSelectInputOption['value'];
}
