import { IBookDateInput } from './types';
import { EBookInputType, TGetBookInput } from '../shared';
import { getDateValidatorList } from 'src/app/validators';

export const getBookDateInputData: TGetBookInput<
  IBookDateInput,
  'type' | 'validator'
> = (params) =>
  ({
    ...params,
    type: EBookInputType.date,
    validator: getDateValidatorList(params.min, params.max),
  } as const);
