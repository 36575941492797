import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionHeaderGapComponent } from './section-header-gap.component';

@NgModule({
  declarations: [SectionHeaderGapComponent],
  imports: [CommonModule],
  exports: [SectionHeaderGapComponent],
})
export class SectionHeaderGapModule {}
