import type { BookData, CommonBookData } from '@shared/models';
import type {
  TCharacterSD,
  TGender,
  THoroscopes,
  TLanguage,
} from '@shared/book/interfaces';
import { ZodiacSigns } from 'src/app/components/zodiac-signs';
import { BOOK_ALIAS_STARRY_DREAM } from '@shared/book/constants';
import type { IBookCreate } from 'src/app/services/book-create/book-create';
import firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;
import capitalize from 'lodash/capitalize';
import differenceInYears from 'date-fns/differenceInYears';

export class StarryDreamBookCreate implements IBookCreate<BookData> {
  createNewBook(
    uid: string,
    docId: string,
    heroName: string,
    birthDate: Date,
    language: TLanguage,
    gender: TGender,
    character: TCharacterSD,
    serverTimestamp: any,
    zodiacSigns?: THoroscopes,
    common: Partial<CommonBookData> = {},
  ): BookData {
    const newBook: BookData = this.createEmptyBook();

    heroName = language === 'en' ? capitalize(heroName) : heroName;

    newBook.uid = uid;
    newBook.bookId = docId;
    newBook.heroName = heroName;
    newBook.birthDate = Timestamp.fromDate(birthDate);
    newBook.birthDateTimeZone =
      Intl.DateTimeFormat().resolvedOptions().timeZone;
    newBook.language = language;
    newBook.gender = gender;
    newBook.character = character;
    newBook.steps.character.isDone = true;
    newBook.steps.horoscope.isDone = true;
    newBook.steps.text.isDone = true;
    newBook.createTimeStamp = serverTimestamp;

    if (language == 'jp') {
      newBook.messageText =
        `${heroName}へ\n` +
        `\n${heroName}は　これからどんな　ぼうけんをするのかな。\n` +
        `ふしぎなことや　すてきなこと、\n` +
        `いろいろなことを　たくさん　けいけんして\n${heroName}が　おおきく　せいちょうしていくのを\n` +
        `これからもずっと　みまもっているよ。`;
    } else {
      newBook.messageText =
        `Dear ${heroName},` +
        `\n\n` +
        `I have a wonderful adventure for you in the pages of this book.\n` +
        `I will be watching over you in this story,\n` +
        `and as you experience fantastic and magical things in your life.\n`;
    }

    const yearsOld = differenceInYears(birthDate, new Date());
    const before4yearsOld = yearsOld < 4;

    if (before4yearsOld) {
      newBook.textType = 'small';
    } else {
      newBook.textType = 'large';
    }
    // newBook.steps.message.isDone = true;
    newBook.steps.text.isDone = true;
    // newBook.steps.starmap.isDone = true;

    newBook.zodiacSigns =
      zodiacSigns || ZodiacSigns.getSignByDate(birthDate).name;

    Object.assign(newBook, common);

    return newBook;
  }

  createEmptyBook(): BookData {
    return {
      steps: {
        character: {
          stepType: 'character',
          isDone: false,
        },
        horoscope: {
          stepType: 'horoscope',
          isDone: false,
        },
        text: {
          stepType: 'text',
          isDone: false,
        },
        photo: {
          stepType: 'photo',
          isDone: false,
        },
        planetStory: {
          stepType: 'planetStory',
          isDone: false,
        },
        starmap: {
          stepType: 'starmap',
          isDone: false,
        },
        message: {
          stepType: 'message',
          isDone: false,
        },
      },
      uid: undefined,
      bookId: undefined,
      heroName: undefined,
      language: undefined,
      gender: undefined,
      character: undefined,
      zodiacSigns: undefined,
      birthDate: null,
      birthTime: null,
      place: null,
      photoTransformData: null,
      fileUploadStatus: 0,
      createTimeStamp: undefined,
      textType: 'small',
      planets: ['planet0', 'planet1', 'planet2'],
      alias: BOOK_ALIAS_STARRY_DREAM,
      cover: 'soft',
      wrapping: 'standart',
    };
  }
}
