import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollaborationPageComponent } from './collaboration-page.component';
import { SectionReviewsYotpoCommonModule } from 'app/shared/section-reviews-yotpo-common/section-reviews-yotpo-common.module';
import { ImageTitleModule } from 'src/app/shared/image-title/image-title.module';
import { SectionHeaderGapModule } from 'src/app/shared/section-header-gap/section-header-gap.module';
import { SectionCollaborationHeaderComponent } from './components/section-collaboration-header/section-collaboration-header.component';
import { FormContactsModule } from 'src/app/shared/form-contacts/form-contacts.module';
import { SectionCollaborationContactsComponent } from './components/section-collaboration-contacts/section-collaboration-contacts.component';
import { SectionCompanyModule } from 'src/app/shared/section-company/section-company.module';
import { SectionCollaborationStatementComponent } from './components/section-collaboration-statement/section-collaboration-statement.component';
import { TabsModule } from '../../shared/tabs/tabs.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TabPointComponent } from './components/tab-point/tab-point.component';
import { ButtonModule } from 'src/app/shared/button/button.module';

@NgModule({
  declarations: [
    CollaborationPageComponent,
    SectionCollaborationHeaderComponent,
    SectionCollaborationContactsComponent,
    SectionCollaborationStatementComponent,
    TabPointComponent,
  ],
  imports: [
    CommonModule,
    SectionHeaderGapModule,
    ImageTitleModule,
    SectionReviewsYotpoCommonModule,
    FormContactsModule,
    SectionCompanyModule,
    TabsModule,
    FlexLayoutModule,
    ButtonModule,
  ],
})
export class CollaborationPageModule {}
