import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-lite',
  templateUrl: './lite.component.html',
  styleUrls: ['./lite.component.scss'],
})
export class LiteComponent {
  @Input() total: number = 0;

  @Input() currency: string = '';

  constructor() {}
}
