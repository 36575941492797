import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionSeeOtherComponent } from './section-see-other.component';
import { ButtonModule } from '../button/button.module';

@NgModule({
  declarations: [SectionSeeOtherComponent],
  imports: [CommonModule, ButtonModule],
  exports: [SectionSeeOtherComponent],
})
export class SectionSeeOtherModule {}
