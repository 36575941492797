<div
  class="wrap"
  (click)="onBookClick()"
  (mouseenter)="onMouseenter()"
  (mouseleave)="onMouseleave()"
>
  <div class="wrap__container">
    <div class="container">
      <div class="container__caption">
        <h4 class="caption">『{{ caption | translate }}』ギフトコード</h4>
      </div>
      <div class="container__content">
        <div class="content">
          <div class="content__tick">
            <app-tick
              [active]="selected"
              [hover]="(hoverObservable | async) || false"
            ></app-tick>
          </div>
          <div class="content__photo">
            <app-photo
              class="photo"
              [src]="photo"
              [alt]="caption"
            ></app-photo>
          </div>
          <div class="content__description">
            <div class="description">
              <div
                *ngIf="!((media.isLtMdObservable | async) || false)"
                class="description__summary"
              >
                <p class="summary">{{ summary }}</p>
              </div>
              <div class="description__notice-points">
                <div class="notice-points">
                  <div
                    class="notice-points__item"
                    *ngFor="let point of notice.points"
                  >
                    <div class="notice-point">
                      <img
                        class="notice-point__img"
                        [src]="point.imgSrc"
                        alt="#"
                      />
                      <p
                        class="notice-point__txt"
                        translate
                      >
                        {{ point.caption }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="selected"
        @slideY
        class="container__toggle"
      >
        <div class="toggle-wrap">
          <div
            class="toggle-wrap__item"
            *ngFor="let c of covers"
          >
            <app-product-radio-card
              [active]="c.name === (coverObservable | async)"
              [imgSrc]="c.image"
              [caption]="c.name"
              [summary]="c.summary"
              [price]="c.price"
              (radioCardClick)="onCoverClick(c.name)"
            ></app-product-radio-card>
          </div>
        </div>
      </div>
      <div
        *ngIf="!selected"
        @slideY
        class="container__price"
      >
        <div
          *ngIf="minPrice"
          class="price-wrap"
        >
          <app-price
            class="price-wrap__item"
            [price]="minPrice.value"
            [vague]="true"
            [shippingIncluded]="minPrice.shippingIncluded"
            [currency]="minPrice.currency"
            [discount]="(discountObservable | async) || undefined"
            [shippingDiscount]="
              (shippingDiscountObservable | async) || undefined
            "
            [boldPrice]="true"
          ></app-price>
        </div>
      </div>
    </div>
  </div>
</div>
