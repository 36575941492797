import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { EButtonColor } from 'app/shared/button/types';
import { Logger } from 'app/services/logger.service';
import { EInputTheme } from 'app/shared/input/interfaces';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ISignInData } from '../../auth-page.interfaces';
import { emailValidatorList } from 'app/validators';
import { EMAIL_ERROR } from 'app/validators.constants';

const log = new Logger('FormSignInComponent');

@Component({
  selector: 'app-form-sign-in',
  templateUrl: './form-sign-in.component.html',
  styleUrls: ['./form-sign-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSignInComponent implements OnInit {
  @Output() signInButtonClick = new EventEmitter<ISignInData>();

  @Output() signUpButtonClick = new EventEmitter<void>();

  @Output() resetButtonClick = new EventEmitter<void>();

  public readonly formGroup = new FormGroup({
    email: new FormControl('', emailValidatorList),
    password: new FormControl('', [Validators.required]),
  });

  public readonly EButtonColor = EButtonColor;

  public readonly EInputTheme = EInputTheme;

  public readonly EMAIL_ERROR = EMAIL_ERROR;

  constructor() {}

  ngOnInit(): void {}

  public onSignInButtonClick() {
    const data = this.formGroup.value as ISignInData;

    log.info('onSignInButtonClick', data);
    this.signInButtonClick.emit(data);
  }

  public onSignUpButtonClick() {
    log.info('onSignUpButtonClick');
    this.signUpButtonClick.emit();
  }

  public onResetButtonClick() {
    log.info('onResetButtonClick');
    this.resetButtonClick.emit();
  }
}
