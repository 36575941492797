import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InputComponent } from './input.component';
import { SelectComponent } from './components/select/select.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LabelComponent } from './components/label/label.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ErrorComponent } from './components/error/error.component';
import { StripeCardComponent } from './components/stripe-card/stripe-card.component';
import { FieldComponent } from './components/field/field.component';
import { InputRadioComponent } from './components/input-radio/input-radio.component';
import { InputRadioOptionComponent } from './components/input-radio-option/input-radio-option.component';
import { TranslateModule } from '@ngx-translate/core';
import { InputDateComponent } from './components/input-date/input-date.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SelectImageTextComponent } from './components/select-image-text/select-image-text.component';
import { SelectImageTextOptionComponent } from './components/select-image-text-option/select-image-text-option.component';
import { SelectButtonComponent } from './components/select-button/select-button.component';
import { SelectImageTextBarComponent } from './components/select-image-text-bar/select-image-text-bar.component';
import { InputRadioImageComponent } from './components/input-radio-image/input-radio-image.component';
import { InputRadioImageOptionComponent } from './components/input-radio-image-option/input-radio-image-option.component';
import { InputRadioColorComponent } from './components/input-radio-color/input-radio-color.component';
import { InputRadioColorOptionComponent } from './components/input-radio-color-option/input-radio-color-option.component';
import { InputRadioIconComponent } from './components/input-radio-icon/input-radio-icon.component';
import { InputRadioIconOptionComponent } from './components/input-radio-icon-option/input-radio-icon-option.component';
import { MultiselectComponent } from './components/multiselect/multiselect.component';
import { MultiselectOptionComponent } from './components/multiselect-option/multiselect-option.component';
import { MatNativeDateModule } from '@angular/material/core';
import { InputRadioCoverComponent } from './components/input-radio-cover/input-radio-cover.component';
import { InputRadioCoverOptionComponent } from './components/input-radio-cover-option/input-radio-cover-option.component';

@NgModule({
  declarations: [
    InputComponent,
    SelectComponent,
    LabelComponent,
    CheckboxComponent,
    ErrorComponent,
    StripeCardComponent,
    FieldComponent,
    InputRadioComponent,
    InputRadioOptionComponent,
    InputDateComponent,
    SelectImageTextComponent,
    SelectImageTextOptionComponent,
    SelectButtonComponent,
    SelectImageTextBarComponent,
    InputRadioImageComponent,
    InputRadioImageOptionComponent,
    InputRadioColorComponent,
    InputRadioColorOptionComponent,
    InputRadioIconComponent,
    InputRadioIconOptionComponent,
    MultiselectComponent,
    MultiselectOptionComponent,
    InputRadioCoverComponent,
    InputRadioCoverOptionComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    TranslateModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  exports: [
    InputComponent,
    InputRadioComponent,
    InputRadioCoverComponent,
    InputRadioImageComponent,
    InputRadioColorComponent,
    InputRadioIconComponent,
    LabelComponent,
    ErrorComponent,
    InputDateComponent,
    SelectImageTextComponent,
    FormsModule,
    ReactiveFormsModule,
    FieldComponent,
    MultiselectComponent,
    FlexLayoutModule,
    AngularSvgIconModule,
    TranslateModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
})
export class InputModule {}
