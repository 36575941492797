import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TBookAlias } from '@shared/book/interfaces';
import { Logger } from 'app/services/logger.service';
import { FirebaseService } from 'app/services/firebase.service';
import { Router } from '@angular/router';
import { GAGetEvents, GATrack } from 'analytics';
import { ALIAS_TO_ID_MAP } from '@shared/book/maps';
import { ROUTE_QUERY_PARAM_GIFT } from 'app/app-routing.constants';
import { checkUserGift } from '@shared/gift/functions/checks';
import { CommonBookData } from '@shared/models';
import { slideY } from 'app/animations';
import { unwrapReservedNames } from './functions';
import { MediaService } from 'app/services/media.service';
import { BookFormCommon } from './book-form.common';

const log = new Logger('BookFormComponent');

@Component({
  selector: 'app-book-form',
  templateUrl: './book-form.component.html',
  styleUrls: ['./book-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    slideY({
      durationEnter: '300ms',
      durationLeave: '300ms',
      preserveStyles: ['display', 'marginLeft'],
    }),
  ],
})
export class BookFormComponent extends BookFormCommon {
  @Input() alias!: TBookAlias;

  /** gift id */
  @Input() gift?: string;

  /** defines whether to show background and paddings or not */
  @Input() unwrapped = false;

  get formClass() {
    const base = 'form';
    const arr: string[] = [base];

    if (this.unwrapped) {
      arr.push(`${base}_unwrapped`);
    }

    arr.push(`${base}_${this.alias}`);

    return arr;
  }

  constructor(
    private firebaseService: FirebaseService,
    private router: Router,
    public media: MediaService,
  ) {
    super();
  }

  public async submit() {
    const { alias, gift } = this;

    const value = unwrapReservedNames(this.formGroup.value);

    let newBookId: string;

    if (gift) {
      const common: Partial<CommonBookData> = {
        gift,
      };

      const userGift = await this.firebaseService.getUserGift(gift);

      if (checkUserGift(userGift)) {
        common.cover = userGift.book.cover;
      }

      newBookId = await this.firebaseService.createBookByAlias(
        alias,
        value,
        common,
      );
    } else {
      newBookId = await this.firebaseService.createBookByAlias(alias, value);
    }

    log.info('created book', newBookId);

    await this.router.navigate(['book', newBookId], {
      // replaceUrl: true,
      queryParams: {
        [ROUTE_QUERY_PARAM_GIFT]: gift,
      },
    });

    log.info('submit', alias, value);

    const bookId = ALIAS_TO_ID_MAP[alias];

    if (!bookId) {
      log.error(`didn't manage to get bookId by alias`, { alias, bookId });
      return;
    }

    GATrack(GAGetEvents.getBookPreview(bookId));
  }
}
