import {
  IArticlePassageCaption,
  IArticlePassageOrderedList,
  IArticlePassageText,
  TActiclePassageItem,
} from './section-text.interfaces';

export const checkArticlePassageCaption = (
  item: TActiclePassageItem,
): item is IArticlePassageCaption => item.type === 'caption';

export const checkArticlePassageText = (
  item: TActiclePassageItem,
): item is IArticlePassageText => item.type === 'text';

export const checkArticlePassageOrderedList = (
  item: TActiclePassageItem,
): item is IArticlePassageOrderedList => item.type === 'ordered-list';

/**
 * @returns `IArticlePassageText` if matches, `undefined` if not
 */
export const articlePassageItemToCaption = (
  item: TActiclePassageItem,
): IArticlePassageCaption | undefined => {
  if (checkArticlePassageCaption(item)) {
    return item;
  }

  return undefined;
};

/**
 * @returns `IArticlePassageText` if matches, `undefined` if not
 */
export const articlePassageItemToText = (
  item: TActiclePassageItem,
): IArticlePassageText | undefined => {
  if (checkArticlePassageText(item)) {
    return item;
  }

  return undefined;
};

/**
 * @returns `IArticlePassageOrderedList` if matches, `undefined` if not
 */
export const articlePassageItemToOrderedList = (
  item: TActiclePassageItem,
): IArticlePassageOrderedList | undefined => {
  if (checkArticlePassageOrderedList(item)) {
    return item;
  }

  return undefined;
};
