<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <app-header class="container__header"></app-header>
      <app-content
        class="container__content"
        [orderId]="orderId"
        [giftId]="giftId"
      ></app-content>
      <app-footer class="container__footer"></app-footer>
    </div>
  </div>
</div>
