<div class="wrap">
  <app-section-header-gap
    class="wrap__section-header-gap"
  ></app-section-header-gap>
  <app-section-collaboration-header
    class="wrap__section-collaboration-header"
    imageTitleType="collaboration"
    imageTitleText="法人向けサービス"
    [text]="[
      'BÜKIは国内パーソナライズ絵本のリーディング・ブランド。',
      'デジタル・テクノロジーを活用した、これまでの絵本とは違う ”主人公になれる絵本” で',
      '小さなお客さまに、もっと特別だと感じてもらいたいと思っています。'
    ]"
  ></app-section-collaboration-header>
  <app-section-collaboration-statement
    class="wrap__section-collaboration-statement"
  ></app-section-collaboration-statement>
  <app-section-reviews-yotpo-common
    class="wrap__section-reviews"
  ></app-section-reviews-yotpo-common>
  <app-section-collaboration-contacts
    class="wrap__section-collaboration-contacts"
  ></app-section-collaboration-contacts>
  <app-section-company
    class="wrap__section-company"
  ></app-section-company>
</div>
