<div
  class="container"
  [ngClass]="{
    'container_active': active,
    'container_theme-alt': theme === EInputTheme.alt
  }"
  (click)="onOptionClick()"
>
  <div
    class="container__item"
    [style.backgroundColor]="color"
  ></div>
  <div class="container__tick">
    <i
      class="tick"
      [ngClass]="{
        'tick_hidden': !active
      }"
    ></i>
  </div>
</div>
