import { Component } from '@angular/core';
import { IArticle } from 'src/app/shared/section-text/section-text.interfaces';
import { ROUTE_PATH_CONTACTS } from 'app/app-routing.constants';
import { BASE_HREF } from 'app/constants';

const contactsHref = `${BASE_HREF}${ROUTE_PATH_CONTACTS}`;

@Component({
  selector: 'app-ordercontract',
  templateUrl: './ordercontract.component.html',
  styleUrls: ['./ordercontract.component.scss'],
})
export class OrdercontractComponent {
  public readonly articles: IArticle[] = [
    {
      caption: '特定商取引法に基づく表示',
      passages: [
        {
          items: [
            {
              type: 'text',
              text: '販売業者<br>STUDIO BUKI株式会社',
            },
          ],
        },
        {
          items: [
            {
              type: 'text',
              text: '住所：〒151-0051<br>東京都渋谷区千駄ヶ谷3-15-3',
            },
          ],
        },
        {
          items: [
            {
              type: 'text',
              text: '運営責任者：<br>コズロブ ふくみ',
            },
          ],
        },
        {
          items: [
            {
              type: 'text',
              text:
                'お問い合せ先：<br>yay@hellobuki.comにメール、もしくは<a href="' +
                contactsHref +
                '">お問い合わせページ</a>よりご連絡お願いします',
            },
          ],
        },
        {
          items: [
            {
              type: 'text',
              text: '電話番号：<br>カスタマーサポート：050-5372-2976（月〜金：10時〜16時 *祝日・連休・年末年始除く）',
            },
          ],
        },
        {
          items: [
            {
              type: 'text',
              text: '商品代金以外の必要料金：<br>表示された販売価格（消費税込）に加え、送料が別途かかります。',
            },
          ],
        },
        {
          items: [
            {
              type: 'text',
              text: 'お支払い方法：<br>各種クレジットカードまたはコンビニ払い（ファミリーマート、ローソン、ミニストップ、セイコーマート）がご利用いただけます。',
            },
          ],
        },
        {
          items: [
            {
              type: 'text',
              text: '商品の引き渡し時期：<br>ご注文時にサイトに記載があった最短発送日に発送いたします。<br>*年末年始など、印刷工場がお休みの時は上記より発送までに日数がかかります。その際には webサイト上でお知らせいたします。',
            },
          ],
        },
        {
          items: [
            {
              type: 'text',
              text: '商品の返品・交換について：<br>オーダーメイド商品のため、お客さま都合の返品・交換はできません。\n万一、商品に不備（落丁、乱丁、印刷ミス、破損、汚れ等）があった場合は、恐れ入りますが発送から14日以内に <a href="mailto:yay@hellobuki.com">yay@hellobuki.com</a> に【ご注文番号】を記載のうえ、メールでご連絡ください。新しいものと交換させていただきます。',
            },
          ],
        },
      ],
    },
  ];

  // constructor() {}
}
