<app-field
  [label]="label"
  [error]="(showError && error) || undefined"
  [forId]="id"
>
  <div
    class="container"
    [ngClass]="{
      container_active: isActive | async
    }"
  >
    <div class="container__bar">
      <app-select-image-text-bar
        [theme]="theme"
        [option]="(option | async) || undefined"
        [placeholder]="placeholder"
        [isActive]="(isActive | async) || undefined"
        [invalid]="showError"
      ></app-select-image-text-bar>
    </div>
    <div class="container__content">
      <div
        class="content"
        [ngClass]="{
          'content_theme-alt': theme === EInputTheme.alt
        }"
      >
        <div
          *ngFor="let option of options"
          class="content__section"
        >
          <app-select-image-text-option
            [label]="option.label"
            [imgSrc]="option.imgSrc"
            [active]="option.value === value"
            (optionClick)="value = option.value"
          ></app-select-image-text-option>
        </div>
      </div>
    </div>
  </div>
</app-field>
