import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { getProductName } from 'app/functions';
import type { Product } from 'app/interfaces';

@Component({
  selector: 'app-delete-produck-dialog',
  templateUrl: './delete-product-dialog.component.html',
  styleUrls: ['./delete-product-dialog.component.scss'],
})
export class DeleteProductDialogComponent {
  public getProductName = getProductName;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { product: Product }) {}
}
