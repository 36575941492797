<div class="wrap">
  <app-section-header-gap
    class="wrap__section wrap__section_header-gap"
  ></app-section-header-gap>
  <div class="wrap__section wrap__section_title">
    <h1 class="title">
      <div class="title__text">Are you ready to take your</div>
      <div class="title__text">little ones to a special adventure?</div>
    </h1>
  </div>
  <app-section-filter
    [books]="Books"
    (filter)="filteredBooks = $event"
    class="wrap__section wrap__section_filter"
  ></app-section-filter>
  <app-section-books
    class="wrap__section wrap__section_books"
    [books]="filteredBooks"
    [hideTitle]="true"
    [disableCarousel]="true"
  ></app-section-books>
  <app-section-features
    class="wrap__section wrap__section_features"
  ></app-section-features>
  <app-section-reviews-yotpo-common
    class="wrap__section-reviews"
  ></app-section-reviews-yotpo-common>
  <app-section-steps
    class="wrap__section wrap__section_steps"
  ></app-section-steps>
  <app-section-faq
    class="wrap__section wrap__section_faq"
    [condensed]="true"
  ></app-section-faq>
  <app-section-banner-mermaid
    class="wrap__section wrap__section_footer-banner"
  ></app-section-banner-mermaid>
</div>
