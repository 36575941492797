<div class="wrap">
  <app-section-header-gap
    class="wrap__section wrap__section_header-gap"
  ></app-section-header-gap>
  <div class="wrap__section wrap__section_image-title">
    <app-image-title type="contact"></app-image-title>
  </div>
  <app-section-form class="wrap__section wrap__section_form"></app-section-form>
  <app-section-collaboration
    class="wrap__section wrap__section_collaboration"
  ></app-section-collaboration>
  <app-section-ducks
    class="wrap__section wrap__section_ducks"
  ></app-section-ducks>
  <app-section-footer-banner
    class="wrap__section wrap__section_footer-banner"
  ></app-section-footer-banner>
</div>
