import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class YotpoService {
  private _loading = false;

  // constructor() {}

  init() {
    if (typeof window.yotpo !== 'undefined') {
      window.yotpo.initWidgets();
    } else {
      if (this._loading) return;

      this._loading = true;

      const e = document.createElement('script');

      e.type = 'text/javascript';
      e.async = true;
      e.src =
        '//staticw2.yotpo.com/SESqWOWTBYbOWK5Lk0e1vvR4rcgfeQ4RWiggKwLF/widget.js';
      e.onload = () => {
        this._loading = false;
      };

      var t = document.getElementsByTagName('script')[0];

      t.parentNode?.insertBefore(e, t);
    }
  }
}
