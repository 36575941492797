import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IBookRadioImageInputOption } from 'app/data/books/inputs/radioImage/types';
import { EInputTheme } from '../../interfaces';

@Component({
  selector: 'app-input-radio-image-option',
  templateUrl: './input-radio-image-option.component.html',
  styleUrls: ['./input-radio-image-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputRadioImageOptionComponent {
  @Input() imgSrc!: IBookRadioImageInputOption['imgSrc'];

  @Input() active?: boolean;

  @Input() theme!: EInputTheme;

  @Output() optionClick = new EventEmitter<void>();

  public readonly EInputTheme = EInputTheme;

  constructor() {}

  public onOptionClick(): void {
    this.optionClick.emit();
  }
}
