import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { Logger } from './logger.service';
import cookie from 'cookie';
import { deleteUndefinedFromObject } from '@shared/utils';
import firebase from 'firebase/app';
import type { BookInOrder, IOrderData } from '@shared/interfaces';

const log = new Logger('OrderService');

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  // public store: {
  //   [orderId: string]: IOrderData;
  // } = {};

  // public update = new EventEmitter<OrderService['store']>();

  constructor(private firebaseService: FirebaseService) {
    this.init();
  }

  private async init() {
    // const usersOrdersRef = await this.firebaseService.getUserOrdersRef();
    // handle orders push to update the store
    // usersOrdersRef.onSnapshot((snapshot) =>
    //   snapshot.forEach((doc) => {
    //     const orderData = doc.data() as IOrderData;
    //     const isAdded = !this.store[doc.id];
    //     const isUpdated =
    //       !isAdded &&
    //       JSON.stringify(this.store[doc.id]) !== JSON.stringify(orderData);
    //     if (!isAdded && !isUpdated) return;
    //     this.store[doc.id] = orderData;
    //     if (isUpdated) {
    //       log.info('order updated in store', doc.id, orderData);
    //     } else {
    //       log.info('order added to store', doc.id, orderData);
    //     }
    //     this.update.emit({ [doc.id]: orderData });
    //   }),
    // );
  }

  public async createOrder(
    bookInOrder: BookInOrder[],
    gift?: IOrderData['gift'],
  ): Promise<IOrderData['orderId']> {
    const doc = (await this.firebaseService.getUserOrdersRef()).doc();
    const user = await this.firebaseService.userAuth();

    if (!user) throw new Error('_getUserDataRef => user is anauthorised');

    const newOrder: IOrderData = {
      books: bookInOrder,
      gift,
      createTimeStamp: firebase.firestore.FieldValue.serverTimestamp(),
      status: 0,
      orderId: doc.id,
      uid: user.uid,
      userAgent: navigator.userAgent,
      userIp: document.documentElement.dataset.userIp as string,
      fbp: cookie.parse(document.cookie)._fbp,
      fbc: cookie.parse(document.cookie)._fbc,
      referrer: document.referrer,
      hostname: location.hostname,
      origin: location.origin,
    };

    deleteUndefinedFromObject(newOrder);

    await doc.set(newOrder);

    return doc.id;
  }

  public async getOrder(orderId: string): Promise<IOrderData> {
    const orderDocRef = await this.firebaseService.getUserOrderRef(orderId);
    const orderDoc = await orderDocRef.get();

    return orderDoc.data() as IOrderData;
  }

  public async updateOrder(orderId: string, orderData: Partial<IOrderData>) {
    deleteUndefinedFromObject(orderData);

    const orderDocRef = await this.firebaseService.getUserOrderRef(orderId);

    await orderDocRef.set(orderData, { merge: true });
  }
}
