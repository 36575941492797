export class ZodiacSigns {
  //from https://github.com/Helmasaur/Zodiac-signs
  static signsData = {
    aries: {
      name: 'aries',
      symbol: '♈',
      dateMin: '2000-03-21 00:00:00',
      dateMax: '2000-04-19 00:00:00',
    },
    taurus: {
      name: 'taurus',
      symbol: '♉',
      dateMin: '2000-04-20 00:00:00',
      dateMax: '2000-05-20 00:00:00',
    },
    gemini: {
      name: 'gemini',
      symbol: '♊',
      dateMin: '2000-05-21 00:00:00',
      dateMax: '2000-06-20 00:00:00',
    },
    cancer: {
      name: 'cancer',
      symbol: '♋',
      dateMin: '2000-06-21 00:00:00',
      dateMax: '2000-07-22 00:00:00',
    },
    leo: {
      name: 'leo',
      symbol: '♌',
      dateMin: '2000-07-23 00:00:00',
      dateMax: '2000-08-22 00:00:00',
    },
    virgo: {
      name: 'virgo',
      symbol: '♍',
      dateMin: '2000-08-23 00:00:00',
      dateMax: '2000-09-22 00:00:00',
    },
    libra: {
      name: 'libra',
      symbol: '♎',
      dateMin: '2000-09-23 00:00:00',
      dateMax: '2000-10-22 00:00:00',
    },
    scorpio: {
      name: 'scorpio',
      symbol: '♏',
      dateMin: '2000-10-23 00:00:00',
      dateMax: '2000-11-21 00:00:00',
    },
    sagittarius: {
      name: 'sagittarius',
      symbol: '♐',
      dateMin: '2000-11-22 00:00:00',
      dateMax: '2000-12-21 00:00:00',
    },
    capricorn: {
      name: 'capricorn',
      symbol: '♑',
      dateMin: '2000-12-22 00:00:00',
      dateMax: '2000-01-19 00:00:00',
    },
    aquarius: {
      name: 'aquarius',
      symbol: '♒',
      dateMin: '2000-01-20 00:00:00',
      dateMax: '2000-02-18 00:00:00',
    },
    pisces: {
      name: 'pisces',
      symbol: '♓',
      dateMin: '2000-02-19 00:00:00',
      dateMax: '2000-03-20 00:00:00',
    },
  };

  static normaliseDateParseToLocalDate(dateStr): Date {
    let s = dateStr;
    let a = s.split(/[^0-9]/);
    return new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5]);
  }

  static getSignByDate(date: Date) {
    const day: number = date.getDate();
    const month: number = date.getMonth() + 1;
    return ZodiacSigns.getSign({ day, month });
  }

  static getSign({ day, month }) {
    const date = ZodiacSigns.normaliseDateParseToLocalDate(
      `2000-${month}-${day} 00:00:00`,
    );
    console.log(date);
    if (date.toString() === 'Invalid Date') {
      return { name: undefined };
    }
    const signsData = Object.values(ZodiacSigns.signsData);

    let dateMin;
    let dateMax;
    const i = signsData.findIndex((sign) => {
      dateMin = ZodiacSigns.normaliseDateParseToLocalDate(sign.dateMin);
      dateMax = ZodiacSigns.normaliseDateParseToLocalDate(sign.dateMax);

      return (
        (date.getDate() >= dateMin.getDate() &&
          date.getMonth() == dateMin.getMonth()) ||
        (date.getDate() <= dateMax.getDate() &&
          date.getMonth() === dateMax.getMonth())
      );
    });
    return signsData[i];
  }
}
