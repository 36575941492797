import type { Provider } from '@angular/core';
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
  forwardRef,
} from '@angular/core';
import type { ControlValueAccessor, FormControl } from '@angular/forms';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { checkControlShowError } from 'app/functions';
import { Logger } from 'app/services/logger.service';
import isEqual from 'lodash/isEqual';
import uniqueId from 'lodash/uniqueId';
import lowerFirst from 'lodash/lowerFirst';
import { BehaviorSubject } from 'rxjs';
import { filter, map, mapTo, switchMap } from 'rxjs/operators';

const log = new Logger('InputCommon');

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputCommon),
  multi: true,
};

@Component({
  template: '',
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class InputCommon<T> implements ControlValueAccessor {
  @Input() public error = '';

  @Output() public valueChange = new EventEmitter<T>();

  @Input() set value(value: T | undefined) {
    if (isEqual(value, this._value$.value)) return;

    log.info('value set', value);
    this.onChange(value);
    this._value$.next(value);
    this.valueChange.emit(value);
    this.onTouched();
  }

  get value() {
    return this._value$.value;
  }

  protected _value$ = new BehaviorSubject<T | undefined>(undefined);

  protected _disabled$ = new BehaviorSubject(false);

  protected _id$ = new BehaviorSubject<string | undefined>(undefined);

  public id$ = this._id$.asObservable().pipe(filter((id) => !!id));

  protected _control$ = new BehaviorSubject<FormControl | undefined>(undefined);

  public control$ = this._control$
    .asObservable()
    .pipe(filter((c): c is FormControl => !!c));

  public showError$ = this.control$.pipe(
    switchMap((control) => control.statusChanges.pipe(mapTo(control))),
    map((control) => checkControlShowError(control) && this.error),
  );

  constructor(protected _injector: Injector) {
    this._id$.next(this._getId());
  }

  writeValue(value: T): void {
    this.value = value;
  }

  setDisabledState(status: boolean): void {
    this._disabled$.next(status);
  }

  public onChange(value: T | undefined) {}

  public onTouched() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;

    const ngControl = this._injector.get(NgControl);

    if (ngControl) {
      this._control$.next(ngControl.control as FormControl);
    }
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  protected _getId(id: number | string = uniqueId()): string {
    return `${lowerFirst(this.constructor.name)}_${id}`;
  }
}
