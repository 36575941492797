<button
  class="container"
  [ngClass]="containerNgClass"
  (click)="onButtonClick()"
  [disabled]="disabled"
  [type]="type"
>
  <span
    class="container__content"
    [class.container__content_hidden]="loading"
    ><ng-content></ng-content
  ></span>
  <app-spinner
    class="container__loader"
    [class.container__loader_hidden]="!loading"
    [color]="spinnerColor"
  ></app-spinner>
</button>
