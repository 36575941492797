import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { getBooksDiscount } from '@shared/discount/utils';
import { IBook } from 'app/data/books/types';
import { DiscountService } from 'app/services/discount.service';

@Component({
  selector: 'app-book[book]',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookComponent {
  @Input() book!: IBook;

  @Output() buttonClick = new EventEmitter<void>();

  public readonly getBooksDiscount = getBooksDiscount;

  constructor(public discountService: DiscountService) {}

  public onButtonClick() {
    this.buttonClick.emit();
  }
}
