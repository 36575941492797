<div class="container">
  <div class="container__background"></div>
  <div class="container__content">
    <div class="content">
      <div class="content__text">
        <div class="text">
          <p class="text__item">Meet new friends and explore</p>
          <p class="text__item">new worlds with our</p>
          <p class="text__item">personalized children’s books.</p>
        </div>
      </div>
      <div class="content__button">
        <app-button
          color="accent-alt"
          size="sm"
          (buttonClick)="onButtonClick()"
          ><b>About BÜKI</b></app-button
        >
      </div>
    </div>
  </div>
</div>
