import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { IBookPrice } from 'src/app/data/books/prices/types';
import { BehaviorSubject } from 'rxjs';
import { MediaObserver } from '@angular/flex-layout';
import Subscriber from 'src/app/subscriber';
import { ESize } from 'src/app/enums';

@Component({
  selector: 'app-product-radio-card',
  templateUrl: './product-radio-card.component.html',
  styleUrls: ['./product-radio-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductRadioCardComponent implements OnInit, OnDestroy {
  @Input() active!: boolean;

  @Input() imgSrc!: string;

  /** available sizes - `lg` */
  @Input() imageSize: ESize = ESize.md;

  @Input() caption!: string;

  /** `innerHTML` value */
  @Input() summary!: string;

  @Input() price?: IBookPrice;

  @Input() discount?: string;

  @Output() radioCardClick = new EventEmitter<void>();

  public isLtSm = new BehaviorSubject<boolean>(false);

  private _sub = new Subscriber();

  constructor(private _media: MediaObserver) {}

  ngOnInit(): void {
    this._sub.push(
      this._media.asObservable().subscribe(() => {
        this._updateIsLtSm();
      }),
    );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  public onRadioCardClick(): void {
    this.radioCardClick.emit();
  }

  private _updateIsLtSm(): void {
    this.isLtSm.next(this._media.isActive('lt-sm'));
  }
}
