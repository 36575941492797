import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormPaymentComponent } from './form-payment.component';
import { FormHeaderModule } from '../form-header/form-header.module';
import { TranslateModule } from '@ngx-translate/core';
import { InputModule } from '../input/input.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TooltipModule } from '../tooltip/tooltip.module';
import { ButtonModule } from '../button/button.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [FormPaymentComponent],
  imports: [
    CommonModule,
    FormHeaderModule,
    InputModule,
    TranslateModule,
    AngularSvgIconModule,
    TooltipModule,
    ButtonModule,
    RouterModule,
  ],
  exports: [FormPaymentComponent],
})
export class FormPaymentModule {}
