<ng-template #content>
  <svg-icon
    *ngIf="svgIconSrc"
    class="menu-item__svg-icon"
    [src]="svgIconSrc"
    [applyClass]="true"
  ></svg-icon>
  <span
    *ngIf="title"
    class="menu-item__text"
    translate
    >{{ title }}</span
  >
</ng-template>

<a
  *ngIf="link"
  class="menu-item"
  [ngClass]="menuItemModArr"
  [routerLink]="link"
  [target]="target || '_self'"
  translate
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<a
  *ngIf="href"
  class="menu-item"
  [ngClass]="menuItemModArr"
  [href]="href"
  [target]="target"
  translate
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<span
  *ngIf="!href && !link"
  class="menu-item"
  [ngClass]="menuItemModArr"
  translate
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</span>
