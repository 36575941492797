import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  IBookCanvasInputCrop,
  IBookCanvasInputItem,
} from 'app/data/books/inputs/canvas/types';
import { IBookInputNameToValueMap } from 'app/data/books/inputs/types';
import { notFirstChanges } from 'app/utils';
import { Logger } from '@shared/logger';
import Konva from 'konva';
import { parseCrop, updateCanvasItems } from './functions';
import { bookInputShowWhenCheck } from 'app/data/books/inputs/utils';
import Subscriber from 'app/subscriber';
import { fromEvent } from 'rxjs';
import PQueue from 'p-queue/dist';
import debounce from 'lodash/debounce';

const log = new Logger('AvatarCanvasComponent');

@Component({
  selector: 'app-avatar-canvas',
  templateUrl: './avatar-canvas.component.html',
  styleUrls: ['./avatar-canvas.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarCanvasComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  @ViewChild('container')
  containerRef: ElementRef<HTMLDivElement>;

  @Input() width!: number;

  @Input() height!: number;

  @Input() crop?: IBookCanvasInputCrop;

  @Input() items!: IBookCanvasInputItem[];

  @Input() value!: IBookInputNameToValueMap;

  private _stage?: Konva.Stage;

  private _layer?: Konva.Layer;

  private _itemToImageMap = new WeakMap<IBookCanvasInputItem, Konva.Image>();

  private _prevItems: IBookCanvasInputItem[] = [];

  private _sub = new Subscriber();

  private _drawItemsQueue = new PQueue({ concurrency: 1 });

  // constructor() {}

  ngAfterViewInit(): void {
    const {
      width,
      height,
      items,
      value,
      containerRef: { nativeElement: container },
    } = this;

    log.info('ngAfterViewInit', {
      width,
      height,
      items,
      value,
    });

    this._stage = new Konva.Stage({
      container,
      width,
      height,
    });

    this._layer = new Konva.Layer();

    this._stage.add(this._layer);

    this._drawItems(items, value);

    const fitStage = () => {
      this._updateStageSizes();
      this._stage.draw();
    };

    fitStage();

    this._sub.push(
      fromEvent(window, 'resize').subscribe(debounce(fitStage, 150)),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { width, height, crop, items, value } = changes;

    if (notFirstChanges(changes)) {
      log.info('ngOnChanges', {
        width,
        height,
        crop,
        items,
        value,
      });

      if (items || value) {
        this._drawItems(this.items, value.currentValue);
      }

      if (width || height || crop) {
        this._updateStageSizes();
        this._stage.draw();
      }
    }
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  private async _drawItems(
    nextItems: IBookCanvasInputItem[],
    value: IBookInputNameToValueMap,
  ) {
    await this._drawItemsQueue.add(async () => {
      const { _layer, _itemToImageMap, _prevItems, width, height } = this;

      const _nextItems = nextItems.filter(({ showWhen }) =>
        bookInputShowWhenCheck(value, showWhen),
      );

      log.info('_drawItems', { nextItems, _prevItems, _nextItems });

      await updateCanvasItems(
        _layer,
        _itemToImageMap,
        _prevItems,
        _nextItems,
        width,
        height,
      );

      this._prevItems = _nextItems;
    });
  }

  private async _updateStageSizes() {
    this._resetStage();
    this._cropStage();
    this._fitStage();
  }

  private async _resetStage() {
    const { width, height, _stage } = this;

    if (!_stage) {
      log.error('_fitStage: this._stage is not defined');
      return;
    }

    _stage.size({
      width,
      height,
    });

    _stage.scale({
      x: 1,
      y: 1,
    });

    _stage.position({
      x: 0,
      y: 0,
    });
  }

  /**
   * pushes stage out of the edges by `this.crop`
   */
  private async _cropStage() {
    const { crop, _stage } = this;

    if (!_stage) {
      log.error('_fitStage: this._stage is not defined');
      return;
    }

    const size = _stage.size();
    const scale = _stage.scale();
    const _crop = parseCrop(crop, scale);

    const newSize = {
      width: size.width - _crop.x,
      height: size.height - _crop.y,
    };

    const newPosition = {
      x: -_crop.left,
      y: -_crop.top,
    };

    _stage.size(newSize);
    _stage.position(newPosition);
  }

  private async _fitStage() {
    const { _stage } = this;

    if (!_stage) {
      log.error('_fitStage: this._stage is not defined');
      return;
    }

    const size = _stage.size();
    const scale = _stage.scale();
    const position = _stage.position();
    const container = _stage.container();
    const k = container.offsetWidth / size.width;

    const newScale = {
      x: scale.x * k,
      y: scale.y * k,
    };

    const newSize = {
      width: size.width * k,
      height: size.height * k,
    };

    const newPosition = {
      x: position.x * k,
      y: position.y * k,
    };

    _stage.size(newSize);
    _stage.scale(newScale);
    _stage.position(newPosition);
  }
}
