import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { REQUIRED_ERROR } from 'src/app/validators.constants';
import { FirebaseService } from 'src/app/services/firebase.service';
import { Logger } from 'src/app/services/logger.service';
import {
  checkError,
  checkUserGift,
  checkUserGiftNotExist,
} from '@shared/gift/functions/checks';
import {
  GIFT_EXPIRED_ERROR,
  GIFT_NOT_FOUND_ERROR,
  GIFT_USED_ERROR,
} from './constants';
import { ActivatedRoute } from '@angular/router';
import { ROUTE_PATH_PARAM_GIFT_ID } from 'app/app-routing.constants';
import { IGiftReceivedData } from './interfaces';
import { LoaderService } from 'app/services/loader.service';

const log = new Logger('SectionGiftUseComponent');

@Component({
  selector: 'app-section-gift-use',
  templateUrl: './section-gift-use.component.html',
  styleUrls: ['./section-gift-use.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionGiftUseComponent implements OnInit, OnDestroy {
  /** emits only valid IUserGift */
  @Output() giftReceived = new EventEmitter<IGiftReceivedData>();

  private readonly _codeFormControl = new FormControl('', Validators.required);

  public readonly giftFormGroup = new FormGroup({
    code: this._codeFormControl,
  });

  public readonly codeError$ = new BehaviorSubject('');

  public readonly loading$ = new BehaviorSubject(false);

  private readonly errorsMap = new Map<string, string>();

  private readonly _sub = new Subscription();

  public readonly REQUIRED_ERROR = REQUIRED_ERROR;

  constructor(
    private _loaderService: LoaderService,
    private _firebaseService: FirebaseService,
    private _route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this._sub.add(
      this.loading$.subscribe((status) => {
        if (status) {
          this._loaderService.show();
        } else {
          this._loaderService.hide();
        }
      }),
    );

    this._sub.add(
      this.codeError$.pipe(filter((error) => !!error)).subscribe((error) => {
        this.errorsMap.set(this._codeFormControl.value, error);
        this._codeFormControl.markAsTouched();
        this._codeFormControl.setErrors({ forbidden: true });
      }),
    );

    this._sub.add(
      this._codeFormControl.valueChanges.subscribe((value) => {
        const error = this.errorsMap.get(value) || '';

        this.codeError$.next(error);
      }),
    );

    this._sub.add(
      this._route.paramMap.subscribe(async (paramMap) => {
        const giftId = paramMap.get(ROUTE_PATH_PARAM_GIFT_ID);

        this._codeFormControl.setValue(giftId);

        if (giftId) {
          await this.onSubmit();
        }
      }),
    );
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  async onSubmit() {
    this.loading$.next(true);

    const userGiftDocRef = await this._firebaseService.createUserGift(
      this._codeFormControl.value,
    );

    const userGiftDocRefUnsubsribe = userGiftDocRef.onSnapshot((snap) => {
      const userGift = snap.data();

      console.log({ userGift });

      if (checkError(userGift)) {
        const { error } = userGift;

        log.error({ error });
        this.codeError$.next(error.message);

        userGiftDocRefUnsubsribe();
        this.loading$.next(false);
      } else if (checkUserGiftNotExist(userGift)) {
        this.codeError$.next(GIFT_NOT_FOUND_ERROR);

        userGiftDocRefUnsubsribe();
        this.loading$.next(false);
      } else if (checkUserGift(userGift)) {
        const { applied, expired } = userGift;

        if (expired) {
          this.codeError$.next(GIFT_EXPIRED_ERROR);
        } else if (applied) {
          this.codeError$.next(GIFT_USED_ERROR);
        } else {
          this.giftReceived.emit({ code: snap.id, gift: userGift });
        }

        userGiftDocRefUnsubsribe();
        this.loading$.next(false);
      }
    });
  }
}
