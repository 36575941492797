import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IBookRadioColorInputOption } from 'app/data/books/inputs/radioColor/types';
import { EInputTheme } from '../../interfaces';

@Component({
  selector: 'app-input-radio-color-option',
  templateUrl: './input-radio-color-option.component.html',
  styleUrls: ['./input-radio-color-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputRadioColorOptionComponent {
  @Input() color!: IBookRadioColorInputOption['color'];

  @Input() active?: boolean;

  @Input() theme!: EInputTheme;

  @Output() optionClick = new EventEmitter<void>();

  public readonly EInputTheme = EInputTheme;

  constructor() {}

  public onOptionClick(): void {
    this.optionClick.emit();
  }
}
