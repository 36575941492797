import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionGiftCheckoutComponent } from './section-gift-checkout.component';
import { FormCustomerInfoModule } from '../form-customer-info/form-customer-info.module';
import { FormShipFromModule } from '../form-ship-from/form-ship-from.module';
import { FormPaymentModule } from '../form-payment/form-payment.module';
import { ImageTitleModule } from '../image-title/image-title.module';
import { DialogsModule } from '../dialogs/dialogs.module';
import { FormTestButtonsModule } from '../form-test-buttons/form-test-buttons.module';
import { FormGiftSpecialModule } from '../form-gift-special/form-gift-special.module';
import { ButtonModule } from '../button/button.module';

@NgModule({
  declarations: [SectionGiftCheckoutComponent],
  imports: [
    CommonModule,
    ImageTitleModule,
    FormCustomerInfoModule,
    FormShipFromModule,
    FormPaymentModule,
    DialogsModule,
    FormTestButtonsModule,
    FormGiftSpecialModule,
    ButtonModule,
  ],
  exports: [SectionGiftCheckoutComponent],
})
export class SectionGiftCheckoutModule {}
