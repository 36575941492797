<div
  class="wrap"
  *ngIf="shown"
  [@fade]
>
  <div class="wrap__container">
    <div class="container">
      <div
        class="container__title"
        *ngIf="title"
      >
        <h3 class="title">
          {{ title }}
        </h3>
      </div>
      <!-- <div class="container__subtitle"
        *ngIf="subtitle"
      >
        <p class="subtitle">
          {{ subtitle }}
        </p>
      </div> -->
      <div class="container__content">
        <div class="content">
          <div class="content__image">
            <div
              class="book"
              [class.book_loaded]="!loading"
            ></div>
          </div>
          <div class="content__text">
            <ng-content
              *ngIf="loading; then loadingText; else loadedText"
            ></ng-content>
            <app-button
              class="button"
              [disabled]="loading"
              (buttonClick)="onButtonClick()"
              [fullWidth]="true"
              >OK</app-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingText>
  <p class="text">
    <span>Crafting your book,<br />please wait 10~15 seconds.</span
    ><i class="dots"></i>
  </p>
  <ng-container *ngIf="progress">
    <progress
      *ngIf="progress.value"
      class="progress"
      [attr.max]="progress.max"
      [attr.value]="progress.value"
    ></progress>
    <progress
      *ngIf="!progress.value"
      class="progress"
    ></progress>
  </ng-container>
</ng-template>

<ng-template #loadedText>
  <p class="text"><span>Ready to sparkle more magic?</span></p>
</ng-template>
