import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageTitleModule } from '../image-title/image-title.module';
import { SectionGiftCartComponent } from './section-gift-cart.component';
import { TranslateModule } from '@ngx-translate/core';
import { PriceModule } from '../price/price.module';

@NgModule({
  declarations: [SectionGiftCartComponent],
  imports: [CommonModule, ImageTitleModule, TranslateModule, PriceModule],
  exports: [SectionGiftCartComponent],
})
export class SectionGiftCartModule {}
