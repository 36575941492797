import { Component, Input } from '@angular/core';
import type { ICalculatorComponentItem } from '../../calculator.component';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.template.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent {
  @Input() items: ICalculatorComponentItem[] = [];

  @Input() total: number = 0;

  @Input() currency: string = '';

  // constructor() {}
}
