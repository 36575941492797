<form
  class="container"
  [formGroup]="formGroup"
>
  <div
    class="container__section"
    [ngClass]="{
      container__section_half: !(isMobileObservable | async)
    }"
  >
    <div class="input-wrap">
      <app-input
        class="input-wrap__item"
        label="{{ 'contact.us.name' | translate }}"
        name="name"
        formControlName="name"
        [error]="REQUIRED_ERROR"
      ></app-input>
    </div>
  </div>
  <div
    class="container__section"
    [ngClass]="{
      container__section_half: !(isMobileObservable | async)
    }"
  >
    <div class="input-wrap">
      <app-input
        class="input-wrap__item"
        label="{{ 'contact.us.mail' | translate }}"
        name="email"
        type="email"
        formControlName="email"
        [error]="EMAIL_ERROR"
      ></app-input>
    </div>
  </div>
  <div class="container__section">
    <div class="input-wrap">
      <app-input
        class="input-wrap__item"
        label="{{ 'contact.us.topic' | translate }}"
        name="topic"
        type="select"
        formControlName="topic"
        [error]="REQUIRED_ERROR"
      >
        <option
          *ngFor="let topic of TOPIC_LIST; index as i"
          [value]="i"
        >
          {{ topic }}
        </option>
      </app-input>
    </div>
  </div>
  <div class="container__section">
    <div class="input-wrap">
      <app-input
        class="input-wrap__item"
        label="{{ 'contact.us.description' | translate }}"
        name="description"
        type="textarea"
        formControlName="message"
        [error]="REQUIRED_ERROR"
      ></app-input>
    </div>
  </div>
  <div class="container__section container__section_button">
    <div class="input-wrap">
      <app-button
        class="input-wrap__item"
        color="accent-2"
        (buttonClick)="onSubmit()"
        [disabled]="formGroup.invalid || (isSendingObservable | async)"
        >{{ 'contact.us.send' | translate }}</app-button
      >
    </div>
  </div>
  <!-- <i class="container__character"></i> -->
</form>
