import type { OnDestroy, OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import type { IFormGiftSpecialData } from './form-gift-special.interfaces';
import { BehaviorSubject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Subscriber from 'src/app/subscriber';
import { EGiftOrderCodeType } from '@shared/gift/enums';
import { createValidator } from 'src/app/validators.functions';
import { MediaService } from 'src/app/services/media.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import isEqual from 'lodash/isEqual';

@Component({
  selector: 'app-form-gift-special',
  templateUrl: './form-gift-special.component.html',
  styleUrls: ['./form-gift-special.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormGiftSpecialComponent implements OnInit, OnDestroy {
  /** `undefined` when invalid */
  @Input() set data(data: IFormGiftSpecialData | undefined) {
    if (!isEqual(data, this.data)) {
      this._dataSubject.next(data);
      this.dataChange.emit(data);

      if (data && !isEqual(data, this.formGroup.value)) {
        this.formGroup.setValue(data);
      }
    }
  }

  get data(): IFormGiftSpecialData | undefined {
    return this._dataSubject.value;
  }

  private _dataSubject = new BehaviorSubject<IFormGiftSpecialData | undefined>(
    undefined,
  );

  @Output() dataChange = new EventEmitter<IFormGiftSpecialData | undefined>();

  @Input() set touchEventEmitter(e: EventEmitter<void>) {
    e.subscribe(() => {
      this.formGroup.markAllAsTouched();
    });
  }

  public formGroup = new FormGroup({
    count: new FormControl(1, [
      Validators.required,
      Validators.min(1),
      Validators.max(1000),
    ]),
    expiration: new FormControl(
      ((date) => {
        // set the next year as the expiration date
        date.setFullYear(date.getFullYear() + 1);

        return date;
      })(new Date()),
    ),
    password: new FormControl('', [Validators.required]),
    campaignCode: new FormControl('1', [Validators.required]),
    campaignDescription: new FormControl('なし', [Validators.required]),
    codeType: new FormControl(EGiftOrderCodeType.special.toString()),
    campaignNumber: new FormControl('', [
      createValidator((control) => {
        let isForbidden = false;
        const { value: campaignNumber, parent } = control;
        const codeTypeControl = parent?.get('codeType');

        if (!codeTypeControl) return isForbidden;

        const { value: codeType } = codeTypeControl;

        if (codeType === EGiftOrderCodeType.special.toString()) {
          isForbidden = !(
            campaignNumber === '' || /^\d{3}$/g.test(campaignNumber)
          );
        }

        return isForbidden;
      }),
    ]),
  });

  private readonly _sub = new Subscriber();

  public readonly expirationMinDate = ((date) => {
    // set the next year as the expiration date
    date.setDate(date.getDate() + 1);

    return date;
  })(new Date());

  public readonly EGiftOrderCodeType = EGiftOrderCodeType;

  constructor(
    private _firebaseService: FirebaseService,
    public media: MediaService,
  ) {}

  ngOnInit() {
    this._sub.push(
      this.formGroup.valueChanges.subscribe(
        (data: IFormGiftSpecialData): void => {
          this.data = this.formGroup.valid ? data : undefined;
        },
      ),
      this._firebaseService.userData$.subscribe((userData) => {
        const adminPassword = userData?.adminPassword;

        this.formGroup.controls.password.setValue(adminPassword);
      }),
    );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }
}
