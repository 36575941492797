import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';
import { OrderService } from 'src/app/services/order.service';
import { UserService } from 'src/app/services/user.service';
import {
  BEToFEWrappingName,
  FEToBEProduct,
  FEToBEWrappingName,
  getBookDataSpecs,
  getCalculatorItems,
  getCover,
  getProduct,
  getProductPrice,
} from 'src/app/functions';
import type { Cover, Product, Wrapping } from 'src/app/interfaces';
import { LIMIT_BOOKS_TO_ORDER } from '@shared/constants';
import { Logger } from 'src/app/services/logger.service';
import { MatDialog } from '@angular/material/dialog';
import { RoutingService } from 'src/app/services/routing.service';
import Subscriber from 'src/app/subscriber';
import { wrappings } from 'src/app/static';
import { LoaderService } from 'src/app/services/loader.service';
import { DiscountService } from 'src/app/services/discount.service';
import { getBooksDiscount } from '@shared/discount/utils';
import { filter, map, take } from 'rxjs/operators';
import type { IOrderData, TCouponData } from '@shared/interfaces';
import { ProductionService } from 'app/services/production.service/production.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { SHIPPING_METHOD_STANDART } from '@shared/shipping/constants';
import type { IShipping } from 'app/data/shipping/types';
import type { TBookCover } from '@shared/book/interfaces';
import { DeleteProductDialogComponent } from '../delete-product-dialog/delete-product-dialog.component';

const log = new Logger('SectionCartComponent');

@Component({
  selector: 'app-section-cart',
  templateUrl: './section-cart.component.html',
  styleUrls: ['./section-cart.component.scss'],
})
export class SectionCartComponent implements OnInit {
  // public orderId$ = new ReplaySubject<IOrderData['orderId']>(1);
  public order$ = new BehaviorSubject<IOrderData | undefined>(undefined);

  public products$ = this.userService.booksInCartList$.pipe(
    map((books) => {
      log.info('load: _userServiceBooksList map');

      return books.map((book) =>
        getProduct({
          alias: book.alias,
          bookId: book.bookId,
          hero: book.heroName,
          gender: 'gender' in book ? book.gender : undefined,
          children:
            'grandchildren' in book
              ? book.grandchildren.data.map(({ childrenName }) => childrenName)
              : undefined,
          wrapping:
            book.wrapping &&
            wrappings.find((w) => w.name === BEToFEWrappingName(book.wrapping)),
          cover: getCover(book.alias, book.cover),
          specs: getBookDataSpecs(book, ['Book format']),
        }),
      );
    }),
  );

  public readonly getBooksDiscount = getBooksDiscount;

  public isLimitExceeded$ = this.products$.pipe(
    map((products) => products.length > LIMIT_BOOKS_TO_ORDER),
  );

  public isLoading$ = new BehaviorSubject(false);

  public isProceed$ = combineLatest([
    this.products$,
    this.isLimitExceeded$,
    this.isLoading$,
  ]).pipe(
    map(
      ([products, isLimitExceeded, isLoading]) =>
        !isLoading && !!products.length && !isLimitExceeded,
    ),
  );

  public shipping$ = this._productionService
    .getShippings$(
      this.products$.pipe(
        map((products) => ({
          booksCount: products.length || 1,
          regionName: 'Maryland',
        })),
      ),
    )
    .pipe(
      map((shippings) =>
        shippings.find(({ id }) => id === SHIPPING_METHOD_STANDART),
      ),
      filter((method): method is IShipping => !!method),
    );

  public booksInOrder$ = this.products$.pipe(
    map((products) => products.map(FEToBEProduct)),
  );

  /** updated by `<app-coupon>` */
  public coupon$ = new BehaviorSubject<TCouponData | undefined>(undefined);

  public calculatorItems$ = combineLatest([
    this.products$,
    this.discountService.activeDiscountCampaignsObservable,
    this.coupon$,
    this.shipping$,
  ]).pipe(
    map(([products, discountCampaign, coupon, shipping]) =>
      getCalculatorItems(products, discountCampaign, coupon, shipping),
    ),
  );

  public coverNameMap: { [key in TBookCover]: string } = {
    hard: 'Hard Cover',
    soft: 'Soft Cover',
  };

  public readonly LIMIT_BOOKS_TO_ORDER = LIMIT_BOOKS_TO_ORDER;

  private _sub = new Subscriber();

  constructor(
    public router: Router,
    public userService: UserService,
    public discountService: DiscountService,
    private _orderService: OrderService,
    private _firebaseService: FirebaseService,
    private _dialog: MatDialog,
    private _routing: RoutingService,
    private _loaderService: LoaderService,
    private _productionService: ProductionService,
  ) {}

  public readonly getProductPrice = getProductPrice;

  async ngOnInit() {
    const orderId = await this.createOrder();
    const orderRef = await this._firebaseService.getUserOrderRef(orderId);

    this._sub.push({
      unsubscribe: orderRef.onSnapshot((snap) => {
        this.order$.next(snap.data());
      }),
    });
  }

  // ngOnDestroy() {}

  public async removeProduct(product: Product) {
    this._dialog
      .open(DeleteProductDialogComponent, {
        data: {
          product,
        },
      })
      .afterClosed()
      .subscribe(async (result) => {
        log.info(`Dialog result: ${result}`);

        if (result) {
          await this._firebaseService.deleteBook(product.bookId);
        }
      });
  }

  public async selectWrapping(p: Product, name: Wrapping['name']) {
    const wrapping = p.wrappings.find((w) => w.name === name);

    if (!wrapping) {
      log.error(
        'selectWrapping - wrapping not found by name',
        name,
        'in product',
        p,
      );
      return;
    }

    await this._firebaseService.updateBookWrapping(
      p.bookId,
      FEToBEWrappingName(name),
    );
  }

  public async selectCover(p: Product, name: Cover['name']) {
    const cover = p.covers.find((c) => c.name === name);

    if (!cover) {
      log.error('selectCover - cover not found by name', name, 'in product', p);
      return;
    }

    await this._firebaseService.updateBookCover(p.bookId, name);
  }

  public addAnotherBookButtonClick(): void {
    // log.info('addAnotherBookButtonClick')
    this._routing.goToAllBooks();
  }

  /**
   * Navigates to order page. Make sure you do check by isProceed
   * before triggering this funciton.
   */
  async proceed() {
    this.isLoading$.next(true);

    const orderData = await this.order$.pipe(take(1)).toPromise();
    let orderId = orderData?.orderId;

    this._loaderService.show();

    if (orderId) {
      await this.updateOrder(orderId);
    } else {
      orderId = await this.createOrder();
    }

    this.isLoading$.next(false);

    return this.router.navigate(['order', orderId]);
  }

  /**
   * Returns orderId and creates new order if we didn`t
   * have it before
   */
  private async createOrder() {
    this.isLoading$.next(true);

    const booksInOrder = await this.booksInOrder$.pipe(take(1)).toPromise();
    const orderId = await this._orderService.createOrder(booksInOrder);

    this.isLoading$.next(false);

    return orderId;
  }

  private async updateOrder(orderId: IOrderData['orderId']) {
    const booksInOrder = await this.booksInOrder$.pipe(take(1)).toPromise();

    await this._orderService.updateOrder(orderId, { books: booksInOrder });
  }
}
