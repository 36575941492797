import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-navigation-dropdown-item',
  templateUrl: './navigation-dropdown-item.component.html',
  styleUrls: ['./navigation-dropdown-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationDropdownItemComponent {
  @Input() imgSrc?: string;

  @Input() link?: string;

  constructor() {}
}
