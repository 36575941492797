import type firebase from 'firebase/app';

export const getCurrentAnonymousUser = (
  Auth: firebase.auth.Auth,
): firebase.User | undefined => {
  const anonymousUser = Auth.currentUser;

  if (!anonymousUser) {
    alert(`Anonymous user was not created, please try again.`);
    return;
  }

  if (!anonymousUser.isAnonymous) {
    alert(`User is not anonymous, sign-out first.`);
    return;
  }

  return anonymousUser;
};
