import {
  IBookInputNameToValueMap,
  IBookInputShowWhen,
  IBookInputValidatorWhen,
  TBookInput,
  TBookInputs,
} from './types';
import { IBookRadioInput } from './radio/types';
import { EBookInputType } from './shared';
import { IBookSelectInput } from './select/types';
import { IBookTextInput } from './text/types';
import { FormControl, Validators } from '@angular/forms';
import memoizee from 'memoizee';
import { IBookDateInput } from './date/types';
import { IBookRadioImageInput } from './radioImage/types';
import { IBookCheckboxInput } from './checkbox/types';
import { Logger } from '@shared/logger';
import { IBookGroupInput } from './group/types';
import { IBookCanvasInput } from './canvas/types';
import { IBookRadioColorInput } from './radioColor/types';
import { IBookRadioIconInput } from './radioIcon/types';
import { IBookMultiselectInput } from './multiselect/types';
import { completeAssign } from 'app/utils';
import { IBookRadioCoverInput } from './radioCover/types';

export const checkBookRadioInput = (
  input: TBookInput,
): input is IBookRadioInput => input.type === EBookInputType.radio;

export const checkBookRadioImageInput = (
  input: TBookInput,
): input is IBookRadioImageInput => input.type === EBookInputType.radioImage;

export const checkBookRadioCoverInput = (
  input: TBookInput,
): input is IBookRadioCoverInput => input.type === EBookInputType.radioCover;

export const checkBookRadioColorInput = (
  input: TBookInput,
): input is IBookRadioColorInput => input.type === EBookInputType.radioColor;

export const checkBookRadioIconInput = (
  input: TBookInput,
): input is IBookRadioIconInput => input.type === EBookInputType.radioIcon;

export const checkBookSelectInput = (
  input: TBookInput,
): input is IBookSelectInput => input.type === EBookInputType.select;

export const checkBookTextInput = (
  input: TBookInput,
): input is IBookTextInput => input.type === EBookInputType.text;

export const checkBookDateInput = (
  input: TBookInput,
): input is IBookDateInput => input.type === EBookInputType.date;

export const checkBookCheckboxInput = (
  input: TBookInput,
): input is IBookCheckboxInput => input.type === EBookInputType.checkbox;

export const checkBookGroupInput = (
  input: TBookInput,
): input is IBookGroupInput => input.type === EBookInputType.group;

export const checkBookCanvasInput = (
  input: TBookInput,
): input is IBookCanvasInput => input.type === EBookInputType.canvas;

export const checkBookMultiselectInput = (
  input: TBookInput,
): input is IBookMultiselectInput => input.type === EBookInputType.multiselect;

export const bookInputToRadio = (
  input: TBookInput,
): IBookRadioInput | undefined => {
  if (checkBookRadioInput(input)) {
    return input;
  }

  return undefined;
};

export const bookInputToRadioCover = (
  input: TBookInput,
): IBookRadioCoverInput | undefined => {
  if (checkBookRadioCoverInput(input)) {
    return input;
  }

  return undefined;
};

export const bookInputToRadioImage = (
  input: TBookInput,
): IBookRadioImageInput | undefined => {
  if (checkBookRadioImageInput(input)) {
    return input;
  }

  return undefined;
};

export const bookInputToRadioColor = (
  input: TBookInput,
): IBookRadioColorInput | undefined => {
  if (checkBookRadioColorInput(input)) {
    return input;
  }

  return undefined;
};

export const bookInputToRadioIcon = (
  input: TBookInput,
): IBookRadioIconInput | undefined => {
  if (checkBookRadioIconInput(input)) {
    return input;
  }

  return undefined;
};

export const bookInputToSelect = (
  input: TBookInput,
): IBookSelectInput | undefined => {
  if (checkBookSelectInput(input)) {
    return input;
  }

  return undefined;
};

export const bookInputToText = (
  input: TBookInput,
): IBookTextInput | undefined => {
  if (checkBookTextInput(input)) {
    return input;
  }

  return undefined;
};

export const bookInputToDate = (
  input: TBookInput,
): IBookDateInput | undefined => {
  if (checkBookDateInput(input)) {
    return input;
  }

  return undefined;
};

export const bookInputToCheckbox = (
  input: TBookInput,
): IBookCheckboxInput | undefined => {
  if (checkBookCheckboxInput(input)) {
    return input;
  }

  return undefined;
};

export const bookInputToGroup = (
  input: TBookInput,
): IBookGroupInput | undefined => {
  if (checkBookGroupInput(input)) {
    return input;
  }

  return undefined;
};

export const bookInputToCanvas = (
  input: TBookInput,
): IBookCanvasInput | undefined => {
  if (checkBookCanvasInput(input)) {
    return input;
  }

  return undefined;
};

export const bookInputToMultiselect = (
  input: TBookInput,
): IBookMultiselectInput | undefined => {
  if (checkBookMultiselectInput(input)) {
    return input;
  }

  return undefined;
};

export const inputToFormControl = memoizee((input: TBookInput): FormControl => {
  return new FormControl(input.value, input.validator || [Validators.required]);
});

export const inputsToFormControls = memoizee(
  (
    inputs: TBookInputs,
  ): {
    [formControlName: string]: FormControl;
  } => {
    const controls: { [formControlName: string]: FormControl } = {};

    inputs.forEach((input: TBookInput) => {
      controls[input.name] = inputToFormControl(input);
    });

    return controls;
  },
);

/**
 * mutates @param input and updates `showWhen` array
 * @default condition = (inputToCheckValue) => !!inputToCheckValue
 * @returns input
 */
export const bookInputShowWhen = <
  Input extends TBookInput,
  InputToCheck extends TBookInput,
>(
  input: Input,
  inputToCheck: InputToCheck,
  condition: IBookInputShowWhen<InputToCheck>['condition'] = (
    inputToCheckValue,
  ) => !!inputToCheckValue,
): Input => {
  input.showWhen = input.showWhen || [];

  input.showWhen.push({
    inputToCheck,
    condition,
  });

  return input;
};

const bookInputShowWhenCheckLog = new Logger('bookInputShowWhenCheck');
export const bookInputShowWhenCheck = (
  data: IBookInputNameToValueMap,
  showWhen?: IBookInputShowWhen[],
): boolean | undefined => {
  if (!showWhen) return true;

  const log = bookInputShowWhenCheckLog;

  return showWhen.every((_showWhen) => {
    const { inputToCheck, condition } = _showWhen;

    if (inputToCheck.name in data) {
      const value = data[inputToCheck.name];

      return condition(value);
    }

    log.warn(`inputToCheck doesn't exist`, { data, showWhen });
    /** fallback logic to skip missing inputs */
    return true;
  });
};

export const bookInputsToNameValueMap = (
  inputs: TBookInputs,
): IBookInputNameToValueMap =>
  inputs.reduce((map, input) => {
    map[input.name] = input.value;

    return map;
  }, {} as IBookInputNameToValueMap);

/**
 * mutates @param input and updates `showWhen` in `validatorWhen` array,
 * creates new if not exist
 * @param validator - validator to apply, should be the same object to add
 * more conditions
 * @default condition = (inputToCheckValue) => !!inputToCheckValue
 * @returns input
 */
export const bookInputValidatorWhen = <Input extends TBookInput>(
  input: Input,
  validatorWhen: IBookInputValidatorWhen[],
): Input => {
  input.validatorWhen = input.validatorWhen || [];

  input.validatorWhen.push(...validatorWhen);

  return input;
};

const bookInputValidatorWhenCheckLog = new Logger(
  'bookInputValidatorWhenCheck',
);
export const bookInputValidatorWhenCheck = (
  data: IBookInputNameToValueMap,
  validatorWhen: IBookInputValidatorWhen[],
): IBookInputValidatorWhen['validator'] | undefined => {
  if (!validatorWhen) return undefined;

  const log = bookInputValidatorWhenCheckLog;

  const validatorWhenItem = validatorWhen.find(({ showWhen }) =>
    bookInputShowWhenCheck(data, showWhen),
  );

  if (!validatorWhenItem) {
    log.error('validatorWhenItem not found', {
      data,
      validatorWhen,
    });

    return undefined;
  }

  return validatorWhenItem.validator;
};

export const bookInputValidatorWhenSet = (
  data: IBookInputNameToValueMap,
  input: TBookInput,
): TBookInput => {
  const validator =
    bookInputValidatorWhenCheck(data, input.validatorWhen) || input.validator;

  if (validator !== input.validator) {
    return completeAssign(
      {
        validator:
          bookInputValidatorWhenCheck(data, input.validatorWhen) ||
          input.validator,
      },
      input,
    );
  } else {
    return input;
  }
};
