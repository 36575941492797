<ng-container *ngIf="service.params$ | async as params">
  <app-book
    *ngIf="loaderParamsToBookLoaderParams(params) as params"
    [shown]="(service.shown$ | async) ? true : false"
    [title]="params.data.title"
    [progress]="params.data.progress"
    [loading]="(service.loading$ | async) ? true : false"
    (buttonClick)="service.confirm()"
  ></app-book>

  <app-default
    *ngIf="loaderParamsToDefaultLoaderParams(params)"
    [shown]="(service.shown$ | async) ? true : false"
  ></app-default>
</ng-container>
