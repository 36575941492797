<div class="wrap">
  <div class="wrap__mouse"></div>
  <app-card class="wrap__item">
    <div class="container">
      <div class="container__title">
        <div class="title">
          <img
            class="title__img"
            src="assets/images/icons/question-icon.png"
            alt="question image"
          />
          <p class="title__txt">FAQs</p>
        </div>
      </div>
      <hr class="container__hr" />
      <div class="container__text">
        <p class="text">You are lost? Maybe you can find your answer here!</p>
      </div>
      <div class="container__subtitle">
        <p
          class="subtitle"
          routerLink="/faq"
        >
          GO TO FAQs
        </p>
      </div>
    </div>
  </app-card>
  <app-card class="wrap__item">
    <div class="container">
      <div class="container__title">
        <div class="title">
          <img
            class="title__img"
            src="assets/images/icons/email-icon.png"
            alt="email image"
          />
          <p class="title__txt">EMAIL</p>
        </div>
      </div>
      <hr class="container__hr" />
      <div class="container__text">
        <p class="text">
          Our supportive fairies will get back to you within 1-2 business days!
        </p>
      </div>
      <div class="container__subtitle">
        <a
          class="subtitle"
          href="mailto:yay@hellobuki.com"
        >
          yay@hellobuki.com
        </a>
      </div>
    </div>
  </app-card>
</div>
