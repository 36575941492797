import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IBookRadioIconInputOption } from 'app/data/books/inputs/radioIcon/types';
import { EInputTheme } from '../../interfaces';

@Component({
  selector: 'app-input-radio-icon-option',
  templateUrl: './input-radio-icon-option.component.html',
  styleUrls: ['./input-radio-icon-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputRadioIconOptionComponent {
  @Input() imgSrc!: IBookRadioIconInputOption['imgSrc'];

  @Input() active?: boolean;

  @Input() theme!: EInputTheme;

  @Output() optionClick = new EventEmitter<void>();

  public readonly EInputTheme = EInputTheme;

  constructor() {}

  public onOptionClick(): void {
    this.optionClick.emit();
  }
}
