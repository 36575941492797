<div
  class="content"
  [formGroup]="formGroup"
>
  <div class="content__input">
    <app-input
      name="email"
      [label]="'Email Address' | translate"
      placeholder="sample@hellobuki.com"
      formControlName="email"
      type="email"
      [error]="EMAIL_ERROR"
      [theme]="EInputTheme.alt"
    ></app-input>
  </div>
  <div class="content__input">
    <app-input
      name="password"
      [label]="'Password' | translate"
      formControlName="password"
      type="password"
      [theme]="EInputTheme.alt"
    ></app-input>
  </div>
  <div class="content__button">
    <app-button
      (buttonClick)="onSignInButtonClick()"
      [fullWidth]="true"
      [disabled]="formGroup.invalid"
    >{{ 'Login' | translate }}</app-button>
  </div>
  <div class="content__button">
    <app-button
      [color]="EButtonColor['accent-2']"
      (buttonClick)="onSignUpButtonClick()"
      [fullWidth]="true"
    >{{ 'Create Account' | translate }}</app-button>
  </div>
  <div class="content__footer">
    <p class="footer-text">Forgot password? <a (click)="onResetButtonClick()">Click here!</a></p>
  </div>
</div>
