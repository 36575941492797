import Konva from 'konva';
import {
  IBookCanvasInputCrop,
  IBookCanvasInputItem,
} from 'app/data/books/inputs/canvas/types';
import { compareArraysRAC } from 'app/utils';
import { Logger } from 'app/services/logger.service';
import { Vector2d } from 'konva/types/types';

/** sets image to map if not exists */
export const itemToImage = (
  item: IBookCanvasInputItem,
  map: WeakMap<IBookCanvasInputItem, Konva.Image>,
  defaultWidth: number,
  defaultHeight: number,
) => {
  const { top, left, width, height, imgSrc } = item;

  return new Promise<Konva.Image>((resolve, reject) => {
    let image = map.get(item);

    if (image) {
      resolve(image);
      return;
    }

    const imgElement = new Image(width, height);

    imgElement.onload = () => {
      image = new Konva.Image({
        x: left,
        y: top,
        width: width || defaultWidth,
        height: height || defaultHeight,
        image: imgElement,
      });

      map.set(item, image);

      resolve(image);
    };

    imgElement.onerror = reject;

    imgElement.src = imgSrc;
  });
};

const updateCanvasItemsLog = new Logger('updateCanvasItems');
/**
 *
 * @param layer
 * @param itemToImageMap
 * @param prevItems
 * @param nextItems
 */
export const updateCanvasItems = async (
  layer: Konva.Layer,
  itemToImageMap: WeakMap<IBookCanvasInputItem, Konva.Image>,
  prevItems: IBookCanvasInputItem[],
  nextItems: IBookCanvasInputItem[],
  defaultWidth: number,
  defaultHeight: number,
) => {
  const log = updateCanvasItemsLog;
  const { removed, added, changed } = compareArraysRAC(
    prevItems,
    nextItems,
    'imgSrc',
  );

  log.info({
    removed,
    added,
    changed,
  });

  const _itemToImage = (item: IBookCanvasInputItem) =>
    itemToImage(item, itemToImageMap, defaultWidth, defaultHeight);

  const promises = [
    ...removed.map(async (item) => {
      const image = await _itemToImage(item);

      image.remove();

      log.info('removed', image, item);
    }),
    ...added.map(async (item) => {
      const image = await _itemToImage(item);

      layer.add(image);

      image.setZIndex(nextItems.indexOf(item));

      log.info('added', image, item);
    }),
    ...changed.map(async (item) => {
      const image = await _itemToImage(item);

      const { top, left, width, height } = item;

      image.setAttrs({
        x: left,
        y: top,
        width,
        height,
      });

      log.info('changed', image, item);
    }),
  ];

  await Promise.all(promises);

  layer.draw();
};

export const parseCrop = (
  crop?: IBookCanvasInputCrop,
  scale?: Partial<Vector2d>,
) => ({
  top: (crop?.top || 0) * (scale?.y || 1),
  right: (crop?.right || 0) * (scale?.x || 1),
  bottom: (crop?.bottom || 0) * (scale?.y || 1),
  left: (crop?.left || 0) * (scale?.x || 1),
  get x() {
    return this.left + this.right;
  },
  get y() {
    return this.top + this.bottom;
  },
});
