import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  EBookDescriptionTextItemType,
  IBookDescriptionItem,
} from 'src/app/data/books/desicription/types';

@Component({
  selector: 'app-description-item',
  templateUrl: './description-item.component.html',
  styleUrls: ['./description-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DescriptionItemComponent {
  @Input() imgSrc!: IBookDescriptionItem['imgSrc'];

  @Input() text!: IBookDescriptionItem['text'];

  @Input() imageFirst!: boolean;

  public EBookDescriptionTextItemType = EBookDescriptionTextItemType;

  constructor() {}
}
