import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './not-found.component';
import { SectionHeaderGapModule } from 'src/app/shared/section-header-gap/section-header-gap.module';
import { ButtonModule } from 'src/app/shared/button/button.module';

@NgModule({
  declarations: [NotFoundComponent],
  imports: [CommonModule, SectionHeaderGapModule, ButtonModule],
})
export class NotFoundModule {}
