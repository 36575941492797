import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { BehaviorSubject } from 'rxjs';
import { Logger } from './logger.service';
import type { TBookData } from '@shared/models';
import { filter, switchMapTo } from 'rxjs/operators';
import differenceInHours from 'date-fns/differenceInHours';

const log = new Logger('UserService');

enum EUserServiceInitStatus {
  none,
  progress,
  done,
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _initStatus$ = new BehaviorSubject(EUserServiceInitStatus.none);

  private _booksList$ = new BehaviorSubject<TBookData[]>([]);

  public booksList$ = this._initStatus$.pipe(
    filter((status) => status === EUserServiceInitStatus.done),
    switchMapTo(this._booksList$),
  );

  private _booksInCartList$ = new BehaviorSubject<TBookData[]>([]);

  public booksInCartList$ = this._initStatus$.pipe(
    filter((status) => status === EUserServiceInitStatus.done),
    switchMapTo(this._booksInCartList$),
  );

  constructor(private firebaseService: FirebaseService) {}

  get booksList(): TBookData[] {
    return this._booksList$.value;
  }

  set booksList(value: TBookData[]) {
    this._booksList$.next(value);
  }

  get booksInCartList(): TBookData[] {
    return this._booksInCartList$.value;
  }

  set booksInCartList(value: TBookData[]) {
    this._booksInCartList$.next(value);
  }

  public async init() {
    if (this._initStatus$.value !== EUserServiceInitStatus.none) {
      const msg = `_initStatus is ${this._initStatus$.value}`;

      log.error(msg);
      throw new Error(msg);
    }

    this._initStatus$.next(EUserServiceInitStatus.progress);

    this.firebaseService.userBooks$.subscribe((books) => {
      this.booksList = books;
      this._updateBooksInCartList(books);

      if (this._initStatus$.value !== EUserServiceInitStatus.done) {
        this._initStatus$.next(EUserServiceInitStatus.done);
      }
    });
  }

  private _updateBooksInCartList(books: TBookData[]): void {
    this.booksInCartList = books.reduce<TBookData[]>((_books, book) => {
      const { isReady, isDelete, createTimeStamp, gift } = book;

      if (!createTimeStamp || isDelete || !isReady || gift) return _books;

      const duration = differenceInHours(
        new Date(),
        book.createTimeStamp.toDate(),
      );

      if (duration > 24) return _books;

      _books.push(book);

      return _books;
    }, []);
  }
}
