import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductRadioCardComponent } from './product-radio-card.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { PriceModule } from 'src/app/shared/price/price.module';

@NgModule({
  declarations: [ProductRadioCardComponent],
  imports: [CommonModule, AngularSvgIconModule, TranslateModule, PriceModule],
  exports: [ProductRadioCardComponent],
})
export class ProductRadioCardModule {}
