import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RoutingService } from 'app/services/routing.service';

@Component({
  selector: 'app-section-banner-mermaid',
  templateUrl: './section-banner-mermaid.component.html',
  styleUrls: ['./section-banner-mermaid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionBannerMermaidComponent {
  constructor(private _routing: RoutingService) {}

  public onButtonClick(): void {
    // log.info('onButtonClick');
    this._routing.goToAbout();
  }
}
