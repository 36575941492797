<div
  class="container"
  [ngClass]="{
    'container_active': active
  }"
  (click)="onOptionClick()"
>
  <div class="container__img">
    <img
      [src]="imgSrc"
      alt="#"
      class="img"
    >
  </div>
  <div class="container__label">{{ label | translate }}</div>
  <div class="container__tick">
    <svg-icon
      class="tick"
      [ngClass]="{
        'tick_active': active
      }"
      src="assets/images/icons/book-check-button-tick.svg"
    ></svg-icon>
  </div>
</div>
