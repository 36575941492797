import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionMessageComponent } from './section-message.component';

@NgModule({
  declarations: [SectionMessageComponent],
  imports: [CommonModule],
  exports: [SectionMessageComponent],
})
export class SectionMessageModule {}
