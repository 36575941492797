import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IBookSelectInputOption } from 'src/app/data/books/inputs/select/types';

@Component({
  selector: 'app-select-image-text-option',
  templateUrl: './select-image-text-option.component.html',
  styleUrls: ['./select-image-text-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectImageTextOptionComponent {
  @Input() label!: IBookSelectInputOption['label'];

  @Input() imgSrc?: IBookSelectInputOption['imgSrc'];

  @Input() active?: boolean;

  @Output() optionClick = new EventEmitter<void>();

  constructor() {}

  public onOptionClick(): void {
    this.optionClick.emit();
  }
}
