import type { AfterContentInit, OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  forwardRef,
} from '@angular/core';
import type { ControlValueAccessor, FormControl } from '@angular/forms';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import type { IBookRadioImageInput } from 'app/data/books/inputs/radioImage/types';
import { checkControlShowError } from 'app/functions';
import { Logger } from 'app/services/logger.service';
import lowerFirst from 'lodash/lowerFirst';
import uniqueId from 'lodash/uniqueId';
import { EInputTheme } from '../../interfaces';

const log = new Logger('InputRadioImageComponent');

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputRadioImageComponent),
  multi: true,
};

@Component({
  selector: 'app-input-radio-image',
  templateUrl: './input-radio-image.component.html',
  styleUrls: ['./input-radio-image.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputRadioImageComponent
  implements OnInit, ControlValueAccessor, AfterContentInit
{
  @Input() set value(v: string) {
    if (v !== this._value) {
      log.info('value set', v);
      this._value = v;
      this.onChange(v);
      this.onTouched();
    }
  }

  get value(): string {
    return this._value;
  }

  @Input() public disabled = false;

  @Input() public label = '';

  @Input() public error = '';

  @Input() public options!: IBookRadioImageInput['options'];

  @Input() public theme: EInputTheme = EInputTheme.default;

  public id?: string;

  public control?: FormControl;

  private _value: string = '';

  get showError(): boolean {
    return checkControlShowError(this.control);
  }

  constructor(private injector: Injector) {}

  writeValue(value: string): void {
    if (value !== this._value) {
      this._value = value;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public onChange(value: string) {}

  public onTouched() {}

  ngOnInit(): void {
    setTimeout(() => (this.id = this.getId()));
  }

  // The form control is only set after initialization
  ngAfterContentInit(): void {
    this.getControl();
  }

  protected getControl() {
    const ngControl = this.injector.get(NgControl, null);

    if (ngControl) {
      this.control = ngControl.control as FormControl;
    } else {
      // Component is missing form control binding
    }
  }

  protected getId(id: number | string = uniqueId()): string {
    return `${lowerFirst(this.constructor.name)}_${id}`;
  }
}
