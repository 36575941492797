<ol
  class="passage-ordered-list"
  [ngStyle]="{
    listStyleType: listStyleType
  }"
>
  <li
    class="passage-ordered-list__item"
    *ngFor="let item of items"
  >
    <app-passage-text [text]="item.text"></app-passage-text>
    <app-passage-ordered-list
      *ngIf="item.items"
      [items]="item.items"
    ></app-passage-ordered-list>
  </li>
</ol>
