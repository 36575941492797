<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div
        class="container__section container__section_label"
        *ngIf="label"
      >
        <app-label
          class="label"
          [forId]="forId"
          [innerHTML]="label"
        ></app-label>
      </div>
      <div class="container__section container__section_input">
        <ng-content></ng-content>
      </div>
      <div
        class="container__section container__section_error"
        *ngIf="error"
        [@slideY]
      >
        <app-error [error]="error"></app-error>
      </div>
    </div>
  </div>
</div>
