<div class="container">
  <div class="container__background"></div>
  <div class="container__content">
    <div class="content">
      <div class="content__caption">
        <h1 class="caption">Children Are The Heroes of The World</h1>
      </div>
      <div class="content__text">
        <p class="text">
          Our mission is to empower children through personalized books that
          inspire, educate, and bring joy. We believe that every child is
          special and deserves to be the hero of their own story.
        </p>
      </div>
    </div>
  </div>
</div>
