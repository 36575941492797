import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormTestButtonsComponent } from './form-test-buttons.component';
import { InputModule } from '../input/input.module';
import { ButtonModule } from '../button/button.module';

@NgModule({
  declarations: [FormTestButtonsComponent],
  imports: [CommonModule, InputModule, ButtonModule],
  exports: [FormTestButtonsComponent],
})
export class FormTestButtonsModule {}
