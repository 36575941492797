<section class="container">
  <div class="container__section">
    <app-product-radio-card
      [active]="(valueObservable | async) === EFormShipFromValue.buki"
      imgSrc="assets/images/ship-from-buki.png"
      caption="発送元をBÜKIにする"
      summary="発送伝票の発送元にはBÜKIが記載され、お客さまの住所等は贈り先には共有されません。<br>ご住所等を贈り先に知らせたくない場合はこちらをご利用ください。"
      imageSize="lg"
      (radioCardClick)="onElementClick(EFormShipFromValue.buki)"
    ></app-product-radio-card>
  </div>
  <div class="container__section">
    <app-product-radio-card
      [active]="(valueObservable | async) === EFormShipFromValue.customer"
      imgSrc="assets/images/ship-from-customer.png"
      caption="発送元をお客さまにする"
      summary="発送伝票の発送元には、上に入力されたお客さま情報（お名前・住所・電話番号）が記載されます。"
      imageSize="lg"
      (radioCardClick)="onElementClick(EFormShipFromValue.customer)"
    ></app-product-radio-card>
  </div>
</section>
