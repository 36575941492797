import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import Subscriber from 'src/app/subscriber';
import { Logger } from 'src/app/services/logger.service';
import { BehaviorSubject } from 'rxjs';
import { FirebaseService } from 'src/app/services/firebase.service';
import firebase from 'firebase/app';
// import { Router } from '@angular/router';
import FakeTimers from '@sinonjs/fake-timers';

const log = new Logger('TimeButtonComponent');
const sub = new Subscriber();

const LOCAL_STORAGE_KEY_FAKE_TIME = 'fakeTime';

@Component({
  selector: 'app-time-button',
  templateUrl: './time-button.component.html',
  styleUrls: ['./time-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeButtonComponent implements OnInit, OnDestroy {
  private _currentDate: Date | undefined | null = undefined;

  get currentDate(): Date | undefined {
    return this._currentDate || undefined;
  }

  set currentDate(date: Date | undefined) {
    const { _currentDate } = this;

    if ((date && date.getDate()) !== (_currentDate && _currentDate.getDate())) {
      this.localStorageFakeDate = date;
      this._currentDate = date;
      this.currentCurrentDateFormControl.setValue(date);
      this._setFirestoreFakeDate(date);
      this._setFakeTimers(date);
    }
  }

  private _adminPassword: string | undefined = undefined;

  get adminPassword(): string | undefined {
    return this._adminPassword;
  }

  set adminPassword(password: string | undefined) {
    if (password !== this._adminPassword) {
      this._adminPassword = password;
      this.adminPasswordFormControl.setValue(password);
      this._setFirestoreAdminPassword(password);
    }
  }

  get localStorageFakeDate(): Date | undefined {
    const time = localStorage.getItem(LOCAL_STORAGE_KEY_FAKE_TIME) || undefined;

    return time && new Date(Number(time));
  }

  set localStorageFakeDate(date: Date | undefined) {
    const key = LOCAL_STORAGE_KEY_FAKE_TIME;
    const prevDate = this.localStorageFakeDate;

    if (prevDate?.getTime() === date?.getTime()) {
      return;
    }

    if (date) {
      localStorage.setItem(key, date.getTime().toString());
    } else {
      localStorage.removeItem(key);
    }

    location.reload();

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw 'reloading';
  }

  public adminPasswordFormControl = new FormControl(this._adminPassword);

  public currentCurrentDateFormControl = new FormControl(this._currentDate);

  private _fakeTimersSubject = new BehaviorSubject<
    FakeTimers.InstalledClock | undefined
  >(undefined);

  public fakeTimersObservable = this._fakeTimersSubject.asObservable();

  get fakeTimers(): FakeTimers.InstalledClock {
    return this._fakeTimersSubject.value;
  }

  set fakeTimers(value: FakeTimers.InstalledClock) {
    if (value !== this._fakeTimersSubject.value) {
      this._fakeTimersSubject.next(value);
    }
  }

  constructor(
    private _firebaseService: FirebaseService, // private _router: Router,
  ) {
    const { localStorageFakeDate } = this;

    if (localStorageFakeDate) {
      this.currentDate = localStorageFakeDate;
    } else {
      // make a fakeDate request to receive it from parameters document
      // this._currentDate = null;
      // this._setFirestoreFakeDate(null);
      this._setFirestoreFakeDate(undefined);
    }
  }

  async ngOnInit(): Promise<void> {
    sub.push(
      // handle adminPassword input change
      this.adminPasswordFormControl.valueChanges.subscribe(
        (adminPassword: string) => {
          this.adminPassword = adminPassword;
        },
      ),

      // handle date input change
      this.currentCurrentDateFormControl.valueChanges.subscribe(
        (date: Date) => {
          this.currentDate = date;
        },
      ),

      this._firebaseService.userData$.subscribe((userData) => {
        const { fakeDate, adminPassword } = userData;

        // prevent reacting on the same value
        // if (fakeDate?.toDate().getTime() === this._currentDate?.getTime()) return;

        if (
          fakeDate &&
          fakeDate?.toDate().getTime() !== this._currentDate?.getTime()
        ) {
          const confirmResult = confirm(`
            Fake date found in firestore.
            Would you like to apply it?
            Time: ${fakeDate.toDate().toLocaleString()}.
          `);

          if (!confirmResult) {
            this.currentDate = undefined;
            return;
          }
        }

        this.currentDate = userData.fakeDate && userData.fakeDate.toDate();
        this.adminPassword = adminPassword;
      }),
    );
  }

  ngOnDestroy(): void {
    sub.unsubscribe();
  }

  public onResetButtonClick(): void {
    this.currentDate = undefined;
  }

  private _setFakeTimers(date: Date | undefined) {
    log.info('_setFakeTimers', { date });

    if (!date) {
      this.fakeTimers?.uninstall();
      this.fakeTimers = undefined;

      return;
    }

    this.fakeTimers = FakeTimers.install({
      now: date,
      shouldAdvanceTime: true,
      shouldClearNativeTimers: true,
    });
  }

  private async _setFirestoreFakeDate(date: Date | undefined | null) {
    if (date === undefined) {
      return this._firebaseService.setUserData(
        {
          // @ts-ignore
          fakeDate: firebase.firestore.FieldValue.delete(),
        },
        { merge: true },
      );
    }

    return this._firebaseService.setUserData(
      {
        fakeDate: date ? firebase.firestore.Timestamp.fromDate(date) : null,
      },
      { merge: true },
    );
  }

  private async _setFirestoreAdminPassword(adminPassword: string) {
    if (!adminPassword) {
      return this._firebaseService.setUserData(
        {
          // @ts-ignore
          adminPassword: firebase.firestore.FieldValue.delete(),
        },
        { merge: true },
      );
    }

    return this._firebaseService.setUserData(
      {
        adminPassword,
      },
      { merge: true },
    );
  }

  // private async _reloadComponent() {
  //   const {
  //     url,
  //     routeReuseStrategy: {
  //       shouldReuseRoute,
  //     },
  //     onSameUrlNavigation,
  //   } = this._router;

  //   this._router.routeReuseStrategy.shouldReuseRoute = () => false;
  //   this._router.onSameUrlNavigation = 'reload';

  //   await this._router.navigate([url]);

  //   this._router.routeReuseStrategy.shouldReuseRoute = shouldReuseRoute;
  //   this._router.onSameUrlNavigation = onSameUrlNavigation;
  // }
}
