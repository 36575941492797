import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HeaderComponent } from './components/header/header.component';
import { FormShippingMethodComponent } from './components/form-shipping-method/form-shipping-method.component';
import { ToggleCardsComponent } from './components/toggle-cards/toggle-cards.component';
import { ToggleCardsItemComponent } from './components/toggle-cards-item/toggle-cards-item.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SharedModule } from 'src/app/shared/shared.module';
import { SectionSummaryComponent } from './components/section-summary/section-summary.component';
import { SectionSummaryItemComponent } from './components/section-summary-item/section-summary-item.component';
import { FormPaymentModule } from 'src/app/shared/form-payment/form-payment.module';
import { TranslateModule } from '@ngx-translate/core';
import { ObserversModule } from '@angular/cdk/observers';
import { FormTestButtonsModule } from 'src/app/shared/form-test-buttons/form-test-buttons.module';
import { FormCustomerInfoModule } from 'app/shared/form-customer-info/form-customer-info.module';
import { FormShippingAddressModule } from 'app/shared/form-shipping-address/form-shipping-address.module';

@NgModule({
  declarations: [
    MainComponent,
    HeaderComponent,
    FormShippingMethodComponent,
    ToggleCardsComponent,
    ToggleCardsItemComponent,
    SectionSummaryComponent,
    SectionSummaryItemComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AngularSvgIconModule,
    SharedModule,
    TranslateModule,
    ObserversModule,
    FormTestButtonsModule,
    FormPaymentModule,
    FormCustomerInfoModule,
    FormShippingAddressModule,
  ],
  exports: [MainComponent],
})
export class MainModule {}
