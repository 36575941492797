import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TBookPoints } from 'src/app/data/books/points/types';

@Component({
  selector: 'app-points',
  templateUrl: './points.component.html',
  styleUrls: ['./points.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PointsComponent {
  @Input() points!: TBookPoints;

  @Input() customPoints?: TBookPoints;

  constructor() {}
}
