import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { EFormShipFromValue } from './form-ship-from.enum';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-form-ship-from',
  templateUrl: './form-ship-from.component.html',
  styleUrls: ['./form-ship-from.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormShipFromComponent {
  @Input() set value(value: EFormShipFromValue) {
    if (value !== this._valueSubject.value) {
      this._valueSubject.next(value);
      this.valueChange.emit(value);
    }
  }

  private _valueSubject = new BehaviorSubject<EFormShipFromValue>(
    EFormShipFromValue.buki,
  );

  public valueObservable = this._valueSubject.asObservable();

  @Output() valueChange = new EventEmitter<EFormShipFromValue>();

  public readonly EFormShipFromValue = EFormShipFromValue;

  constructor() {}

  public onElementClick(value: EFormShipFromValue): void {
    this.value = value;
  }
}
