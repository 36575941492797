import { Component, Input } from '@angular/core';
import type { ICalculatorComponentItem } from '../../calculator.component';

@Component({
  selector: 'app-order',
  templateUrl: './order.template.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent {
  @Input() items: ICalculatorComponentItem[] = [];

  @Input() total: number = 0;

  @Input() currency: string = '';

  // constructor() {}
}
