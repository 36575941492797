import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqItemComponent } from './components/faq-item/faq-item.component';
import { FaqGroupComponent } from './components/faq-group/faq-group.component';

@NgModule({
  declarations: [FaqItemComponent, FaqGroupComponent],
  imports: [CommonModule],
  exports: [FaqGroupComponent],
})
export class FaqModule {}
