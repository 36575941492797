import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import type { EButtonIcon } from './types';
import { EButtonColor, EButtonSize } from './types';
import { ESpinnerColor } from '../spinner/spinner.enum';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  /** @default EButtonColor.accent */
  @Input() color: keyof typeof EButtonColor = EButtonColor.accent;

  /** @default EButtonSize.md */
  @Input() size: keyof typeof EButtonSize = EButtonSize.md;

  @Input() icons: (keyof typeof EButtonIcon)[] = [];

  @Input() disabled = false;

  @Input() fullWidth = false;

  @Input() bordered = false;

  @Input() loading = false;

  /** default is `submit` */
  @Input() type: 'submit' | 'reset' | 'button' | 'menu' = 'submit';

  @Output() buttonClick = new EventEmitter<void>();

  get containerNgClass() {
    const arr = [
      `container_color-${this.color}`,
      `container_size-${this.size}`,
      ...this.icons.map((icon) => `container_icon-${icon}`),
    ];

    if (this.fullWidth) {
      arr.push('container_full-width');
    }

    if (this.bordered) {
      arr.push('container_bordered');
    }

    return arr;
  }

  get spinnerColor() {
    switch (this.color) {
      case EButtonColor['accent-alt']:
        return ESpinnerColor.dark;
      default:
        return ESpinnerColor.light;
    }
  }

  // constructor() {}

  public onButtonClick(): void {
    if (!this.disabled) this.buttonClick.emit();
  }
}
