<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <app-section-collaboration-header
        class="container__title"
        imageTitleType="contact"
        imageTitleText="お問い合わせ"
        [text]="['パーソナライズ絵本に興味があれば、是非お気軽にお問い合わせください。']"
      ></app-section-collaboration-header>
      <app-form-contacts
        class="container__form"
      ></app-form-contacts>
    </div>
  </div>
</div>
