<article class="container">
  <div class="container__header">
    <header class="header">
      <div class="header__photo">
        <img [src]="member.photoSrc" alt="#">
      </div>
      <div class="header__description">
        <div class="description">
          <div class="description__name">
            <div class="name">
              <h2 class="name__text">{{ member.name }}</h2>
              <div
                *ngIf="member.instagramSrc"
                class="name__link-icon"
              >
                <a
                  [href]="member.instagramSrc"
                  class="instagram-link"
                  target="_blank"
                >
                  <svg-icon
                    class="instagram-link__icon"
                    src="assets/images/icons/instagram.svg"
                    [applyClass]="true"
                  ></svg-icon>
                </a>
              </div>
            </div>
          </div>
          <p class="description__position">{{ member.position }}</p>
        </div>
      </div>
    </header>
  </div>
  <div class="container__specs">
    <div class="specs-table">
      <div *ngFor="let spec of member.specs" class="specs-table__row">
        <div class="spec">
          <h3 class="spec__name">{{ spec[0] }}</h3>
          <p class="spec__description">{{ spec[1] }}</p>
        </div>
      </div>
    </div>
  </div>
</article>
