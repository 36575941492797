<section class="wrap">
  <div class="wrap__character"></div>
  <div class="wrap__container">
    <div class="container">
      <h1 class="container__title">Thank you!</h1>
      <p
        fxHide.lt-md
        class="container__text"
      >
        Thank you for your order! We can’t wait to deliver our magic to your
        special child!
      </p>
      <div
        *ngIf="barOrderId"
        class="container__plate"
      >
        <div class="plate">
          <p class="plate__label">Order Number</p>
          <p class="plate__item">{{ barOrderId }}</p>
        </div>
      </div>
      <p
        *ngIf="barOrderId"
        class="container__text"
      >
        <span fxHide.lt-lg>
          Please take a note of the Order Number, so we can assist you in case
          you need help.
        </span>
        <span fxHide.gt-md>
          We recommend you to note the order ID or take a screen shot.
        </span>
      </p>
      <app-block-sns-card class="container__sns"></app-block-sns-card>
      <div class="container__button">
        <app-button
          class="button"
          type="button"
          color="accent-2"
          [fullWidth]="true"
          (buttonClick)="router.navigate(['/'])"
          >Home</app-button
        >
      </div>
    </div>
  </div>
</section>
