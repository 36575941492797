<div
  class="container"
  [ngClass]="{
    'container_condensed': styleCondensed
  }"
  [ngStyle]="{
    minWidth: label ? undefined : 0
  }"
  (click)="onOptionClick()"
>
  <div
    *ngIf="imgSrc"
    class="container__img"
    [ngStyle]="{
      marginTop: -(imgExpandTopPx||0) + 'px',
      marginRight: -(imgExpandRightPx||0) + 'px',
      marginBottom: -(imgExpandBottomPx||0) + -(styleCondensed ? 0 : 5) + 'px',
      marginLeft: -(imgExpandLeftPx||0) + 'px'
    }"
  >
    <img
      [src]="imgSrc"
      alt="#"
      class="img"
      [ngStyle]="{
        width: (imgExpandLeftPx||0) + 70 + (imgExpandRightPx||0) + 'px',
        height: (imgExpandTopPx||0) + 70 + (imgExpandBottomPx||0) + 'px'
      }"
    >
  </div>
  <div
    *ngIf="imgSrc"
    class="container__tick"
  >
    <i
      class="tick"
      [ngClass]="{
        'tick_hidden': !active
      }"
    ></i>
  </div>
  <div
    *ngIf="label"
    class="container__content"
  >
    <div
      class="content"
      [ngClass]="{
        'content_filled': active,
        'content_condensed': styleCondensed
      }"
      translate
    >{{ label }}</div>
  </div>
</div>
