import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { LogoModule } from 'src/app/components/logo/logo.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { NavigationItemComponent } from './components/navigation-item/navigation-item.component';
import { NavigationDropdownComponent } from './components/navigation-dropdown/navigation-dropdown.component';
import { NavigationDropdownItemComponent } from './components/navigation-dropdown-item/navigation-dropdown-item.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { BookButtonComponent } from './components/book-button/book-button.component';
import { TimeButtonComponent } from './components/time-button/time-button.component';
import { InputModule } from 'src/app/shared/input/input.module';
import { TopbarComponent } from './components/topbar/topbar.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { LocationPickerModule } from '../../shared/location-picker/location-picker.module';

@NgModule({
  declarations: [
    HeaderComponent,
    NavigationItemComponent,
    NavigationDropdownComponent,
    NavigationDropdownItemComponent,
    MenuItemComponent,
    BookButtonComponent,
    TimeButtonComponent,
    TopbarComponent,
  ],
  exports: [HeaderComponent],
  imports: [
    CommonModule,
    AngularSvgIconModule,
    TranslateModule,
    LogoModule,
    FlexLayoutModule.withConfig({ disableDefaultBps: true }),
    RouterModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    InputModule,
    LocationPickerModule,
  ],
})
export class HeaderModule {}
