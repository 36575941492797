import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqComponent } from './faq.component';
import { SectionFaqModule } from 'src/app/shared/section-faq/section-faq.module';
import { SectionFooterBannerModule } from 'src/app/shared/section-footer-banner/section-footer-banner.module';
import { SectionHeaderGapModule } from 'src/app/shared/section-header-gap/section-header-gap.module';

@NgModule({
  declarations: [FaqComponent],
  imports: [
    CommonModule,
    SectionFaqModule,
    SectionFooterBannerModule,
    SectionHeaderGapModule,
  ],
})
export class FaqModule {}
