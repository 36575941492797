import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class LinkComponent {
  @Input() linkSrc: string;

  @Input() text: string;

  @Output() buttonClick = new EventEmitter<void>();

  // constructor() {}

  public onButtonClick() {
    this.buttonClick.emit();
  }
}
