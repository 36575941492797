import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionBooksComponent } from './section-books.component';
import { ImageTitleModule } from '../image-title/image-title.module';
import { ProductModule } from '../product/product.module';
import { SwiperModule } from 'swiper/angular';
import { BooksComponent } from './components/books/books.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { BookComponent } from './components/book/book.component';

@NgModule({
  declarations: [
    SectionBooksComponent,
    BooksComponent,
    CarouselComponent,
    BookComponent,
  ],
  imports: [CommonModule, ImageTitleModule, ProductModule, SwiperModule],
  exports: [SectionBooksComponent],
})
export class SectionBooksModule {}
