import type { OnChanges, SimpleChanges } from '@angular/core';
import { Component, Input } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';

export interface ICalculatorComponentItem {
  name: string;
  price: number;
  currency: string;
}

export enum ECalculatorComponentType {
  cart,
  order,
  lite,
}

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
})
export class CalculatorComponent implements OnChanges {
  @Input() items!: ICalculatorComponentItem[];

  @Input() type: ECalculatorComponentType = ECalculatorComponentType.cart;

  public CalculatorComponentType = ECalculatorComponentType;

  public localItems: ICalculatorComponentItem[] = [];

  public total: number = 0;

  public currency: string = '';

  // constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes.items?.currentValue) {
        this.updateLocalItems(changes.items.currentValue);
        this.updateTotal(this.localItems);
      }
    }
  }

  private updateLocalItems(items: ICalculatorComponentItem[]) {
    this.localItems = cloneDeep(items).reduce<ICalculatorComponentItem[]>(
      (localItems, item) => {
        // looking for existing item by name
        const existIndex = localItems.map((i) => i.name).indexOf(item.name);

        if (existIndex > -1) {
          localItems[existIndex].price += item.price;
        } else {
          localItems.push(item);
        }

        return localItems;
      },
      [],
    );
  }

  private updateTotal(localItems: ICalculatorComponentItem[]) {
    this.total = localItems.reduce((total, item) => {
      total += item.price;

      return total;
    }, 0);

    if (localItems.length) {
      this.currency = localItems[0].currency;
    }
  }
}
