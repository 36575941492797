import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GiftUsePageComponent } from './gift-use-page.component';
import { ContentModule } from '../gift-thanks-page/components/content/content.module';
import { SectionGiftHeaderComponent } from './components/section-gift-header/section-gift-header.component';
import { ImageTitleModule } from 'src/app/shared/image-title/image-title.module';
import { SectionHeaderGapModule } from 'src/app/shared/section-header-gap/section-header-gap.module';
import { SectionGiftUseComponent } from './components/section-gift-use/section-gift-use.component';
import { InputModule } from 'src/app/shared/input/input.module';
import { ButtonModule } from 'src/app/shared/button/button.module';
import { BookFormDialogModule } from './components/book-form-dialog/book-form-dialog.module';

@NgModule({
  declarations: [
    GiftUsePageComponent,
    SectionGiftHeaderComponent,
    SectionGiftUseComponent,
  ],
  imports: [
    CommonModule,
    ContentModule,
    SectionHeaderGapModule,
    ImageTitleModule,
    InputModule,
    ButtonModule,
    BookFormDialogModule,
  ],
})
export class GiftUsePageModule {}
