import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PolicyComponent } from './policy.component';
import { SectionHeaderGapModule } from 'src/app/shared/section-header-gap/section-header-gap.module';
import { SectionTextModule } from 'src/app/shared/section-text/section-text.module';
import { SectionFooterBannerModule } from 'src/app/shared/section-footer-banner/section-footer-banner.module';

@NgModule({
  declarations: [PolicyComponent],
  imports: [
    CommonModule,
    SectionHeaderGapModule,
    SectionTextModule,
    SectionFooterBannerModule,
  ],
})
export class PolicyModule {}
