import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentComponent } from './content.component';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'src/app/shared/button/button.module';
import { CopyPlateComponent } from './components/copy-plate/copy-plate.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BlockSnsCardComponent } from './components/block-sns-card/block-sns-card.component';
import { CardModule } from 'app/shared/card/card.module';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [ContentComponent, CopyPlateComponent, BlockSnsCardComponent],
  imports: [
    CommonModule,
    RouterModule,
    ButtonModule,
    MatSnackBarModule,
    CardModule,
    AngularSvgIconModule,
  ],
  exports: [ContentComponent],
})
export class ContentModule {}
