import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputCanvasComponent } from './input-canvas.component';
import { InputModule } from 'app/shared/input/input.module';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarCanvasComponent } from './components/avatar-canvas/avatar-canvas.component';

@NgModule({
  declarations: [InputCanvasComponent, AvatarCanvasComponent],
  imports: [CommonModule, InputModule, TranslateModule],
  exports: [InputCanvasComponent, AvatarCanvasComponent],
})
export class InputCanvasModule {}
