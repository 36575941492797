/**
 *
 * @param el - Element which position to control
 * @param offset - padding from the left and right side of the window
 */
export const controlPopupPosition = <T extends HTMLElement>(
  el: T,
  offset: number,
) => {
  const { innerWidth } = window;
  const { x, width } = el.getBoundingClientRect();
  /** from style attribute to exclude it from calculation */
  const marginLeft = parseInt(el.style.marginLeft, 10) || 0;
  /** x position in px from the left border to left window edge */
  const crossLeft = x - offset - marginLeft;
  /** x position in px from the right border to right window edge */
  const crossRight = x + offset + width - marginLeft - innerWidth;

  /** only negative */
  const gapLeft =
    crossLeft < 0
      ? crossLeft
      : crossLeft - crossRight < 0
      ? crossLeft - crossRight
      : 0;

  /** only positive */
  const gapRight =
    crossRight > 0
      ? crossRight
      : crossRight - crossLeft > 0
      ? crossRight - crossLeft
      : 0;

  /** value in px to compensate cross */
  const gap =
    gapLeft && gapRight
      ? /** using average value to position by center */
        (crossLeft * -1 + crossRight) / 2 - crossRight
      : gapLeft
      ? gapLeft * -1
      : gapRight
      ? gapRight * -1
      : 0;

  /** without average */
  // const gap = gapLeft ? gapLeft * -1 : gapRight ? gapRight * -1 : 0;

  return (el.style.marginLeft = `${gap}px`);
};
