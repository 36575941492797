<div
  class="container"
  [ngClass]="{
    container_final: isCompleted
  }"
>
  <app-section-full
    class="container__section"
    *ngIf="isCompleted"
  >
    <ng-container *ngTemplateOutlet="sectionLeft"></ng-container>
  </app-section-full>
  <app-section-left
    class="container__section container__section_left"
    *ngIf="!isCompleted"
  >
    <ng-container *ngTemplateOutlet="sectionLeft"></ng-container>
  </app-section-left>
  <app-section-right
    class="container__section container__section_right"
    *ngIf="!isCompleted && (order$ | async) as order"
  >
    <app-aside
      [books]="order.books"
      [shipping]="(shipping$ | async) || undefined"
      [coupon]="(coupon$ | async) || undefined"
    ></app-aside>
  </app-section-right>
  <app-header
    class="container__section container__section_header header"
    [step]="stepId"
    [steps]="steps"
    *ngIf="media.isActive('lt-xl')"
  ></app-header>
</div>

<ng-template #sectionLeft>
  <app-header
    class="header"
    [step]="stepId"
    [steps]="steps"
    *ngIf="media.isActive('gt-lg')"
  ></app-header>
  <app-main
    *ngIf="!isCompleted"
    class="main"
    [(step)]="stepId"
    (dataChange)="onChange($event)"
    [disabledShippings]="disabledShippings"
    [shippings]="(shippings$ | async) || undefined"
    [shipping]="(shipping$ | async) || undefined"
    (nextClick)="nextClick()"
    [isNextDisabled]="isNextDisabled"
  ></app-main>
  <app-final
    class="main"
    *ngIf="isCompleted"
    [barOrderId]="(order$ | async)?.barOrderId"
  ></app-final>
  <app-footer
    class="footer"
    [isFinal]="isFinalStep"
    [nextText]="isFinalStep ? 'Pay now' : 'Next'"
    [isCompleted]="isCompleted"
    [isBackDisabled]="isBackDisabled"
    [isNextDisabled]="isNextDisabled"
    (back)="backClick()"
    (next)="nextClick()"
  ></app-footer>
</ng-template>
