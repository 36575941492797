<div class="content">
  <div class="content__swiper">
    <swiper
      [navigation]="{
        prevEl: prevEl,
        nextEl: nextEl,
        disabledClass: 'navigation__item_disabled'
      }"
      [slidesPerView]="4"
      [spaceBetween]="25"
      [autoplay]="{
        delay: 1000
      }"
    >
      <ng-template
        *ngFor="let book of books"
        swiperSlide
      >
        <app-book
          [book]="book"
          (buttonClick)="onButtonClick(book.alias)"
        ></app-book>
      </ng-template>
    </swiper>
  </div>
  <div class="content__navigation">
    <div class="navigation">
      <button
        #prevEl
        class="navigation__item navigation__item_left"
      ></button>
      <button
        #nextEl
        class="navigation__item navigation__item_right"
      ></button>
    </div>
  </div>
</div>
