import { IBookRadioInput, TBookRadioInputOption } from './types';
import { EBookInputType, TGetBookInput } from '../shared';

export const getBookRadioInputOptionData = <
  T extends B,
  B extends TBookRadioInputOption,
>(
  params: T,
): Readonly<B & T> =>
  ({
    ...params,
  } as const);

export const getBookRadioInputData: TGetBookInput<IBookRadioInput> = (params) =>
  ({
    ...params,
    type: EBookInputType.radio,
  } as const);
