import { Component } from '@angular/core';
import { Reviews } from 'src/app/data/books';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent {
  public reviews = Reviews;

  // constructor() {}
}
