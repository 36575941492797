import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IArticlePassageListItem } from '../../section-text.interfaces';

@Component({
  selector: 'app-passage-ordered-list',
  templateUrl: './passage-ordered-list.component.html',
  styleUrls: ['./passage-ordered-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PassageOrderedListComponent {
  @Input() items!: IArticlePassageListItem[];

  @Input() listStyleType?: IArticlePassageListItem['listStyleType'];

  constructor() {}
}
