import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import type { TBookAlias } from '@shared/book/interfaces';
import { TBookCover, TBookWrapping } from '@shared/book/interfaces';
import type { IBook } from 'app/data/books/types';
import { BehaviorSubject } from 'rxjs';
import { slideY } from 'src/app/animations';
import { INPUT_VALUE_COVER_TYPE_SOFT } from '@shared/book/constants';

@Component({
  selector: 'app-section-gift-books',
  templateUrl: './section-gift-books.component.html',
  styleUrls: ['./section-gift-books.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideY()],
})
export class SectionGiftBooksComponent {
  @Input() books!: IBook[];

  @Input() set alias(value: TBookAlias | undefined) {
    if (this._alias$.value !== value) {
      this._alias$.next(value);
      this.aliasChange.emit(value);
    }
  }

  @Output() aliasChange = new EventEmitter<TBookAlias | undefined>();

  @Input() set cover(value: TBookCover) {
    if (this._cover$.value !== value) {
      this._cover$.next(value);
      this.coverChange.emit(value);
    }
  }

  @Output() coverChange = new EventEmitter();

  @Input() set wrapping(value: TBookWrapping) {
    if (this._wrapping$.value !== value) {
      this._wrapping$.next(value);
      this.wrappingChange.emit(value);
    }
  }

  @Output() wrappingChange = new EventEmitter();

  private _alias$ = new BehaviorSubject<TBookAlias | undefined>(undefined);

  public alias$ = this._alias$.asObservable();

  private _cover$ = new BehaviorSubject<TBookCover>(
    INPUT_VALUE_COVER_TYPE_SOFT,
  );

  public cover$ = this._cover$;

  private _wrapping$ = new BehaviorSubject<TBookWrapping>('standart');

  public wrapping$ = this._wrapping$.asObservable();

  // constructor() {}

  public onBookClick(alias: TBookAlias): void {
    this.alias = alias;
  }

  public onChangeClick(): void {
    this.alias = undefined;
  }
}
