import {
  DB_DISCOUNT_CAMPAIGNS,
  DB_PARAMETERS,
  DB_SYSTEM,
} from '@shared/constants';
import type { IDiscountCampaignFB } from '@shared/discount/interfaces';
import firebase from 'firebase/app';

export const getPercentFromValue = (v: number, p: number) => (v / 100) * p;

export const removePercentFromValue = (v: number, p: number) =>
  v - getPercentFromValue(v, p);

export const getDiscountCampaignsColRef = () =>
  firebase
    .firestore()
    .collection(DB_SYSTEM)
    .doc(DB_PARAMETERS)
    .collection(
      DB_DISCOUNT_CAMPAIGNS,
    ) as firebase.firestore.CollectionReference<IDiscountCampaignFB>;
