<div class="wrap">
  <app-section-header-gap></app-section-header-gap>
  <app-section-faq
    class="wrap__section-faq"
  ></app-section-faq>
  <app-section-footer-banner
    class="wrap__section-footer-banner"
  ></app-section-footer-banner>
  <i class="wrap__dino"></i>
</div>
