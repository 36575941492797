<div class="container">
  <div class="container__caption">
    <h4 class="caption">{{ caption | translate }}</h4>
  </div>
  <div class="container__photo">
    <img
      [src]="photo"
      alt="#"
      class="photo"
    />
  </div>
  <div class="container__summary">
    <div
      *ngFor="let summaryItem of summary"
      class="summary"
    >
      <p class="summary__item">{{ summaryItem }}</p>
    </div>
  </div>
  <div class="container__button">
    <app-button
      color="accent-alt"
      size="xs"
      (buttonClick)="onButtonClick()"
      [fullWidth]="true"
      ><b>Personalize right away!</b></app-button
    >
  </div>
</div>
