<div class="container">
  <!-- <app-section-message-date
    class="container__section container__section_date"
    size="lg"
    [isGift]="true"
  ></app-section-message-date> -->
  <div class="container__section">
    <div class="message">
      {{ (region$ | async).name }}への最短お届け日
      <strong>{{ expectedArrivalDate$ | async }}</strong>
      ※離島・一部山間部を除きます
    </div>
  </div>
  <div class="container__section">
    <div class="content">
      <div class="content__img">
        <i class="cat"></i>
      </div>
      <app-section-message
        class="content__msg"
        message="ネコポス（郵便ポスト受け取り・日時指定不可・追跡あり）でお届けします"
        type="sm"
        textAlign="left"
      ></app-section-message>
    </div>
  </div>
</div>
