import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { ISignUpData } from '../../auth-page.interfaces';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EButtonColor } from 'app/shared/button/types';
import { EInputTheme } from 'app/shared/input/interfaces';
import { Logger } from 'app/services/logger.service';
import { emailValidatorList } from 'app/validators';
import {
  EMAIL_ERROR,
  PASSWORD_MATCH_ERROR,
  REQUIRED_ERROR,
} from 'app/validators.constants';
import { createSecondFieldValidator } from 'app/validators.functions';

const log = new Logger('FormSignInComponent');

@Component({
  selector: 'app-form-sign-up',
  templateUrl: './form-sign-up.component.html',
  styleUrls: ['./form-sign-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSignUpComponent implements OnInit {
  @Output() signUpButtonClick = new EventEmitter<ISignUpData>();

  @Output() signInButtonClick = new EventEmitter<void>();

  public readonly formGroup = new FormGroup({
    email: new FormControl('', emailValidatorList),
    emailReceive: new FormControl(true),
    password: new FormControl('', [Validators.required]),
    password2: new FormControl('', [createSecondFieldValidator('password')]),
  });

  public readonly EButtonColor = EButtonColor;

  public readonly EInputTheme = EInputTheme;

  public readonly EMAIL_ERROR = EMAIL_ERROR;

  public readonly REQUIRED_ERROR = REQUIRED_ERROR;

  public readonly PASSWORD_MATCH_ERROR = PASSWORD_MATCH_ERROR;

  constructor() {}

  ngOnInit(): void {}

  public onSignUpButtonClick() {
    const data = this.formGroup.value as ISignUpData;

    log.info('onSignUpButtonClick', data);
    this.signUpButtonClick.emit(data);
  }

  public onSignInButtonClick() {
    log.info('onSignInButtonClick');
    this.signInButtonClick.emit();
  }
}
