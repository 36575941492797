<div class="wrap">
  <div class="wrap__photo">
    <app-photo
      [src]="photo"
      [alt]="title"
    ></app-photo>
  </div>
  <div class="wrap__description">
    <div class="description">
      <div class="description__title">
        <app-title
          [title]="title"
        ></app-title>
      </div>
      <div class="description__specs">
        <div class="description-specs">
          <div class="description-specs__specs" *ngIf="specs">
            <app-spec
              *ngFor="let key of specsKeys"
              [key]="key"
              [value]="specs[key]"
            ></app-spec>
          </div>
        </div>
      </div>
      <div
        *ngIf="price"
        class="description__price"
      >
        <app-price
          class="price"
          [price]="price.value"
          [currency]="price.currency"
          [discount]="discount"
        ></app-price>
      </div>
    </div>
  </div>
  <div
    *ngIf="price"
    class="wrap__price"
  >
    <app-price
      class="price"
      [price]="price.value"
      [currency]="price.currency"
      [discount]="discount"
      [type]="(isLtXl | async) ? 'H' : 'V'"
    ></app-price>
  </div>
</div>
