import {
  INPUT_VALUE_DISLIKE_SOGG_BATH,
  INPUT_VALUE_DISLIKE_SOGG_BUGS,
  INPUT_VALUE_DISLIKE_SOGG_CLEANING_UP,
  INPUT_VALUE_DISLIKE_SOGG_GHOST_AND_MONSTERS,
  INPUT_VALUE_DISLIKE_SOGG_SECRET,
  INPUT_VALUE_DISLIKE_SOGG_VEGGIES,
  INPUT_VALUE_ENGLISH,
  INPUT_VALUE_JAPANESE,
  INPUT_VALUE_LIKE_SOGG_ANIMALS,
  INPUT_VALUE_LIKE_SOGG_CRAFTING,
  INPUT_VALUE_LIKE_SOGG_DOLLS,
  INPUT_VALUE_LIKE_SOGG_DRAWING,
  INPUT_VALUE_LIKE_SOGG_EATING,
  INPUT_VALUE_LIKE_SOGG_INSECTS,
  INPUT_VALUE_LIKE_SOGG_MUSIC,
  INPUT_VALUE_LIKE_SOGG_PARK,
  INPUT_VALUE_LIKE_SOGG_READING,
  INPUT_VALUE_LIKE_SOGG_ROLE_PLAY,
  INPUT_VALUE_LIKE_SOGG_VEHICLES,
  INPUT_VALUE_LIKE_SOGG_WATER_ANIMALS,
  INPUT_VALUE_PLANETS_0,
  INPUT_VALUE_PLANETS_1,
  INPUT_VALUE_PLANETS_2,
  INPUT_VALUE_PLANETS_3,
  INPUT_VALUE_PLANETS_4,
  INPUT_VALUE_PLANETS_5,
} from './constants';

export const planetTypeArr = [
  INPUT_VALUE_PLANETS_0,
  INPUT_VALUE_PLANETS_1,
  INPUT_VALUE_PLANETS_2,
  INPUT_VALUE_PLANETS_3,
  INPUT_VALUE_PLANETS_4,
  INPUT_VALUE_PLANETS_5,
] as const;

export const languageArr = [INPUT_VALUE_ENGLISH, INPUT_VALUE_JAPANESE] as const;

export const likeSoggArr = [
  INPUT_VALUE_LIKE_SOGG_ROLE_PLAY,
  INPUT_VALUE_LIKE_SOGG_READING,
  INPUT_VALUE_LIKE_SOGG_DRAWING,
  INPUT_VALUE_LIKE_SOGG_MUSIC,
  INPUT_VALUE_LIKE_SOGG_ANIMALS,
  INPUT_VALUE_LIKE_SOGG_WATER_ANIMALS,
  INPUT_VALUE_LIKE_SOGG_INSECTS,
  INPUT_VALUE_LIKE_SOGG_CRAFTING,
  INPUT_VALUE_LIKE_SOGG_PARK,
  INPUT_VALUE_LIKE_SOGG_DOLLS,
  INPUT_VALUE_LIKE_SOGG_VEHICLES,
  INPUT_VALUE_LIKE_SOGG_EATING,
] as const;

export const dislikeSoggArr = [
  INPUT_VALUE_DISLIKE_SOGG_SECRET,
  INPUT_VALUE_DISLIKE_SOGG_VEGGIES,
  INPUT_VALUE_DISLIKE_SOGG_CLEANING_UP,
  INPUT_VALUE_DISLIKE_SOGG_BUGS,
  INPUT_VALUE_DISLIKE_SOGG_GHOST_AND_MONSTERS,
  INPUT_VALUE_DISLIKE_SOGG_BATH,
] as const;
