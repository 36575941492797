import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-tabs-nav-item',
  templateUrl: './tabs-nav-item.component.html',
  styleUrls: ['./tabs-nav-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsNavItemComponent {
  @Input() imgSrcActive!: string;

  @Input() imgSrcInactive!: string;

  @Input() title!: string;

  @Input() isActive!: boolean;

  @Input() isEven!: boolean;

  @Output() tabsNavItemClick = new EventEmitter<void>();

  constructor() {}

  public onTabsNavItemClick(): void {
    this.tabsNavItemClick.emit();
  }
}
