import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartComponent } from './cart.component';
import { SectionCartComponent } from './components/section-cart/section-cart.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SelectComponent } from './components/select/select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CouponComponent } from './components/coupon/coupon.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ProductModule } from './components/product/product.module';
import { SelectOptionModule } from './components/select-option/select-option.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DeleteProductDialogComponent } from './components/delete-product-dialog/delete-product-dialog.component';
import { SectionMessageModule } from 'src/app/shared/section-message/section-message.module';
import { SectionTitleModule } from 'src/app/shared/section-title/section-title.module';
import { InputModule } from 'src/app/shared/input/input.module';
import { CalculatorModule } from 'src/app/shared/calculator/calculator.module';
import { ImageTitleModule } from 'src/app/shared/image-title/image-title.module';
import { SectionHeaderGapModule } from 'src/app/shared/section-header-gap/section-header-gap.module';
import { SectionMessageDateModule } from 'src/app/shared/section-message-date/section-message-date.module';
import { ButtonModule } from 'src/app/shared/button/button.module';
import { ProductRadioCardModule } from 'app/shared/product-radio-card/product-radio-card.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    CartComponent,
    SectionCartComponent,
    SelectComponent,
    CouponComponent,
    PaymentsComponent,
    DeleteProductDialogComponent,
  ],
  imports: [
    CommonModule,
    AngularSvgIconModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ProductModule,
    SelectOptionModule,
    RouterModule,
    TranslateModule,
    SectionMessageModule,
    SectionTitleModule,
    InputModule,
    CalculatorModule,
    ImageTitleModule,
    SectionHeaderGapModule,
    SectionMessageDateModule,
    ButtonModule,
    ProductRadioCardModule,
    MatDialogModule,
    MatButtonModule,
  ],
})
export class CartModule {}
