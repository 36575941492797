import type { AfterContentInit, OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  forwardRef,
} from '@angular/core';
import type { ControlValueAccessor, FormControl } from '@angular/forms';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { Logger } from 'src/app/services/logger.service';
import { checkControlShowError } from 'src/app/functions';
import type { IBookRadioInput } from 'src/app/data/books/inputs/radio/types';
import type { Property } from 'csstype';
import uniqueId from 'lodash/uniqueId';
import lowerFirst from 'lodash/lowerFirst';

const log = new Logger('InputRadioComponent');

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputRadioComponent),
  multi: true,
};

@Component({
  selector: 'app-input-radio',
  templateUrl: './input-radio.component.html',
  styleUrls: ['./input-radio.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputRadioComponent
  implements OnInit, ControlValueAccessor, AfterContentInit
{
  @Input() set value(v: string) {
    if (v !== this._value) {
      log.info('value set', v);
      this._value = v;
      this.onChange(v);
      this.onTouched();
    }
  }

  get value(): string {
    return this._value;
  }

  @Input() public disabled = false;

  @Input() public label = '';

  @Input() public error = '';

  @Input() public options!: IBookRadioInput['options'];

  /** defines whether to show in condensed view or not */
  @Input() public styleCondensed = false;

  /** defines direction of options */
  // @Input() set styleVertical(value: InputRadioComponent['_styleVertical']) {
  //   this._styleVertical = value;

  //   // * backwards compatibility
  //   this._styleColumns = value ? 1 : 0; // https://jsben.ch/dYdis
  // }

  get styleVertical() {
    return this._styleVertical;
  }

  private _styleVertical = false;

  /**
   * defines columns
   * @default 0 - auto
   */
  @Input() set styleColumns(value: InputRadioComponent['_styleColumns']) {
    if (value && this.justifyContent) {
      log.error(
        'justifyContent will not take an effect when styleColumns is set',
      );
    }

    this._styleColumns = value;

    // * backwards compatibility
    this._styleVertical = !!value; // https://jsben.ch/1Zbj5
  }

  get styleColumns() {
    return this._styleColumns;
  }

  private _styleColumns = 0;

  @Input() set justifyContent(value: InputRadioComponent['_justifyContent']) {
    if (value && this._styleVertical) {
      log.error(
        'justifyContent will not take an effect when styleColumns is set',
      );
    }

    this._justifyContent = value;
  }

  get justifyContent() {
    return this._justifyContent;
  }

  _justifyContent?: Property.JustifyContent;

  public id?: string;

  public control?: FormControl;

  private _value: string = '';

  get showError(): boolean {
    return checkControlShowError(this.control);
  }

  constructor(private injector: Injector) {}

  writeValue(value: string): void {
    if (value !== this._value) {
      this._value = value;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public onChange(value: string) {}

  public onTouched() {}

  ngOnInit(): void {
    setTimeout(() => (this.id = this._getId()));
  }

  // The form control is only set after initialization
  ngAfterContentInit(): void {
    this._getControl();
  }

  private _getControl() {
    const ngControl = this.injector.get(NgControl, null);

    if (ngControl) {
      this.control = ngControl.control as FormControl;
    } else {
      // Component is missing form control binding
    }
  }

  private _getId(id: number | string = uniqueId()): string {
    return `${lowerFirst(this.constructor.name)}_${id}`;
  }
}
