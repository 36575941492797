<div class="wrap">
  <div class="wrap__photo">
    <app-photo
      [src]="photo"
      [alt]="title"
    ></app-photo>
  </div>
  <div class="wrap__description">
    <div class="description">
      <div class="description__title">
        <div class="title-wrap">
          <app-title
            class="title-wrap__title"
            [title]="title"
          ></app-title>
          <app-edit
            class="title-wrap__edit"
            [routerLink]="'/book/'+id"
          ></app-edit>
        </div>
      </div>
      <div class="description__specs">
        <app-spec
          *ngFor="let key of specsKeys"
          [key]="key"
          [value]="specs[key]"
        ></app-spec>
      </div>
      <div class="description__toggle">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
  <div class="wrap__right">
    <div class="right">
      <div class="right__x">
        <app-x-button
          (click)="onClose()"
        ></app-x-button>
      </div>
      <div class="right__price">
        <app-price
          [price]="price.value"
          [currency]="price.currency"
          [discount]="discount"
          [boldPrice]="true"
          type="V"
        ></app-price>
      </div>
    </div>
  </div>
</div>
