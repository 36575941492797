import { ChangeDetectionStrategy, Component } from '@angular/core';
// import { Logger } from 'src/app/services/logger.service';
import { RoutingService } from 'src/app/services/routing.service';

// const log = new Logger('ButtonSeeOtherComponent');

@Component({
  selector: 'app-button-see-other',
  templateUrl: './button-see-other.component.html',
  styleUrls: ['./button-see-other.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonSeeOtherComponent {
  constructor(private _routing: RoutingService) {}

  public onButtonClick(): void {
    // log.info('onButtonClick');
    this._routing.goToAllBooks();
  }
}
