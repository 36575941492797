import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import type { IFormData } from '../../interfaces';
import { FormCustomerInfoComponent } from 'app/shared/form-customer-info/form-customer-info.component';
import Regions from '@shared/shipping/data';
import type { TRegion } from '@shared/shipping/interfaces';
import { BehaviorSubject } from 'rxjs';
import { SHIPPING_METHOD_FAST } from '@shared/shipping/constants';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {
  @Input() touchEventEmitter?: EventEmitter<void>;

  @Output() dataChange = new EventEmitter<IFormData>();

  @ViewChild(FormCustomerInfoComponent)
  public formCustomerInfoComponent: FormCustomerInfoComponent;

  public formCustomerInfo: IFormData['formCustomerInfo'];

  public readonly environment = environment;

  get region(): TRegion | undefined {
    const form = this.formCustomerInfo;

    if (form) {
      return Regions[form.addressFirst];
    }

    return Regions.find(({ name }) => name === 'Washington');
  }

  shippingMethod$ = new BehaviorSubject(SHIPPING_METHOD_FAST);

  // constructor() {}

  public onChange() {
    const data: IFormData = {
      formCustomerInfo: this.formCustomerInfo,
    };

    this.dataChange.emit(data);
  }

  /**
   * filling the customer info form group with test data
   */
  public fillCustomerInfo(
    data: Partial<IFormData['formCustomerInfo']> = {},
  ): void {
    this.formCustomerInfoComponent.formGroup.setValue(
      Object.assign(this.formCustomerInfoComponent.formGroup.value || {}, data),
    );
  }
}
