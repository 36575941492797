<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div class="container__title">
        <app-image-title type="checkout">お会計</app-image-title>
      </div>
      <div
        *ngIf="
          type === EGiftOrderType.special &&
          (formGiftSpecialDataObservable | async) as formGiftSpecialData
        "
        class="container__form-gift-special"
      >
        <app-form-gift-special
          [data]="formGiftSpecialData"
          (dataChange)="onFormGiftSpecialDataChange($event)"
          [touchEventEmitter]="touchEventEmitter"
        ></app-form-gift-special>
      </div>
      <div
        class="container__test"
        *ngIf="!environment.production"
      >
        <app-form-test-buttons
          *ngIf="type === EGiftOrderType.standart"
          (customerInfoChange)="fillCustomerInfo($event)"
          (paymentDataChange)="onFormPaymentDataChange($event)"
        ></app-form-test-buttons>
        <app-form-test-buttons
          *ngIf="type === EGiftOrderType.special"
          (customerInfoChange)="fillCustomerInfo($event)"
        ></app-form-test-buttons>
      </div>
      <div
        *ngIf="formCustomerInfoDataObservable | async as formCustomerInfoData"
        class="container__form-customer-info"
      >
        <app-form-customer-info
          headerTitle="ご注文者さま情報"
          headerTitleContent="※贈り先ではなく、お支払いをされるご注文者さまの情報を記入してください。"
          [data]="formCustomerInfoData"
          (dataChange)="onFormCustomerInfoDataChange($event)"
          [touchEventEmitter]="touchEventEmitter"
        ></app-form-customer-info>
      </div>
      <div class="container__ship-from">
        <app-form-ship-from
          (valueChange)="onShipFromChange($event)"
        ></app-form-ship-from>
      </div>
      <div
        *ngIf="
          type === EGiftOrderType.standart &&
          (formPaymentDataObservable | async) as formPaymentData
        "
        class="container__form-payment"
      >
        <app-form-payment
          [data]="formPaymentData"
          (dataChange)="onFormPaymentDataChange($event)"
          [touchEventEmitter]="touchEventEmitter"
          [submitButtonDisabled]="
            (submitButtonDisabledObservable | async) || false
          "
          (submitButtonClick)="onSubmitButtonClick()"
          [isGift]="true"
        ></app-form-payment>
      </div>
      <div
        *ngIf="type === EGiftOrderType.special"
        class="container__submit"
      >
        <app-button
          (buttonClick)="onSubmitButtonClick()"
          [disabled]="(submitButtonDisabledObservable | async) || false"
          >Submit</app-button
        >
      </div>
    </div>
  </div>
</div>
