<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div class="container__section container__section_message">
        <p class="message">
          Do you need some assistance? <br />
          Please check FAQ or send us an e-mail.
        </p>
      </div>
      <div class="container__section container__section_card">
        <app-block-email-phone-card></app-block-email-phone-card>
      </div>
    </div>
  </div>
</div>
