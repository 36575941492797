import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { IFooterItem } from 'src/app/data/footer/types';
import { EMenuItemStyle } from './types';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemComponent {
  @Input() title?: IFooterItem['title'];

  @Input() link?: IFooterItem['link'];

  @Input() href?: IFooterItem['href'];

  @Input() svgIconSrc?: IFooterItem['svgIconSrc'];

  @Input() target: IFooterItem['target'];

  @Input() styleMod = EMenuItemStyle.default;

  get menuItemModArr(): string[] {
    const styleMod = this.styleMod ? `menu-item_style-${this.styleMod}` : '';

    return [styleMod];
  }

  // constructor() {}
}
