import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-section-ducks',
  templateUrl: './section-ducks.component.html',
  styleUrls: ['./section-ducks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionDucksComponent {
  constructor() {}
}
