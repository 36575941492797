import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-section-see-other',
  templateUrl: './section-see-other.component.html',
  styleUrls: ['./section-see-other.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionSeeOtherComponent {
  constructor() {}
}
