import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { IResetData } from '../../auth-page.interfaces';
import { FormControl, FormGroup } from '@angular/forms';
import { emailValidatorList } from 'app/validators';
import { EButtonColor } from 'app/shared/button/types';
import { EInputTheme } from 'app/shared/input/interfaces';
import { EMAIL_ERROR } from 'app/validators.constants';
import { Logger } from 'app/services/logger.service';

const log = new Logger('FormResetComponent');

@Component({
  selector: 'app-form-reset',
  templateUrl: './form-reset.component.html',
  styleUrls: ['./form-reset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormResetComponent implements OnInit {
  @Output() resetButtonClick = new EventEmitter<IResetData>();

  @Output() signInButtonClick = new EventEmitter<void>();

  public readonly formGroup = new FormGroup({
    email: new FormControl('', emailValidatorList),
  });

  public readonly EButtonColor = EButtonColor;

  public readonly EInputTheme = EInputTheme;

  public readonly EMAIL_ERROR = EMAIL_ERROR;

  constructor() {}

  ngOnInit(): void {}

  public onResetButtonClick() {
    const data = this.formGroup.value as IResetData;

    log.info('onResetButtonClick', data);
    this.resetButtonClick.emit(data);
  }

  public onSignInButtonClick() {
    log.info('onSignInButtonClick');
    this.signInButtonClick.emit();
  }
}
