import { Component } from '@angular/core';
import { RoutingService } from 'src/app/services/routing.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  constructor(private _routing: RoutingService) {}

  onHomeButtonClick() {
    this._routing.goToIndex();
  }

  onBooksButtonClick() {
    this._routing.goToAllBooks();
  }
}
