import {
  DB_PARAMETERS,
  DB_PRODUCTION,
  DB_SHARED,
  DB_SYSTEM,
} from '@shared/constants';
import type {
  IProductionFB,
  TShippingMap,
} from '@shared/production/interfaces';
import type { TRegionName } from '@shared/shipping/interfaces';
import type firebase from 'firebase/app';

export const getProductionDocRef = (db: firebase.firestore.Firestore) =>
  db
    .collection(DB_SYSTEM)
    .doc(DB_PARAMETERS)
    .collection(DB_SHARED)
    .doc(DB_PRODUCTION) as firebase.firestore.DocumentReference<IProductionFB>;

/** @throws { Error } */
export const getShortestShippingRegion = (shippingMap: TShippingMap) => {
  const regions = Object.keys(shippingMap);

  if (!regions.length) throw new Error('empty shipping map');

  let region: string;
  let shipping: number;

  for (let i = 0; i < regions.length; i++) {
    const _region = regions[i];
    const _value = shippingMap[_region];
    const _shipping = typeof _value === 'number' ? _value : _value[0];

    if (_shipping < shipping || !shipping) {
      shipping = _shipping;
      region = _region;
    }
  }

  if (!region) throw new Error('shortest region not found');

  return region as TRegionName;
};
