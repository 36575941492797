import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EImageTitleType } from 'src/app/shared/image-title/types';

@Component({
  selector: 'app-section-collaboration-header',
  templateUrl: './section-collaboration-header.component.html',
  styleUrls: ['./section-collaboration-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionCollaborationHeaderComponent {
  @Input() imageTitleType!: EImageTitleType;

  @Input() imageTitleText!: string;

  @Input() text!: string[];

  // constructor() {}
}
