import type { TSectionSteps } from './types';

export const sectionSteps: TSectionSteps = [
  {
    imgSrc: 'assets/images/step1.png',
    text: [
      'Customize your book in just a few',
      'clicks by entering the child’s name',
      'character, and birthday!',
    ],
  },
  {
    imgSrc: 'assets/images/step2.png',
    text: [
      'Preview your personalized picture',
      'book on our website and add a',
      'special touch with additional',
      'customization options.',
    ],
  },
  {
    imgSrc: 'assets/images/step3.png',
    text: [
      'Once you place your order, we’ll',
      'print your customized picture book ',
      'and deliver it to you with care.',
    ],
  },
];
