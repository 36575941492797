import type { AfterContentInit } from '@angular/core';
import {
  Component,
  HostListener,
  Injector,
  Input,
  forwardRef,
} from '@angular/core';
import type { ControlValueAccessor, FormControl } from '@angular/forms';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { EInputTheme, Value } from '../../interfaces';
import { Logger } from 'src/app/services/logger.service';

const log = new Logger('CheckboxComponent');

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxComponent),
  multi: true,
};

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class CheckboxComponent
  implements AfterContentInit, ControlValueAccessor
{
  @Input() public id?: string;

  @Input() public name?: string;

  @Input() set value(v: Value) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
      this.onTouched();
    }
  }

  get value(): Value {
    return this._value;
  }

  @Input() public disabled: boolean = false;

  @Input() public label?: string;

  @Input() public invalid?: boolean;

  @Input() public theme: EInputTheme = EInputTheme.default;

  private _value: Value = '';

  public control?: FormControl;

  public readonly EInputTheme = EInputTheme;

  constructor(private injector: Injector) {}

  ngAfterContentInit(): void {
    this._initControl();
  }

  // ngModel implementation
  writeValue(value: Value): void {
    if (value !== this._value) {
      this._value = value;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public onChange(value: Value) {}

  public onTouched() {}

  // functionality
  private toggle() {
    this.value = !this.value;
  }

  // on host click handler
  @HostListener('click', ['$event'])
  public onClick() {
    this.toggle();
  }

  private _initControl(): void {
    const control = this.getControl();

    if (control) {
      log.info('value set by control', control.value);
      this._value = control.value;

      control.valueChanges.subscribe((v: Value) => {
        log.info('value set by control', v);
        this._value = v;
      });
    } else {
      log.info('control is not defined');
    }
  }

  protected getControl(): FormControl | undefined {
    const ngControl = this.injector.get(NgControl, null);

    if (ngControl) {
      this.control = ngControl.control as FormControl;
    } else {
      // Component is missing form control binding
    }

    return this.control;
  }
}
