import { IBookCanvasInput } from './types';
import { EBookInputType, TGetBookInput } from '../shared';
import { getGroupValidatorList } from 'app/validators';

export const getBookCanvasInputData: TGetBookInput<
  IBookCanvasInput,
  'type' | 'validator'
> = (params) =>
  ({
    ...params,
    type: EBookInputType.canvas,
    validator: getGroupValidatorList(params.inputs),
  } as const);
