import Books from 'src/app/data/books';
import type { IBook } from 'app/data/books/types';
import type { TSectionSteps } from 'src/app/shared/section-steps/types';
import { getShippingFast } from 'src/app/data/shipping/utils';

/** TODO */
const shipping = getShippingFast('', 'Alabama', 0);

export const GiftBooks: IBook[] = Books.map((book) => {
  return {
    ...book,
    prices: book.prices.map((price) => {
      return [
        price[0],
        {
          ...price[1],
          value: price[1].value,
          shippingIncluded: shipping,
        },
      ];
    }),
  };
});

export const GiftSteps: TSectionSteps = [
  {
    imgSrc: 'assets/images/gift-step-1.png',
    text: ['贈りたい絵本のギフトコードを', '選択し、お会計をします。'],
  },
  {
    imgSrc: 'assets/images/gift-step-2.png',
    text: [
      'メールに記載のギフトコードを',
      '絵本を贈りたい相手にメールやSNSで送ります。',
    ],
  },
  {
    imgSrc: 'assets/images/gift-step-3.png',
    text: [
      'ギフトコードを受け取った方が',
      '子供の情報や住所をを入力して',
      'プレゼント完了です！',
    ],
  },
];
