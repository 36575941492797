import type { OnInit } from '@angular/core';
import { Component, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ROUTE_PATH_PARAM_ORDER_ID } from 'app/app-routing.constants';
import type { IGiftOrderPageResolverData } from './gift-order-page.resolver';
import { RoutingService } from 'app/services/routing.service';
import type { IOrderData } from '@shared/interfaces';
import { MediaObserver } from '@angular/flex-layout';
import { getError } from '@shared/utils';
import type { IFormData } from './interfaces';
import { OrderService } from 'app/services/order.service';
import { Logger } from 'app/services/logger.service';
import { Subscription } from 'rxjs';
import { scrollToSelector } from 'app/functions';
import { LoaderService } from 'app/services/loader.service';
import { FirebaseService } from 'app/services/firebase.service';

const log = new Logger('GiftOrderPageComponent');

@Component({
  templateUrl: './gift-order-page.component.html',
  styleUrls: ['./gift-order-page.component.scss'],
})
export class GiftOrderPageComponent implements OnInit {
  /** used to touch forms to make errors appear */
  public readonly touchEventEmitter = new EventEmitter<void>();

  public order!: IOrderData;

  private _form: IFormData = {};

  public step: 1 | 2 = 1;

  public steps = 1;

  public isNextDisabled = false;

  get isCompleted(): boolean {
    return this.step > this.steps;
  }

  private _sub = new Subscription();

  constructor(
    private _route: ActivatedRoute,
    private _routing: RoutingService,
    private _orderService: OrderService,
    private _firebaseService: FirebaseService,
    private _loaderService: LoaderService,
    public media: MediaObserver,
  ) {
    const { data, paramMap } = this._route.snapshot;

    const { order } = data.resolved as IGiftOrderPageResolverData;

    if (!paramMap.get(ROUTE_PATH_PARAM_ORDER_ID)) {
      this._routing.goToGiftOrder(order.orderId);
    }

    this._handleOrder(order);
  }

  async ngOnInit() {
    const { order } = this;
    const orderRef = await this._firebaseService.getUserOrderRef(order.orderId);

    this._sub.add({
      unsubscribe: orderRef.onSnapshot((snap) => {
        const _order = snap.data();

        if (_order) {
          log.info('order updated', _order);
          this._handleOrder(_order);
        }
      }),
    });
  }

  public onEditCartClick() {
    this._goToBook();
  }

  public onBackClick() {
    this._goToBook();
  }

  public async onNextClick() {
    const isValid = this._validate();

    log.info('onNextClick', { isValid });

    if (!isValid) return;

    this._loaderService.show();
    await this._updateOrderData();
  }

  public onFormChange(data: IFormData) {
    this._form = data;
    this.isNextDisabled = false;
  }

  private _goToBook() {
    const book = this.order.books[0];

    if (!book) {
      alert(getError('book not found').message);
      return;
    }

    const giftId = this.order.gift;

    if (!giftId) {
      alert(getError('gift not found').message);
      return;
    }

    this._routing.goToBook(book.bookId, giftId);
  }

  private _validate() {
    const isValid = !!this._form.formCustomerInfo;

    if (!isValid) {
      scrollToSelector('app-form-customer-info');
    }

    this.isNextDisabled = !isValid;
    this.touchEventEmitter.next();

    return isValid;
  }

  private async _updateOrderData() {
    const { formCustomerInfo } = this._form;
    const { order } = this;

    this.order.addressShippingData = formCustomerInfo;

    return this._orderService.updateOrder(order.orderId, order);
  }

  private _handleOrder(order: IOrderData) {
    this.order = order;

    if (order.error) {
      alert(order.error.message);
      this._routing.goToIndex();
      this._loaderService.hide();
      return;
    }

    if (order.barOrderId) {
      log.info('got barOrderId', new Date());
      this.step = 2;
      this._loaderService.hide();
    }
  }
}
