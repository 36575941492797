<div class="container">
  <div class="container__swiper">
    <swiper
      [navigation]="{
        prevEl: prevEl,
        nextEl: nextEl,
        disabledClass: 'navigation__item_disabled'
      }"
    >
      <ng-template
        *ngFor="let step of steps"
        swiperSlide
      >
        <app-step
          [imgSrc]="step.imgSrc"
          [text]="step.text"
        ></app-step>
      </ng-template>
    </swiper>
  </div>
  <div class="container__navigation">
    <div class="navigation">
      <button #prevEl class="navigation__item navigation__item_left"></button>
      <button #nextEl class="navigation__item navigation__item_right"></button>
    </div>
  </div>
</div>
