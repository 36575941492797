import type { UnionToIntersection } from './interfaces';
import type { SimpleChanges } from '@angular/core';
import isEqual from 'lodash/isEqual';
import FontFaceObserver from 'fontfaceobserver';
import { Logger } from '@shared/logger';

/**
 * Works the same as `Object.assign` but copies getters and setters from sources
 */
export const completeAssign = <T extends object, S extends any[]>(
  target: T,
  ...sources: S
) => {
  sources.forEach((source) => {
    const descriptors = Object.keys(source).reduce<PropertyDescriptorMap>(
      (_descriptors, key) => {
        const descriptor = Object.getOwnPropertyDescriptor(source, key);

        if (descriptor) {
          _descriptors[key] = descriptor;
        }

        return _descriptors;
      },
      {},
    );

    // by default, Object.assign copies enumerable Symbols too
    Object.getOwnPropertySymbols(source).forEach((sym) => {
      const descriptor = Object.getOwnPropertyDescriptor(source, sym);

      if (descriptor?.enumerable) {
        descriptors[sym as never as string] = descriptor;
      }
    });

    Object.defineProperties(target, descriptors);
  });

  return target as T & UnionToIntersection<S[number]>;
};

export const notFirstChanges = (changes: SimpleChanges) => {
  return Object.values(changes).every((change) => !change.firstChange);
};

/**
 * RA - Removed/Added from/to nextItems
 */
export const compareArraysRA = <T>(prevItems: T[], nextItems: T[]) => {
  const removed = prevItems.reduce((_removed, item) => {
    if (!nextItems.find((_item) => isEqual(_item, item))) {
      _removed.push(item);
    }

    return _removed;
  }, [] as T[]);

  const added = nextItems.reduce((_added, item) => {
    if (!prevItems.find((_item) => isEqual(_item, item))) {
      _added.push(item);
    }

    return _added;
  }, [] as T[]);

  return {
    removed,
    added,
  };
};

/**
 * RAC - Removed/Added/Changed from/to/with nextItems
 */
export const compareArraysRAC = <
  K extends string,
  T extends { [key in K]: any },
>(
  prevItems: T[],
  nextItems: T[],
  key: K,
) => {
  const removed = prevItems.reduce((_removed, item) => {
    if (!nextItems.find((_item) => _item[key] === item[key])) {
      _removed.push(item);
    }

    return _removed;
  }, [] as T[]);

  const { added, changed } = nextItems.reduce(
    (obj, item) => {
      const { added: _added, changed: _changed } = obj;

      const prevItem = prevItems.find((_item) => _item[key] === item[key]);

      if (!prevItem) {
        _added.push(item);
      } else if (!isEqual(item, prevItem)) {
        _changed.push(item);
      }

      return obj;
    },
    { added: [] as T[], changed: [] as T[] },
  );

  return {
    removed,
    added,
    changed,
  };
};

export const getNgClass = (base: string, ...modificators: string[]) => {
  const classArr: string[] = [base];

  modificators.forEach((modificator) => {
    classArr.push(`${base}_${modificator}`);
  });

  return classArr;
};

const preloadFontsLog = new Logger('preloadFonts');
export const preloadFonts = async (
  fonts: string[],
  /** triggers every time when font is loaded */
  cb?: (font: string) => void,
  symbols = 'おやすみ',
  timeout = 120000, // 120 seconds
) => {
  const log = preloadFontsLog;

  for (let i = 0; i < fonts.length; i++) {
    const font = fonts[i];
    const observer = new FontFaceObserver(font);

    try {
      await observer.load(symbols, timeout);
    } catch (e) {
      log.error(`Font ${font} is not loaded!`, e);
    }

    cb?.(font);
  }
};
