import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import Team from 'src/app/data/team/data';
import Subscriber from 'src/app/subscriber';
import { BehaviorSubject } from 'rxjs';
import { MediaObserver } from '@angular/flex-layout';

const sub = new Subscriber();

@Component({
  selector: 'app-section-team',
  templateUrl: './section-team.component.html',
  styleUrls: ['./section-team.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionTeamComponent implements OnInit, OnDestroy {
  public readonly data = Team;

  public isLtMd = new BehaviorSubject(false);

  constructor(private _media: MediaObserver) {}

  ngOnInit(): void {
    sub.push(
      this._media.asObservable().subscribe(() => {
        this._updateIsLtMd();
      }),
    );
  }

  ngOnDestroy(): void {
    sub.unsubscribe();
  }

  private _updateIsLtMd(): void {
    this.isLtMd.next(this._media.isActive('lt-md'));
  }
}
