import { IBook } from 'app/data/books/types';
import { TNavigationItemChildren } from '../../types';
import memoizee from 'memoizee';

export const bookToNavigationItemChild = memoizee((book: IBook) => ({
  title: book.caption,
  link: book.alias,
  imgSrc: book.menuPhoto,
}));

export const booksToNavigationItemChildren = (
  books: IBook[],
): TNavigationItemChildren => books.map(bookToNavigationItemChild);
