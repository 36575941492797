import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionGiftFaqComponent } from './section-gift-faq.component';
import { FaqModule } from '../faq/faq.module';

@NgModule({
  declarations: [SectionGiftFaqComponent],
  imports: [CommonModule, FaqModule],
  exports: [SectionGiftFaqComponent],
})
export class SectionGiftFaqModule {}
