import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { EImageTitleType } from './types';
import { imageTitleTypeImgSrcMap } from './data';

@Component({
  selector: 'app-image-title',
  templateUrl: './image-title.component.html',
  styleUrls: ['./image-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageTitleComponent {
  @Input() type!: keyof typeof EImageTitleType;

  get src(): string {
    return imageTitleTypeImgSrcMap[this.type];
  }

  // constructor() {}
}
