import type { Wrapping } from './interfaces';

export const wrappingDefault: Wrapping = {
  name: 'No',
  price: {
    value: 0,
    currency: '$',
  },
  label: 'Standart Wrapping',
};

export const wrappingFilled: Wrapping = {
  name: 'Gift Wrapping',
  price: {
    value: 300,
    currency: '$',
  },
  image: 'assets/images/cart-select-option-photo.jpg',
  description:
    'We will wrap the picture book in the wrapping paper, and we will deliver it with a ribbon. To one important book of the important child.',
};

export const wrappings: Wrapping[] = [wrappingFilled, wrappingDefault];

/** default product object */
// export const product: Product = {
//   alias: BOOK_ALIAS_STARRY_DREAM,
//   bookId: '',
//   type: '絵本',
//   name: '”ほしのゆめ”',
//   cover: 'soft',
//   hero: 'Yuri',
//   price: 3900,
//   currency: '円',
//   image: 'assets/images/cart-product-photo.jpg',
//   wrappings,
//   wrapping: wrappingDefault
// }
