export enum EBookPointType {
  default,
  additional,
}

export interface IBookPoint {
  caption: string;
  description: string;
  /** @default EBookPointType.default */
  type?: EBookPointType;
}

export type TBookPoints = IBookPoint[];
