import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ESpinnerColor } from './spinner.enum';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
  @Input() color = ESpinnerColor.light;

  public readonly ESpinnerColor = ESpinnerColor;

  constructor() {}
}
