import { EventEmitter } from 'events';

enum ESubscriberEvents {
  push = 'push',
  unsubscribe = 'unsubscribe',
}

type TUnsubscribable = { unsubscribe: () => void };

export default class Subscriber {
  static events = ESubscriberEvents;

  public on = new EventEmitter();

  private _subscriptions: TUnsubscribable[] = [];

  public push(...subscription: TUnsubscribable[]): void {
    this._subscriptions.push(...subscription);

    this.on.emit(Subscriber.events.push);
  }

  public unsubscribe(): void {
    this._subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this._subscriptions.length = 0;

    this.on.emit(Subscriber.events.unsubscribe);
  }
}
