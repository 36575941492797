import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import {
  ROUTE_PATH_404,
  ROUTE_PATH_ABOUT,
  ROUTE_PATH_ALL_BOOKS,
  ROUTE_PATH_AUTH,
  ROUTE_PATH_BOOK,
  ROUTE_PATH_BOOK_STARRY_DREAM,
  ROUTE_PATH_BOOK_WELCOME_TO_THE_WORLD,
  ROUTE_PATH_BOOK_WHOS_BIRTHDAY_TOMORROW,
  ROUTE_PATH_CART,
  ROUTE_PATH_COLLABORATION,
  ROUTE_PATH_CONTACTS,
  ROUTE_PATH_FAQ,
  ROUTE_PATH_GIFT_BUY,
  ROUTE_PATH_GIFT_ORDER,
  ROUTE_PATH_GIFT_THANKS,
  ROUTE_PATH_GIFT_USE,
  ROUTE_PATH_MY_PAGE,
  ROUTE_PATH_ORDER,
  ROUTE_PATH_ORDERCONTRACT,
  ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_UP_FINAL,
  ROUTE_PATH_PARAM_GIFT_ID,
  ROUTE_PATH_PARAM_ORDER_ID,
  ROUTE_PATH_POLICY,
  ROUTE_PATH_PREVIEW,
  ROUTE_PATH_TERMS,
  ROUTE_QUERY_PARAM_AGE,
  ROUTE_QUERY_PARAM_BOOK,
  ROUTE_QUERY_PARAM_GIFT,
  ROUTE_QUERY_PARAM_PURPOSE,
} from '../app-routing.constants';
import { TBookAlias } from '@shared/book/interfaces';
import { TPropertyPurpose } from 'app/data/books/properties/types';
import { TAuthQueryParams, TAuthType } from 'app/app-routing.interfaces';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  constructor(private _router: Router) {}

  /**
   * Returns a promise that:
   * - resolves to 'true' when navigation succeeds,
   * - resolves to 'false' when navigation fails,
   * - is rejected when an error happens.
   */
  public goToIndex() {
    return this._router.navigate(['/']);
  }

  /**
   * Returns a promise that:
   * - resolves to 'true' when navigation succeeds,
   * - resolves to 'false' when navigation fails,
   * - is rejected when an error happens.
   */
  public goToAbout() {
    return this._router.navigate([ROUTE_PATH_ABOUT]);
  }

  /**
   * Returns a promise that:
   * - resolves to 'true' when navigation succeeds,
   * - resolves to 'false' when navigation fails,
   * - is rejected when an error happens.
   */
  public goToTerms() {
    return this._router.navigate([ROUTE_PATH_TERMS]);
  }

  /**
   * Returns a promise that:
   * - resolves to 'true' when navigation succeeds,
   * - resolves to 'false' when navigation fails,
   * - is rejected when an error happens.
   */
  public goToPolicy() {
    return this._router.navigate([ROUTE_PATH_POLICY]);
  }

  /**
   * Returns a promise that:
   * - resolves to 'true' when navigation succeeds,
   * - resolves to 'false' when navigation fails,
   * - is rejected when an error happens.
   */
  public goToFAQ() {
    return this._router.navigate([ROUTE_PATH_FAQ]);
  }

  /**
   * Returns a promise that:
   * - resolves to 'true' when navigation succeeds,
   * - resolves to 'false' when navigation fails,
   * - is rejected when an error happens.
   */
  public goToContacts() {
    return this._router.navigate([ROUTE_PATH_CONTACTS]);
  }

  /**
   * Returns a promise that:
   * - resolves to 'true' when navigation succeeds,
   * - resolves to 'false' when navigation fails,
   * - is rejected when an error happens.
   */
  public goToGiftBuy() {
    return this._router.navigate([ROUTE_PATH_GIFT_BUY]);
  }

  /**
   * Returns a promise that:
   * - resolves to 'true' when navigation succeeds,
   * - resolves to 'false' when navigation fails,
   * - is rejected when an error happens.
   */
  public goToGiftUse(giftId: string) {
    return this._router.navigate([ROUTE_PATH_GIFT_USE, giftId]);
  }

  /**
   * Returns a promise that:
   * - resolves to 'true' when navigation succeeds,
   * - resolves to 'false' when navigation fails,
   * - is rejected when an error happens.
   */
  public goToGiftThanks(queryParams?: {
    [ROUTE_PATH_PARAM_ORDER_ID]?: string;
    [ROUTE_PATH_PARAM_GIFT_ID]?: string;
  }) {
    return this._router.navigate([ROUTE_PATH_GIFT_THANKS], { queryParams });
  }

  /**
   * Returns a promise that:
   * - resolves to 'true' when navigation succeeds,
   * - resolves to 'false' when navigation fails,
   * - is rejected when an error happens.
   */
  public goToCollaboration() {
    return this._router.navigate([ROUTE_PATH_COLLABORATION]);
  }

  /**
   * Returns a promise that:
   * - resolves to 'true' when navigation succeeds,
   * - resolves to 'false' when navigation fails,
   * - is rejected when an error happens.
   */
  public goToOrdercontract() {
    return this._router.navigate([ROUTE_PATH_ORDERCONTRACT]);
  }

  /**
   * Returns a promise that:
   * - resolves to 'true' when navigation succeeds,
   * - resolves to 'false' when navigation fails,
   * - is rejected when an error happens.
   */
  public goToCart() {
    return this._router.navigate([ROUTE_PATH_CART]);
  }

  /**
   * Returns a promise that:
   * - resolves to 'true' when navigation succeeds,
   * - resolves to 'false' when navigation fails,
   * - is rejected when an error happens.
   */
  public goToOrder(orderId: string) {
    return this._router.navigate([
      ROUTE_PATH_ORDER.replace('/:orderId', ''),
      orderId,
    ]);
  }

  /**
   * Returns a promise that:
   * - resolves to 'true' when navigation succeeds,
   * - resolves to 'false' when navigation fails,
   * - is rejected when an error happens.
   */
  public goToGiftOrder(orderId: string, extras?: NavigationExtras) {
    return this._router.navigate([ROUTE_PATH_GIFT_ORDER, orderId], extras);
  }

  public goToGiftOrderCreation(
    bookId: string,
    giftId: string,
    extras?: NavigationExtras,
  ) {
    return this._router.navigate(
      [ROUTE_PATH_GIFT_ORDER],
      Object.assign<NavigationExtras, NavigationExtras>(extras || {}, {
        queryParams: {
          [ROUTE_QUERY_PARAM_GIFT]: giftId,
          [ROUTE_QUERY_PARAM_BOOK]: bookId,
        },
      }),
    );
  }

  /**
   * Returns a promise that:
   * - resolves to 'true' when navigation succeeds,
   * - resolves to 'false' when navigation fails,
   * - is rejected when an error happens.
   */
  public goToBook(bookId: string, giftId?: string) {
    return this._router.navigate(
      [ROUTE_PATH_BOOK.replace('/:id', ''), bookId],
      {
        // replaceUrl: true,
        queryParams: {
          [ROUTE_QUERY_PARAM_GIFT]: giftId,
        },
      },
    );
  }

  /**
   * Returns a promise that:
   * - resolves to 'true' when navigation succeeds,
   * - resolves to 'false' when navigation fails,
   * - is rejected when an error happens.
   */
  public goToBookStarryDream(bookId: string) {
    return this._router.navigate([
      ROUTE_PATH_BOOK_STARRY_DREAM.replace('/:id', ''),
      bookId,
    ]);
  }

  /**
   * Returns a promise that:
   * - resolves to 'true' when navigation succeeds,
   * - resolves to 'false' when navigation fails,
   * - is rejected when an error happens.
   */
  public goToBookWelcomeToTheWorld(bookId: string) {
    return this._router.navigate([
      ROUTE_PATH_BOOK_WELCOME_TO_THE_WORLD.replace('/:id', ''),
      bookId,
    ]);
  }

  /**
   * Returns a promise that:
   * - resolves to 'true' when navigation succeeds,
   * - resolves to 'false' when navigation fails,
   * - is rejected when an error happens.
   */
  public goToBookWhosBirthdayTomorrow(bookId: string) {
    return this._router.navigate([
      ROUTE_PATH_BOOK_WHOS_BIRTHDAY_TOMORROW.replace('/:id', ''),
      bookId,
    ]);
  }

  /**
   * Returns a promise that:
   * - resolves to 'true' when navigation succeeds,
   * - resolves to 'false' when navigation fails,
   * - is rejected when an error happens.
   */
  public goToAllBooks(queryParams?: {
    [ROUTE_QUERY_PARAM_AGE]?: number;
    [ROUTE_QUERY_PARAM_PURPOSE]?: TPropertyPurpose;
  }) {
    return this._router.navigate([ROUTE_PATH_ALL_BOOKS], {
      queryParams,
    });
  }

  /**
   * Returns a promise that:
   * - resolves to 'true' when navigation succeeds,
   * - resolves to 'false' when navigation fails,
   * - is rejected when an error happens.
   */
  public goTo404() {
    return this._router.navigate([ROUTE_PATH_404]);
  }

  /**
   * Returns a promise that:
   * - resolves to 'true' when navigation succeeds,
   * - resolves to 'false' when navigation fails,
   * - is rejected when an error happens.
   */
  public goToPreview(alias: TBookAlias) {
    return this._router.navigate([
      ROUTE_PATH_PREVIEW.replace(':alias', ''),
      alias,
    ]);
  }

  public goToAuth(
    type: typeof ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_UP_FINAL,
    queryParams: Pick<TAuthQueryParams, 'email'>,
  ): Promise<boolean>;
  public goToAuth(
    type: Exclude<TAuthType, typeof ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_UP_FINAL>,
    queryParams?: TAuthQueryParams,
  ): Promise<boolean>;
  public goToAuth(type: TAuthType, queryParams?: TAuthQueryParams) {
    return this._router.navigate([ROUTE_PATH_AUTH, type], { queryParams });
  }

  public goToMyPage(orderId?: string) {
    const commands = [ROUTE_PATH_MY_PAGE];

    if (orderId) {
      commands.push(orderId);
    }

    return this._router.navigate(commands);
  }
}
