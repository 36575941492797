import { Component } from '@angular/core';
import Books, { Reviews } from 'src/app/data/books';

@Component({
  templateUrl: './all-books.component.html',
  styleUrls: ['./all-books.component.scss'],
})
export class AllBooksComponent {
  public readonly Books = Books;

  public readonly reviews = Reviews;

  public filteredBooks = this.Books;

  constructor() {}
}
