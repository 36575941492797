<ng-template #content>
  <svg-icon
    *ngIf="svgIconSrc"
    class="menu-item__svg-icon"
    [src]="svgIconSrc"
    [applyClass]="true"
  ></svg-icon>
  <span
    *ngIf="title"
    class="menu-item__text"
    translate
  >{{ title }}</span>
  <i
    *ngIf="children.length"
    class="menu-item__dropdown-icon"
  ></i>
  <span
    *ngIf="(isDropdownActive | async) && children.length"
    class="menu-item__dropdown"
    @slideY
  >
    <span class="menu-dropdown">
      <a
        *ngFor="let child of children"
        [routerLink]="child.link"
        class="menu-dropdown__item"
        translate
      >{{ child.title }}</a>
    </span>
  </span>
  <!-- <app-menu-dropdown
    *ngIf="(isDropdownActive | async) && children.length"
    @fade
  >
    <app-menu-dropdown-item
      *ngFor="let child of children"
      [imgSrc]="child.imgSrc"
    >{{ child.title }}</app-menu-dropdown-item>
  </app-menu-dropdown> -->
</ng-template>

<a
  *ngIf="link"
  class="menu-item"
  [routerLink]="link"
  [target]="target"
  translate
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<a
  *ngIf="href"
  class="menu-item"
  [href]="href"
  [target]="target"
  translate
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<span
  *ngIf="!href && !link"
  class="menu-item"
  translate
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</span>
