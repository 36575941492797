import type {
  BookWhosBirthdayTomorrowData,
  CommonBookData,
} from '@shared/models';
import type {
  TCharacterSD,
  TGender,
  TLanguage,
  TWhosCelebrating,
} from '@shared/book/interfaces';
import { MONTH_INDEX_TO_TIME_OF_THE_YEAR_MAP } from '@shared/book/maps';
import { BOOK_ALIAS_WHOS_BIRTHDAY_TOMORROW } from '@shared/book/constants';
import type { IBookCreate } from 'src/app/services/book-create/book-create';
import capitalize from 'lodash/capitalize';

export class WhosBirthdayTomorrowBookCreate
  implements IBookCreate<BookWhosBirthdayTomorrowData>
{
  private readonly ageTobe: {
    [keyLang in TLanguage]: { [keyAge in string]: string };
  } = {
    jp: {
      '1': '１さい',
      '2': '２さい',
      '3': '３さい',
      '4': '４さい',
      '5': '５さい',
      '6': '６さい',
      '7': '７さい',
      '8': '８さい',
      '9': '９さい',
    },
    en: {
      '1': '1st',
      '2': '2nd',
      '3': '3rd',
      '4': '4th',
      '5': '5th',
      '6': '6th',
      '7': '7th',
      '8': '8th',
      '9': '9th',
    },
  };

  createNewBook(
    uid: string,
    docId: string,
    heroName: string,
    birthMonth: string,
    birthDate: string,
    ageToBe: string,
    whosCelebrating: TWhosCelebrating,
    language: TLanguage,
    gender: TGender,
    character: TCharacterSD,
    serverTimestamp: any,
    common: Partial<CommonBookData> = {},
  ): BookWhosBirthdayTomorrowData {
    const newBook: BookWhosBirthdayTomorrowData = this.createEmptyBook();

    heroName = language === 'en' ? capitalize(heroName) : heroName;

    newBook.uid = uid;
    newBook.bookId = docId;
    newBook.heroName = heroName;
    newBook.birthMonth = birthMonth;
    newBook.birthDate = birthDate;
    newBook.ageToBe = ageToBe;
    newBook.whosCelebrating = whosCelebrating;
    newBook.language = language;
    newBook.gender = gender;
    newBook.character = character;
    newBook.createTimeStamp = serverTimestamp;
    newBook.steps.character.isDone = true;
    newBook.steps.ageToBeBirthTime.isDone = true;
    newBook.steps.whosCelebrating.isDone = true;
    newBook.timeOfTheYear =
      MONTH_INDEX_TO_TIME_OF_THE_YEAR_MAP[Number(birthMonth) - 1];
    newBook.steps.season.isDone = true;
    if (language == 'jp') {
      newBook.messageText =
        `${heroName}へ\n` +
        `\n` +
        `あしたは　とっても　とくべつなひ。\n` +
        `たのしくって　すてきな\n` +
        `いろいろなことを　たくさん　けいけんして\n${heroName}いちにちに　なりますように。\n` +
        `{{agetobe}}の　おたんじょうび　おめでとう！`;
    } else {
      newBook.messageText =
        `Dear ${heroName},\n\n` +
        `Tomorrow is a very special day.\n` +
        `I hope you have an enjoyable and wonderful day.\n` +
        `Happy {{agetobe}} birthday!\n`;
    }
    newBook.messageText = newBook.messageText.replace(
      '{{agetobe}}',
      this.ageTobe[language][ageToBe],
    );

    Object.assign(newBook, common);

    return newBook;
  }

  createEmptyBook(): BookWhosBirthdayTomorrowData {
    return {
      alias: BOOK_ALIAS_WHOS_BIRTHDAY_TOMORROW,
      steps: {
        character: {
          stepType: 'character',
          isDone: false,
        },
        message: {
          stepType: 'message',
          isDone: false,
        },
        ageToBeBirthTime: {
          stepType: 'ageToBeBirthTime',
          isDone: false,
        },
        season: {
          stepType: 'season',
          isDone: false,
        },
        whosCelebrating: {
          stepType: 'whosCelebrating',
          isDone: false,
        },
        photo: {
          stepType: 'photo',
          isDone: false,
        },
      },
      ageToBe: undefined,
      birthMonth: undefined,
      birthDate: undefined,
      bookId: undefined,
      createTimeStamp: undefined,
      gender: undefined,
      character: undefined,
      heroName: undefined,
      messageText: undefined,
      timeOfTheYear: undefined,
      uid: undefined,
      whosCelebrating: undefined,
      cover: 'soft',
      fileUploadStatus: 0,
      language: undefined,
      wrapping: 'standart',
    };
  }
}
