import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionCollaborationComponent } from './section-collaboration.component';
import { ButtonModule } from '../button/button.module';

@NgModule({
  declarations: [SectionCollaborationComponent],
  imports: [CommonModule, ButtonModule],
  exports: [SectionCollaborationComponent],
})
export class SectionCollaborationModule {}
