<ng-template #template>
  <div class="container">
    <div
      class="container__section container__section_key"
      *ngIf="key"
    >
      <div class="key">{{ key | translate }}</div>
    </div>
    <div
      class="container__section container__section_value"
      *ngIf="value"
    >
      <div
        class="value"
        [innerHTML]="value"
      ></div>
    </div>
    <div
      class="container__section container__section_change"
      *ngIf="change && change.observers && change.observers.length"
    >
      <div
        class="change"
        translate
        (click)="onChange()"
      >
        Change
      </div>
    </div>
  </div>
</ng-template>
