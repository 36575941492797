<app-field
  *ngIf="id$ | async as id"
  [label]="(media.isLtMdObservable | async) ? undefined : label"
  [error]="showError$ | async"
  [forId]="id"
  style="text-align: center"
>
  <div
    class="container"
    style="text-align: left"
  >
    <div class="container__form">
      <form
        class="form"
        [formGroup]="formGroup"
      >
        <div
          *ngFor="let input of inputs"
          [ngClass]="getFormItemClass(input)"
          @slideY
        >
          <app-input
            *ngIf="bookInputToText(input) as input"
            [name]="input.name"
            [placeholder]="input.placeholder | translate"
            [label]="input.label | translate"
            [formControl]="formGroup.controls[input.name]"
            [error]="input.error"
            [theme]="EInputTheme.alt"
          ></app-input>
          <app-input-date
            *ngIf="bookInputToDate(input) as input"
            [placeholder]="input.placeholder | translate"
            [label]="input.label | translate"
            [max]="input.max"
            [min]="input.min"
            [formControl]="formGroup.controls[input.name]"
            [theme]="EInputTheme.alt"
          ></app-input-date>
          <ng-container *ngIf="bookInputToSelect(input) as input">
            <ng-container [ngSwitch]="input.selectType">
              <app-select-image-text
                *ngSwitchCase="EBookSelectInputType.imageText"
                type="select"
                [name]="input.name"
                [placeholder]="input.placeholder | translate"
                [label]="input.label | translate"
                [error]="input.error"
                [options]="input.options"
                [formControl]="formGroup.controls[input.name]"
                [theme]="EInputTheme.alt"
              ></app-select-image-text>
              <app-input
                *ngSwitchDefault
                type="select"
                [name]="input.name"
                [placeholder]="input.placeholder | translate"
                [label]="input.label | translate"
                [formControl]="formGroup.controls[input.name]"
                [theme]="EInputTheme.alt"
              >
                <option
                  *ngFor="let option of input.options"
                  [value]="option.value"
                >
                  {{ option.label }}
                </option>
              </app-input>
            </ng-container>
          </ng-container>
          <app-input-radio
            *ngIf="bookInputToRadio(input) as input"
            [label]="input.label | translate"
            [formControl]="formGroup.controls[input.name]"
            [options]="input.options"
            [styleCondensed]="
              input.name === 'whos-celebrating' &&
              (media.isSmObservable | async)
            "
            [styleColumns]="
              (input.name === 'whos-celebrating' &&
                (media.isXsObservable | async) &&
                1) ||
              0
            "
            justifyContent="space-between"
          ></app-input-radio>
          <app-input-radio-image
            *ngIf="bookInputToRadioImage(input) as input"
            [label]="input.label | translate"
            [formControl]="formGroup.controls[input.name]"
            [options]="input.options"
            [theme]="EInputTheme.alt"
          ></app-input-radio-image>
          <app-input-radio-color
            *ngIf="bookInputToRadioColor(input) as input"
            [label]="input.label | translate"
            [formControl]="formGroup.controls[input.name]"
            [options]="input.options"
            [theme]="EInputTheme.alt"
            justifyContent="space-between"
          ></app-input-radio-color>
          <app-input-radio-icon
            *ngIf="bookInputToRadioIcon(input) as input"
            [label]="input.label | translate"
            [formControl]="formGroup.controls[input.name]"
            [options]="input.options"
            [theme]="EInputTheme.alt"
            justifyContent="space-between"
          ></app-input-radio-icon>
          <app-input
            *ngIf="bookInputToCheckbox(input) as input"
            [label]="input.label | translate"
            [formControl]="formGroup.controls[input.name]"
            type="checkbox"
            [theme]="EInputTheme.alt"
          ></app-input>
          <app-input-canvas
            *ngIf="bookInputToCanvas(input) as input"
            [label]="input.label | translate"
            [formControl]="formGroup.controls[input.name]"
            [error]="input.error"
          ></app-input-canvas>
        </div>
      </form>
    </div>
    <div
      class="container__canvas"
      [class.container__canvas_lt-md-background]="!noLtMdBackground"
    >
      <div class="canvas">
        <div
          *ngIf="media.isLtMdObservable | async"
          class="canvas__label"
          style="text-align: center"
        >
          <app-label [innerHTML]="label"></app-label>
        </div>
        <div
          class="canvas__content"
          [ngStyle]="
            borderRadius
              ? {
                  borderRadius: borderRadius,
                  overflow: 'hidden'
                }
              : undefined
          "
        >
          <app-avatar-canvas
            [width]="width"
            [height]="height"
            [crop]="getCurrentCrop(media.isLtMdObservable | async)"
            [items]="items"
            [value]="value"
          ></app-avatar-canvas>
        </div>
      </div>
    </div>
  </div>
</app-field>
