/* eslint-disable no-plusplus */
/* eslint-disable unused-imports/no-unused-vars */
import type { OnDestroy, OnInit } from '@angular/core';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MediaObserver } from '@angular/flex-layout';
import {
  getPaymentInformation,
  resetOnBeforeUnload,
  setOnBeforeUnload,
} from 'src/app/functions';
import { StripeService } from 'src/app/services/stripe.service';
import { Logger } from 'src/app/services/logger.service';
import { LoaderService } from 'src/app/services/loader.service';
import {
  FBEvents,
  FBTrack,
  GAGetEvents,
  GATrack,
  sendConversionEventByOrder,
} from 'src/analytics';
import Subscriber from 'src/app/subscriber';
import { DiscountService } from 'src/app/services/discount.service';
import { filter, map, switchMap, take } from 'rxjs/operators';
import type {
  IOrderData,
  TCheckoutData,
  TCouponData,
} from '@shared/interfaces';
import { RoutingService } from 'app/services/routing.service';
import type { TShippingMethod } from '@shared/shipping/interfaces';
import { SHIPPING_METHOD_STANDART } from '@shared/shipping/constants';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { ProductionService } from 'app/services/production.service/production.service';
import type { IShipping } from 'app/data/shipping/types';
import { getRegionByCode } from '@shared/shipping/utils';
import { FirebaseService } from 'app/services/firebase.service';
import { MainComponent } from './components/main/main.component';
import type { IFormData, IStep, TStepId } from './interfaces';
import { UserService } from '../../services/user.service';
import { OrderService } from '../../services/order.service';

const log = new Logger('OrderComponent');
const sub = new Subscriber();

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnInit, OnDestroy {
  @ViewChild(MainComponent) mainComponent!: MainComponent;

  private _order$ = new BehaviorSubject<IOrderData | undefined>(undefined);

  public order$ = this._order$.pipe(filter((_): _ is IOrderData => !!_));

  public coupon$ = new BehaviorSubject<TCouponData | undefined>(undefined);

  public checkout$ = new BehaviorSubject<TCheckoutData | undefined>(undefined);

  private _formData$ = new BehaviorSubject<IFormData>({
    formCustomerInfo: undefined,
    formShippingAddress: undefined,
    formShippingAddressActive: undefined,
    formShippingMethod: undefined,
    formPayment: undefined,
  });

  get formData() {
    return this._formData$.value;
  }

  set formData(formData: IFormData) {
    const prevFormData = this._formData$.value;

    this._formData$.next(formData);

    if (formData.formCustomerInfo !== prevFormData?.formCustomerInfo) {
      this._onCustomerInfoUpdate(formData.formCustomerInfo);
    }

    if (formData.formShippingMethod !== prevFormData?.formShippingMethod) {
      this._onShippingMethodUpdate(formData.formShippingMethod);
    }

    if (formData.formShippingAddress !== prevFormData?.formShippingAddress) {
      this._onShippingAdreessUpdate(formData.formShippingAddress);
    }
  }

  public disabledShippings: TShippingMethod[] = [];

  /** selected shipping */
  public shippings$ = this._productionService.getShippings$(
    combineLatest([this.order$, this._formData$]).pipe(
      map(([orderData, formData]) => {
        const state = formData.formShippingAddressActive
          ? formData.formCustomerInfo?.state
          : formData.formShippingAddress?.state;

        return {
          booksCount: orderData.books.length,
          regionName: state ? getRegionByCode(state).name : 'Maryland',
        };
      }),
    ),
  );

  public shippingMethod$ = new BehaviorSubject<TShippingMethod>(
    SHIPPING_METHOD_STANDART,
  );

  public shipping$ = this.shippingMethod$.pipe(
    switchMap((method) =>
      this.shippings$.pipe(
        map((shippings) => shippings.find(({ id }) => id === method)),
      ),
    ),
    filter((method): method is IShipping => !!method),
  );

  private _stepId: TStepId = 1;

  public readonly steps: IStep[] = [
    {
      id: 1,
      name: 'Customer Infromation',
    },
    {
      id: 2,
      name: 'Delivery',
    },
    {
      id: 3,
      name: 'Review & Pay',
    },
  ];

  public readonly totalSteps = this.steps.length;

  set stepId(step: IStep['id']) {
    const { totalSteps: steps } = this;

    // min max check including thankyou step
    if (step > 0 && step <= steps + 1) {
      this._stepId = step;

      // not including thankyou step
      if (step <= steps) {
        GATrack(GAGetEvents.getCheckoutStep(step));
      } else {
        this.order$.pipe(take(1)).subscribe((orderData) => {
          sendConversionEventByOrder(orderData);
          this.loaderService.hide();
        });
      }
    }

    document.documentElement.scrollTop = 0;
  }

  get stepId(): IStep['id'] {
    return this._stepId;
  }

  get isFirstStep(): boolean {
    return this.stepId === 1;
  }

  get isFinalStep(): boolean {
    return this.stepId === this.totalSteps;
  }

  get isCompleted(): boolean {
    return this.stepId > this.totalSteps;
  }

  get isBackDisabled(): boolean {
    return false; // this.isFirstStep
  }

  public isNextDisabled = false;

  constructor(
    private _productionService: ProductionService,
    private orderService: OrderService,
    public userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    public media: MediaObserver,
    public stripeService: StripeService,
    public loaderService: LoaderService,
    private _discountService: DiscountService,
    private _routing: RoutingService,
    private _firebaseService: FirebaseService,
  ) {}

  async ngOnInit() {
    this.loaderService.show();

    const orderId = this.route.snapshot.paramMap.get('orderId');

    if (!orderId) {
      alert(`Didn't manage to get orderId from paramMap!`);
      return;
    }

    const orderRef = await this._firebaseService.getUserOrderRef(orderId);
    const couponRef = await this._firebaseService.getStripeCouponRef(orderId);
    const checkoutRef = await this._firebaseService.getStripeCheckoutRef(
      orderId,
    );

    sub.push(
      {
        unsubscribe: orderRef.onSnapshot(async (snap) => {
          const order = snap.data();

          if (!order) {
            log.error('!order');
            return;
          }

          const isFirstLoad = !this._order$.value;

          log.info('order updated', order);
          this._order$.next(order);

          const { error, barOrderId, sessionId } = order;

          if (error) {
            alert(error?.message || error);
            this._routing.goToIndex();
            this.loaderService.hide();
            return;
          }

          // check if order is already paid
          if (barOrderId) {
            log.info('got barOrderId', barOrderId, new Date());
            this.stepId = 4;
            this.loaderService.hide();

            return;
          }
          if (sessionId) {
            // if we are here that means that checkout is created but user didn't pay yet
            return;
          }

          if (isFirstLoad) {
            this.loaderService.hide();
            this.stepId = 1;
            setOnBeforeUnload(`
              Thank you for checking out books!\n
              Can you please tell us how we can improve (or what issues you found)?\n
              We will send you a 5% discount coupon to your e-mail to thank you.
            `);
          }
        }),
      },
      {
        unsubscribe: couponRef.onSnapshot((snap) => {
          this.coupon$.next(snap.data());
        }),
      },
      {
        unsubscribe: checkoutRef.onSnapshot((snap) => {
          this.checkout$.next(snap.data());
        }),
      },
    );

    // if (currentShippingName === SHIPPING_PREMIUM_NAME) {
    //   this.disabledShippingNames.push(SHIPPING_REGULAR_NAME);
    // }

    FBTrack(FBEvents.initiateCheckout);
  }

  ngOnDestroy(): void {
    resetOnBeforeUnload();
    sub.unsubscribe();
  }

  public onChange(data: IFormData) {
    this.formData = data;
    this.isNextDisabled = false;
  }

  private _onCustomerInfoUpdate(
    formCustomerInfo?: IFormData['formCustomerInfo'],
  ): void {
    log.info('_onCustomerInfoUpdate', formCustomerInfo);
  }

  private _onShippingMethodUpdate(
    formShippingMethod?: IFormData['formShippingMethod'],
  ): void {
    log.info('_onShippingMethodUpdate', formShippingMethod);

    if (formShippingMethod) {
      this.shippingMethod$.next(formShippingMethod);
    }
  }

  private _onShippingAdreessUpdate(
    formShippingAddress?: IFormData['formShippingAddress'],
  ): void {
    log.info('_onShippingAdreessUpdate', formShippingAddress);
  }

  public backClick() {
    if (this.stepId === 1) {
      this.router.navigate(['/cart']);
    } else {
      this.stepId--;
    }

    console.log('backClick', this.stepId);
  }

  public async nextClick() {
    log.info('nextClick: step', this.stepId);

    const isValid = this._validateStep();

    if (!isValid) {
      this.isNextDisabled = true;
      return;
    }

    if (this.stepId === this.totalSteps) {
      this.loaderService.show();

      await this.updateOrderData();

      const { sessionId } = await this.order$
        .pipe(
          filter((_) => !!_.sessionId),
          take(1),
        )
        .toPromise();

      await this.pay(sessionId);
    } else {
      this.stepId++;
    }
  }

  private async pay(sessionId: string) {
    const stripe = await this.stripeService.stripe;

    resetOnBeforeUnload();
    const res = await stripe.redirectToCheckout({
      sessionId,
    });

    if ('error' in res) {
      const { error } = res;

      alert(error?.message || error);
    }
  }

  private async updateOrderData() {
    const orderData = await this.order$.pipe(take(1)).toPromise();
    const shipping = await this.shipping$.pipe(take(1)).toPromise();

    if (orderData) {
      const {
        formCustomerInfo,
        formShippingAddressActive,
        formShippingAddress,
        formPayment,
        printOnStaging,
      } = this.formData;

      // local replacing
      if (formCustomerInfo) {
        orderData.addressBillingData = formCustomerInfo;
      }

      // check if the tick 'Ship to the address above' is active
      orderData.addressShippingData =
        !formShippingAddressActive && formShippingAddress
          ? formShippingAddress
          : formCustomerInfo;

      // const fSM = fD.formShippingMethod

      orderData.shippingMethod = shipping.id;

      orderData.printOnStaging = printOnStaging;

      const couponData = await this.coupon$.pipe(take(1)).toPromise();

      const discountCampaign =
        await this._discountService.activeDiscountCampaignsObservable
          .pipe(take(1))
          .toPromise();

      orderData.paymentInformation = getPaymentInformation(
        orderData.books,
        orderData.shippingMethod,
        shipping.price,
        formPayment.type,
        couponData,
        discountCampaign,
      );

      this._order$.next(orderData);
      // saving on the BE side
      await this.orderService.updateOrder(orderData.orderId, orderData);
    }
  }

  /**
   * @returns `true` if valid
   * @returns `false` and touches all the inputs to show up errors if not
   */
  private _validateStep(): boolean {
    const { mainComponent, stepId: step } = this;

    if (!mainComponent) return false;

    let status = false;

    switch (step) {
      case 1:
        const { formCustomerInfoComponent, formShippingAddressComponent } =
          mainComponent;

        if (!formCustomerInfoComponent || !formShippingAddressComponent) {
          // set status
          status = false;

          break;
        }

        const isFormCustomerInfoValid =
          formCustomerInfoComponent.formGroup.valid;

        const isFormShippingAddressValid = formShippingAddressComponent.active
          ? isFormCustomerInfoValid
          : formShippingAddressComponent.formGroup.valid;

        // set status
        status = isFormCustomerInfoValid && isFormShippingAddressValid;

        // touch if not valid to show errors
        if (!status) {
          formCustomerInfoComponent.touch();

          if (formShippingAddressComponent.active === false) {
            formShippingAddressComponent.touch();
          }
        }

        break;
      case 2:
        status = true;

        break;
      case 3:
        const { formPaymentComponent } = mainComponent;

        if (!formPaymentComponent) {
          // set status
          status = false;

          break;
        }

        const { formGroup } = formPaymentComponent;

        const isFormPaymentValid = formGroup.valid;

        // set status
        status = isFormPaymentValid;

        // touch if not valid to show errors
        if (!status) {
          formGroup.markAllAsTouched();
        }

        break;

      // no default
    }

    return status;
  }
}
