import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() isFinal: boolean = false;

  @Input() nextText: string;

  @Input() isCompleted: boolean = false;

  @Input() isBackDisabled: boolean = false;

  @Input() isNextDisabled: boolean = false;

  @Output() back: EventEmitter<void> = new EventEmitter();

  @Output() next: EventEmitter<void> = new EventEmitter();

  // constructor() {}
}
