<div class="content">
  <div
    *ngFor="let book of books"
    class="content__section"
  >
    <app-book
      [book]="book"
      (buttonClick)="onButtonClick(book.alias)"
    ></app-book>
  </div>
</div>
