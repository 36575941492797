<div class="wrap">
  <div class="wrap__main">
    <div class="main">
      <div class="main__photo">
        <app-photo
          [src]="photo"
          [alt]="title"
        ></app-photo>
      </div>
      <div class="main__content">
        <div class="content">
          <div class="content__title">
            <app-title [title]="title"></app-title>
          </div>
          <div class="content__edit">
            <app-edit [routerLink]="'/book/'+id"></app-edit>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="wrap__specs">
    <div
      class="specs"
      [class.specs_collapsed]="specsCollapsed"
      [@specsCollapse]="specsCollapsed"
    >
      <app-spec
        class="specs__item"
        *ngFor="let key of specsKeys"
        [key]="key"
        [value]="specs[key]"
        (click)="showSpecs()"
      ></app-spec>
      <button
        class="specs__toggle"
        (click)="onSpecsToggle()"
      >
        {{ specsCollapsed ? 'Show more ↓' : 'Hide ↑'}}
      </button>
    </div>
  </div>
  <div class="wrap__toggles">
    <ng-content></ng-content>
  </div>
  <div class="wrap__price">
    <app-price
      [price]="price.value"
      [currency]="price.currency"
      [discount]="discount"
      [boldPrice]="true"
    ></app-price>
  </div>
  <app-x-button
    class="wrap__x"
    (click)="onClose()"
  ></app-x-button>
</div>
