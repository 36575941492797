import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionFilterComponent } from './section-filter.component';
import { InputModule } from '../input/input.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SectionFilterComponent],
  imports: [CommonModule, InputModule, TranslateModule],
  exports: [SectionFilterComponent],
})
export class SectionFilterModule {}
