import { ChangeDetectionStrategy, Component } from '@angular/core';
// import { Logger } from 'src/app/services/logger.service';
import { RoutingService } from 'app/services/routing.service';

// const log = new Logger('SectionFooterBannerComponent');

@Component({
  selector: 'app-section-footer-banner',
  templateUrl: './section-footer-banner.component.html',
  styleUrls: ['./section-footer-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionFooterBannerComponent {
  constructor(private _routing: RoutingService) {}

  public onButtonClick(): void {
    // log.info('onButtonClick');
    this._routing.goToAllBooks();
  }
}
