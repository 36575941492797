<button
  mat-fab
  [color]="(fakeTimersObservable | async) ? 'accent' : 'warn'"
  aria-label="Debug"
  [matMenuTriggerFor]="menuDebug"
  fxHide.xs
>
  <mat-icon>
    access_time
  </mat-icon>
</button>
<mat-menu #menuDebug="matMenu" class="debug-menu">
  <div class="form">
    <app-label>admin password</app-label>
    <br><br>
    <app-input
      type="password"
      placeholder="admin password"
      [formControl]="adminPasswordFormControl"
      (click)="$event.stopPropagation()"
    ></app-input>
    <br>
    <mat-divider></mat-divider>
    <br>
    <app-label>fake date</app-label>
    <br><br>
    <app-input-date
      placeholder="fake date"
      [formControl]="currentCurrentDateFormControl"
      (click)="$event.stopPropagation()"
    ></app-input-date>
    <br>
    <mat-divider></mat-divider>
    <br>
    <button
      mat-menu-item
      color="primary"
      (click)="onResetButtonClick()"
      [disabled]="!(fakeTimersObservable | async)"
    >reset</button>
  </div>
</mat-menu>
