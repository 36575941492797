import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  ROUTE_PATH_PARAM_GIFT_ID,
  ROUTE_PATH_PARAM_ORDER_ID,
} from 'src/app/app-routing.constants';
import { RoutingService } from 'src/app/services/routing.service';
import { MediaService } from 'src/app/services/media.service';

@Component({
  templateUrl: './gift-thanks-page.component.html',
  styleUrls: ['./gift-thanks-page.component.scss'],
})
export class GiftThanksPageComponent implements OnInit, OnDestroy {
  private _sub = new Subscription();

  public orderId?: string;

  public giftId?: string;

  constructor(
    private _route: ActivatedRoute,
    public routing: RoutingService,
    public media: MediaService,
  ) {}

  ngOnInit(): void {
    this._sub.add(
      this._route.queryParams.subscribe((params) => {
        this.orderId = params[ROUTE_PATH_PARAM_ORDER_ID];
        this.giftId = params[ROUTE_PATH_PARAM_GIFT_ID];
      }),
    );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }
}
