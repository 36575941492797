import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { calculateDiscount } from '@shared/discount/utils';
import { IShipping } from 'src/app/data/shipping/types';
import { getTotal } from './price.utils';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceComponent {
  @Input() price!: number;

  @Input() currency!: string;

  @Input() discount?: string;

  @Input() vague?: boolean;

  @Input() type: 'V' | 'H' = 'H';

  /** default is `md` */
  @Input() size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'md'; // \ 'lg' | 'xl'

  /** default is `md` */
  @Input() spacing: 'sm' | 'md' | 'lg' = 'md';

  @Input() boldPrice?: boolean;

  @Input() boldLabel?: boolean;

  @Input() shippingIncluded?: IShipping;

  @Input() shippingDiscount?: string;

  // constructor() {}

  get currencyAddition(): string {
    if (this.currency === '$') {
      return '';
    } else {
      return '';
    }
  }

  get shippingPrice(): number {
    return this.shippingIncluded?.price || 0;
  }

  get rawTotal(): string {
    return getTotal(this.currency, this.price + this.shippingPrice, this.vague);
  }

  get calculatedTotal(): string {
    const price = this.discount
      ? calculateDiscount(this.price, this.discount)
      : this.price;

    const shippingPrice = this.shippingDiscount
      ? calculateDiscount(this.shippingPrice, this.shippingDiscount)
      : this.shippingPrice;

    return getTotal(this.currency, price + shippingPrice, this.vague);
  }

  get discountTotal(): string {
    const { currency, discount, shippingIncluded, shippingDiscount, rawTotal } =
      this;

    if (!discount && !shippingDiscount) return '';

    const discountCurrency = discount?.match('%') ? '' : currency;
    const discountLabel = !shippingIncluded
      ? `<sup> -${discountCurrency}${discount}</sup>`
      : '';

    return `<del>${rawTotal}</del>${discountLabel}`;
  }

  get label(): string {
    // return `(${this.shippingIncluded ? '送料込・' : ''}税込)`;
    return '';
  }
}
