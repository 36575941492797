<div
  class="container"
  [ngClass]="{
    container_carousel: !disableCarousel && (showCarousel | async)
  }"
>
  <div
    *ngIf="!hideTitle"
    class="container__title"
  >
    <app-image-title type="books"></app-image-title>
  </div>
  <div class="container__content">
    <app-books
      *ngIf="disableCarousel || !(showCarousel | async)"
      [books]="books"
      (buttonClick)="onButtonClick($event)"
    ></app-books>
    <app-carousel
      *ngIf="!disableCarousel && (showCarousel | async)"
      [books]="books"
      (buttonClick)="onButtonClick($event)"
    ></app-carousel>
  </div>
</div>
