<section
  class="container"
>
  <div class="container__question">
    <div class="question">
      <div class="question__button">
        <button
          class="button"
          [ngClass]="{
            'button_active': active | async
          }"
          (click)="onClick()"
        ></button>
      </div>
      <h4 class="question__text">{{ question }}</h4>
    </div>
  </div>
  <div
    *ngIf="active | async"
    class="container__answer"
    @slideY
  >
    <p class="answer" [innerHTML]="answer"></p>
  </div>
</section>
