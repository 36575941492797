import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TFaqData } from 'src/app/data/faq/types';

@Component({
  selector: 'app-faq-group',
  templateUrl: './faq-group.component.html',
  styleUrls: ['./faq-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqGroupComponent {
  @Input() data!: TFaqData;

  // constructor() {}
}
