<figure class="step">
  <img class="step__photo"
    [src]="imgSrc"
    alt="#"
  >
  <figcaption class="step__text">
    <div class="text">
      <p
        *ngFor="let textItem of text"
        class="text__item"
      >{{ textItem }}</p>
    </div>
  </figcaption>
</figure>
