import { Component } from '@angular/core';

@Component({
  selector: 'app-section-full',
  templateUrl: '../../templates/section.template.html',
  styleUrls: ['./section-full.component.scss'],
})
export class SectionFullComponent {
  // constructor() { }
}
