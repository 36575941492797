import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RoutingService } from 'app/services/routing.service';

@Component({
  selector: 'app-section-collaboration',
  templateUrl: './section-collaboration.component.html',
  styleUrls: ['./section-collaboration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionCollaborationComponent {
  constructor(public routing: RoutingService) {}
}
