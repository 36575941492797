<div class="container">
  <div class="container__photo">
    <img
      class="photo"
      [src]="photo"
      alt="#"
    />
  </div>
  <div class="container__caption">
    <h4
      class="caption"
      translate
    >
      {{ caption }}
    </h4>
  </div>
  <div class="container__summary">
    <p class="summary">{{ summary }}</p>
  </div>
  <div
    class="container__notice-point"
    *ngFor="let point of notice.points"
  >
    <div class="notice-point">
      <img
        class="notice-point__img"
        [src]="point.imgSrc"
        alt="#"
      />
      <p
        class="notice-point__txt"
        translate
      >
        {{ point.caption }}
      </p>
    </div>
  </div>
  <div class="container__footer">
    <div class="footer">
      <div class="footer__button">
        <app-button
          size="xs"
          (buttonClick)="onButtonClick()"
          >Create my book</app-button
        >
      </div>
      <div class="footer__price">
        <app-price
          [currency]="price.currency"
          [price]="price.value"
          [discount]="discount"
          [vague]="true"
          size="sm"
          spacing="sm"
          type="V"
        ></app-price>
      </div>
    </div>
  </div>
</div>
