<select
  class="native"
  [id]="id"
  [name]="name || ''"
  [(ngModel)]="value"
  [disabled]="disabled || false"
  (blur)="onTouched()"
  #native
  (cdkObserveContent)="writeOptions(native)"
>
  <option
    *ngIf="placeholder"
    value=""
    disabled
    hidden
  >
    {{ placeholder }}
  </option>
  <ng-content></ng-content>
</select>
<div
  class="select"
  [ngClass]="{
    select_active: isActive | async,
    select_disabled: disabled
  }"
>
  <div class="select__title">
    <div
      class="select-item"
      [ngClass]="{
        'select-item_disabled': option?.disabled
      }"
    >
      <div
        class="select-item__text"
        [innerHTML]="option?.innerHTML"
      ></div>
      <div class="select-item__button">
        <app-select-button
          [active]="(isActive | async) || false"
          [theme]="selectButtonTheme"
          [disabled]="disabled"
        ></app-select-button>
      </div>
    </div>
  </div>
  <div class="select__content">
    <ng-container *ngFor="let o of options">
      <div
        class="select-item"
        [ngClass]="{
          'select-item_active': o.value === option?.value,
          'select-item_disabled': o.disabled
        }"
        *ngIf="o && !o.hidden && o.nodeType === Node.ELEMENT_NODE"
        (click)="!o.disabled && (value = o.value)"
      >
        <div
          class="select-item__text"
          [innerHTML]="o.innerHTML"
        ></div>
      </div>
    </ng-container>
  </div>
</div>
