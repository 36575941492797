<ng-container
  *ngIf="discount || shippingDiscount; then discountTpl; else noDiscountTpl"
></ng-container>
<ng-template #discountTpl>
  <div
    class="price price_discount"
    [ngClass]="{
      price_xl: size === 'xl',
      price_lg: size === 'lg',
      price_sm: size === 'sm',
      price_xs: size === 'xs'
    }"
  >
    <div
      class="price__label"
      [innerHTML]="discountTotal"
    ></div>
  </div>
  <ng-container *ngTemplateOutlet="noDiscountTpl"></ng-container>
</ng-template>
<ng-template #noDiscountTpl>
  <div
    class="price"
    [ngClass]="{
      price_vertical: type === 'V',
      price_xl: size === 'xl',
      price_lg: size === 'lg',
      price_sm: size === 'sm',
      price_xs: size === 'xs',
      'price_spacing-sm': spacing === 'sm',
      'price_spacing-lg': spacing === 'lg'
    }"
  >
    <div
      class="price__price"
      [ngClass]="{
        price__price_bold: boldPrice
      }"
    >
      {{ calculatedTotal }}{{ currencyAddition }}
    </div>
    <div
      class="price__label"
      [ngClass]="{
        price__label_bold: boldLabel
      }"
    >
      {{ label }}
    </div>
  </div>
</ng-template>
