import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionTextComponent } from './section-text.component';
import { PassageOrderedListComponent } from './components/passage-ordered-list/passage-ordered-list.component';
import { PassageTextComponent } from './components/passage-text/passage-text.component';

@NgModule({
  declarations: [
    SectionTextComponent,
    PassageOrderedListComponent,
    PassageTextComponent,
  ],
  imports: [CommonModule],
  exports: [SectionTextComponent],
})
export class SectionTextModule {}
