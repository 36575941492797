import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { YotpoService } from 'app/services/yotpo.service';

@Component({
  selector: 'app-section-reviews-yotpo-common',
  templateUrl: './section-reviews-yotpo-common.component.html',
  styleUrls: ['./section-reviews-yotpo-common.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionReviewsYotpoCommonComponent implements AfterViewInit {
  constructor(private yotpo: YotpoService) {}

  ngAfterViewInit(): void {
    this.yotpo.init();
  }
}
