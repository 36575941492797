import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionFaqComponent } from './section-faq.component';
import { ImageTitleModule } from '../image-title/image-title.module';
import { ButtonModule } from '../button/button.module';
import { FaqModule } from '../faq/faq.module';

@NgModule({
  declarations: [SectionFaqComponent],
  imports: [CommonModule, ImageTitleModule, ButtonModule, FaqModule],
  exports: [SectionFaqComponent],
})
export class SectionFaqModule {}
