import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

// fade-in/fade-out animation.
export const fade = () =>
  trigger('fade', [
    // the "in" style determines the "resting" state of the element when it is visible.
    state('in', style({ opacity: 1 })),

    // fade in when created. this could also be written as transition('void => *')
    transition(':enter', [
      style({
        opacity: 0,
      }),
      animate(100),
    ]),

    // fade out when destroyed. this could also be written as transition('void => *')
    transition(
      ':leave',
      animate(
        100,
        style({
          opacity: 0,
        }),
      ),
    ),
  ]);
