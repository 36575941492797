import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { sectionSteps } from './data';
import { TSectionSteps } from './types';
import { MediaObserver } from '@angular/flex-layout';
import { BehaviorSubject } from 'rxjs';
import Subscriber from 'src/app/subscriber';

const sub = new Subscriber();

@Component({
  selector: 'app-section-steps',
  templateUrl: './section-steps.component.html',
  styleUrls: ['./section-steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionStepsComponent implements OnInit, OnDestroy {
  @Input() title: string = '';

  @Input() steps: TSectionSteps = sectionSteps;

  public showCarousel = new BehaviorSubject(false);

  constructor(private _media: MediaObserver) {}

  ngOnInit(): void {
    sub.push(
      this._media.asObservable().subscribe(() => {
        this._updateShowCarousel();
      }),
    );
  }

  ngOnDestroy(): void {
    sub.unsubscribe();
  }

  private _updateShowCarousel(): void {
    this.showCarousel.next(this._media.isActive('lt-sm'));
  }
}
