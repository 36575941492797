/* eslint-disable @typescript-eslint/naming-convention */
// here we can implement analytics events triggering functions

import type { TBookId } from '@shared/book/interfaces';
import type { IAddressBillingData, IOrderData } from '@shared/interfaces';
import type {
  TGiftOrderCreated,
  TGiftOrderGenerated,
} from '@shared/gift/interfaces';
import { environment } from 'src/environments/environment';
import Hotjar from '@hotjar/browser';
import type { TStepId } from './app/pages/order/interfaces';
import { Logger } from './app/services/logger.service';

const isProduction = environment.production;

if (isProduction) {
  Hotjar.init(3506983, 6);
}

const triggerCheck = <FN, ARGS extends any[]>(
  log: Logger,
  fn: FN,
  ...args: ARGS
) => {
  if (typeof fn === 'function') {
    fn(...args);
  } else {
    const level = isProduction ? 'error' : 'info';

    log[level](`trigger doesn't exist, arguments:`, ...args);
  }
};

const gtagTriggerLog = new Logger('gtagTrigger');
export const gtagTrigger: Gtag.Gtag = (...args: any[]) =>
  triggerCheck(gtagTriggerLog, window.gtag, ...args);

const fbqTriggerLog = new Logger('fbqTrigger');
export const fbqTrigger: facebook.Pixel.Event = (...args: any[]) =>
  triggerCheck(fbqTriggerLog, window.fbq, ...args);

// const _ltTriggerLog = new Logger('_ltTrigger');
// export const _ltTrigger = (...args: any) =>
//   triggerCheck(_ltTriggerLog, window._lt, ...args);

// const ytagTriggerLog = new Logger('ytagTrigger');
// export const ytagTrigger = (...args: any) =>
//   triggerCheck(ytagTriggerLog, window.ytag, ...args);

const pintrkTriggerLog = new Logger('pintrkTrigger');
export const pintrkTrigger = (...args: any) =>
  triggerCheck(pintrkTriggerLog, window.pintrk, ...args);

// * Google Anlytics section

/**
 * set of google analytics events we are waiting for
 */
export enum GAEvents {
  thankyou = 'thankyou',
}

export const GAGetEvents = {
  getBookPreview: (bookId: TBookId) => `book_preview_${bookId}`,
  getAddToCart: (bookId: TBookId) => `add_to_cart_${bookId}`,
  getCheckoutStep: (stepId: TStepId) => `checkout_step_${stepId}`,
};

/**
 * triggers Google Analytics event by
 * ! ga('track', `event`)
 * @param event - one of `FBEvents` values
 */
export const GATrack = (event: GAEvents | string) =>
  gtagTrigger('event', event, {
    event_category: 'Default',
    send_to: 'G-V8R516H1SR',
  });

// * Facebook Pixel section

/**
 * set of facebook pixel events we are waiting for
 */
export enum FBEvents {
  viewContent = 'ViewContent',
  addToCart = 'AddToCart',
  initiateCheckout = 'InitiateCheckout',
  purchase = 'Purchase',
}

/**
 * triggers Facebook Pixel event by
 * fbq('track', `event`)
 * @param event - one of `FBEvents` values
 */
export const FBTrack = (
  event: FBEvents,
  parameters?:
    | facebook.Pixel.ViewContentParameters
    | facebook.Pixel.SearchParameters
    | facebook.Pixel.AddToCartParameters
    | facebook.Pixel.AddToWishlistParameters
    | facebook.Pixel.InitiateCheckoutParameters
    | facebook.Pixel.AddPaymentInfoParameters
    | facebook.Pixel.PurchaseParameters
    | facebook.Pixel.LeadParameters
    | facebook.Pixel.CompleteRegistrationParameters,
  // @ts-ignore
) => fbqTrigger('track', event, parameters);

const sendConversionEvent = (orderData: {
  addressBillingData: IAddressBillingData;
  orderId: string;
}) => {
  // FBTrack(FBEvents.purchase, {
  //   value: 3900,
  //   currency: 'JPY',
  // });

  // * new approach
  gtagTrigger('set', 'user_data', {
    //  ***Change yourEmailVariable to the actual Javascript variable name where you are storing the user’s email data. Do the same for the other variables below. Make sure the values aren't hashed.
    email: orderData.addressBillingData.email,
    phone_number: `+81${orderData.addressBillingData.phone.replace(
      /(^0|\D)/g,
      '',
    )}`,
    // address: {
    //   first_name: orderData.addressBillingData.givenName,
    //   last_name: orderData.addressBillingData.familyName,
    //   // street: yourStreetAddressVariable,
    //   // city: yourCityVariable,
    //   region: orderData.addressBillingData.addressLine1,
    //   postal_code: orderData.addressBillingData.postalCode.replace(/\D/g, ''),
    //   country: 'JP',
    // },
  });
  GATrack(GAEvents.thankyou);
  gtagTrigger('event', 'conversion', {
    send_to: 'AW-11146476523/ZkrjCLKTnZ0YEOv3hsMp',
    transaction_id: orderData.orderId,
  });
  // _ltTrigger(
  //   'send',
  //   'cv',
  //   {
  //     type: 'Conversion',
  //   },
  //   ['1011300d-ebc2-41d8-bbab-99cb084c7a71'],
  // );
  // ytagTrigger({
  //   type: 'yss_conversion',
  //   config: {
  //     yahoo_conversion_id: '1001220847',
  //     yahoo_conversion_label: 'z9LKCOvDo4ADENjZ6Z0o',
  //     yahoo_conversion_value: '4200',
  //   },
  // });
  // ytagTrigger({
  //   type: 'yjad_conversion',
  //   config: {
  //     yahoo_ydn_conv_io: 'ZoUdsP-7teY_QFIYSJpYGg..',
  //     yahoo_ydn_conv_label: '3J9CPRTH0PY7E9TLHEH857474',
  //     yahoo_ydn_conv_transaction_id: '',
  //     yahoo_ydn_conv_value: '4200',
  //     yahoo_email: orderData.addressBillingData.email,
  //     yahoo_phone_number: orderData.addressBillingData.phone.replace(/\D/g, ''),
  //   },
  // });

  const tempErr = console.error;
  console.error = console.warn;
  pintrkTrigger('load', '2613331368869', {
    em: orderData.addressBillingData.email,
  });
  pintrkTrigger('page');
  console.error = tempErr;

  pintrkTrigger('track', 'checkout', {
    value: 40,
    currency: 'USD',
    order_id: orderData.orderId,
  });
};

export const sendConversionEventByOrder = (orderData: IOrderData) => {
  const { addressBillingData, orderId } = orderData;

  if (!addressBillingData) {
    console.error('!addressBillingData');
    return;
  }

  sendConversionEvent({
    addressBillingData,
    orderId,
  });
};

export const sendConversionEventByGiftOrder = (
  orderId: string,
  giftOrder: TGiftOrderCreated | TGiftOrderGenerated,
) => {
  const { addressBillingData } = giftOrder;

  sendConversionEvent({
    addressBillingData,
    orderId,
  });
};
