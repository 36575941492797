<div class="container">
  <div class="container__section">
    <a class="link"
      routerLink="/policy"
      translate
    >Privacy Policy</a>
  </div>
  <div class="container__section">
    <a class="link"
      routerLink="/terms"
      translate
    >Terms and Conditions</a>
  </div>
  <div class="container__section">
    <a class="link"
      routerLink="/ordercontract"
      translate
    >Order Contract</a>
  </div>
</div>
