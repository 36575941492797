<div class="logo"
  [ngClass]="{
    'logo_inverted': inverted
  }"
>
  <div #eyeLeft class="logo__eye logo__eye_left">
    <div class="logo__eyeball"
      #eyeBallLeft
      [ngStyle]="{
        marginLeft: marginLeft | async,
        marginTop: marginTop | async
      }"
    ></div>
  </div>
  <div #eyeRight class="logo__eye logo__eye_right">
    <div class="logo__eyeball"
      #eyeBallRight
      [ngStyle]="{
        marginLeft: marginLeft | async,
        marginTop: marginTop | async
      }"
    ></div>
  </div>
  <svg-icon class="logo__image"
    src="assets/images/buki-logo-no-eyes.svg"
    [applyClass]="true"
  ></svg-icon>
</div>
