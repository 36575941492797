<input
  #native
  class="native"
  [id]="id"
  [name]="name"
  [checked]="value"
  [disabled]="disabled"
  (blur)="onTouched()"
  type="checkbox"
/>

<div
  class="container"
  [title]="label || ''"
>
  <div class="container__section container__section_checkbox">
    <div
      class="checkbox"
      [ngClass]="{
        'checkbox_theme-alt': theme === EInputTheme.alt,
        checkbox_active: value,
        checkbox_invalid: invalid
      }"
    >
      <div class="checkbox__box"></div>
      <svg-icon
        class="checkbox__tick"
        src="assets/images/icons/input-checkbox-tick.svg"
        [applyClass]="true"
      ></svg-icon>
    </div>
  </div>
  <div class="container__section container__section_label">
    <div class="label">
      {{ label }}
      <ng-content></ng-content>
    </div>
  </div>
</div>
