<div class="container">
  <div class="container__image">
    <img
      src="assets/images/promise.png"
      alt="#"
    />
  </div>
  <div class="container__content">
    <div class="content">
      <div class="content__title">
        <app-image-title type="promise"></app-image-title>
      </div>
      <div class="content__text">
        <p class="text">
          We believe that our planet's future is the future of our children.
        </p>
      </div>
      <div class="content__text">
        <p class="text">
          ✓ All of our books are made-to-order, and we do not print books that
          will not be read.
        </p>
      </div>
      <div class="content__text">
        <p class="text">
          ✓ We currently use and will continue further improving materials to
          comply with international standards that promote environmentally
          appropriate, socially beneficial and economically viable management of
          the world's forests.
        </p>
      </div>
      <div class="content__text">
        <p class="text">
          Our contribution is small, but step by step, we will strive to keep
          improving ourselves and our services so that this world becomes a
          better place for the children.
        </p>
      </div>
    </div>
  </div>
</div>
