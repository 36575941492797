import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Logger } from 'app/services/logger.service';
import { ECardTitleColor } from './card.enum';

const log = new Logger('CardComponent');

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
  @Input() title?: string;

  @Input() titleColor: ECardTitleColor = ECardTitleColor.default;

  @Input() buttonText?: string;

  @Output() buttonClick = new EventEmitter<void>();

  public readonly ECardTitleColor = ECardTitleColor;

  // constructor() {}

  public onButtonClick() {
    log.info('onButtonClick');
    this.buttonClick.emit();
  }
}
