<div class="container">
  <div
    *ngFor="let descriptionItem of description; index as i"
    class="container__section"
  >
    <app-description-item
      [imgSrc]="descriptionItem.imgSrc"
      [text]="descriptionItem.text"
      [imageFirst]="(forceImageFirst | async) || !(i % 2)"
    ></app-description-item>
  </div>
</div>
