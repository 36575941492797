<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div class="container__content">
        <form
          class="content"
          [formGroup]="giftFormGroup"
          (submit)="onSubmit()"
        >
          <div class="content__input">
            <app-input
              label="ギフトコード"
              formControlName="code"
              [error]="(codeError$ | async) || REQUIRED_ERROR"
              [disabled]="(loading$ | async)"
            ></app-input>
          </div>
          <div class="content__button">
            <app-button
              [disabled]="(loading$ | async) || giftFormGroup.invalid"
              [fullWidth]="true"
            >OK</app-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
