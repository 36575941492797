<div class="container">
  <div
    *ngIf="title"
    class="container__title"
  >
    <h4
      class="title"
      [ngClass]="{
        'title_c-link': titleColor === ECardTitleColor.cLink,
        'title_c-accent-2': titleColor === ECardTitleColor.cAccent2
      }"
    >{{ title }}</h4>
  </div>
  <div class="container__content"><ng-content></ng-content></div>
  <div
    *ngIf="buttonClick.observers.length"
    class="container__button"
  ><app-button (buttonClick)="onButtonClick()">{{ buttonText }}</app-button></div>
</div>
