<div class="container">
  <app-logo
    class="container__logo"
    (click)="router.navigate(['/'])"
  ></app-logo>
  <app-steps
    *ngIf="steps"
    class="container__steps"
    [steps]="steps"
    [step]="step"
  ></app-steps>
</div>
