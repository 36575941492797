<div class="content">
  <ng-container [ngSwitch]="translateService.currentLang">
    <ng-container *ngSwitchCase="'en-US'">
      <ng-container *ngTemplateOutlet="enUS"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'jp-JP'">
      <ng-container *ngTemplateOutlet="jpJP"></ng-container>
    </ng-container>
  </ng-container>
  <div class="content__button">
    <app-button
      (buttonClick)="onSignUpFinalButtonClick()"
      [fullWidth]="true"
    >{{ 'auth.sign-up-final.button' | translate }}</app-button>
  </div>
</div>

<ng-template #enUS>
  <div class="content__text">
    <p class="text">We have sent you an email to {{ email }}. Please click the URL in the email to authenticate.</p>
  </div>
  <div class="content__hr">
    <hr class="hr">
  </div>
  <div class="content__text">
    <p class="text">If you did not receive the email, it may be spamed.<br>Please check your spam box or contact our customer support!</p>
  </div>
</ng-template>

<ng-template #jpJP>
  <div class="content__text">
    <p class="text">確認用のメールを {{ email }} 宛てにお送りしました。メールに記載のURLをクリックして、認証を行ってください。</p>
  </div>
  <div class="content__hr">
    <hr class="hr">
  </div>
  <div class="content__text">
    <p class="text">メールが届いていない場合は、迷惑メールになっている場合があります。</p>
  </div>
  <div class="content__text">
    <p class="text">迷惑メールボックスをご確認いただくか、hellobuki.com をドメイン指定してもう一度確認メールを送信してください。</p>
  </div>
</ng-template>
