import { IBookTextInput } from './types';
import { EBookInputType, TGetBookInput } from '../shared';

// export const getBookTextInputData: TGetBookInput<IBookTextInput>
//   = (params) => ({
//     ...params,
//     type: EBookInputType.text,
//   } as const);

export const getBookTextInputData: TGetBookInput<IBookTextInput> = (params) => {
  const _params = Object.assign(params, { type: EBookInputType.text as const });

  return _params;
};
