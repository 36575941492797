<div class="container">
  <div class="container__title">
    <div
      class="title"
      translate
    >
      Coupon Code
    </div>
  </div>
  <div class="container__content">
    <div class="content">
      <div class="content__section content__section_input">
        <app-input
          type="text"
          name="couponId"
          [formControl]="couponIdControl"
          [error]="(stripeError$ | async) || REQUIRED_ERROR"
        ></app-input>
      </div>
      <div class="content__section content__section_button">
        <app-button
          [disabled]="
            (loading$ | async) ||
            couponIdControl.invalid ||
            (coupon && (stripeError$ | async) === undefined)
          "
          (buttonClick)="redeemCoupon()"
          [loading]="loading$ | async"
          >{{ 'Apply' | translate }}</app-button
        >
      </div>
    </div>
  </div>
</div>
