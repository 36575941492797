import type { AbstractControl, ValidatorFn } from '@angular/forms';
import type { ValidationErrors } from '@angular/forms';
import { Logger } from './services/logger.service';
import Subscriber from './subscriber';

function* countStringByLength(str: string, maxLength: number) {
  for (let i = 0; i < str.length / maxLength; i++) {
    const start = i * maxLength;

    yield str.substring(start, start + maxLength);
  }
}

export const checkNumbers = (str: string): boolean => {
  const reg = /^[0-9]+$/;

  return reg.test(str);
};

// 1 \w is equivalent to [A-Za-z0-9_]
export const checkAlphanumeric = (str: string): boolean => {
  const reg = /^\w+$/;

  return reg.test(str);
};

// 2 3000 - 303f
export const checkJapaneseStylePunctuation = (str: string): boolean => {
  const reg = /^[\u3000-\u303f]+$/;

  return reg.test(str);
};

//  3
export const checkHiragana = (str: string): boolean => {
  const reg = /^[\u3040-\u309f]+$/;

  return reg.test(str);
};

// 4
export const checkKatakana = (str: string): boolean => {
  const reg = /^[\u30a0-\u30ff]+$/;

  return reg.test(str);
};

// 5
export const checkKatakanaDash = (str: string): boolean => {
  const reg = /^\u30FC+$/;

  return reg.test(str);
};

// 4 + 5
export const checkHiraganaAndKatakanaDash = (str: string): boolean => {
  const reg = /^([\u3040-\u309f]|\u30FC)+$/;

  return reg.test(str);
};

// 6
export const checkFullAlphanumericAndHalfKatakana = (str: string): boolean => {
  const reg = /^[\uff00-\uffef]+$/g;

  return reg.test(str);
};

// 7
export const checkKanji = (str: string): boolean => {
  const reg = /^[\u4e00-\u9faf]+$/g;

  return reg.test(str);
};

export const checkAllAlphabets = (str: string): boolean => {
  const reg =
    /^[一-龠ぁ-んァ-ンｧ-ﾝﾞﾟ0-9０-９A-zＡ-ｚ-－ｰー−〜 　　々ヶ仝〆]+$/g;

  return reg.test(str);
};

export const checkRows = (
  maxCharsInRow: number,
  maxRows: number,
  str: string,
): boolean => {
  const rows = str.split('\n').reduce((arr, row) => {
    if (row.length <= maxCharsInRow) {
      arr.push(row);
    } else {
      const count = countStringByLength(row, maxCharsInRow);

      for (
        let countRes = count.next();
        !countRes.done;
        countRes = count.next()
      ) {
        arr.push(countRes.value);
      }
    }

    return arr;
  }, []);

  return rows.length <= maxRows;
};

/** @return `forbidden` */
export type TCreateValidatorCustomFunction = (
  control: AbstractControl,
) => boolean;

export const createValidator =
  (fn: TCreateValidatorCustomFunction): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null =>
    fn(control) ? { forbidden: true } : null;

const createSecondFieldValidatorLog = new Logger('createSecondFieldValidator');
const createSecondFieldValidatorSubMap = new Map<AbstractControl, Subscriber>();
const createSecondFieldValidatorGetSub = (
  control: AbstractControl,
): Subscriber => {
  const map = createSecondFieldValidatorSubMap;

  if (!map.get(control)) {
    map.set(control, new Subscriber());
  }

  return map.get(control);
};
export const createSecondFieldValidator = (secondFieldName: string) =>
  createValidator(
    /**
     * this function will be triggered all the time when input updates
     * or when we update it forcibly
     */
    (control) => {
      const log = createSecondFieldValidatorLog;
      const sub = createSecondFieldValidatorGetSub(control);
      const { parent } = control;
      let forbidden = false;

      // unsubscribe previous subscriptions
      sub.unsubscribe();

      // parent check
      if (!parent) {
        log.info('parent not found in control', control);
        return false;
      }

      const secondFieldControl = parent.get(secondFieldName);

      // second field control check
      if (!secondFieldControl) {
        log.error('secondFieldControl not found by name', secondFieldName);
        return false;
      }

      // push subscription to unsubscribe on the next entry
      sub.push(
        secondFieldControl.valueChanges.subscribe(() => {
          control.updateValueAndValidity();
        }),
      );

      // validation time
      if (control.value !== secondFieldControl.value) {
        forbidden = true;
      }

      return forbidden;
    },
  );

export const createBookMessageValidator = (
  maxCharsInRow: number,
  maxRows: number,
) =>
  createValidator(
    (control) =>
      control.value &&
      (!checkRows(maxCharsInRow, maxRows, control.value) ||
        !((str: string): boolean => {
          // updated checkAllAlphabets function
          // const reg = /^[一-龠ぁ-んァ-ンｧ-ﾝﾞﾟ0-9０-９A-zＡ-ｚ-－ｰー−〜 　　々ヶ仝〆]+$/g
          const reg =
            /^[一-龠ぁ-んァ-ンｧ-ﾝﾞﾟ０-９Ａ-ｚ\-－ｰー−〜 　　々ヶ仝〆。’、\u0000-\u007F\uff00-\uffef]+$/g;

          return reg.test(str);
        })(control.value)),
  );

export const bookNameValidator = createValidator(
  (control) => control.value && !checkHiraganaAndKatakanaDash(control.value),
);

/**
 * U+0041 - U+005A
 * U+0061 - U+007A
 * U+0030 - U+0039
 */
export const bookNameEnValidator = createValidator(
  (control) =>
    control.value &&
    !((str: string): boolean => {
      const reg = /^[A-Za-z]+$/g;

      return reg.test(str);
    })(control.value),
);

export const firstNameValidator = createValidator(
  (control) => control.value && !checkAllAlphabets(control.value),
);

export const lastNameValidator = firstNameValidator;

// export const postalCodeValidator = createValidator((control) => (

// ))

export const addressSecondValidator = createValidator(
  (control) => control.value && !checkAllAlphabets(control.value),
);

export const addressThirdValidator = addressSecondValidator;

export const numbersValidator = createValidator(
  (control) => control.value && !checkNumbers(control.value),
);

// stripe card can be object only
export const stripeCardValidator = createValidator(
  (control) => typeof control !== 'object',
);
