import {
  BOOK_ALIAS_STARRY_DREAM,
  BOOK_ALIAS_STORY_OF_GRANDPA_GRANDMA,
  BOOK_ALIAS_WELCOME_TO_THE_WOLD,
  BOOK_ALIAS_WHOS_BIRTHDAY_TOMORROW,
} from '../book/constants';
import type { TBookAlias } from '../book/interfaces';
import {
  SHIPPING_METHOD_EXPRESS,
  SHIPPING_METHOD_FAST,
  SHIPPING_METHOD_STANDART,
} from '../shipping/constants';
import type { TShippingMethod } from '../shipping/interfaces';
import { datesOverlap, removePercentFromValue } from '../utils';
import type {
  IDiscountCampaign,
  IDiscountCampaignFB,
  TDiscountCampaigns,
  TDiscountCampaignsFB,
} from './interfaces';

export const parseDiscountCampaign = (
  discountCampaignFB: IDiscountCampaignFB,
): IDiscountCampaign => {
  return {
    ...discountCampaignFB,
    start: discountCampaignFB.start.toDate(),
    end: discountCampaignFB.end.toDate(),
  };
};

export const parseDiscountCampaigns = (
  discountCampaignsFB: TDiscountCampaignsFB,
): TDiscountCampaigns => {
  const discountCampaigns: TDiscountCampaigns = {};

  for (const docId in discountCampaignsFB) {
    discountCampaigns[docId] = parseDiscountCampaign(
      discountCampaignsFB[docId],
    );
  }

  return discountCampaigns;
};

export const getActiveDiscountCampaign = (
  discountCampaigns?: TDiscountCampaigns,
) => {
  return (
    discountCampaigns &&
    Object.values(discountCampaigns).find(({ start, end }) => {
      if (!start || !end) {
        throw new Error('discountCampaign is invalid');
      }

      const startUnix = start.getTime();
      const todayUnix = new Date().getTime();
      const endUnix = end.getTime();

      return todayUnix > startUnix && todayUnix <= endUnix;
    })
  );
};

/**
 * @param price to apply discount on
 * @param discount could be percentOff: `50%` or amountOff: `200`
 * @returns updated price
 */
export const calculateDiscount = (price: number, discount: string) => {
  const isPercent = /\%$/g.test(discount);

  if (isPercent) {
    const percentOff = Number(discount.slice(0, discount.length - 1));

    if (isNaN(percentOff)) throw new Error(`invalid percent ${discount}`);

    price = removePercentFromValue(price, percentOff);
  } else {
    const amountOff = Number(discount);

    if (isNaN(amountOff)) throw new Error(`invalid amount ${discount}`);

    price = price - amountOff;
  }

  price = Math.round(price);

  return price < 0 ? 0 : price;
};

/**
 * @returns `alias specific discount` or `global discount` or `undefined`
 */
export const getBooksDiscount = (
  discountCampaign: IDiscountCampaign,
  alias: TBookAlias,
): string | undefined => {
  switch (alias) {
    case BOOK_ALIAS_STARRY_DREAM:
      return discountCampaign.discountStarryDream || discountCampaign.discount;
    case BOOK_ALIAS_WHOS_BIRTHDAY_TOMORROW:
      return (
        discountCampaign.discountWhosBirthdayTomorrow ||
        discountCampaign.discount
      );
    case BOOK_ALIAS_WELCOME_TO_THE_WOLD:
      return (
        discountCampaign.discountWelcomeToTheWorld || discountCampaign.discount
      );
    case BOOK_ALIAS_STORY_OF_GRANDPA_GRANDMA:
      return (
        discountCampaign.discountStoryOfGrandmaGrandpa ||
        discountCampaign.discount
      );
    default:
      console.error(
        `getBooksDiscount: Book by alias '${alias}' not handled yet.`,
        { discountCampaign },
      );
      return undefined;
  }
};

/**
 * @returns `shipping specific discount` or `undefined`
 */
export const getShippingDiscount = (
  discountCampaign: IDiscountCampaign,
  shippingMethod: TShippingMethod,
): string | undefined => {
  const {
    standartShippingDiscount,
    fastShippingDiscount,
    expressShippingDiscount,
  } = discountCampaign;

  switch (shippingMethod) {
    case SHIPPING_METHOD_STANDART:
      return standartShippingDiscount;
    case SHIPPING_METHOD_FAST:
      return fastShippingDiscount;
    case SHIPPING_METHOD_EXPRESS:
      return expressShippingDiscount;
    default:
      console.error(
        `getShippingDiscount: Shipping method '${shippingMethod}' not handled yet.`,
        {
          discountCampaign,
        },
      );
      return undefined;
  }
};

export const validateDiscountCampaigns = (
  discountCampaigns: IDiscountCampaign[],
) => {
  const errors: string[] = [];

  // * end should be same or more than start
  const invalidStart = discountCampaigns.find((item) => item.end < item.start);

  if (invalidStart) {
    errors.push(
      `Discount Campaign ${
        discountCampaigns.indexOf(invalidStart) + 1
      } - End should be the same or more than Start.`,
    );
  }

  // * shouldn't overlap
  const overlaps = discountCampaigns.reduce<
    [IDiscountCampaign, IDiscountCampaign][]
  >((acc, a) => {
    const overlap = discountCampaigns.find(
      (b) => a !== b && datesOverlap(a, b),
    );

    if (overlap) {
      acc.push([a, overlap]);
    }

    return acc;
  }, []);

  overlaps.forEach(([a, b]) => {
    errors.push(
      `Discount Campaign ${discountCampaigns.indexOf(a) + 1} overlaps with ${
        discountCampaigns.indexOf(b) + 1
      }`,
    );
  });

  return errors;
};
