import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IBook } from 'app/data/books/types';
import { TPropertyPurpose } from 'app/data/books/properties/types';
import { BehaviorSubject } from 'rxjs';
import { EInputTheme } from '../input/interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import Subscriber from 'app/subscriber';
import {
  ROUTE_QUERY_PARAM_AGE,
  ROUTE_QUERY_PARAM_PURPOSE,
} from 'app/app-routing.constants';

@Component({
  selector: 'app-section-filter',
  templateUrl: './section-filter.component.html',
  styleUrls: ['./section-filter.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionFilterComponent implements OnInit, OnChanges, OnDestroy {
  @Input() books!: IBook[];

  @Output() filter = new BehaviorSubject<IBook[]>([]);

  public ages = new Set<number>();

  set age(age: number | undefined) {
    if (age !== this._age) {
      this._age = age;
      this._updateQueryParams({});
      // this._filter(); // there's no need for filter because it handled on queryParams change
    }
  }

  get age() {
    return this._age;
  }

  private _age?: number = undefined;

  public purposes = new Set<TPropertyPurpose>();

  set purpose(purpose: TPropertyPurpose | undefined) {
    if (purpose !== this._purpose) {
      this._purpose = purpose;
      this._updateQueryParams({});
      // this._filter(); // there's no need for filter because it handled on queryParams change
    }
  }

  get purpose() {
    return this._purpose;
  }

  private _purpose?: TPropertyPurpose = undefined;

  public readonly EInputTheme = EInputTheme;

  private readonly _sub = new Subscriber();

  constructor(private _router: Router, private _route: ActivatedRoute) {}

  ngOnInit() {
    this._sub.push(
      this._route.queryParams.subscribe((_queryParams) => {
        this._purpose = _queryParams[ROUTE_QUERY_PARAM_PURPOSE];
        this._age = _queryParams[ROUTE_QUERY_PARAM_AGE];
        this._filter();
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    const booksChange = changes.books;

    if (booksChange?.currentValue) {
      const books = booksChange.currentValue as SectionFilterComponent['books'];

      this._updateAges(books);
      this._updatePurposes(books);
      this._filter(books);
    }
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  public reset() {
    this._age = undefined;
    this._purpose = undefined;
    this._updateQueryParams({});
    // this._filter(); // there's no need for filter because it handled on queryParams change
  }

  private _updateAges(books = this.books) {
    const ages: SectionFilterComponent['ages'] = new Set();

    books.forEach((book) => {
      const { age } = book.properties;

      for (let i = age.start; i <= age.end; i++) {
        ages.add(i);
      }
    });

    this.ages = new Set([...ages].sort((a, b) => (a > b ? 1 : a < b ? -1 : 0)));
  }

  private _updatePurposes(books = this.books) {
    const purposes: SectionFilterComponent['purposes'] = new Set();

    books.forEach((book) => {
      const { purpose } = book.properties;

      purpose.forEach((_purpose) => {
        purposes.add(_purpose);
      });
    });

    this.purposes = purposes;
  }

  private _updateQueryParams({ purpose = this.purpose, age = this.age }) {
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        [ROUTE_QUERY_PARAM_PURPOSE]: purpose,
        [ROUTE_QUERY_PARAM_AGE]: age,
      },
      queryParamsHandling: 'merge',
    });
  }

  private _filter(books = this.books) {
    const { age: _age, purpose: _purpose } = this;

    this.filter.next(
      books.filter((book) => {
        const { age, purpose } = book.properties;

        if (
          _age !== undefined &&
          (Number(_age) < age.start || Number(_age) > age.end)
        ) {
          return false;
        }

        if (_purpose !== undefined && purpose.indexOf(_purpose) === -1) {
          return false;
        }

        return true;
      }),
    );
  }
}
