import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TSectionSteps } from '../../types';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepsComponent {
  @Input() steps!: TSectionSteps;

  constructor() {}
}
