import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import Subscriber from '../subscriber';
import { MediaObserver } from '@angular/flex-layout';

const sub = new Subscriber();

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  private readonly _isXsSubject = new BehaviorSubject<boolean>(false);

  public readonly isXsObservable = this._isXsSubject.asObservable();

  private readonly _isSmSubject = new BehaviorSubject<boolean>(false);

  public readonly isSmObservable = this._isSmSubject.asObservable();

  private readonly _isLtSmSubject = new BehaviorSubject<boolean>(false);

  public readonly isLtSmObservable = this._isLtSmSubject.asObservable();

  private readonly _isLtMdSubject = new BehaviorSubject<boolean>(false);

  public readonly isLtMdObservable = this._isLtMdSubject.asObservable();

  private readonly _isLtLgSubject = new BehaviorSubject<boolean>(false);

  public readonly isLtLgObservable = this._isLtLgSubject.asObservable();

  private readonly _isLtXlSubject = new BehaviorSubject<boolean>(false);

  public readonly isLtXlObservable = this._isLtXlSubject.asObservable();

  // uncomment _updateIsMobile when changing
  private readonly _isMobileSubject = this._isLtMdSubject;

  public readonly isMobileObservable = this._isMobileSubject.asObservable();

  constructor(private _media: MediaObserver) {
    this._init();
  }

  private _init(): void {
    sub.push(
      this._media.asObservable().subscribe((mediaChange) => {
        this._updateIsXs();
        this._updateIsSm();
        this._updateIsLtSm();
        this._updateIsLtMd();
        this._updateIsLtLg();
        this._updateIsLtXl();
      }),
    );
  }

  private _update(subject: BehaviorSubject<boolean>, status: boolean): void {
    if (subject.value === status) return;

    subject.next(status);
  }

  private _updateIsXs(): void {
    this._update(this._isXsSubject, this._media.isActive('xs'));
  }

  private _updateIsSm(): void {
    this._update(this._isSmSubject, this._media.isActive('sm'));
  }

  private _updateIsLtSm(): void {
    this._update(this._isLtSmSubject, this._media.isActive('lt-sm'));
  }

  private _updateIsLtMd(): void {
    this._update(this._isLtMdSubject, this._media.isActive('lt-md'));
  }

  private _updateIsLtLg(): void {
    this._update(this._isLtLgSubject, this._media.isActive('lt-lg'));
  }

  private _updateIsLtXl(): void {
    this._update(this._isLtXlSubject, this._media.isActive('lt-xl'));
  }

  // private _updateIsMobile(): void {
  //   this._update(this._isMobileSubject, this._media.isActive('lt-md'));
  // }
}
