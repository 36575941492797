import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IFaqItem } from 'app/data/faq/types';
import { slideY } from 'src/app/animations';

@Component({
  selector: 'app-faq-item',
  templateUrl: './faq-item.component.html',
  styleUrls: ['./faq-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideY()],
})
export class FaqItemComponent {
  @Input() question!: IFaqItem['question'];

  @Input() answer!: IFaqItem['answer'];

  public active = new BehaviorSubject(false);

  constructor() {}

  public onClick(): void {
    this._toggleActive();
  }

  private _toggleActive(): void {
    this.active.next(!this.active.value);
  }
}
