<div class="points">
  <div
    *ngFor="let point of points"
    class="points__item"
  >
    <app-point
      [caption]="point.caption"
      [description]="point.description"
      [type]="point.type"
    ></app-point>
  </div>
  <div
    *ngFor="let point of customPoints"
    class="points__item"
  >
    <app-point
      [caption]="point.caption"
      [description]="point.description"
      [type]="point.type"
    ></app-point>
  </div>
</div>
