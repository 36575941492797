<section class="form">
  <header class="form__section form__section_header">
    <app-form-header
      [title]="headerTitle"
      [direction]="(media.isMobileObservable | async) ? 'column' : 'row'"
      >{{ headerTitleContent }}</app-form-header
    >
  </header>
  <form
    class="form__section form__section_content"
    [formGroup]="formGroup"
  >
    <div class="section">
      <!-- NAME -->
      <div class="section__input">
        <div
          class="input-wrap"
          [ngClass]="{
            'input-wrap_column': media.isMobileObservable | async
          }"
        >
          <div class="input-wrap__input">
            <div class="input">
              <div class="input__item">
                <app-input
                  type="text"
                  name="firstName"
                  label="First Name"
                  formControlName="firstName"
                  [error]="FIRSTNAME_ERROR"
                ></app-input>
              </div>
              <div class="input__item">
                <app-input
                  type="text"
                  name="lastName"
                  label="Last Name"
                  formControlName="lastName"
                  [error]="LASTNAME_ERROR"
                ></app-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Address 1 -->
      <div class="section__input">
        <div
          class="input-wrap"
          [ngClass]="{
            'input-wrap_column': media.isMobileObservable | async
          }"
        >
          <div class="input-wrap__input">
            <div class="input">
              <div class="input__item">
                <app-input
                  type="text"
                  name="addressFirst"
                  label="Street address (or P.PO. Box)"
                  formControlName="addressFirst"
                  [error]="ADDRESS_1_ERROR"
                ></app-input>
              </div>
              <!-- adding to fill empty space making input 50% size -->
              <!-- <div
                class="input__item"
                fxHide.lt-sm
              ></div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- Address 2 -->
      <div class="section__input">
        <div
          class="input-wrap"
          [ngClass]="{
            'input-wrap_column': media.isMobileObservable | async
          }"
        >
          <div class="input-wrap__input">
            <div class="input">
              <div class="input__item">
                <app-input
                  type="text"
                  name="addressSecond"
                  label="Apt #, Building, etc. (leave blank if none)"
                  formControlName="addressSecond"
                  [error]="ADDRESS_2_ERROR"
                ></app-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Address 3 -->
      <!-- Postal Code -->
      <div class="section__input">
        <div
          class="input-wrap"
          [ngClass]="{
            'input-wrap_column': media.isMobileObservable | async
          }"
        >
          <div class="input-wrap__input">
            <div class="input">
              <div class="input__item">
                <app-input
                  type="text"
                  name="city"
                  label="Town/City"
                  formControlName="city"
                  [error]="ADDRESS_3_ERROR"
                ></app-input>
              </div>
            </div>
          </div>
          <div class="input-wrap__input">
            <div class="input">
              <div class="input__item">
                <app-input
                  type="text"
                  name="postalCode"
                  formControlName="postalCode"
                  label="ZIP/Potcode"
                  [error]="POSTAL_CODE_ERROR"
                ></app-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Country -->
      <!-- State -->
      <div class="section__input">
        <div
          class="input-wrap"
          [ngClass]="{
            'input-wrap_column': media.isMobileObservable | async
          }"
        >
          <div class="input-wrap__input">
            <div class="input">
              <div class="input__item">
                <app-input
                  type="text"
                  name="country"
                  label="Country"
                  formControlName="country"
                  readonly="true"
                ></app-input>
              </div>
            </div>
          </div>
          <div class="input-wrap__input">
            <div class="input">
              <div class="input__item">
                <app-input
                  type="select"
                  name="state"
                  label="State"
                  formControlName="state"
                  [error]="ADDRESS_1_ERROR"
                >
                  <option
                    *ngFor="let region of regions; index as i"
                    [value]="region.code"
                  >
                    {{ region.name }}
                  </option>
                </app-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Phone -->
      <div class="section__input">
        <div
          class="input-wrap"
          [ngClass]="{
            'input-wrap_column': media.isMobileObservable | async
          }"
        >
          <div class="input-wrap__input">
            <div class="input">
              <div class="input__item">
                <app-input
                  type="text"
                  name="phone"
                  formControlName="phone"
                  label="Phone number (optional, courier may contact you in case of delivery difficulties)"
                ></app-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Email Address -->
      <div class="section__input">
        <div
          class="input-wrap"
          [ngClass]="{
            'input-wrap_column': media.isMobileObservable | async
          }"
        >
          <div class="input-wrap__input">
            <div
              class="input"
              [ngClass]="{
                input_column: media.isMobileObservable | async
              }"
            >
              <div class="input__item">
                <app-input
                  type="email"
                  name="email"
                  placeholder="sample@hellobuki.com"
                  formControlName="email"
                  [error]="EMAIL_ERROR"
                  label="Email address"
                ></app-input>
              </div>
              <div class="input__item">
                <app-input
                  type="email"
                  name="email2"
                  placeholder="sample@hellobuki.com"
                  formControlName="email2"
                  [error]="EMAIL_MATCH_ERROR"
                  label="Email address (re-enter)"
                ></app-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>
