import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionFeaturesComponent } from './section-features.component';
import { FeatureComponent } from './components/feature/feature.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SectionFeaturesComponent, FeatureComponent],
  imports: [CommonModule, TranslateModule],
  exports: [SectionFeaturesComponent],
})
export class SectionFeaturesModule {}
