import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-use-case-item',
  templateUrl: './use-case-item.component.html',
  styleUrls: ['./use-case-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UseCaseItemComponent {
  @Input() caption!: string;

  @Input() photo!: string;

  @Input() summary!: string[];

  // @Output() buttonClick = new EventEmitter<void>();

  // constructor() {}

  // public onButtonClick(): void {
  //   this.buttonClick.emit();
  // }
}
