import { Component, TemplateRef } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { BookFormDialogComponent } from './components/book-form-dialog/book-form-dialog.component';
import { getBookByAlias } from 'app/data/books/utils';
import { IGiftReceivedData } from './components/section-gift-use/interfaces';

@Component({
  templateUrl: './gift-use-page.component.html',
  styleUrls: ['./gift-use-page.component.scss'],
})
export class GiftUsePageComponent {
  constructor(private _dialog: MatDialog) {}

  /**
   * Opens a modal dialog containing the given component.
   * @param componentOrTemplateRef Type of the component to load into the dialog,
   *     or a TemplateRef to instantiate as the dialog content.
   * @param config Extra configuration options.
   * @returns Reference to the newly-opened dialog.
   */

  private _showMatDialog<T extends { data: any }, D = T['data'], R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config?: MatDialogConfig<D>,
  ): MatDialogRef<T, R> {
    return this._dialog.open<T, D, R>(componentOrTemplateRef, {
      maxWidth: '',
      panelClass: 'book-overlay-pane',
      // backdropClass: 'order-overlay-dark-backdrop',
      disableClose: true,
      ...config,
    });
  }

  public onGiftReceived({ code, gift }: IGiftReceivedData) {
    const book = getBookByAlias(gift.book.alias);

    this._showMatDialog(BookFormDialogComponent, {
      data: {
        alias: book.alias,
        inputs: book.inputs,
        gift: code,
      },
    });
  }
}
