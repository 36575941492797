import { LINK_INSTAGRAM } from 'src/app/constants';
import type { TFooterMenu } from './types';

export const footerMainMenu: TFooterMenu = [
  {
    title: 'About BÜKI',
    link: '/about',
  },
  {
    title: 'FAQ',
    link: '/faq',
  },
  {
    title: 'Contact',
    link: '/contacts',
  },
  // {
  //   title: '法人向けサービス',
  //   link: '/collaboration',
  // },
  {
    title: '@BUKI',
    href: LINK_INSTAGRAM,
    svgIconSrc: 'assets/images/icons/instagram.svg',
  },
];

export const footerSideMenu: TFooterMenu = [
  // {
  //   title: '特定商取引法に基づく表示',
  //   link: LINK_ORDER_CONTACT,
  // },
  {
    title: 'Terms & Conditions',
    link: '/terms',
  },
  {
    title: 'Privacy Policy',
    link: '/policy',
  },
];
