import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimepickerSelectComponent } from './timepicker-select.component';
import { InputModule } from '../input/input.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [TimepickerSelectComponent],
  imports: [CommonModule, InputModule, ReactiveFormsModule],
  exports: [TimepickerSelectComponent],
})
export class TimepickerSelectModule {}
