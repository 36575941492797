<div class="wrap">
  <app-section-header-gap
    class="wrap__section-header-gap"
  ></app-section-header-gap>
  <app-section-gift-header
    class="wrap__section-gift-header"
  ></app-section-gift-header>
  <app-section-gift-use
    class="wrap__section-gift-use"
    (giftReceived)="onGiftReceived($event)"
  ></app-section-gift-use>
</div>
