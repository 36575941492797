import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookComponent } from './book.component';
import { PhotoComponent } from './components/photo/photo.component';
import { TranslateModule } from '@ngx-translate/core';
import { PriceModule } from 'src/app/shared/price/price.module';
import { TickComponent } from './components/tick/tick.component';
import { ProductRadioCardModule } from 'app/shared/product-radio-card/product-radio-card.module';

@NgModule({
  declarations: [BookComponent, PhotoComponent, TickComponent],
  imports: [CommonModule, TranslateModule, PriceModule, ProductRadioCardModule],
  exports: [BookComponent],
})
export class BookModule {}
