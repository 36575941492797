import { animate, style, transition, trigger } from '@angular/animations';
import omit from 'lodash/omit';

const slideYCommonStart = {
  display: 'block',
  opacity: 1,
  height: '*',
  marginTop: '*',
  marginLeft: '*',
  marginRight: '*',
  marginBottom: '*',
  padding: '*',
};

const slideYCommonEnd = {
  display: 'block',
  opacity: 0,
  height: 0,
  marginTop: 0,
  marginLeft: 0,
  marginRight: 0,
  marginBottom: 0,
  padding: 0,
};

type TSlideYStyles = keyof typeof slideYCommonEnd;

export const slideY = ({
  durationEnter = '150ms',
  durationLeave = '150ms',
  triggerName = 'slideY',
  preserveStyles = [],
}: {
  durationEnter?: string;
  durationLeave?: string;
  triggerName?: string;
  /** list of css parameters to remove from animation */
  preserveStyles?: TSlideYStyles[];
} = {}) => {
  return trigger(triggerName, [
    transition(':enter', [
      style(omit(slideYCommonEnd, preserveStyles)),
      animate(durationEnter, style(omit(slideYCommonStart, preserveStyles))),
    ]),
    transition(':leave', [
      style(omit(slideYCommonStart, preserveStyles)),
      animate(durationLeave, style(omit(slideYCommonEnd, preserveStyles))),
    ]),
  ]);
};

const fadeCommonStart = {
  display: 'block',
  opacity: 1,
};

const fadeCommonEnd = {
  display: 'block',
  opacity: 0,
};

export const fade = ({
  durationEnter = '150ms',
  delayEnter = '0ms',
  durationLeave = '150ms',
  delayLeave = '0ms',
  triggerName = 'fade',
} = {}) => {
  return trigger(triggerName, [
    transition(':enter', [
      style(fadeCommonEnd),
      animate(`${durationEnter} ${delayEnter}`, style(fadeCommonStart)),
    ]),
    transition(':leave', [
      style(fadeCommonStart),
      animate(`${durationLeave} ${delayLeave}`, style(fadeCommonEnd)),
    ]),
  ]);
};
