export enum EImageTitleType {
  reviews = 'reviews',
  steps = 'steps',
  faq = 'faq',
  books = 'books',
  promise = 'promise',
  story = 'story',
  media = 'media',
  contact = 'contact',
  cart = 'cart',
  collaboration = 'collaboration',
  gift = 'gift',
  checkout = 'checkout',
}
