import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductComponent } from './product.component';
import { SpecComponent } from './components/spec/spec.component';
import { PhotoComponent } from './components/photo/photo.component';
import { TitleComponent } from './components/title/title.component';
import { PriceModule } from 'src/app/shared/price/price.module';
import { DefaultComponent } from './components/default/default.component';
import { MobileComponent } from './components/mobile/mobile.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    ProductComponent,
    SpecComponent,
    PhotoComponent,
    TitleComponent,
    DefaultComponent,
    MobileComponent,
  ],
  imports: [CommonModule, PriceModule, TranslateModule],
  exports: [ProductComponent],
})
export class ProductModule {}
