import { ELoaderType } from './loader.enum';
import { IDefaultLoaderParams } from './interfaces';

// no need to define false
export const requiresConfirmationDict = {
  [ELoaderType.book]: true,
};

export const defaultLoaderParams: IDefaultLoaderParams = {
  type: ELoaderType.default,
  data: {},
};
