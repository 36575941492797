<div class="container">
  <div class="container__swiper">
    <swiper
      [navigation]="{
        prevEl: prevEl,
        nextEl: nextEl,
        disabledClass: 'navigation__item_disabled'
      }"
      [autoHeight]="true"
    >
      <ng-template
        *ngFor="let member of members"
        swiperSlide
      >
        <app-card-member
          [member]="member"
        ></app-card-member>
      </ng-template>
    </swiper>
  </div>
  <div class="container__navigation">
    <div class="navigation">
      <button #prevEl class="navigation__item navigation__item_left"></button>
      <button #nextEl class="navigation__item navigation__item_right"></button>
    </div>
  </div>
</div>
