import { IBookRadioImageInput, IBookRadioImageInputOption } from './types';
import { EBookInputType, TGetBookInput } from '../shared';

export const getBookRadioImageInputOptionData = ({
  imgSrc,
  value,
}: IBookRadioImageInputOption): IBookRadioImageInputOption => ({
  imgSrc,
  value,
});

export const getBookRadioImageInputData: TGetBookInput<IBookRadioImageInput> = (
  params,
) =>
  ({
    ...params,
    type: EBookInputType.radioImage,
  } as const);
