<div class="container">
  <div
    *ngFor="let member of members"
    class="container__section"
  >
    <app-card-member
      [member]="member"
    ></app-card-member>
  </div>
</div>
