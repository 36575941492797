<div class="wrap">
  <div class="wrap__content">
    <div class="container">
      <div class="container__title">
        <app-image-title type="steps">{{ title }}</app-image-title>
      </div>
      <div class="container__steps">
        <app-carousel
          *ngIf="showCarousel | async; else stepsTemplate"
          [steps]="steps"
        ></app-carousel>
        <ng-template #stepsTemplate>
          <app-steps [steps]="steps"></app-steps>
        </ng-template>
      </div>
    </div>
  </div>
</div>
