import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ITeamMember } from 'src/app/data/team/types';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent {
  @Input() members!: ITeamMember[];

  constructor() {}
}
