<div class="steps">
  <div
    *ngFor="let step of steps"
    class="steps__item"
  >
    <app-step
      [imgSrc]="step.imgSrc"
      [text]="step.text"
    ></app-step>
  </div>
</div>
