import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { LogoModule } from 'src/app/components/logo/logo.module';
import { StepsComponent } from './components/steps/steps.component';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [HeaderComponent, StepsComponent],
  imports: [CommonModule, LogoModule, AngularSvgIconModule],
  exports: [HeaderComponent],
})
export class HeaderModule {}
