<div
  class="container"
  (click)="onOptionClick()"
>
  <div
    *ngIf="imgSrc"
    class="container__img"
  >
    <img
      [src]="imgSrc"
      alt="#"
      class="img"
    >
  </div>
  <div
    *ngIf="imgSrc"
    class="container__tick"
  >
    <i
      class="tick"
      [ngClass]="{
        'tick_hidden': !active
      }"
    ></i>
  </div>
</div>
