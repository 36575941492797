import { IBookCheckboxInput } from './types';
import { EBookInputType, TGetBookInput } from '../shared';

export const getBookCheckboxInputData: TGetBookInput<IBookCheckboxInput> = (
  params,
) =>
  ({
    ...params,
    type: EBookInputType.checkbox,
  } as const);
