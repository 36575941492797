import { BrowserModule } from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import type { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG, SwiperModule } from 'ngx-swiper-wrapper';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import localeUs from '@angular/common/locales/es-US';
import { APP_BASE_HREF, Location, registerLocaleData } from '@angular/common';
import { Location as CustomLocation } from 'app/angular/packages/common/src/location/location';
import { ActivationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexModule } from './pages/index/index.module';
import { BookModule } from './pages/book/book.module';
import { CustomBreakPointsProvider } from './breakpoints';
import { AboutModule } from './pages/about/about.module';
import { TermsModule } from './pages/terms/terms.module';
import { FaqModule } from './pages/faq/faq.module';
import { ContactsModule } from './pages/contacts/contacts.module';
import { NotFoundModule } from './pages/not-found/not-found.module';
import { CartModule } from './pages/cart/cart.module';
import { OrderModule } from './pages/order/order.module';
import { LogoModule } from './components/logo/logo.module';
import { PolicyModule } from './pages/policy/policy.module';
import { OrdercontractModule } from './pages/ordercontract/ordercontract.module';
import { InputModule } from './shared/input/input.module';
import { HeaderModule } from './components/header/header.module';
import { PreviewModule } from './pages/preview/preview.module';
import { SectionFooterModule } from './shared/section-footer/section-footer.module';
import { AllBooksModule } from './pages/all-books/all-books.module';
import { LoaderModule } from './components/loader/loader.module';
import { CollaborationPageModule } from './pages/collaboration-page/collaboration-page.module';
import { GiftBuyPageModule } from './pages/gift-buy-page/gift-buy-page.module';
import { GiftBuySpecialPageModule } from './pages/gift-buy-special-page/gift-buy-special-page.module';
import { GiftUsePageModule } from './pages/gift-use-page/gift-use-page.module';
import { GiftThanksPageModule } from './pages/gift-thanks-page/gift-thanks-page.module';
import { GiftOrderPageModule } from './pages/gift-order-page/gift-order-page.module';
import {
  ROUTE_BOOK,
  ROUTE_BOOK_STARRY_DREAM,
  ROUTE_BOOK_STORY_OF_GRANDPA_GRANDMA,
  ROUTE_BOOK_WELCOME_TO_THE_WORLD,
  ROUTE_BOOK_WHERE_IS_BIRTHDAY_CAKE,
  ROUTE_BOOK_WHOS_BIRTHDAY_TOMORROW,
  ROUTE_GIFT_BUY,
  ROUTE_GIFT_BUY_SPECIAL,
  ROUTE_GIFT_USE,
} from './routes';
import { HeaderService } from './services/header.service';
import { ROUTE_QUERY_PARAM_GIFT } from './app-routing.constants';
import { AuthPageModule } from './pages/auth-page/auth-page.module';
import { MyPageModule } from './pages/my-page/my-page.module';
import { BASE_HREF } from './constants';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
};

function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http, './app/translations/', '.json'); // assets
}

registerLocaleData(localeUs, 'en-US');

@NgModule({
  declarations: [AppComponent],
  imports: [
    FlexLayoutModule.withConfig({ disableDefaultBps: true }),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    IndexModule,
    AboutModule,
    TermsModule,
    PolicyModule,
    FaqModule,
    ContactsModule,
    NotFoundModule,
    CartModule,
    OrderModule,
    OrdercontractModule,
    BookModule,
    SwiperModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    LogoModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      useDefaultLang: false,
    }),
    InputModule,
    HeaderModule,
    PreviewModule,
    SectionFooterModule,
    AllBooksModule,
    LoaderModule,
    CollaborationPageModule,
    GiftBuyPageModule,
    GiftBuySpecialPageModule,
    GiftUsePageModule,
    GiftThanksPageModule,
    GiftOrderPageModule,
    AuthPageModule,
    MyPageModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        // TODO: turn on when possible
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
    CustomBreakPointsProvider,
    { provide: LOCALE_ID, useValue: 'en-US' },
    { provide: APP_BASE_HREF, useValue: BASE_HREF },
    {
      provide: Location,
      useExisting: CustomLocation,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private _router: Router, private _headerService: HeaderService) {
    this._router.events
      .pipe(
        filter(
          (event): event is ActivationEnd => event instanceof ActivationEnd,
        ),
      )
      .subscribe((event) => {
        const { queryParamMap, routeConfig } = event.snapshot;

        switch (routeConfig.path) {
          case ROUTE_GIFT_BUY.path:
          case ROUTE_GIFT_BUY_SPECIAL.path:
          case ROUTE_GIFT_USE.path:
            this._headerService.hideCart();
            break;

          case ROUTE_BOOK.path:
          case ROUTE_BOOK_STARRY_DREAM.path:
          case ROUTE_BOOK_WELCOME_TO_THE_WORLD.path:
          case ROUTE_BOOK_WHOS_BIRTHDAY_TOMORROW.path:
          case ROUTE_BOOK_WHERE_IS_BIRTHDAY_CAKE.path:
          case ROUTE_BOOK_STORY_OF_GRANDPA_GRANDMA.path:
            const gift = queryParamMap.get(ROUTE_QUERY_PARAM_GIFT);

            if (gift) {
              this._headerService.hideCart();
            } else {
              this._headerService.showCart();
            }

            break;

          default:
            this._headerService.showCart();
            break;
        }
      });
  }
}
