/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, Injector, Input, forwardRef } from '@angular/core';
import {
  IBookInputNameToValueMap,
  TBookInputs,
} from 'app/data/books/inputs/types';
import { Logger } from 'app/services/logger.service';
import { InputCommon } from 'app/shared/input/input.common';
import { BookFormMixin } from './book-form.common';
import { Constructor } from 'typescript-mix';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Provider } from '@angular/compiler/src/core';

const log = new Logger('BookFormInputCommon');

const Mix =
  BookFormMixin<Constructor<InputCommon<IBookInputNameToValueMap>>>(
    InputCommon,
  );

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BookFormInputCommon),
  multi: true,
};

@Component({
  template: '',
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class BookFormInputCommon extends Mix {
  @Input() set inputs(inputs: TBookInputs) {
    super.inputs = inputs;
  }

  get inputs() {
    return super.inputs;
  }

  @Input() public error = super.error;

  @Input() set value(value: IBookInputNameToValueMap | undefined) {
    super.value = value;
  }

  get value() {
    return super.value;
  }

  constructor(protected _injector: Injector) {
    super(_injector);

    this._sub.push(
      // * handle inside change
      this.formGroup.valueChanges.subscribe((data) => {
        this.value = data;
      }),
      // * handle outside change
      this._value$.subscribe((value) => {
        log.info('_value$', value);

        this.setFormGroupValues(value);
      }),
    );
  }

  ngOnDestroy() {
    this._ngOnDestroy();
  }
}
