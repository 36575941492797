import type { AfterViewInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TBookPrices } from 'app/data/books/prices/types';
import type { IBook } from 'app/data/books/types';
import { TBookAlias } from '@shared/book/interfaces';
import { YotpoService } from 'app/services/yotpo.service';
import { ALIAS_TO_YOTPO_EXTERNAL_ID_MAP } from '@shared/book/maps';

@Component({
  selector: 'app-section-reviews-yotpo-book',
  templateUrl: './section-reviews-yotpo-book.component.html',
  styleUrls: ['./section-reviews-yotpo-book.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionReviewsYotpoBookComponent implements AfterViewInit {
  @Input() alias!: TBookAlias;

  @Input() caption!: IBook['caption'];

  @Input() prices!: TBookPrices;

  /** relative path */
  @Input() photo!: string;

  get bookExternalId() {
    return ALIAS_TO_YOTPO_EXTERNAL_ID_MAP[this.alias];
  }

  get bookPrice() {
    return (this.prices[0]?.[1]?.value || 0) / 100;
  }

  get pageOrigin() {
    return window.location.origin;
  }

  get pageUrl() {
    return window.location.href;
  }

  constructor(private yotpo: YotpoService) {}

  ngAfterViewInit(): void {
    this.yotpo.init();
  }
}
