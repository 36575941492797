<div class="container">
  <div class="container__img">
    <img [src]="imgSrc" alt="#" class="img">
  </div>
  <div class="container__text">
    <div class="text">
      <p
        *ngFor="let textItem of text"
        class="text__item"
      >{{ textItem | translate }}</p>
    </div>
  </div>
</div>
