import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  Provider,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  IBookCanvasInput,
  IBookCanvasInputCrop,
  IBookCanvasInputItem,
} from 'app/data/books/inputs/canvas/types';
import { MediaService } from 'app/services/media.service';
import { slideY } from 'app/animations';
import { BookFormInputCommon } from '../../book-form-input.common';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputCanvasComponent),
  multi: true,
};

@Component({
  selector: 'app-input-canvas',
  templateUrl: './input-canvas.component.html',
  styleUrls: ['./input-canvas.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    slideY({
      durationEnter: '300ms',
      durationLeave: '300ms',
      preserveStyles: ['display', 'marginLeft'],
    }),
  ],
})
export class InputCanvasComponent extends BookFormInputCommon /*  implements OnInit */ {
  @Input() label!: string;

  @Input() items!: IBookCanvasInputItem[];

  /** canvas width */
  @Input() width!: IBookCanvasInput['width'];

  /** canvas height */
  @Input() height!: IBookCanvasInput['height'];

  /** defines a negative canvas margin */
  @Input() crop?: IBookCanvasInputCrop;

  /** defines a negative canvas margin */
  @Input() cropLtMd?: IBookCanvasInputCrop;

  /** disables a canvas background on lt-md  */
  @Input() noLtMdBackground?: IBookCanvasInput['noLtMdBackground'];

  /** defines a canvas border-radius */
  @Input() borderRadius?: IBookCanvasInput['borderRadius'];

  constructor(protected _injector: Injector, public media: MediaService) {
    super(_injector);
  }

  public getCurrentCrop = (isLtMd: boolean) =>
    isLtMd ? this.cropLtMd : this.crop;
}
