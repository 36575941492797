<div class="wrap">
  <app-section-header-gap></app-section-header-gap>
  <app-image-title
    type="cart"
    class="wrap__section wrap__section_image-title"
  ></app-image-title>
  <!-- <app-section-message
    class="wrap__section wrap__section_section-message"
    message="送料：1冊の場合は385円（全国一律）、2冊以上で770円〜（お届け先により変動）"
  ></app-section-message> -->
  <app-section-message-date
    class="wrap__section wrap__section_section-message-date"
  ></app-section-message-date>
  <app-section-cart
    class="wrap__section wrap__section_section-cart"
  ></app-section-cart>
</div>
