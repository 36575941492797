import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';
import { ELoaderType } from './loader.enum';
import {
  loaderParamsToBookLoaderParams,
  loaderParamsToDefaultLoaderParams,
} from './functions';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  public readonly ELoaderType = ELoaderType;

  public readonly loaderParamsToBookLoaderParams =
    loaderParamsToBookLoaderParams;

  public readonly loaderParamsToDefaultLoaderParams =
    loaderParamsToDefaultLoaderParams;

  constructor(public service: LoaderService) {}
}
