<div class="wrap">
  <div class="wrap__container">
    <app-tabs>
      <app-tabs-item
        imgSrcActive="assets/images/collaboration-tab-1-active.png"
        imgSrcInactive="assets/images/collaboration-tab-1-inactive.png"
        title="パーソナライズ絵本出版"
      >
        <div class="content">
          <div class="content__title">
            <h4 class="title">御社の絵本の中に、子供たちを登場させてみませんか？<br>BÜKIは御社のパーソナライズ絵本の企画からお客さまのサポートまで、一気通貫で併走しサポートします。</h4>
          </div>
          <div class="content__grid">
            <app-tab-point
              imgSrc="assets/images/collaboration-tab-1-point-1.png"
            >
              <p class="text">パーソナライズ絵本の企画から制作まで、必要なことは何でもBÜKIがお手伝い</p>
              <p class="label">どういうこと？</p>
              <ul class="list">
                <li class="list__item">内容からデザインまで、パーソナライズ絵本を一緒に企画します</li>
                <li class="list__item">イラストレーターのご紹介</li>
                <li class="list__item">デジタルでの絵本制作をサポート</li>
              </ul>
            </app-tab-point>
            <app-tab-point
              imgSrc="assets/images/collaboration-tab-1-point-2.png"
            >
              <p class="text">BÜKIのパーソナライズ絵本プラットフォームをご利用いただけます</p>
              <p class="label">どういうこと？</p>
              <ul class="list">
                <li class="list__item">名前や性別での主人公のカスタマイズに加え、キャラクターやストーリーもカスタマイズ可能なプラットフォーム</li>
                <li class="list__item">絵本の表紙や本文の中に、様々な形で子供の名前を加えることができます</li>
                <li class="list__item">写真や生まれた日の星空を加えたり、条件によってイラストを加えたり、豊富なカスタマイズをすることができます</li>
              </ul>
            </app-tab-point>
            <app-tab-point
              imgSrc="assets/images/collaboration-tab-1-point-3.png"
            >
              <p class="text">購入後は自動でファイルが生成され、提携している印刷工場で印刷・梱包・発送。日々の運用にかかる手間はありません。</p>
            </app-tab-point>
            <app-tab-point
              imgSrc="assets/images/collaboration-tab-1-point-4.png"
            >
              <p class="text">お客さまとのやりとりから売上管理まで、販売後もお任せください</p>
            </app-tab-point>
          </div>
        </div>
      </app-tabs-item>
      <app-tabs-item
        imgSrcActive="assets/images/collaboration-tab-2-active.png"
        imgSrcInactive="assets/images/collaboration-tab-2-inactive.png"
        title="景品利用・大口注文"
      >
        <div class="content">
          <div class="content__title">
            <h4 class="title">世界でたった一冊の特別な絵本で、子供をもっと笑顔に。<br>BÜKIのパーソナライズ絵本をキャンペーンの景品や、プレゼントとして利用しませんか？</h4>
          </div>
          <div class="content__grid">
            <app-tab-point
              imgSrc="assets/images/collaboration-tab-2-point-1.png"
            >
              <p class="text">保育園や幼稚園、また学校から、子供たちへ。また会社から社員の子供たちへのプレゼントとして</p>
            </app-tab-point>
            <app-tab-point
              imgSrc="assets/images/collaboration-tab-2-point-2.png"
            >
              <p class="text">ご利用数により、ある程度の割引が適応可能です</p>
            </app-tab-point>
            <app-tab-point
              imgSrc="assets/images/collaboration-tab-2-point-3.png"
            >
              <p class="text">相手にギフトコードを贈るだけで、簡単プレゼント</p>
            </app-tab-point>
            <app-tab-point
              imgSrc="assets/images/collaboration-tab-2-point-4.png"
            >
              <p class="text">わからないことは何でも、BÜKIの担当者にお問い合わせください</p>
            </app-tab-point>
          </div>
        </div>
      </app-tabs-item>
    </app-tabs>
  </div>
</div>
