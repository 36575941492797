import { Component } from '@angular/core';

@Component({
  selector: 'app-section-left',
  templateUrl: '../../templates/section.template.html',
  styleUrls: ['./section-left.component.scss'],
})
export class SectionLeftComponent {
  // constructor() {}
}
