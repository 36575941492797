import type { ErrorObject } from 'serialize-error';
import { Logger } from '../../logger';
import { EGiftOrderStatus, EGiftOrderType } from '../enums';
import type {
  IGiftOrderBook,
  IUserGift,
  IUserGiftNotExist,
  TGiftOrderCreateParamsError,
  TGiftOrderCreated,
  TGiftOrderError,
  TGiftOrderGenerated,
  TGiftOrderPaid,
  TGiftOrderSpecial,
  TGiftOrderStandard,
} from '../interfaces';

export const checkGiftOrderError = (
  giftOrder: any,
): giftOrder is TGiftOrderCreateParamsError | TGiftOrderError =>
  !!giftOrder.error;

export const checkCreatedGiftOrder = (
  giftOrder: any,
): giftOrder is TGiftOrderCreated =>
  giftOrder.status === EGiftOrderStatus.created;

export const checkPaidGiftOrder = (
  giftOrder: any,
): giftOrder is TGiftOrderPaid => giftOrder.status === EGiftOrderStatus.paid;

export const checkGeneratedGiftOrder = (
  giftOrder: any,
): giftOrder is TGiftOrderGenerated =>
  giftOrder.status === EGiftOrderStatus.generated;

export const checkStandartGiftOrder = (
  giftOrder: any,
): giftOrder is TGiftOrderStandard =>
  giftOrder.type === EGiftOrderType.standart;

export const checkSpecialGiftOrder = (
  giftOrder: any,
): giftOrder is TGiftOrderSpecial => giftOrder.type === EGiftOrderType.special;

const checkErrorLog = new Logger('checkError');

export const checkError = <T>(obj?: any): obj is T & { error: ErrorObject } => {
  const log = checkErrorLog;

  if (!obj) {
    log.warn({ obj });
    return false;
  }

  const { error } = obj;

  if (typeof error !== 'object') {
    log.warn({ error });
    return false;
  }

  return true;
};

export const checkExpired = (expiration: Date): boolean => {
  const now = new Date();

  return expiration.getTime() <= now.getTime();
};

const checkGiftOrderBookLog = new Logger('checkGiftOrderBook');

export const checkGiftOrderBook = (book: any): book is IGiftOrderBook => {
  const log = checkGiftOrderBookLog;

  if (!book) {
    log.warn({ book });
    return false;
  }

  const { alias, cover, wrapping } = book;

  if (!alias) {
    log.warn({ alias });
    return false;
  }

  if (!cover) {
    log.warn({ cover });
    return false;
  }

  if (!wrapping) {
    log.warn({ wrapping });
    return false;
  }

  return true;
};

const checkUserGiftLog = new Logger('checkUserGift');

export const checkUserGift = (userGift: any): userGift is IUserGift => {
  const log = checkUserGiftLog;

  if (!userGift) {
    log.warn({ userGift });
    return false;
  }

  const { exist, book, applied, expired } = userGift;

  if (exist !== true) {
    log.warn({ exist });
    return false;
  }

  if (!checkGiftOrderBook(book)) {
    log.warn({ book });
    return false;
  }

  if (typeof applied !== 'boolean') {
    log.warn({ applied });
    return false;
  }

  if (typeof expired !== 'boolean') {
    log.warn({ expired });
    return false;
  }

  return true;
};

const checkUserGiftNotExistLog = new Logger('checkUserGiftNotExist');

export const checkUserGiftNotExist = (
  userGift: any,
): userGift is IUserGiftNotExist => {
  const log = checkUserGiftNotExistLog;

  if (!userGift) {
    log.warn({ userGift });
    return false;
  }

  const { exist } = userGift;

  if (exist !== false) {
    log.warn({ exist });
    return false;
  }

  return true;
};
