<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div class="container__section container__section_left">
        <div class="content">
          <div class="content__section content__section_products">
            <ng-container
              *ngIf="
                (discountService.activeDiscountCampaignsObservable | async) ===
                undefined
              "
            >
              <app-product
                class="product"
                *ngFor="let p of products$ | async"
                [id]="p.bookId"
                [title]="p.name"
                [specs]="p.specs"
                [price]="getProductPrice(p)"
                [photo]="p.image"
                (close)="removeProduct(p)"
              >
                <!-- <div class="product__select">
                  <app-select
                    [value]="p.wrapping.name"
                    (change)="selectWrapping(p, $event)"
                  >
                    <app-select-option *ngFor="let w of p.wrappings"
                      [label]="w.label"
                      [title]="w.name"
                      [photo]="w.image"
                      [price]="w.price.currency+w.price.value"
                      [value]="w.name"
                      [descr]="w.description"
                    ></app-select-option>
                  </app-select>
                </div> -->
                <div
                  class="product__radio"
                  *ngFor="let cover of p.covers"
                >
                  <app-product-radio-card
                    [active]="cover.name === p.cover.name"
                    [imgSrc]="cover.image"
                    [caption]="coverNameMap[cover.name]"
                    [summary]="cover.summary"
                    [price]="cover.price"
                    (radioCardClick)="selectCover(p, cover.name)"
                  ></app-product-radio-card>
                </div>
              </app-product>
            </ng-container>
            <ng-container
              *ngIf="
                discountService.activeDiscountCampaignsObservable
                  | async as discountCampaign
              "
            >
              <app-product
                class="product"
                *ngFor="let p of products$ | async"
                [id]="p.bookId"
                [title]="p.name"
                [specs]="p.specs"
                [price]="getProductPrice(p)"
                [discount]="getBooksDiscount(discountCampaign, p.alias)"
                [photo]="p.image"
                (close)="removeProduct(p)"
              >
                <!-- <div class="product__select">
                  <app-select
                    [value]="p.wrapping.name"
                    (change)="selectWrapping(p, $event)"
                  >
                    <app-select-option *ngFor="let w of p.wrappings"
                      [label]="w.label"
                      [title]="w.name"
                      [photo]="w.image"
                      [price]="w.price.currency+w.price.value"
                      [value]="w.name"
                      [descr]="w.description"
                    ></app-select-option>
                  </app-select>
                </div> -->
                <div
                  class="product__radio"
                  *ngFor="let cover of p.covers"
                >
                  <app-product-radio-card
                    [active]="cover.name === p.cover.name"
                    [imgSrc]="cover.image"
                    [caption]="cover.name"
                    [summary]="cover.summary"
                    [price]="cover.price"
                    [discount]="getBooksDiscount(discountCampaign, p.alias)"
                    (radioCardClick)="selectCover(p, cover.name)"
                  ></app-product-radio-card>
                </div>
              </app-product>
            </ng-container>
          </div>
          <div class="content__section content__section_button">
            <app-button
              color="accent-2"
              (buttonClick)="addAnotherBookButtonClick()"
              [fullWidth]="true"
              >Add another book</app-button
            >
          </div>
          <div
            class="content__section content__section_payment"
            fxHide.lt-lg
          >
            <app-payments></app-payments>
          </div>
        </div>
      </div>
      <div class="container__section container__section_right">
        <div class="sidebar">
          <div
            *ngIf="calculatorItems$ | async as calculatorItems"
            class="sidebar__calculator"
          >
            <div class="calculator">
              <div class="calculator__component">
                <app-calculator [items]="calculatorItems"></app-calculator>
              </div>
              <p class="calculator__footer-text">
                * Shipping fee may vary depending on the shipping method and
                shipping address.
              </p>
            </div>
          </div>
          <div
            *ngIf="order$ | async as order"
            class="sidebar__coupon"
          >
            <app-coupon
              [orderId]="order.orderId"
              [coupon]="(coupon$ | async) || undefined"
              (couponChange)="coupon$.next($event)"
            ></app-coupon>
          </div>
          <div class="sidebar__button">
            <div class="proceed-btn">
              <p class="proceed-btn__header-text">
                Kindly double-check your order. We are unable <br />
                to cancel after production has started.
              </p>
              <div class="proceed-btn__component">
                <app-button
                  color="default"
                  [disabled]="!((isProceed$ | async) === true)"
                  [icons]="['arrow-right']"
                  (buttonClick)="proceed()"
                  [fullWidth]="true"
                  >Checkout</app-button
                >
              </div>
              <p
                *ngIf="isLimitExceeded$ | async"
                class="proceed-btn__footer-text"
              >
                You can order only {{ LIMIT_BOOKS_TO_ORDER }} books per 1 order.
                If you want to order more, please separate your order. Sorry for
                inconvenience.
              </p>
            </div>
          </div>
          <div
            class="sidebar__payment"
            fxHide.gt-md
          >
            <app-payments></app-payments>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
