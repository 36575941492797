<app-field
  *ngIf="id$ | async as id"
  [label]="label"
  [error]="showError$ | async"
  [forId]="id"
  style="text-align: center"
>
  <form
    class="form"
    [formGroup]="formGroup"
    style="text-align: left"
  >
    <div
      *ngFor="let input of inputs"
      [ngClass]="getFormItemClass(input)"
      @slideY
    >
      <app-input
        *ngIf="bookInputToText(input) as input"
        [name]="input.name"
        [placeholder]="input.placeholder | translate"
        [label]="input.label | translate"
        [formControl]="formGroup.controls[input.name]"
        [error]="input.error"
        [theme]="EInputTheme.alt"
      ></app-input>
      <app-input-date
        *ngIf="bookInputToDate(input) as input"
        [placeholder]="input.placeholder | translate"
        [label]="input.label | translate"
        [max]="input.max"
        [min]="input.min"
        [formControl]="formGroup.controls[input.name]"
        [theme]="EInputTheme.alt"
      ></app-input-date>
      <ng-container *ngIf="bookInputToSelect(input) as input">
        <ng-container [ngSwitch]="input.selectType">
          <app-select-image-text
            *ngSwitchCase="EBookSelectInputType.imageText"
            type="select"
            [name]="input.name"
            [placeholder]="input.placeholder | translate"
            [label]="input.label | translate"
            [error]="input.error"
            [options]="input.options"
            [formControl]="formGroup.controls[input.name]"
            [theme]="EInputTheme.alt"
          ></app-select-image-text>
          <app-input
            *ngSwitchDefault
            type="select"
            [name]="input.name"
            [placeholder]="input.placeholder | translate"
            [label]="input.label | translate"
            [formControl]="formGroup.controls[input.name]"
            [theme]="EInputTheme.alt"
          >
            <option
              *ngFor="let option of input.options"
              [value]="option.value"
            >
              {{ option.label }}
            </option>
          </app-input>
        </ng-container>
      </ng-container>
      <app-input-radio
        *ngIf="bookInputToRadio(input) as input"
        [label]="input.label | translate"
        [formControl]="formGroup.controls[input.name]"
        [options]="input.options"
        [styleCondensed]="
          input.name === 'whos-celebrating' && (media.isSmObservable | async)
        "
        [styleColumns]="
          (input.name === 'whos-celebrating' &&
            (media.isXsObservable | async) &&
            1) ||
          0
        "
        justifyContent="space-between"
      ></app-input-radio>
      <app-input-radio-image
        *ngIf="bookInputToRadioImage(input) as input"
        [label]="input.label | translate"
        [formControl]="formGroup.controls[input.name]"
        [options]="input.options"
        [theme]="EInputTheme.alt"
      ></app-input-radio-image>
      <app-input-radio-color
        *ngIf="bookInputToRadioColor(input) as input"
        [label]="input.label | translate"
        [formControl]="formGroup.controls[input.name]"
        [options]="input.options"
        [theme]="EInputTheme.alt"
        justifyContent="space-between"
      ></app-input-radio-color>
      <app-input
        *ngIf="bookInputToCheckbox(input) as input"
        [label]="input.label | translate"
        [formControl]="formGroup.controls[input.name]"
        type="checkbox"
        [theme]="EInputTheme.alt"
      ></app-input>
    </div>
  </form>
</app-field>
