import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TBookAlias, TBookCover, TBookWrapping } from '@shared/book/interfaces';
import type { IBook } from 'app/data/books/types';
import { TBookPhoto } from 'src/app/data/books/photos/types';
import { IBookNotice } from 'src/app/data/books/notice/types';
import type { IBookPrice } from 'src/app/data/books/prices/types';
import { TBookPrices } from 'src/app/data/books/prices/types';
import { INPUT_VALUE_COVER_TYPE_SOFT } from '@shared/book/constants';
import type { Cover } from 'src/app/interfaces';
import { FEToBEShipping, getCovers } from 'src/app/functions';
import { slideY } from 'src/app/animations';
import { MediaService } from 'src/app/services/media.service';
import { DiscountService } from 'src/app/services/discount.service';
import { map } from 'rxjs/operators';
import { getBooksDiscount, getShippingDiscount } from '@shared/discount/utils';
import type { IDiscountCampaign } from '@shared/discount/interfaces';
// import { Logger } from 'src/app/services/logger.service';

// const log = new Logger('SectionGiftBooksComponent -> BookComponent');

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideY()],
})
export class BookComponent {
  @Input() selected = false;

  @Input() alias!: TBookAlias;

  @Input() photo!: TBookPhoto;

  @Input() caption!: IBook['caption'];

  @Input() summary!: IBook['summary'];

  @Input() notice!: IBookNotice;

  @Input() prices!: TBookPrices;

  @Output() bookClick = new EventEmitter<void>();

  @Input() set cover(value: TBookCover) {
    if (this._coverSubject.value !== value) {
      this._coverSubject.next(value);
      this.coverChange.emit(value);
    }
  }

  @Output() coverChange = new EventEmitter<TBookCover>();

  @Input() set wrapping(value: TBookWrapping) {
    if (this._wrappingSubject.value !== value) {
      this._wrappingSubject.next(value);
      this.wrappingChange.next(value);
    }
  }

  @Output() wrappingChange = new EventEmitter<TBookWrapping>();

  get minPrice(): IBookPrice | undefined {
    const coverPrice = this.prices[0];

    return coverPrice && coverPrice[1];
  }

  get covers(): Cover[] {
    return getCovers(this.alias, this.prices);
  }

  private _coverSubject = new BehaviorSubject<TBookCover>(
    INPUT_VALUE_COVER_TYPE_SOFT,
  );

  public coverObservable = this._coverSubject.asObservable();

  private _wrappingSubject = new BehaviorSubject<TBookWrapping>('standart');

  public wrappingObservable = this._wrappingSubject.asObservable();

  private _hoverSubject = new BehaviorSubject(false);

  public hoverObservable = this._hoverSubject.asObservable();

  public discountObservable =
    this._discount.activeDiscountCampaignsObservable.pipe(
      map((_dC) => _dC && getBooksDiscount(_dC, this.alias)),
    );

  public shippingDiscountObservable =
    this._discount.activeDiscountCampaignsObservable.pipe(
      map((_dC) => {
        const shipping = _dC && this.minPrice?.shippingIncluded;

        if (shipping) {
          return getShippingDiscount(
            _dC as IDiscountCampaign,
            FEToBEShipping(shipping),
          );
        }
      }),
    );

  constructor(public media: MediaService, private _discount: DiscountService) {}

  public onBookClick(): void {
    this.bookClick.emit();
  }

  public onCoverClick(cover: TBookCover): void {
    this.cover = cover;
  }

  public onWrappingClick(wrapping: TBookWrapping): void {
    this.wrapping = wrapping;
  }

  public onMouseenter(): void {
    this._hoverSubject.next(true);
  }

  public onMouseleave(): void {
    this._hoverSubject.next(false);
  }
}
