import {
  ADDRESS_2_MAX_LENGTH,
  ADDRESS_3_MAX_LENGTH,
  NAME_MAX_LENGTH,
  PHONE_MAX_LENGTH,
  POSTAL_CODE_MAX_LENGTH,
} from './validators.constants';
import { FormControl, Validators } from '@angular/forms';
import {
  bookNameEnValidator,
  bookNameValidator,
  createBookMessageValidator,
  createValidator,
  numbersValidator,
  stripeCardValidator,
} from './validators.functions';
import type { TBookInput } from './data/books/inputs/types';
import {
  bookInputShowWhenCheck,
  inputToFormControl,
} from './data/books/inputs/utils';

export const getGroupValidatorList = (inputs: TBookInput[]) => [
  Validators.required,
  createValidator((control) => {
    const { value: values } = control;

    if (values instanceof Object) {
      const invalid = inputs
        .filter(({ showWhen }) => bookInputShowWhenCheck(values, showWhen))
        .some((input) => {
          const _control = inputToFormControl(input);

          const validator = _control.validator;

          // TODO: check if it doesn't cause the memory leak
          const errors = validator?.(new FormControl(values[input.name]));

          return !!errors;
        });

      return invalid;
    }

    return true;
  }),
];

export const getDateValidatorList = (min?: Date, max?: Date) => [
  Validators.required,
  createValidator((control) => {
    const { value } = control;

    if (value instanceof Date) {
      if (min instanceof Date && value.getTime() < min.getTime()) {
        return true;
      }

      if (max instanceof Date && value.getTime() > max.getTime()) {
        return true;
      }

      return false;
    }

    return true;
  }),
];

export const getMultiselectValidatorList = (min?: number, max?: number) => [
  Validators.required,
  createValidator((control) => {
    const { value } = control;

    if (value instanceof Object) {
      const activeKeys = Object.keys(value).filter((key) => value[key]);

      if (min && activeKeys.length < min) {
        return true;
      }

      if (max && activeKeys.length > max) {
        return true;
      }

      return false;
    }

    return true;
  }),
];

export const getBookNameValidatorList = (
  maxLength: number,
  minLength?: number,
) => {
  const arr = [
    Validators.required,
    Validators.maxLength(maxLength),
    bookNameValidator,
  ];

  if (minLength) {
    arr.push(Validators.minLength(minLength));
  }

  return arr;
};

export const getBookNameEnValidatorList = (
  maxLength: number,
  minLength?: number,
) => {
  const arr = [
    Validators.required,
    Validators.maxLength(maxLength),
    bookNameEnValidator,
  ];

  if (minLength) {
    arr.push(Validators.minLength(minLength));
  }

  return arr;
};

// book message
export const getBookMessageValidatorList = (
  maxCharsInRow: number,
  maxRows: number,
) => [Validators.required, createBookMessageValidator(maxCharsInRow, maxRows)];

// name
export const firstNameValidatorList = [
  Validators.required,
  Validators.maxLength(NAME_MAX_LENGTH),
  // firstNameValidator,
];

export const lastNameValidatorList = [
  Validators.required,
  Validators.maxLength(NAME_MAX_LENGTH),
  // lastNameValidator,
];

// postal code
export const postalCodeValidatorList = [
  Validators.required,
  Validators.maxLength(POSTAL_CODE_MAX_LENGTH),
  numbersValidator,
];

// address
// STREET
export const addressFirstValidatorList = [Validators.required];

// APT
export const addressSecondValidatorList = [
  // Validators.required,
  Validators.maxLength(ADDRESS_2_MAX_LENGTH),
];

// CITY
export const cityValidatorList = [
  Validators.required,
  Validators.maxLength(ADDRESS_3_MAX_LENGTH),
];

export const countryValidatorList = [Validators.required];

export const stateValidatorList = [Validators.required];

// phone
export const phoneValidatorList = [
  Validators.maxLength(PHONE_MAX_LENGTH),
  numbersValidator,
];

// email
export const emailValidatorList = [Validators.required, Validators.email];

// card
export const stripeCardValidatorList = [
  Validators.required,
  stripeCardValidator,
];
