<div
  *ngIf="data"
  class="container"
  [ngClass]="['container_'+data.alias]"
>
  <div class="container__title">
    <h2 class="title">物語の主人公になれる絵本が<br>プレゼントされました！</h2>
  </div>
  <div class="container__subtitle">
    <h3 class="subtitle">この絵本の主人公になる子は、誰ですか？</h3>
  </div>
  <app-book-form
    class="container__content"
    [alias]="data.alias"
    [inputs]="data.inputs"
    [gift]="data.gift"
    [unwrapped]="true"
  ></app-book-form>
</div>
