<div class="container">
  <div
    *ngFor="let c of cases"
    class="container__section"
  >
    <app-use-case-item
      [caption]="c.caption"
      [photo]="c.photo"
      [summary]="c.summary"
    ></app-use-case-item>
  </div>
</div>
