import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-tabs-item',
  template: '<ng-template><ng-content></ng-content></ng-template>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsItemComponent {
  @Input() imgSrcActive!: string;

  @Input() imgSrcInactive!: string;

  @Input() title!: string;

  @ViewChild(TemplateRef) template!: TemplateRef<any>;

  // constructor() {}
}
