import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  Provider,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IBookRadioColorInput } from 'app/data/books/inputs/radioColor/types';
import { EInputTheme } from '../../interfaces';
import type { Property } from 'csstype/index.d';
import { InputCommon } from '../../input.common';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputRadioColorComponent),
  multi: true,
};

@Component({
  selector: 'app-input-radio-color',
  templateUrl: './input-radio-color.component.html',
  styleUrls: ['./input-radio-color.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputRadioColorComponent extends InputCommon<string> {
  @Input() public label = '';

  @Input() public options!: IBookRadioColorInput['options'];

  @Input() public theme: EInputTheme = EInputTheme.default;

  @Input() public justifyContent?: Property.JustifyContent;

  constructor(protected _injector: Injector) {
    super(_injector);
  }
}
