<div class="container">
  <div class="container__title">
    <p class="title">Estimated Arrival Date</p>
  </div>
  <div class="container__date">
    <p class="date">{{ arrivalDate$ | async }}</p>
  </div>
  <!-- <div class="container__subtitle">
    <p class="subtitle">*Subject to delivery option and location*</p>
  </div> -->
</div>
