import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { getDiscountCampaignsColRef } from './discount.functions';
import { map } from 'rxjs/operators';
import type { TDiscountCampaigns } from '@shared/discount/interfaces';
import {
  getActiveDiscountCampaign,
  parseDiscountCampaign,
} from '@shared/discount/utils';

@Injectable({
  providedIn: 'root',
})
export class DiscountService {
  private readonly _discountCampaignsSubject =
    new BehaviorSubject<TDiscountCampaigns>({});

  public readonly discountCampaignsObservable =
    this._discountCampaignsSubject.asObservable();

  public readonly activeDiscountCampaignsObservable =
    this.discountCampaignsObservable.pipe(map(getActiveDiscountCampaign));

  constructor() {
    this._init();
  }

  /** can be triggered only once */
  private _init() {
    getDiscountCampaignsColRef().onSnapshot((snapshot) => {
      const discountCampaigns: TDiscountCampaigns = {};

      snapshot.docs.forEach((doc) => {
        discountCampaigns[doc.id] = parseDiscountCampaign(doc.data());
      });

      this._discountCampaignsSubject.next(discountCampaigns);
    });
  }
}
