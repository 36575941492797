<div class="container">
  <div class="container__navigation">
    <div class="navigation">
      <div #prevEl class="navigation__item navigation__item_left"></div>
      <div #nextEl class="navigation__item navigation__item_right"></div>
    </div>
  </div>
  <div class="container__photos">
    <swiper
      style="--swiper-navigation-color: #fff;--swiper-pagination-color: #fff"
      (swiper)="photosSwiper = $event"
      [spaceBetween]="10"
      [navigation]="{
        prevEl: prevEl,
        nextEl: nextEl,
        disabledClass: 'navigation__item_disabled'
      }"
      [loop]="loop"
      [loopedSlides]="loopedSlides"
      [controller]="{
        control: thumbsSwiper
      }"
      class="photos"
    >
      <ng-template
        *ngFor="let src of photos"
        swiperSlide
      >
        <img
          [src]="src"
          alt="#"
          class="photos__img"
        >
      </ng-template>
    </swiper>
  </div>
  <div
    class="container__thumbs"
    [ngStyle]="!(showThumbs | async) && {
      'display': 'none'
    }"
  >
    <swiper
      (swiper)="thumbsSwiper = $event"
      [spaceBetween]="10"
      [centeredSlides]="true"
      [loop]="loop"
      [loopedSlides]="loopedSlides"
      [slidesPerView]="5"
      [slideToClickedSlide]="true"
      [controller]="{
        control: photosSwiper
      }"
      class="thumbs"
    >
      <ng-template
        *ngFor="let src of photos"
        swiperSlide
      >
        <img
          [src]="src"
          alt="#"
          class="thumbs__img"
        >
      </ng-template>
    </swiper>
  </div>
</div>
