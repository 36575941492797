<div class="wrap">
  <ng-container *ngIf="stepChange.observers.length">
    <app-button
      (buttonClick)="stepChange.emit(1)"
      [bordered]="true"
      >Step 1</app-button
    >
    <app-button
      (buttonClick)="stepChange.emit(2)"
      [bordered]="true"
      >Step 2</app-button
    >
    <app-button
      (buttonClick)="stepChange.emit(3)"
      [bordered]="true"
      >Step 3</app-button
    >
  </ng-container>

  <app-button
    *ngIf="customerInfoChange.observers.length"
    (buttonClick)="fillCustomerInfo()"
    [bordered]="true"
  >
    Fill Customer Info
  </app-button>

  <app-button
    *ngIf="shippingAddressChange.observers.length"
    (buttonClick)="fillShippingAddress()"
    [bordered]="true"
  >
    Fill Separate Shipping Address
  </app-button>

  <app-input
    *ngIf="printOnStagingChange.observers.length"
    type="checkbox"
    (valueChange)="printOnStagingChange.emit($event)"
    >Print on staging</app-input
  >
</div>
