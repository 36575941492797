import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { IBookPrice } from 'src/app/data/books/prices/types';
import Subscriber from 'src/app/subscriber';
import { BehaviorSubject } from 'rxjs';
import { MediaObserver } from '@angular/flex-layout';

const sub = new Subscriber();

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileComponent implements OnInit, OnDestroy {
  @Input() id: string;

  @Input() title: string;

  @Input() specs: { [key: string]: string };

  @Input() price?: IBookPrice;

  @Input() photo: string;

  @Input() discount?: string;

  public Object = Object;

  get specsKeys(): string[] {
    return Object.keys(this.specs);
  }

  public isLtSm = new BehaviorSubject<boolean>(false);

  constructor(private _media: MediaObserver) {}

  ngOnInit(): void {
    sub.push(
      this._media.asObservable().subscribe(() => {
        this._updateIsLtSm();
      }),
    );
  }

  ngOnDestroy(): void {
    sub.unsubscribe();
  }

  private _updateIsLtSm(): void {
    this.isLtSm.next(this._media.isActive('lt-sm'));
  }
}
