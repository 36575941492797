import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionGiftBooksComponent } from './section-gift-books.component';
import { ImageTitleModule } from '../image-title/image-title.module';
import { BookModule } from './components/book/book.module';
import { ChangeComponent } from './components/change/change.component';

@NgModule({
  declarations: [SectionGiftBooksComponent, ChangeComponent],
  imports: [CommonModule, ImageTitleModule, BookModule],
  exports: [SectionGiftBooksComponent],
})
export class SectionGiftBooksModule {}
