import { EImageTitleType } from './types';

export const imageTitleTypeImgSrcMap = {
  [EImageTitleType.reviews]: 'assets/images/title-reviews.png',
  [EImageTitleType.steps]: 'assets/images/title-steps.png',
  [EImageTitleType.faq]: 'assets/images/title-faq.png',
  [EImageTitleType.books]: 'assets/images/title-books.png',
  [EImageTitleType.promise]: 'assets/images/title-promise.png',
  [EImageTitleType.story]: 'assets/images/title-story.png',
  [EImageTitleType.media]: 'assets/images/title-media.png',
  [EImageTitleType.contact]: 'assets/images/title-contact.png',
  [EImageTitleType.cart]: 'assets/images/title-cart.png',
  [EImageTitleType.collaboration]: 'assets/images/title-collaboration.png',
  [EImageTitleType.gift]: 'assets/images/title-gift.png',
  [EImageTitleType.checkout]: 'assets/images/title-checkout.png',
};
