<div class="container">
  <div class="container__section container__section_items"
    *ngIf="items && items.length"
  >
    <div class="item" *ngFor="let item of items">
      <div class="item__col item__col_key">{{ item.name | translate }}</div>
      <div class="item__col item__col_value">
        <app-price
          [price]="item.price"
          [currency]="item.currency"
          spacing="lg"
          size="xs"
        ></app-price>
      </div>
    </div>
  </div>
  <div class="container__section container__section_total">
    <div class="item item_lg">
      <div class="item__col item__col_key">Order total</div>
      <div class="item__col item__col_value">
        <app-price
          [price]="total"
          [currency]="currency"
          spacing="lg"
        ></app-price>
      </div>
    </div>
  </div>
</div>
