import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexComponent } from './index.component';
import { TranslateModule } from '@ngx-translate/core';
import { BannerOneModule } from './components/banner-one/banner-one.module';
import { SectionFeaturesModule } from 'src/app/shared/section-features/section-features.module';
import { SectionBannerMermaidModule } from 'app/shared/section-banner-mermaid/section-banner-mermaid.module';
import { SectionBooksPreviewModule } from 'app/shared/section-books-preview/section-books-preview.module';
import { SectionFooterBannerModule } from 'app/shared/section-footer-banner/section-footer-banner.module';
import { SectionBooksModule } from 'app/shared/section-books/section-books.module';
import { SectionSeeOtherModule } from 'app/shared/section-see-other/section-see-other.module';
import { SectionStepsModule } from 'app/shared/section-steps/section-steps.module';
import { SectionFaqModule } from 'app/shared/section-faq/section-faq.module';
import { SectionReviewsYotpoCommonModule } from 'app/shared/section-reviews-yotpo-common/section-reviews-yotpo-common.module';

@NgModule({
  declarations: [IndexComponent],
  imports: [
    CommonModule,
    TranslateModule,
    BannerOneModule,
    SectionFeaturesModule,
    SectionBannerMermaidModule,
    SectionBooksPreviewModule,
    SectionFooterBannerModule,
    SectionBooksModule,
    SectionSeeOtherModule,
    SectionStepsModule,
    SectionFaqModule,
    SectionReviewsYotpoCommonModule,
  ],
})
export class IndexModule {}
