import type { OnDestroy, OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  ADDRESS_1_ERROR,
  ADDRESS_2_ERROR,
  ADDRESS_3_ERROR,
  EMAIL_ERROR,
  EMAIL_MATCH_ERROR,
  FIRSTNAME_ERROR,
  LASTNAME_ERROR,
  PHONE_ERROR,
  POSTAL_CODE_ERROR,
  REQUIRED_ERROR,
} from 'src/app/validators.constants';
import {
  addressFirstValidatorList,
  addressSecondValidatorList,
  cityValidatorList,
  countryValidatorList,
  emailValidatorList,
  firstNameValidatorList,
  lastNameValidatorList,
  phoneValidatorList,
  postalCodeValidatorList,
  stateValidatorList,
} from 'src/app/validators';
import { createSecondFieldValidator } from 'src/app/validators.functions';
import Subscriber from 'src/app/subscriber';
import isEqual from 'lodash/isEqual';
import { BehaviorSubject } from 'rxjs';
import type { IFormCustomerInfoData } from './form-customer-info.interfaces';
import { MediaService } from 'app/services/media.service';
import Regions from '@shared/shipping/data';

@Component({
  selector: 'app-form-customer-info',
  templateUrl: './form-customer-info.component.html',
  styleUrls: ['./form-customer-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormCustomerInfoComponent implements OnInit, OnDestroy {
  @Input() headerTitle = 'Customer Information (Billing Address)';

  @Input() headerTitleContent?: string;

  /** `undefined` when invalid */
  @Input() set data(data: IFormCustomerInfoData | undefined) {
    if (!isEqual(data, this.data)) {
      this._data$.next(data);
      this.dataChange.emit(data);

      if (data && !isEqual(data, this.formGroup.value)) {
        this.formGroup.setValue(data);
      }
    }
  }

  get data(): IFormCustomerInfoData | undefined {
    return this._data$.value;
  }

  private _data$ = new BehaviorSubject<IFormCustomerInfoData | undefined>(
    undefined,
  );

  @Output() dataChange = new EventEmitter<IFormCustomerInfoData | undefined>();

  private _touchEventEmitterSub = new Subscriber();

  @Input() set touchEventEmitter(e: EventEmitter<void> | undefined) {
    this._touchEventEmitterSub.unsubscribe(); // unsubscribe from previous sub`s

    if (!e) return;

    this._touchEventEmitterSub.push(
      // add new subscription
      e.subscribe(() => {
        this.touch();
      }),
    );
  }

  public formGroup = new FormGroup({
    // name
    firstName: new FormControl('', firstNameValidatorList),
    lastName: new FormControl('', lastNameValidatorList),
    // address
    /// street
    addressFirst: new FormControl('', addressFirstValidatorList),
    /// apt
    addressSecond: new FormControl('', addressSecondValidatorList),
    /// city
    city: new FormControl('', cityValidatorList),
    // postal code
    postalCode: new FormControl('', postalCodeValidatorList),
    country: new FormControl('US', countryValidatorList),
    state: new FormControl(Regions[0].code, stateValidatorList),
    // phone
    phone: new FormControl('', phoneValidatorList),
    // email
    email: new FormControl('', emailValidatorList),
    email2: new FormControl('', [createSecondFieldValidator('email')]),
  });

  public readonly FIRSTNAME_ERROR = FIRSTNAME_ERROR;

  public readonly LASTNAME_ERROR = LASTNAME_ERROR;

  public readonly ADDRESS_1_ERROR = ADDRESS_1_ERROR;

  public readonly ADDRESS_2_ERROR = ADDRESS_2_ERROR;

  public readonly ADDRESS_3_ERROR = ADDRESS_3_ERROR;

  public readonly EMAIL_ERROR = EMAIL_ERROR;

  public readonly EMAIL_MATCH_ERROR = EMAIL_MATCH_ERROR;

  public readonly REQUIRED_ERROR = REQUIRED_ERROR;

  public readonly POSTAL_CODE_ERROR = POSTAL_CODE_ERROR;

  public readonly PHONE_ERROR = PHONE_ERROR;

  public readonly regions = Regions;

  private readonly _sub = new Subscriber();

  constructor(public media: MediaService, private _cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.formGroup.controls.country.disable();

    this._sub.push(
      this.formGroup.valueChanges.subscribe(
        (data: IFormCustomerInfoData): void => {
          this._cdr.detectChanges();

          if (this.formGroup.valid) {
            this.data = {
              ...data,
              /** @see this.formGroup.controls.country.disable() */
              country: 'US',
            };
          } else {
            this.data = undefined;
          }
        },
      ),
    );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  public touch() {
    this.formGroup.markAllAsTouched();
    this._cdr.detectChanges(); // detect changes to redraw the component
  }
}
