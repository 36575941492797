<div class="wrap">
  <div class="wrap__container">
    <article class="container">
      <div class="container__header">
        <h2 class="title">STUDIO BUKI, Inc</h2>
      </div>
      <div class="container__content">
        <div class="content">
          <div class="content__item">
            <p class="text">
              970 W. 190th Street, Suite 920, Torrance, CA 90502, USA
            </p>
          </div>
          <div class="content__item">
            <p class="text">
              <a
                href="tel:5856288952"
                target="_blank"
                >(585)628-8952</a
              >
            </p>
          </div>
          <div class="content__item">
            <p class="text">We print in the USA and Japan.</p>
          </div>
          <div class="content__item">
            <p class="text">
              <a
                href="mailto:yay@hellobuki.com"
                target="_blank"
                >yay@hellobuki.com</a
              >
            </p>
          </div>
        </div>
      </div>
      <div class="container__character"></div>
    </article>
  </div>
</div>
