import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionTeamComponent } from './section-team.component';
import { GridComponent } from './components/grid/grid.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { CardMemberComponent } from './components/card-member/card-member.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SwiperModule } from 'swiper/angular';

@NgModule({
  declarations: [
    SectionTeamComponent,
    GridComponent,
    GalleryComponent,
    CardMemberComponent,
  ],
  imports: [CommonModule, AngularSvgIconModule, SwiperModule],
  exports: [SectionTeamComponent],
})
export class SectionTeamModule {}
