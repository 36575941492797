import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionStepsComponent } from './section-steps.component';
import { ImageTitleModule } from '../image-title/image-title.module';
import { StepComponent } from './components/step/step.component';
import { StepsComponent } from './components/steps/steps.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { SwiperModule } from 'swiper/angular';

@NgModule({
  declarations: [
    SectionStepsComponent,
    StepComponent,
    StepsComponent,
    CarouselComponent,
  ],
  imports: [CommonModule, ImageTitleModule, SwiperModule],
  exports: [SectionStepsComponent],
})
export class SectionStepsModule {}
