import { EBookInputType, TGetBookInput } from '../shared';
import { IBookRadioCoverInput, IBookRadioCoverInputOption } from './types';

export const getBookRadioCoverInputOptionData = ({
  label,
  imgSrc,
  value,
}: IBookRadioCoverInputOption): IBookRadioCoverInputOption => ({
  label,
  imgSrc,
  value,
});

export const getBookRadioCoverInputData: TGetBookInput<
  IBookRadioCoverInput,
  'type'
> = (params) =>
  ({
    ...params,
    type: EBookInputType.radioCover,
  } as const);
