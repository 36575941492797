import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { IBookNotice } from 'src/app/data/books/notice/types';
import { TBookPrices } from 'src/app/data/books/prices/types';
import { MediaObserver } from '@angular/flex-layout';
import Subscriber from 'src/app/subscriber';
import { BehaviorSubject } from 'rxjs';

const sub = new Subscriber();

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoticeComponent implements OnInit, OnDestroy {
  @Input() caption!: IBookNotice['caption'];

  @Input() description!: IBookNotice['description'];

  @Input() points?: IBookNotice['points'];

  @Input() prices!: TBookPrices;

  @Input() discount?: string;

  @Input() buttonText?: string;

  @Output() buttonClick = new EventEmitter<void>();

  private _footerPrices = new BehaviorSubject(false);

  public footerPrices = this._footerPrices.asObservable();

  get bookPrice() {
    return this.prices[0]?.[1];
  }

  constructor(private _media: MediaObserver) {}

  ngOnInit(): void {
    sub.push(
      this._media.asObservable().subscribe(() => {
        this._updateFooterPrices();
      }),
    );
  }

  ngOnDestroy(): void {
    sub.unsubscribe();
  }

  private _updateFooterPrices(): void {
    this._footerPrices.next(this._media.isActive('lt-md'));
  }

  onButtonClick() {
    this.buttonClick.emit();
  }
}
