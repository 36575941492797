<div class="container">
  <div
    *ngIf="title"
    class="container__title"
  >
    <p class="title">{{ title }}</p>
  </div>
  <div
    *ngIf="!noContent"
    class="container__content"
  >
    <div
      class="content"
      [ngStyle]="{
        borderTopLeftRadius: borderTopLeftRadius,
        borderTopRightRadius: borderTopRightRadius,
        borderBottomRightRadius: borderBottomRightRadius,
        borderBottomLeftRadius: borderBottomLeftRadius
      }"
    ><ng-content></ng-content></div>
  </div>
</div>
