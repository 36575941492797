<div class="container">
  <app-image-title
    class="container__title"
    type="media"
  >{{ data.caption }}</app-image-title>
  <div class="container__content">
    <div class="content">
      <div *ngFor="let item of data.items" class="content__item">
        <ng-container *ngIf="item.pageSrc; then link else image"></ng-container>
        <ng-template #link>
          <a
            class="link"
            [href]="item.pageSrc"
            target="_blank"
          >
            <img
              class="image"
              [src]="item.photoSrc"
              alt="#"
            >
          </a>
        </ng-template>
        <ng-template #image>
          <img
            class="image"
            [src]="item.photoSrc"
            alt="#"
          >
        </ng-template>
      </div>
    </div>
  </div>
</div>
