import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from 'src/app/services/logger.service';
import { BehaviorSubject } from 'rxjs';
import { TBookAlias } from '@shared/book/interfaces';
import { IBook } from 'app/data/books/types';
import { getBookByAlias } from 'src/app/data/books/utils';

const log = new Logger('Preview');

@Component({
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
})
export class PreviewComponent implements OnInit {
  private _book = new BehaviorSubject<IBook | undefined>(undefined);

  public book = this._book.asObservable();

  constructor(private _router: Router, private _route: ActivatedRoute) {}

  ngOnInit(): void {
    this._route.params.subscribe((p) => {
      const { alias } = p;
      const { path } = this._route.routeConfig;

      this._loadBook(alias || path);
    });
  }

  private _loadBook(alias?: TBookAlias): void {
    const book = getBookByAlias(alias);

    if (!alias) {
      log.error('alias is not defined');
      this._router.navigate(['404']);
      return;
    }

    if (!book) {
      log.error('book not found by alias', alias);
      this._router.navigate(['404']);
      return;
    }

    this._book.next(book);
  }
}
