<section class="form">
  <header class="form__section form__section_header">
    <app-header title="Shipping Method"></app-header>
  </header>
  <form class="form__section form__section_content">
    <app-toggle-cards
      class="cards"
      [(value)]="i"
    >
      <ng-container
        *ngIf="
          discountService.activeDiscountCampaignsObservable
            | async as discountCampaign;
          else nonDiscountCards
        "
      >
        <app-toggle-cards-item
          class="cards__item"
          *ngFor="let s of shippings; index as _i"
          [name]="s.name"
          [description]="s.description"
          [price]="{
            value: s.price,
            currency: s.currency
          }"
          [value]="_i"
          [disabled]="checkDisabled(s)"
          [discount]="getShippingDiscount(discountCampaign, FEToBEShipping(s))"
        ></app-toggle-cards-item>
      </ng-container>
      <ng-template #nonDiscountCards>
        <app-toggle-cards-item
          class="cards__item"
          *ngFor="let s of shippings; index as _i"
          [name]="s.name"
          [description]="s.description"
          [price]="{
            value: s.price,
            currency: s.currency
          }"
          [value]="_i"
          [disabled]="checkDisabled(s)"
        ></app-toggle-cards-item>
      </ng-template>
    </app-toggle-cards>
  </form>
</section>
