import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import Books from 'src/app/data/books';
import { Router } from '@angular/router';
import type { IBook } from 'app/data/books/types';
import { BehaviorSubject } from 'rxjs';
import Subscriber from 'src/app/subscriber';
import { MediaObserver } from '@angular/flex-layout';
import { Logger } from '@shared/logger';

const log = new Logger('SectionBooksComponent');

@Component({
  selector: 'app-section-books',
  templateUrl: './section-books.component.html',
  styleUrls: ['./section-books.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionBooksComponent implements OnInit, OnDestroy {
  @Input() books = Books;

  @Input() hideTitle = false;

  @Input() disableCarousel = false;

  public readonly showCarousel = new BehaviorSubject(false);

  private readonly _sub = new Subscriber();

  constructor(private _router: Router, private _media: MediaObserver) {}

  ngOnInit(): void {
    this._sub.push(
      this._media.asObservable().subscribe(() => {
        this._updateShowCarousel();
      }),
    );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  private _updateShowCarousel(): void {
    log.warn('_updateShowCarousel: did nothing');
    // this.showCarousel.next(this._media.isActive('gt-lg'));
  }

  public onButtonClick(alias: IBook['alias']): void {
    this._router.navigate([alias]);
  }
}
