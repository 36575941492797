<app-field
  [label]="label"
  [error]="showError && error"
  [forId]="id"
>
  <div
    class="container"
    [ngStyle]="{
      justifyContent: justifyContent
    }"
  >
    <div
      *ngFor="let option of options"
      class="container__section"
    >
      <app-input-radio-icon-option
        [imgSrc]="option.imgSrc"
        [active]="value === option.value"
        [theme]="theme"
        (optionClick)="value = option.value"
      ></app-input-radio-icon-option>
    </div>
  </div>
</app-field>
