<div class="toggle">
  <svg-icon
    class="toggle__arrow"
    [ngClass]="{
      toggle__arrow_top: type === 'top'
    }"
    src="assets/images/icons/order-aside-toggle-arrow.svg"
    [applyClass]="true"
  ></svg-icon>
  <div
    class="toggle__text"
    *ngIf="text"
  >
    {{ text }}
  </div>
</div>
