import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactsComponent } from './contacts.component';
import { SectionFormComponent } from './components/section-form/section-form.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { CardModule } from 'app/shared/card/card.module';
import { BlockEmailPhoneCardComponent } from './components/block-email-phone-card/block-email-phone-card.component';
import { ImageTitleModule } from 'src/app/shared/image-title/image-title.module';
import { SectionHeaderGapModule } from 'src/app/shared/section-header-gap/section-header-gap.module';
import { SectionFooterBannerModule } from 'src/app/shared/section-footer-banner/section-footer-banner.module';
import { ButtonModule } from 'src/app/shared/button/button.module';
import { SectionDucksModule } from '../../shared/section-ducks/section-ducks.module';
import { SectionCollaborationModule } from 'app/shared/section-collaboration/section-collaboration.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    ContactsComponent,
    SectionFormComponent,
    BlockEmailPhoneCardComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    CardModule,
    ImageTitleModule,
    SectionHeaderGapModule,
    SectionFooterBannerModule,
    ButtonModule,
    SectionCollaborationModule,
    SectionDucksModule,
    RouterModule,
  ],
})
export class ContactsModule {}
