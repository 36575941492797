<div class="wrap">
  <app-section-header-gap></app-section-header-gap>
  <div class="wrap__container">
    <div class="container">
      <h1 class="container__title">The page you're looking for is missing!</h1>
      <p class="container__text">
        Maybe you are looking for one of our<br />books?Please try the following
        links.
      </p>
      <div class="container__buttons">
        <app-button
          (buttonClick)="onHomeButtonClick()"
          [fullWidth]="true"
          >Home</app-button
        >
        <app-button
          (buttonClick)="onBooksButtonClick()"
          [fullWidth]="true"
          color="accent-alt"
          >See All Books</app-button
        >
      </div>
    </div>
  </div>
  <!-- center alignment compenstaion -->
  <app-section-header-gap></app-section-header-gap>
</div>
