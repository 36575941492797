import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionReviewsYotpoBookComponent } from './section-reviews-yotpo-book.component';
import { ImageTitleModule } from '../image-title/image-title.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SectionReviewsYotpoBookComponent],
  imports: [CommonModule, ImageTitleModule, TranslateModule],
  exports: [SectionReviewsYotpoBookComponent],
})
export class SectionReviewsYotpoBookModule {}
