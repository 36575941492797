import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main.component';
import { FormTestButtonsModule } from 'src/app/shared/form-test-buttons/form-test-buttons.module';
import { FormCustomerInfoModule } from 'app/shared/form-customer-info/form-customer-info.module';
import { DateComponent } from './components/date/date.component';
import { SectionMessageDateModule } from 'app/shared/section-message-date/section-message-date.module';
import { SectionMessageModule } from 'app/shared/section-message/section-message.module';

@NgModule({
  declarations: [MainComponent, DateComponent],
  imports: [
    CommonModule,
    FormTestButtonsModule,
    FormCustomerInfoModule,
    SectionMessageDateModule,
    SectionMessageModule,
  ],
  exports: [MainComponent],
})
export class MainModule {}
