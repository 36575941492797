import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TBookPhotos } from 'src/app/data/books/photos/types';
import SwiperCore, { Controller, Navigation, Swiper } from 'swiper';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { BehaviorSubject } from 'rxjs';
import Subscriber from 'src/app/subscriber';

const sub = new Subscriber();

// install Swiper modules
SwiperCore.use([Navigation, Controller]);

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryComponent implements OnInit, OnDestroy, OnChanges {
  private static _showThumbsBp = 'gt-lg';

  @Input() photos: TBookPhotos;

  public photosSwiper?: Swiper;

  public thumbsSwiper?: Swiper;

  private _showThumbs = new BehaviorSubject(false);

  public showThumbs = this._showThumbs.asObservable();

  get loop(): boolean {
    return !!this.loopedSlides;
  }

  get loopedSlides(): number {
    const { length } = this.photos;

    return length > 4 ? 4 : 0;
  }

  constructor(private _media: MediaObserver) {}

  ngOnInit(): void {
    this._initUpdateShowThumbs();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.photos && !changes.photos.firstChange) {
      setTimeout(() => {
        this.photosSwiper?.update();
        this.thumbsSwiper?.update();
        this.thumbsSwiper?.slideToLoop(0, 0);
      });
    }
  }

  ngOnDestroy(): void {
    sub.unsubscribe();
  }

  private _initUpdateShowThumbs(): void {
    this._updateShowThumbs();

    // init listener
    sub.push(
      this._media.asObservable().subscribe((mediaChangeArr: MediaChange[]) => {
        this._updateShowThumbs(
          mediaChangeArr
            .map((mediaChange) => mediaChange.mqAlias)
            .indexOf(GalleryComponent._showThumbsBp) !== -1,
        );
      }),
    );
  }

  private _updateShowThumbs(
    status: boolean = this._media.isActive(GalleryComponent._showThumbsBp),
  ): void {
    this._showThumbs.next(status);
  }
}
