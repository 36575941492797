import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { IArticle } from './section-text.interfaces';
import {
  articlePassageItemToCaption,
  articlePassageItemToOrderedList,
  articlePassageItemToText,
} from './section-text.functions';

@Component({
  selector: 'app-section-text',
  templateUrl: './section-text.component.html',
  styleUrls: ['./section-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionTextComponent {
  @Input() articles!: IArticle[];

  public articlePassageItemToCaption = articlePassageItemToCaption;

  public articlePassageItemToText = articlePassageItemToText;

  public articlePassageItemToOrderedList = articlePassageItemToOrderedList;

  constructor() {}
}
