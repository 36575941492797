<div style="margin: 30px">
  <!--<h2 mat-dialog-title>Delete Book</h2>-->
  <mat-dialog-content class="mat-typography">
    <p>
      Are you sure you want to delete <strong>{{ data.product.name }}</strong>
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-button
      mat-dialog-close
      cdkFocusInitial
    >
      No
    </button>
    <button
      mat-button
      [mat-dialog-close]="true"
    >
      Yes
    </button>
  </mat-dialog-actions>
</div>
