<div class="container">
  <app-form-test-buttons
    *ngIf="!environment.production"
    class="container__section container__section_test"
    (customerInfoChange)="fillCustomerInfo($event)"
  ></app-form-test-buttons>
  <app-form-customer-info
    class="container__section"
    headerTitle="お届け先情報"
    [(data)]="formCustomerInfo"
    (dataChange)="onChange()"
    [touchEventEmitter]="touchEventEmitter"
  ></app-form-customer-info>
  <app-date
    class="container__section container__section_date"
    [region]="region"
    [shippingMethod]="shippingMethod$"
  ></app-date>
</div>
