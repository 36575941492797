<div class="container">
  <div class="container__content">
    <div class="content">
      <p class="content__text">
        コンビニ払いはお支払い完了後のキャ
        ンセルや修正、返金が一切できません。
        絵本の内容にお間違いはありませんか？
      </p>
      <div class="content__buttons">
        <app-button
          [mat-dialog-close]="true"
          [fullWidth]="true"
        >確認した</app-button>
        <app-button
          mat-dialog-close
          [fullWidth]="true"
          [bordered]="true"
        >戻る</app-button>
      </div>
    </div>
  </div>
  <i class="container__character"></i>
  <i
    class="container__x"
    mat-dialog-close
  ></i>
</div>
