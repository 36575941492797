import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  IBookSelectInput,
  IBookSelectInputOption,
} from 'src/app/data/books/inputs/select/types';
import { EInputTheme } from '../../interfaces';
import { ESelectButtonTheme } from '../select-button/enums';

@Component({
  selector: 'app-select-image-text-bar',
  templateUrl: './select-image-text-bar.component.html',
  styleUrls: ['./select-image-text-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectImageTextBarComponent {
  @Input() public theme = EInputTheme.default;

  @Input() public option?: IBookSelectInputOption;

  @Input() public placeholder!: IBookSelectInput['placeholder'];

  @Input() public isActive?: boolean;

  @Input() public invalid?: boolean;

  public readonly EInputTheme = EInputTheme;

  constructor() {}

  get selectButtonTheme(): ESelectButtonTheme {
    switch (this.theme) {
      case EInputTheme.alt:
        return ESelectButtonTheme.alt;
      default:
        return ESelectButtonTheme.default;
    }
  }
}
