import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IBook } from 'app/data/books/types';
import { TBookPhoto } from 'src/app/data/books/photos/types';
import { IBookPrice } from 'src/app/data/books/prices/types';
import { IBookNotice } from 'src/app/data/books/notice/types';
import { calculateDiscount } from '@shared/discount/utils';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductComponent {
  @Input() photo!: TBookPhoto;

  @Input() caption!: IBook['caption'];

  @Input() summary!: IBook['summary'];

  @Input() notice!: IBookNotice;

  @Input() price!: IBookPrice;

  @Input() discount?: string;

  @Output() buttonClick = new EventEmitter<void>();

  public readonly calculateDiscount = calculateDiscount;

  // constructor() {}

  public onButtonClick(): void {
    this.buttonClick.emit();
  }
}
