<div class="container">
  <div class="container__section">
    <app-book-item caption="Everyday / Adventure Book" [photo]="StaryDream.previewPhoto" [summary]="[
        'Discover the stars with your child’s zodiac sign',
        'in this original picture book!'
      ]" (buttonClick)="onButtonClick(StaryDream.alias)"></app-book-item>
  </div>
  <div class="container__section">
    <app-book-item caption="Birthday Book" [photo]="WhosBirthdayTomorrow.previewPhoto" [summary]="[
        'Create a unique birthday gift for your child',
        'with a personalized book!'
      ]" (buttonClick)="onButtonClick(WhosBirthdayTomorrow.alias)"></app-book-item>
  </div>
  <div class="container__section">
    <app-book-item caption="Newborn Baby Book" [photo]="WelcomeToTheWorld.previewPhoto"
      [summary]="WelcomeToTheWorld.summary" (buttonClick)="onButtonClick(WelcomeToTheWorld.alias)"></app-book-item>
  </div>
</div>
