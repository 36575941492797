import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  public isCartVisible$ = new BehaviorSubject(true);

  get isCartVisible() {
    return this.isCartVisible$.value;
  }

  constructor() {}

  public hideCart() {
    this.isCartVisible$.next(false);
  }

  public showCart() {
    this.isCartVisible$.next(true);
  }
}
