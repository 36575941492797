export const ROUTE_PATH_INDEX = '';

export const ROUTE_PATH_ABOUT = 'about';

export const ROUTE_PATH_TERMS = 'terms';

export const ROUTE_PATH_POLICY = 'policy';

export const ROUTE_PATH_FAQ = 'faq';

export const ROUTE_PATH_CONTACTS = 'contacts';

export const ROUTE_PATH_ORDERCONTRACT = 'ordercontract';

export const ROUTE_PATH_CART = 'cart';

// ! DO NOT CHANGE PARAM NAMES
export const ROUTE_PATH_ORDER = 'order/:orderId';

// ! DO NOT CHANGE PARAM NAMES
export const ROUTE_PATH_BOOK = 'book/:id';

// ! DO NOT CHANGE PARAM NAMES
export const ROUTE_PATH_BOOK_STARRY_DREAM = 'book/starry-dream/:id';

// ! DO NOT CHANGE PARAM NAMES
export const ROUTE_PATH_BOOK_WELCOME_TO_THE_WORLD =
  'book/welcome-to-the-world/:id';

// ! DO NOT CHANGE PARAM NAMES
export const ROUTE_PATH_BOOK_WHOS_BIRTHDAY_TOMORROW =
  'book/whos-birthday-tomorrow/:id';

// ! DO NOT CHANGE PARAM NAMES
export const ROUTE_PATH_BOOK_WHERE_IS_BIRTHDAY_CAKE =
  'book/where_is_birthday_cake/:id';

// ! DO NOT CHANGE PARAM NAMES
export const ROUTE_PATH_BOOK_STORY_OF_GRANDPA_GRANDMA =
  'book/story-of-grandpa-grandma/:id';

export const ROUTE_PATH_ALL_BOOKS = 'all-books';

export const ROUTE_PATH_404 = '404';

export const ROUTE_PATH_COLLABORATION = 'collaboration';

// ! DO NOT CHANGE PARAM NAMES
export const ROUTE_PATH_PREVIEW = ':alias';

// * gift
export const ROUTE_PATH_GIFT_BUY = 'giftshop';

export const ROUTE_PATH_GIFT_BUY_SPECIAL = 'giftshop-special';

export const ROUTE_PATH_GIFT_USE = 'redeemgift';

export const ROUTE_PATH_GIFT_USE_NO_LOGO = 'usegift';

export const ROUTE_PATH_GIFT_THANKS = 'gift-thanks';

export const ROUTE_PATH_GIFT_ORDER = 'gift-order';

export const ROUTE_PATH_PARAM_ORDER_ID = 'orderId';

export const ROUTE_PATH_PARAM_GIFT_ID = 'giftId';

export const ROUTE_QUERY_PARAM_GIFT = 'gift';

export const ROUTE_QUERY_PARAM_BOOK = 'book';

export const ROUTE_QUERY_PARAM_PURPOSE = 'purpose';

export const ROUTE_QUERY_PARAM_AGE = 'age';

// * auth
export const ROUTE_PATH_AUTH = 'auth';

// * auth params
export const ROUTE_PATH_PARAM_AUTH_TYPE = 'type';

export const ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_IN = 'sign-in';

export const ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_UP = 'sign-up';

export const ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_UP_FINAL = 'sign-up-final';

export const ROUTE_PATH_PARAM_AUTH_TYPE_RESET = 'reset';

// * auth query params
export const ROUTE_PATH_QUERY_PARAM_AUTH_EMAIL = 'email';

// * my page
export const ROUTE_PATH_MY_PAGE = 'my-page';
