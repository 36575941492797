<app-default
  [id]="id"
  [title]="title"
  [specs]="specs"
  [price]="price"
  [discount]="discount"
  [photo]="photo"
  (close)="onClose()"
  *ngIf="(isDesktop | async); else mobile"
>
  <ng-container
    *ngTemplateOutlet="content"
  ></ng-container>
</app-default>

<ng-template #mobile>
  <app-mobile
    [id]="id"
    [title]="title"
    [specs]="specs"
    [price]="price"
    [discount]="discount"
    [photo]="photo"
    (close)="onClose()"
  >
    <ng-container
      *ngTemplateOutlet="content"
    ></ng-container>
  </app-mobile>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
