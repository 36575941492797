<div
  class="content"
  [formGroup]="formGroup"
>
  <div class="content__input">
    <app-input
      name="email"
      [label]="'Email Address' | translate"
      placeholder="sample@hellobuki.com"
      formControlName="email"
      type="email"
      [error]="EMAIL_ERROR"
      [theme]="EInputTheme.alt"
    ></app-input>
  </div>
  <div class="content__button">
    <app-button
      (buttonClick)="onResetButtonClick()"
      [fullWidth]="true"
      [disabled]="formGroup.invalid"
    >{{ 'auth.reset.button' | translate }}</app-button>
  </div>
  <div class="content__footer">
    <p class="footer-text">Go back <a (click)="onSignInButtonClick()">to login</a>.</p>
  </div>
</div>
