import {
  IBookInputNameToValueMap,
  TBookInput,
  TBookInputs,
} from 'app/data/books/inputs/types';
import { EBookInputType } from 'app/data/books/inputs/shared';
import { inputToFormControl } from 'app/data/books/inputs/utils';
import { FormGroup } from '@angular/forms';
import { compareArraysRAC } from 'app/utils';
import { INPUT_NAME_RESERVED_ARR } from 'app/data/books/inputs/constants';

export const getInputClass = (base: string, input: TBookInput) => {
  const obj = {
    [`${base}_text`]:
      input.type === EBookInputType.text || input.type === EBookInputType.date,
    [`${base}_select`]: input.type === EBookInputType.select,
    [`${base}_radio`]: input.type === EBookInputType.radio,
    [`${base}_radio-image`]: input.type === EBookInputType.radioImage,
    [`${base}_checkbox`]: input.type === EBookInputType.checkbox,
    [`${base}_canvas`]: input.type === EBookInputType.canvas,
    [`${base}_group`]: input.type === EBookInputType.group,
    [`${base}_multiselect`]: input.type === EBookInputType.multiselect,
  };

  const arr = Object.entries(obj)
    .filter(([, s]) => s)
    .map(([c]) => c);

  arr.push(base, `${base}_${input.name}`);

  return arr;
};

export const updateFormControls = <T extends TBookInputs>(
  formGroup: FormGroup,
  prevInputs: T,
  nextInputs: T,
) => {
  const { removed, added, changed } = compareArraysRAC(
    prevInputs,
    nextInputs,
    'name',
  );

  removed.forEach(({ name }) => {
    formGroup.removeControl(name);
  });

  added.forEach((input) => {
    const control = inputToFormControl(input);

    formGroup.addControl(input.name, control);
  });

  changed.forEach((input) => {
    const control = inputToFormControl(input);

    formGroup.setControl(input.name, control);
  });

  formGroup.updateValueAndValidity();

  return { removed, added, changed };
};

export const unwrapReservedNames = (value: IBookInputNameToValueMap) => {
  INPUT_NAME_RESERVED_ARR.forEach((_r) => {
    if (_r in value) {
      value = {
        ...value,
        ...value[_r],
      };

      delete value[_r];
    }
  });

  return value;
};
