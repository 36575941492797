import type { OnChanges, SimpleChanges } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
} from '@angular/core';
import { EFormShipFromValue } from 'src/app/shared/form-ship-from/form-ship-from.enum';
import { TBookAlias, TBookCover, TBookWrapping } from '@shared/book/interfaces';
import { Logger } from 'src/app/services/logger.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DialogsService } from 'src/app/shared/dialogs/services/dialogs.service';
import { LoaderService } from 'src/app/services/loader.service';
import { getPaymentInformation, scrollToSelector } from 'src/app/functions';
import type { IFormPaymentData } from '../form-payment/form-payment.interfaces';
import type { IFormCustomerInfoData } from '../form-customer-info/form-customer-info.interfaces';
import { EGiftOrderType } from '@shared/gift/enums';
import type { IFormGiftSpecialData } from '../form-gift-special/form-gift-special.interfaces';
import { FirebaseService } from 'src/app/services/firebase.service';
import type {
  IGiftOrderCreateParamsCommon,
  TGiftOrderCreateParams,
  TGiftOrderCreated,
  TGiftOrderGenerated,
} from '@shared/gift/interfaces';
import cookie from 'cookie';
import { StripeService } from 'src/app/services/stripe.service';
import {
  checkGeneratedGiftOrder,
  checkGiftOrderError,
} from '@shared/gift/functions/checks';
import { take } from 'rxjs/operators';
import { RoutingService } from 'src/app/services/routing.service';
import firebase from 'firebase/app';
import { DiscountService } from 'app/services/discount.service';
import { sendConversionEventByGiftOrder } from 'analytics';
import { deleteUndefinedFromObject, getError } from '@shared/utils';
import { EFormPaymentType } from '@shared/interfaces';

const log = new Logger('SectionGiftCheckoutComponent');

@Component({
  selector: 'app-section-gift-checkout',
  templateUrl: './section-gift-checkout.component.html',
  styleUrls: ['./section-gift-checkout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionGiftCheckoutComponent implements OnChanges {
  @Input() type: EGiftOrderType = EGiftOrderType.standart;

  @Input() alias?: TBookAlias;

  @Input() cover!: TBookCover;

  @Input() wrapping!: TBookWrapping;

  /** used to touch forms to make errors appear */
  public readonly touchEventEmitter = new EventEmitter<void>();

  private readonly _formGiftSpecialDataSubject = new BehaviorSubject<
    IFormGiftSpecialData | undefined
  >(undefined);

  public readonly formGiftSpecialDataObservable =
    this._formGiftSpecialDataSubject.asObservable();

  private readonly _formCustomerInfoDataSubject = new BehaviorSubject<
    IFormCustomerInfoData | undefined
  >(undefined);

  public readonly formCustomerInfoDataObservable =
    this._formCustomerInfoDataSubject.asObservable();

  private readonly _shipFromSubject = new BehaviorSubject<EFormShipFromValue>(
    EFormShipFromValue.buki,
  );

  private readonly _formPaymentDataSubject = new BehaviorSubject<
    IFormPaymentData | undefined
  >(undefined);

  public readonly formPaymentDataObservable =
    this._formPaymentDataSubject.asObservable();

  private readonly _submitButtonDisabledSubject = new BehaviorSubject<boolean>(
    true,
  );

  public readonly submitButtonDisabledObservable =
    this._submitButtonDisabledSubject.asObservable();

  public readonly environment = environment;

  public readonly EGiftOrderType = EGiftOrderType;

  constructor(
    private _dialogsService: DialogsService,
    private _loaderService: LoaderService,
    private _firebaseService: FirebaseService,
    private _stripeService: StripeService,
    private _routingService: RoutingService,
    private _discountService: DiscountService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.alias && !changes.alias.firstChange) {
      this._submitButtonDisabledSubject.next(false);
    }
  }

  public async onSubmitButtonClick() {
    const isValid = this._validateForms();

    if (!isValid) return;

    const emit = async () => {
      this._loaderService.show();
      // await this.updateOrderData();
      // await this.pay();

      try {
        const { userGiftOrderId, giftOrder } = await this._submit();

        if (checkGeneratedGiftOrder(giftOrder)) {
          this._routingService.goToGiftThanks({
            giftId: giftOrder.gifts[0],
            orderId: giftOrder.giftOrderId,
          });
        } else {
          this._routingService.goToGiftThanks();
        }

        sendConversionEventByGiftOrder(userGiftOrderId, giftOrder);
      } catch (error) {
        log.error(error);
      }

      this._loaderService.hide();
    };

    await emit();
  }

  public onFormGiftSpecialDataChange(
    data: IFormGiftSpecialData | undefined,
  ): void {
    this._formGiftSpecialDataSubject.next(data);
    this._submitButtonDisabledSubject.next(false);
  }

  public onFormCustomerInfoDataChange(
    data: IFormCustomerInfoData | undefined,
  ): void {
    this._formCustomerInfoDataSubject.next(data);
    this._submitButtonDisabledSubject.next(false);
  }

  public onShipFromChange(value: EFormShipFromValue): void {
    this._shipFromSubject.next(value);
    this._submitButtonDisabledSubject.next(false);
  }

  public onFormPaymentDataChange(data: IFormPaymentData | undefined): void {
    this._formPaymentDataSubject.next(data);
    this._submitButtonDisabledSubject.next(false);
  }

  /**
   * filling the customer info form group with test data
   */
  public fillCustomerInfo(data: IFormCustomerInfoData): void {
    this.onFormCustomerInfoDataChange(
      Object.assign(this._formCustomerInfoDataSubject.value || {}, data),
    );
  }

  /**
   * @returns `true` if form is valid
   */
  private _validateForms(): boolean {
    const {
      alias,
      _formGiftSpecialDataSubject: { value: giftSpecialData },
      _formCustomerInfoDataSubject: { value: addressBillingData },
      _formPaymentDataSubject: { value: formPaymentData },
      type,
    } = this;

    const isAliasInvalid = !alias;
    const isFormGiftSpecialInvalid =
      type === EGiftOrderType.special && !giftSpecialData;
    const isAddressBillingDataInvalid = !addressBillingData;
    const isFormPaymentDataInvalid =
      type === EGiftOrderType.standart && !formPaymentData;
    /** all forms */
    const isInvalid =
      isAliasInvalid ||
      isFormGiftSpecialInvalid ||
      isAddressBillingDataInvalid ||
      isFormPaymentDataInvalid;

    if (isInvalid) {
      if (isAliasInvalid) {
        scrollToSelector('app-section-gift-books');
      } else if (isFormGiftSpecialInvalid) {
        scrollToSelector('app-form-gift-special');
      } else if (isAddressBillingDataInvalid) {
        scrollToSelector('app-form-customer-info');
      } else if (isFormPaymentDataInvalid) {
        scrollToSelector('app-form-payment');
      }
    }

    this._submitButtonDisabledSubject.next(isInvalid);
    this.touchEventEmitter.next();

    return !isInvalid;
  }

  private async _submit() {
    const {
      type,
      alias,
      cover,
      wrapping,
      _formGiftSpecialDataSubject: { value: giftSpecialData },
      _formCustomerInfoDataSubject: { value: customerInfoData },
      _shipFromSubject: { value: shipFrom },
      _formPaymentDataSubject: { value: formPaymentData },
    } = this;

    if (!alias || !giftSpecialData || !customerInfoData || !formPaymentData) {
      const err = getError('invalid form', {
        alias,
        giftSpecialData,
        customerInfoData,
        shipFrom,
        formPaymentData,
      });

      log.error(err);

      throw err;
    }

    const book = {
      alias,
      cover,
      wrapping,
    };

    const discountCampaign =
      await this._discountService.activeDiscountCampaignsObservable
        .pipe(take(1))
        .toPromise();

    const giftOrderCreateParamsCommon: IGiftOrderCreateParamsCommon = {
      type,
      book,
      shipFrom,
      addressBillingData: customerInfoData,
      receiveNewsletters: true,
      paymentInformation: getPaymentInformation(
        [book],
        // TODO
        'fast',
        // TODO
        0,
        formPaymentData?.type || EFormPaymentType.card,
        undefined,
        discountCampaign,
      ),
      referrer: document.referrer,
      hostname: location.hostname,
      origin: location.origin,
      userAgent: navigator.userAgent,
      userIp: `${document.documentElement.dataset.userIp}`,
      fbp: cookie.parse(document.cookie)._fbp,
      fbc: cookie.parse(document.cookie)._fbc,
    };

    let giftOrderCreateParams: TGiftOrderCreateParams;

    switch (type) {
      case EGiftOrderType.standart: {
        giftOrderCreateParams = {
          ...giftOrderCreateParamsCommon,
          type: EGiftOrderType.standart, // * typescript issue
        };
        break;
      }
      case EGiftOrderType.special: {
        const {
          password,
          count,
          expiration,
          campaignCode,
          campaignDescription,
          campaignNumber,
          codeType,
        } = giftSpecialData;

        giftOrderCreateParams = {
          ...giftOrderCreateParamsCommon,
          type: EGiftOrderType.special, // * typescript issue
          codeType: Number(codeType),
          password,
          count,
          expiration: firebase.firestore.Timestamp.fromDate(expiration),
          campaign: {
            code: campaignCode,
            description: campaignDescription,
            number: campaignNumber,
          },
        };
        break;
      }
    }

    deleteUndefinedFromObject(giftOrderCreateParams);

    const userGiftOrderRef = await this._firebaseService.createUserGiftOrder(
      giftOrderCreateParams,
    );

    return new Promise<{
      userGiftOrderId: string;
      giftOrder: TGiftOrderCreated | TGiftOrderGenerated;
    }>((_resolve, _reject) => {
      let unsubscribe: () => void;
      const sub = new Subscription();

      const complete = () => {
        unsubscribe?.();
        sub.unsubscribe();
      };

      const resolve = (...args: Parameters<typeof _resolve>) => {
        complete();
        _resolve(...args);
      };

      const reject = (...args: Parameters<typeof _reject>) => {
        complete();
        _reject(...args);
      };

      // handle stripe errors
      // TODO
      // sub.add(
      //   this._stripeService.giftPaymentUpdated
      //     .pipe(
      //       filter(
      //         (data) =>
      //           data.id === userGiftOrderRef.id && 'error' in data.payment,
      //       ),
      //     )
      //     .subscribe((data) => {
      //       log.error('paymentUpdate: error', new Date(), data.payment);
      //       alert('お支払いにエラーが発生しました。');
      //       // @ts-ignore
      //       reject(data.payment.error);
      //     }),
      // );

      unsubscribe = userGiftOrderRef.onSnapshot(async (snapshot) => {
        // snapshot.data
        const giftOrder = snapshot.data();

        if (checkGiftOrderError(giftOrder)) {
          reject(giftOrder.error);
          return;
        }

        // TODO
        // if (checkCreatedGiftOrder(giftOrder)) {
        //   console.log('created', { giftOrder });
        //   const { card, name, pmToken, type: pmType } = formPaymentData;

        //   switch (pmType) {
        //     case EFormPaymentType.card: {
        //       // here we should wait untill the next snapshot will have status 'generated'
        //       await this._stripeService.payWithCard(
        //         snapshot.id,
        //         card,
        //         name,
        //         pmToken,
        //         DB_GIFT_PAYMENTS,
        //       );
        //       return;
        //       // break;
        //     }
        //     default: {
        //       reject(
        //         new Error(
        //           'Invalid payment type! Please reload the page and try again.',
        //         ),
        //       );
        //       return;
        //       // break;
        //     }
        //   }
        // }

        if (checkGeneratedGiftOrder(giftOrder)) {
          console.log('success! generated', { giftOrder });
          resolve({ userGiftOrderId: snapshot.id, giftOrder });
          return;
        }
      });
    });
  }
}
