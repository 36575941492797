import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SectionTitleComponent } from './section-title/section-title.component';
import { InputModule } from './input/input.module';
import { SectionMessageComponent } from './section-message/section-message.component';
import { TooltipModule } from './tooltip/tooltip.module';
import { CalculatorModule } from './calculator/calculator.module';
import { PriceModule } from './price/price.module';
import { SectionHeaderGapComponent } from './section-header-gap/section-header-gap.component';
import { TimepickerSelectModule } from './timepicker-select/timepicker-select.module';
import { ButtonModule } from './button/button.module';
import { SectionFeaturesModule } from './section-features/section-features.module';
import { SectionSeeOtherModule } from './section-see-other/section-see-other.module';
import { SectionReviewsModule } from './section-reviews/section-reviews.module';
import { ImageTitleModule } from './image-title/image-title.module';
import { SectionStepsModule } from './section-steps/section-steps.module';
import { SectionFaqModule } from './section-faq/section-faq.module';
import { SectionFooterBannerModule } from './section-footer-banner/section-footer-banner.module';
import { SectionHeaderGapModule } from './section-header-gap/section-header-gap.module';
import { SectionFooterModule } from './section-footer/section-footer.module';
import { SectionBooksPreviewModule } from './section-books-preview/section-books-preview.module';
import { SectionBooksModule } from './section-books/section-books.module';
import { ProductModule } from './product/product.module';
import { SectionMessageModule } from './section-message/section-message.module';
import { SectionTitleModule } from './section-title/section-title.module';
import { SectionMessageDateModule } from './section-message-date/section-message-date.module';
import { SectionTextModule } from './section-text/section-text.module';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    InputModule,
    CalculatorModule,
    PriceModule,
    TimepickerSelectModule,
    ButtonModule,
    SectionFeaturesModule,
    SectionSeeOtherModule,
    SectionReviewsModule,
    ImageTitleModule,
    SectionStepsModule,
    SectionFaqModule,
    SectionFooterBannerModule,
    SectionHeaderGapModule,
    SectionFooterModule,
    SectionBooksPreviewModule,
    SectionBooksModule,
    ProductModule,
    SectionMessageModule,
    SectionTitleModule,
    SectionMessageDateModule,
    SectionTextModule,
    TooltipModule,
  ],
  exports: [
    SectionTitleComponent,
    InputModule,
    SectionMessageComponent,
    SectionHeaderGapComponent,
    CalculatorModule,
    PriceModule,
    TimepickerSelectModule,
    ButtonModule,
    SectionFeaturesModule,
    SectionSeeOtherModule,
    SectionReviewsModule,
    ImageTitleModule,
    SectionStepsModule,
    SectionFaqModule,
    SectionFooterBannerModule,
    SectionHeaderGapModule,
    SectionFooterModule,
    SectionBooksPreviewModule,
    SectionBooksModule,
    ProductModule,
    SectionMessageModule,
    SectionTitleModule,
    SectionMessageDateModule,
    SectionTextModule,
    TooltipModule,
  ],
})
export class SharedModule {}
