<div
  *ngIf="items$ | async as items"
  class="container"
>
  <div class="container__row">
    <div class="row">
      <div class="row__item">
        <p class="text">Select the state you would like to ship to</p>
      </div>
      <div class="row__item row__item_select">
        <app-input
          type="select"
          label="*Estimated delivery time for:"
          [ngModel]="region$ | async"
          (ngModelChange)="region$.next($event)"
        >
          <option
            value=""
            disabled
            selected
          >
            [Select state]
          </option>
          <option
            *ngFor="let item of items"
            [value]="item.value"
          >
            {{ item.label }}
          </option>
        </app-input>
      </div>
      <div
        *ngIf="region$ | async"
        class="row__item row__item_dates"
      >
        <p class="text">
          <strong>Standard</strong><br />{{
            expectedArrivalDateStandart$ | async
          }}
        </p>
        <p class="text">
          <strong>Fast</strong><br />{{ expectedArrivalDateFast$ | async }}
        </p>
      </div>
    </div>
  </div>
</div>
