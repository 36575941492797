<div class="wrap"
  *ngIf="shown"
  [@fade]
>
  <div class="wrap__container">
    <div class="container">
      <div class="container__section">
        <div class="ufo">
          <div class="ufo__item spin"></div>
        </div>
      </div>
    </div>
  </div>
</div>
