import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyPageComponent } from './my-page.component';
import { SectionHeaderGapModule } from 'app/shared/section-header-gap/section-header-gap.module';
import { ImageTitleModule } from 'app/shared/image-title/image-title.module';
import { CardComponent } from './components/card/card.component';
import { ButtonModule } from 'app/shared/button/button.module';
import { ListComponent } from './components/list/list.component';

@NgModule({
  declarations: [MyPageComponent, CardComponent, ListComponent],
  imports: [
    CommonModule,
    SectionHeaderGapModule,
    ImageTitleModule,
    ButtonModule,
  ],
})
export class MyPageModule {}
