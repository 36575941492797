<app-product
  *ngIf="!(discountService.activeDiscountCampaignsObservable | async)"
  [photo]="book.menuPhoto"
  [caption]="book.caption"
  [summary]="book.summary"
  [notice]="book.notice"
  [price]="book.prices[0][1]"
  (buttonClick)="onButtonClick()"
></app-product>
<app-product
  *ngIf="(discountService.activeDiscountCampaignsObservable | async) as discountCampaign"
  [photo]="book.menuPhoto"
  [caption]="book.caption"
  [summary]="book.summary"
  [notice]="book.notice"
  [price]="book.prices[0][1]"
  [discount]="getBooksDiscount(discountCampaign, book.alias)"
  (buttonClick)="onButtonClick()"
></app-product>
