import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IArticlePassageText } from '../../section-text.interfaces';

@Component({
  selector: 'app-passage-text',
  templateUrl: './passage-text.component.html',
  styleUrls: ['./passage-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PassageTextComponent {
  @Input() text!: IArticlePassageText['text'];

  constructor() {}
}
