import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionDetailsComponent } from './section-details.component';
import { TranslateModule } from '@ngx-translate/core';
import { NoticeComponent } from './components/notice/notice.component';
import { GalleryComponent } from './components/gallery/gallery.component';
// import { SwiperModule } from 'ngx-swiper-wrapper';
import { SwiperModule } from 'swiper/angular';
import { PointComponent } from './components/point/point.component';
import { BookFormModule } from '../../../../components/book-form/book-form.module';
// import { PricesComponent } from './components/prices/prices.component';
import { BannerComponent } from './components/banner/banner.component';
import { PointsComponent } from './components/points/points.component';
import { DescriptionComponent } from './components/description/description.component';
import { DescriptionItemComponent } from './components/description-item/description-item.component';
import { ButtonModule } from 'src/app/shared/button/button.module';
import { SectionFeaturesModule } from 'src/app/shared/section-features/section-features.module';
import { SectionSeeOtherModule } from 'src/app/shared/section-see-other/section-see-other.module';
import { SectionReviewsModule } from 'src/app/shared/section-reviews/section-reviews.module';
import { SectionStepsModule } from 'src/app/shared/section-steps/section-steps.module';
import { SectionFaqModule } from 'src/app/shared/section-faq/section-faq.module';
import { SectionMessageDateModule } from 'src/app/shared/section-message-date/section-message-date.module';
import { PriceModule } from 'src/app/shared/price/price.module';
import { SectionBannerMermaidModule } from 'app/shared/section-banner-mermaid/section-banner-mermaid.module';
import { SectionReviewsYotpoBookModule } from 'app/shared/section-reviews-yotpo-book/section-reviews-yotpo-book.module';

@NgModule({
  declarations: [
    NoticeComponent,
    SectionDetailsComponent,
    GalleryComponent,
    PointComponent,
    // PricesComponent,
    BannerComponent,
    PointsComponent,
    DescriptionComponent,
    DescriptionItemComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SwiperModule,
    BookFormModule,
    ButtonModule,
    SectionFeaturesModule,
    SectionSeeOtherModule,
    SectionReviewsModule,
    SectionReviewsYotpoBookModule,
    SectionStepsModule,
    SectionFaqModule,
    SectionMessageDateModule,
    PriceModule,
    SectionBannerMermaidModule,
  ],
  exports: [SectionDetailsComponent],
})
export class SectionDetailsModule {}
