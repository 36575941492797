import type { OnDestroy, OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { slideY } from 'src/app/animations';
import Subscriber from 'src/app/subscriber';
import isEqual from 'lodash/isEqual';
import type { IFormPaymentData } from './form-payment.interfaces';
import { EFormPaymentType } from '@shared/interfaces';
import { ProductionService } from 'app/services/production.service/production.service';
import { map } from 'rxjs/operators';
import { MediaService } from 'app/services/media.service';
import { ReplaySubject, of } from 'rxjs';
import { TRegion } from '@shared/shipping/interfaces';
import { SHIPPING_METHOD_FAST } from '@shared/shipping/constants';
import { REQUIRED_ERROR } from 'app/validators.constants';
import { PRODUCTION_TIME_ZONE } from '@shared/production/constants';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';

@Component({
  selector: 'app-form-payment',
  templateUrl: './form-payment.component.html',
  styleUrls: ['./form-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideY()],
})
export class FormPaymentComponent implements OnInit, OnDestroy {
  @Input() isGift?: boolean;

  @Input() set region(r: TRegion) {
    this.region$.next(r);
  }

  /** `undefined` when invalid */
  @Input() set data(data: IFormPaymentData | undefined) {
    if (!isEqual(data, this.data)) {
      if (data) {
        this.typeFormControl.setValue(data.type);
        this.formGroup.setValue(data);
      }

      this.dataChange.emit(data);
    }
  }

  get data() {
    return this.formGroup.value;
  }

  @Output() dataChange = new EventEmitter<IFormPaymentData | undefined>();

  @Input() set touchEventEmitter(e: EventEmitter<void>) {
    e.subscribe(() => {
      this.formGroup.markAllAsTouched();
    });
  }

  @Input() submitButtonDisabled: boolean = false;

  @Output() submitButtonClick = new EventEmitter<void>();

  public typeFormControl = new FormControl(EFormPaymentType.card, [
    Validators.required,
  ]);

  public cardFormGroup = new FormGroup({
    type: this.typeFormControl,
    terms: new FormControl(false, [Validators.requiredTrue]),
  });

  public readonly EFormPaymentType = EFormPaymentType;

  public readonly region$ = new ReplaySubject<TRegion>(1);

  public readonly expectedArrivalDate$ = this._productionService
    .getExpectedArrivalDate$(
      of(SHIPPING_METHOD_FAST),
      this.region$.pipe(map((r) => r.name)),
    )
    .pipe(
      map(([date]) =>
        formatInTimeZone(date, PRODUCTION_TIME_ZONE, 'yyyy/MM/dd'),
      ),
    );

  public readonly endOfCycleDate$ =
    this._productionService.endOfCycleDate$.pipe(
      map((date) => formatInTimeZone(date, PRODUCTION_TIME_ZONE, 'yyyy/MM/dd')),
    );

  public readonly nextExpectedArrivalDate$ = this._productionService
    .getExpectedArrivalDate$(
      of(SHIPPING_METHOD_FAST),
      this.region$.pipe(map((r) => r.name)),
      this._productionService.nextExpectedShippingDate$,
    )
    .pipe(
      map(([date]) =>
        formatInTimeZone(date, PRODUCTION_TIME_ZONE, 'yyyy/MM/dd'),
      ),
    );

  private readonly _sub = new Subscriber();

  get formGroup(): FormGroup {
    return this.cardFormGroup;
  }

  public readonly REQUIRED_ERROR = REQUIRED_ERROR;

  constructor(
    public mediaService: MediaService,
    private _productionService: ProductionService,
  ) {}

  ngOnInit(): void {
    this._sub.push(
      this.cardFormGroup.valueChanges.subscribe((data: IFormPaymentData) => {
        this._onDataChange(data);
      }),
    );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  private _onDataChange(data: IFormPaymentData) {
    if (this.formGroup.valid) {
      this.dataChange.emit(data);
    } else {
      this.dataChange.emit(undefined);
    }
  }
}
