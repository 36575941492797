import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IBookPrice } from 'src/app/data/books/prices/types';
import { Specs } from 'src/app/interfaces';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultComponent {
  @Input() id: string;

  @Input() title: string;

  @Input() specs: Specs;

  @Input() price: IBookPrice;

  @Input() photo: string;

  @Input() discount?: string;

  @Output() close = new EventEmitter<void>();

  get specsKeys(): string[] {
    return Object.keys(this.specs);
  }

  constructor() {}

  public onClose() {
    this.close.emit();
  }
}
