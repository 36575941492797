<div
  [ngClass]="containerClass"
  (click)="toggle()"
>
  <div class="container__caption">
    <h4 class="caption">{{ caption | translate }}</h4>
    <i
      class="arrow"
      [ngClass]="{
        'arrow_top': shown | async
      }"
    ></i>
  </div>
  <div
    *ngIf="shown | async"
    class="container__description"
    @slideY
  >
    <p class="description" [innerHTML]="description"></p>
  </div>
</div>
