<div
  class="container"
  [ngClass]="{
    container_active: active
  }"
  (click)="onRadioCardClick()"
>
  <div
    class="container__photo"
    [ngClass]="['container__photo_' + imageSize]"
  >
    <img
      [src]="imgSrc"
      alt="#"
    />
  </div>
  <div class="container__content">
    <div class="content">
      <div class="content__header">
        <div class="header">
          <div class="header__caption">
            <h5 class="caption">{{ caption }}</h5>
          </div>
          <div class="header__content">
            <div class="header-content">
              <div
                *ngIf="!(isLtSm | async)"
                class="header-content__price"
              >
                <ng-container *ngTemplateOutlet="priceTemplate"></ng-container>
              </div>
              <div class="header-content__icon">
                <svg-icon
                  src="assets/images/icons/radio-card-tick.svg"
                  class="icon"
                  [ngClass]="{
                    icon_active: active
                  }"
                  [applyClass]="true"
                ></svg-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content__summary">
        <p
          class="summary"
          [innerHTML]="summary"
        ></p>
      </div>
      <div
        *ngIf="isLtSm | async"
        class="content__price"
      >
        <ng-container *ngTemplateOutlet="priceTemplate"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #priceTemplate>
  <app-price
    *ngIf="price"
    [currency]="price.currency"
    [price]="price.value"
    [discount]="discount"
    [shippingIncluded]="price.shippingIncluded"
    size="xs"
  ></app-price>
</ng-template>
