import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionFooterComponent } from './section-footer.component';
import { LogoModule } from 'src/app/components/logo/logo.module';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { RouterModule } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [SectionFooterComponent, MenuItemComponent],
  imports: [CommonModule, LogoModule, RouterModule, AngularSvgIconModule],
  exports: [SectionFooterComponent],
})
export class SectionFooterModule {}
