import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TBookCover, TBookWrapping } from '@shared/book/interfaces';
import { IBook } from 'app/data/books/types';
import { TranslateService } from '@ngx-translate/core';
import type { IBookPrice } from 'src/app/data/books/prices/types';
import { getBookPriceData } from 'src/app/data/books/prices/utils';
import { Logger } from '@shared/logger';
import capitalize from 'lodash/capitalize';

const log = new Logger('SectionGiftCartComponent');

@Component({
  selector: 'app-section-gift-cart',
  templateUrl: './section-gift-cart.component.html',
  styleUrls: ['./section-gift-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionGiftCartComponent {
  @Input() book?: IBook;

  @Input() cover!: TBookCover;

  @Input() wrapping!: TBookWrapping;

  get description(): string {
    const {
      book,
      cover,
      // wrapping,
      _translate,
    } = this;

    return book
      ? `“${book.caption}” Gift Code<br>${capitalize(
          cover,
        )} Cover・Shipping included`
      : 'No product has been selected yet.';
  }

  get price(): IBookPrice {
    const { book, cover } = this;

    const bookPrice = book?.prices.find(([_cover]) => _cover === cover)?.[1];

    if (!bookPrice) {
      log.error(
        'description => bookPrice not found by',
        { cover },
        'in',
        book?.prices,
      );
      return getBookPriceData(0);
    }

    return bookPrice;
  }

  constructor(private _translate: TranslateService) {}
}
