import type { BookWelcomeToTheWoldData, CommonBookData } from '@shared/models';
import type {
  TClothColor,
  THairColor,
  THairStyle,
  TLanguage,
  TParents,
  TSkinColor,
} from '@shared/book/interfaces';
import { MONTH_INDEX_TO_TIME_OF_THE_YEAR_MAP } from '@shared/book/maps';
import {
  BOOK_ALIAS_WELCOME_TO_THE_WOLD,
  INPUT_VALUE_COVER_IMAGE_A,
} from '@shared/book/constants';
import type { IBookCreate } from 'src/app/services/book-create/book-create';
import firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;
import capitalize from 'lodash/capitalize';

export class WelcomeToTheWoldBookCreate
  implements IBookCreate<BookWelcomeToTheWoldData>
{
  createNewBook(
    uid: string,
    docId: string,
    heroName: string,
    birthDate: Date,
    hairColor: THairColor,
    p1hairColor: THairColor,
    p2hairColor: THairColor,
    frekles: boolean,
    skinColor: TSkinColor,
    p1skinColor: TSkinColor,
    p2skinColor: TSkinColor,
    hairStyle: THairStyle,
    clothColor: TClothColor,
    language: TLanguage,
    parents: TParents,
    serverTimestamp: any,
    common: Partial<CommonBookData> = {},
  ): BookWelcomeToTheWoldData {
    const newBook: BookWelcomeToTheWoldData = this.createEmptyBook();

    heroName = language === 'en' ? capitalize(heroName) : heroName;

    newBook.uid = uid;
    newBook.bookId = docId;
    newBook.heroName = heroName;
    newBook.birthDate = Timestamp.fromDate(birthDate);
    newBook.birthDateTimeZone =
      Intl.DateTimeFormat().resolvedOptions().timeZone;
    newBook.hairColor = hairColor;
    newBook.p1hairColor = p1hairColor;
    newBook.p2hairColor = p2hairColor;
    newBook.frekles = frekles;
    newBook.skinColor = skinColor;
    newBook.p1skinColor = p1skinColor;
    newBook.p2skinColor = p2skinColor;
    newBook.hairStyle = hairStyle;
    newBook.clothColor = clothColor;
    newBook.language = language;
    newBook.parents = parents;
    newBook.createTimeStamp = serverTimestamp;
    newBook.steps.character.isDone = true;

    // newBook.messageText =
    //   `${heroName}へ\n` +
    //   `\n` +
    //   `このせかいは　ふしぎで　すてきな\n` +
    //   `まほうで　いっぱいです。\n` +
    //   `${heroName}\n` +
    //   `たくさん　であえますように。\n` +
    //   `ここにきてくれて　どうもありがとう。`;

    if (language == 'jp') {
      newBook.messageText =
        `${heroName}へ\n` +
        `\n` +
        `このせかいは　ふしぎで　すてきな\n` +
        `まほうで　いっぱいです。\n` +
        `${heroName}が　このせかいの　まほうに\n` +
        `たくさん　であえますように。\n` +
        `ここにきてくれて　どうもありがとう。`;
    } else {
      if (
        parents == 'parents1' ||
        parents == 'parents2' ||
        parents == 'parents3'
      ) {
        newBook.messageText =
          `Dear ${heroName},\n` +
          `\n` +
          `This world is filled with many wonderful and good things.\n` +
          `We hope you will encounter many of them.\n` +
          `It’s wonderful to meet you.\n`;
      } else {
        newBook.messageText =
          `Dear ${heroName},\n` +
          `\n` +
          `This world is filled with many wonderful and good things.\n` +
          `I hope you will encounter many of them.\n` +
          `It’s wonderful to meet you.\n`;
      }
    }

    newBook.timeOfTheYear =
      MONTH_INDEX_TO_TIME_OF_THE_YEAR_MAP[birthDate.getMonth()];

    Object.assign(newBook, common);

    return newBook;
  }

  createEmptyBook(): BookWelcomeToTheWoldData {
    return {
      birthTime: null,
      steps: {
        coverImage: {
          stepType: 'coverImage',
          isDone: true,
        },
        character: {
          stepType: 'character',
          isDone: false,
        },
        parents: {
          stepType: 'parents',
          isDone: false,
        },
        message: {
          stepType: 'message',
          isDone: false,
        },
        birthTime: {
          stepType: 'birthTime',
          isDone: false,
        },
        photo: {
          stepType: 'photo',
          isDone: false,
        },
      },
      alias: BOOK_ALIAS_WELCOME_TO_THE_WOLD,
      hairColor: undefined,
      p1hairColor: undefined,
      p2hairColor: undefined,
      frekles: undefined,
      skinColor: undefined,
      p1skinColor: undefined,
      p2skinColor: undefined,
      hairStyle: undefined,
      clothColor: undefined,
      language: undefined,
      parents: undefined,
      createTimeStamp: undefined,
      uid: undefined,
      bookId: undefined,
      heroName: undefined,
      birthDate: null,
      bornTime: 'noinput',
      messageText: '',
      timeOfTheYear: undefined,
      fileUploadStatus: 0,
      cover: 'soft',
      coverImage: INPUT_VALUE_COVER_IMAGE_A,
      wrapping: 'standart',
    };
  }
}
